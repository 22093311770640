/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { THUNK_STATUS } from '../../../constants';

interface Props {
  unpublishMode?: boolean;
  prefixModalName?: string;
  handlePublish: () => void;
}

const MODAL_NAME = 'PublishAdvertisementModal';

function PublishAdvertisement({ handlePublish, unpublishMode, prefixModalName }: Props) {
  const dispatch = useAppDispatch();
  const {
    loading: { form: status },
    modalName,
  } = useAppSelector((state) => state.marketing);
  const showModal = useAppSelector((state) => state.modal.visible === `${prefixModalName}${MODAL_NAME}`);
  const handleCloseModal = () => dispatch(modalActions.closeModal());

  useEffect(() => {
    if (
      status === THUNK_STATUS.SUCCEEDED &&
      (modalName === 'createMarketing' ||
        modalName === 'editMarketing' ||
        modalName === 'deleteMarketing')
    ) {
      handleCloseModal();
    }
  }, [status]);
  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h4 className="mb-4">{`${unpublishMode ? 'Unpublish' : 'Publish'} Advertisment?`}</h4>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 ps-0">
              <button
                type="button"
                id="closeModal_publishWeb"
                className="btn btn-warning w-100"
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  <div
                    onClick={() => handlePublish()}
                    role="button"
                    style={{ width: '100%', boxSizing: 'border-box' }}
                  >
                    <i className="fa fa-check" />
                    {unpublishMode ? 'Unpublish' : 'Publish'}
                  </div>
                )}
              </button>
            </div>
            <div className="col-6 px-1 pe-0">
              <button
                type="button"
                className="btn btn-outline-dark w-100"
                onClick={handleCloseModal}
              >
                <i className="fa fa-times" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

PublishAdvertisement.defaultProps = {
  unpublishMode: false,
  prefixModalName: '',
};

export default PublishAdvertisement;
