/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { resetPasswordAppUser } from '../../../redux/thunks/appUsersThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import Input from '../../../components/input';

function AppUserResetPassword() {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.appUsers);

  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (status === 'succeeded' && modalName === 'resetPassword') {
      hideModal({ modalId: '#closeModal_appUserResetPassword' });
    }
  }, [modalOpen]);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="reset_password_to_email"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              data-bs-target="#user_settings"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5">
            <div className="text-center font-light">
              <h4 className="mb-4">Reset current password</h4>
            </div>

            <form>
              <div className="mb-3">
                <Input
                  type="email"
                  id="email"
                  aria-describedby="email"
                  placeholder="Email"
                  onValueChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <div className="mb-3">
                    <button
                      type="button"
                      id="closeModal_appUserResetPassword"
                      className="btn btn-primary w-100"
                      disabled={status === 'loading'}
                    >
                      {status === 'loading' ? (
                        <span
                          className="spinner-border"
                          style={{ width: '1em', height: '1em' }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      ) : (
                        <div
                          role="button"
                          style={{ width: '100%', boxSizing: 'border-box' }}
                          onClick={() => {
                            dispatch(resetPasswordAppUser(email));
                          }}
                        >
                          <i className="fa fa-check" />
                          Reset
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#user_settings"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppUserResetPassword;
