/* eslint-disable  @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import AddNewAdminUser from './modals/addAdminUser';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AdminUser from './adminUser';
import { fetchAdminUsers } from '../../redux/thunks/adminUsersThunk';
import EditAdminUser from './modals/editAdminUser';
import DeleteAdminUser from './modals/deleteAdminUser';
import { handlePagination, setParamValue } from '../../helpers';
import { ROLES } from '../../constants/index';

export interface NewUser {
  name: string;
  email: string;
  userType: ROLES | string;
}
interface EditUserProps {
  id: string;
  name: string;
  email: string;
  role: string;
  userType: ROLES;
}

const BASE_URL = '/api/users/admin-users';

function AdminUsers() {
  const dispatch = useAppDispatch();

  // setting local state
  const [newUser, setNewUser] = useState<NewUser>({
    name: '',
    email: '',
    userType: '',
  });

  const [userToEdit, setUserToEdit] = useState<EditUserProps>({
    id: '',
    name: '',
    email: '',
    role: '',
    userType: ROLES.USER,
  });
  const [userToDelete, setUserToDelete] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [numberOfRows, setNumberOfRows] = useState(10);
  const [searchInput, setSearchInput] = useState('');

  // redux store
  const status = useAppSelector((state) => state.adminUsers.status);
  const users = useAppSelector((state) => state.adminUsers.adminUsers);

  // actions
  const handleEditUser = (user: EditUserProps) => setUserToEdit(user);
  const handleDeleteUser = (userId: string) => setUserToDelete(userId);

  // Pagination
  const totalAdminUsers = useAppSelector((state) => state.adminUsers.totalItems);

  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.adminUsers.pagination,
  );
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'search', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  // fetching data
  useEffect(() => {
    if (
      Math.ceil(totalAdminUsers / numberOfRows) !== 0 &&
      Math.ceil(totalAdminUsers / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(fetchAdminUsers(currentUrl));
  }, [currentUrl, totalAdminUsers]);

  return (
    <>
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
        <div className="content_decor" />
        <div className="row pb-3 pb-md-4 align-items-center">
          <div className="col-12 col-sm-6">
            <h1 className="headline-decor">Admin Users</h1>
          </div>
          <div className="col-12 col-sm-6 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary px-md-3 px-lg-5"
              data-bs-toggle="modal"
              data-bs-target="#add_new_admin_user"
            >
              <i className="fa fa-plus" />
              Add New User
            </button>
          </div>
        </div>

        <div className="container-fluid content-inner">
          <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <Search value={searchInput} onInputChange={handleFilterUsers} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 p-0">
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex align-items-center col-3">
                          Name
                          <button type="button" className="btn-icon d-inline color-gray-600 ms-2">
                            <i className="fa-regular fa-angle-down" />
                          </button>
                        </div>
                      </th>
                      <th className="col-3">Email</th>
                      <th className="col-3">User level</th>
                      <th className="col-3">Actions</th>
                    </tr>
                  </thead>
                  {status === 'loading' ? (
                    <tbody>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>{' '}
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>{' '}
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {users &&
                        users?.map((user) => (
                          <AdminUser
                            key={user.id}
                            id={user.id}
                            name={user.name}
                            email={user.email}
                            userType={user.userType}
                            role={user.roles[0]}
                            handleEdit={handleEditUser}
                            handleDelete={handleDeleteUser}
                          />
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
              </div>
            </div>
            <div className="col-6 pe-0">
              <div className="d-flex justify-content-end">
                <Pagination
                  onPageChange={handlePageChange}
                  totalCount={totalAdminUsers || 0}
                  currentPage={currentPage}
                  pageSize={numberOfRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewAdminUser />
      <EditAdminUser userToEdit={userToEdit} />
      <DeleteAdminUser id={userToDelete} status={status} />
    </>
  );
}
export default AdminUsers;
