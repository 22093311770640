/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from 'classnames';
import { PAGES } from '../constants';
import { usePagination, DOTS } from '../customHooks/usePagination';

interface Props {
  onPageChange: (pageNumber: number, page: string) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
}
function Pagination(props: Props) {
  const { onPageChange, totalCount, siblingCount, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1, PAGES.NEXT);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1, PAGES.PREVIOUS);
  };

  const onFirst = () => {
    onPageChange(1, PAGES.FIRST);
  };

  const onLast = () => {
    onPageChange(lastPage, PAGES.LAST);
  };

  const onPageNumber = (pageNumber: number) => {
    onPageChange(pageNumber, PAGES.PAGE_NUMBER);
  };
  return (
    <ul className="pagination-container">
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onFirst}
      >
        <i className="fa fa-chevrons-left" />
      </li>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <i className="fa-solid fa-angle-left" />
      </li>
      {paginationRange.map((pageNumber: number) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={pageNumber}>
              &#8230;
            </li>
          );
        }
        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageNumber(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <i className="fa-solid fa-angle-right" />
      </li>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onLast}
      >
        <i className="fa fa-chevrons-right" />
      </li>
    </ul>
  );
}

Pagination.defaultProps = {
  siblingCount: 1,
};
export default Pagination;
