import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { toast } from '../../helpers/toaster';
import { AdminUsersState } from '../interface/adminUserInterface';
import {
  fetchAdminUsers,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
} from '../thunks/adminUsersThunk';

const initialState: AdminUsersState = {
  adminUsers: [],
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const adminUserSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAdminUsers.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchAdminUsers.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        pagination: action.payload.pagination,
        totalItems: action.payload.totalItems,
        adminUsers: action.payload.users,
      }))
      .addCase(fetchAdminUsers.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(createAdminUser.pending, (state) => ({
        ...state,
        modalName: 'addNewAdminUser',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(createAdminUser.fulfilled, (state, action) => {
        toast('Created admin user successfully', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          adminUsers: [...state.adminUsers, action.payload],
          totalItems: state.totalItems + 1,
          modalOpen: false,
        };
      })
      .addCase(createAdminUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error creating admin user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(updateAdminUser.pending, (state) => ({
        ...state,
        modalName: 'editAdminUser',
        modalOpen: true,
        status: 'loading',
      }))
      .addCase(updateAdminUser.fulfilled, (state, action) => {
        toast('Edited admin user successfully', 'success');
        const indexToUpdate = findIndex(state.adminUsers, (user) => user.id === action.payload.id);
        const newUpdatedAdminUser = [...state.adminUsers];
        newUpdatedAdminUser[indexToUpdate] = action.payload;

        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          adminUsers: newUpdatedAdminUser,
          modalOpen: false,
        };
      })
      .addCase(updateAdminUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error editing admin user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteAdminUser.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteAdminUser.fulfilled, (state, action) => {
        toast('Deleted admin user successfully', 'success');
        return {
          ...state,
          status: 'succceeded',
          error: undefined,
          adminUsers: state.adminUsers.filter((user) => user.id !== action.payload.userId),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(deleteAdminUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error deleting admin user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default adminUserSlice.reducer;
