import React from 'react';
import { Routes, Route, useLocation, matchRoutes } from 'react-router-dom';
import { useAuth } from './redux/authLayer';
import { LoginAreaUrls, URL_ROUTES } from './constants/routes';
import LoginArea from './loginArea';
import AuthenticatedApp from './authenticatedApp';
import UserApp from './userApp';
import Checkout from './containers/userSettings/checkout';
import ServicesLayer from './redux/servicesLayer';
import SharePlayer from './containers/sharePlayer';
import CustomCheckout from './containers/userSettings/customCheckout';
import SubCancellation from './containers/subCancellation';
import ThankYouPage from './containers/thank-you';

declare global {
  interface Window {
    rewardful: any;
    Rewardful: any;
  }
}

function App() {
  const { token, isEndUser, isSuperuser } = useAuth();
  const location = useLocation();

  const matchedRoutes = matchRoutes([{ path: URL_ROUTES.SharePlay }], location);
  const sessionToken = matchedRoutes?.[0]?.params?.sessionToken;

  if (matchedRoutes && sessionToken) {
    return <SharePlayer sessionToken={sessionToken} />;
  }

  if (LoginAreaUrls.includes(location.pathname as URL_ROUTES) || !token) {
    return <LoginArea />;
  }

  if (location.pathname.slice(0, 20) === URL_ROUTES.CancelSub.slice(0, 20)) {
    return <SubCancellation />;
  }

  if (location.pathname.slice(0, 20) === URL_ROUTES.ThankYou.slice(0, 20)) {
    return <ThankYouPage />;
  }

  if (isEndUser) {
    if (location.pathname.slice(0, 9) === URL_ROUTES.Checkout.slice(0, 9)) {
      return (
        <ServicesLayer>
          <Routes>
            <Route path={URL_ROUTES.Checkout} element={<Checkout />} />
            <Route path={URL_ROUTES.CustomCheckOutPage} element={<CustomCheckout />} />
          </Routes>
        </ServicesLayer>
      );
    }
    return <UserApp />;
  }

  if (isSuperuser) {
    if (location.pathname.slice(0, 9) === URL_ROUTES.Checkout.slice(0, 9)) {
      return (
        <ServicesLayer>
          <Routes>
            <Route path={URL_ROUTES.CustomCheckOutPage} element={<CustomCheckout />} />
          </Routes>
        </ServicesLayer>
      );
    }
    return <AuthenticatedApp />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <span className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </span>
    </div>
  );
}

export default App;
