import React, { useEffect } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import { gapi } from 'gapi-script';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { userOAuth } from '../redux/thunks/currentUserThunk';
import { OAuthType } from '../redux/interface/currentUserInterface';
import { useAuth } from '../redux/authLayer';
import { toast } from '../helpers/toaster';
import { THUNK_STATUS } from '../constants';
import { pushWithDataLayer } from '../@types/global';
import { URL_ROUTES } from '../constants/routes';

const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID || '';
const appleAppId = process.env.REACT_APP_APPLE_APP_ID || '';
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';

interface Props {
  signUp: boolean;
}
type LocationState = {
  from: string;
};

function OAuthSignIn({ signUp }: Props) {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const navigate = useNavigate();
  const { status } = useAppSelector((state) => state.currentUser.oauth);
  const lmData = signUp && auth.linkMinkRefData ? auth.linkMinkRefData : undefined;

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED && signUp) {
      if (locationState?.from === 'sharedPlayer') {
        pushWithDataLayer({
          event: 'sign_up_from_shared_player',
          formType: 'Sign up',
          source: 'From Shared Player',
        });
      }
      navigate(URL_ROUTES.Home);
    }
  }, [status]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleAppId,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const onFBOauthResponse = ({ accessToken }: ReactFacebookLoginInfo) => {
    dispatch(
      userOAuth({
        type: OAuthType.Facebook,
        access_token: accessToken,
        lmData,
      }),
    );
  };

  const onGoogleOauthResponse = (response: CredentialResponse) => {
    if ('credential' in response) {
      dispatch(
        userOAuth({
          type: OAuthType.Google,
          access_token: response.credential || '',
          lmData,
        }),
      );
    }
  };

  const onAppleOauthResponse = (response: { [any: string]: any }) => {
    if ('authorization' in response) {
      const { authorization } = response;
      dispatch(
        userOAuth({
          type: OAuthType.Apple,
          access_token: authorization?.code,
          lmData,
        }),
      );
    }
  };

  return (
    <>
      <div className="mb-4 text-center">Sign in using:</div>
      <div className="mb-5 d-flex justify-content-center align-items-center">
        <div style={{ marginRight: '24px' }}>
          <GoogleLogin
            type="icon"
            shape="circle"
            theme="outline"
            size="large"
            width="58px"
            onSuccess={(credentialResponse) => {
              onGoogleOauthResponse(credentialResponse);
            }}
            onError={() => {
              toast('Unable to login using Google account.', 'error');
            }}
          />
        </div>
        <AppleSignin
          authOptions={{
            clientId: appleAppId,
            scope: 'name email',
            redirectURI: `${window.location.origin}`,
            usePopup: true,
          }}
          onSuccess={onAppleOauthResponse}
          render={(renderProps: any) => (
            <button
              type="button"
              className="btn-icon btn-icon-round bg-color-gray-200 me-4"
              onClick={renderProps.onClick}
              disabled={renderProps.isDisabled}
            >
              <i className="fa-brands fa-apple" />
            </button>
          )}
        />
        <FacebookLogin
          appId={facebookAppId}
          fields="name,email,picture"
          scope="public_profile"
          render={(renderProps: any) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.isDisabled}
              type="button"
              className="btn-icon btn-icon-round bg-color-gray-200"
            >
              <i className="fa-brands fa-facebook" />
            </button>
          )}
          callback={onFBOauthResponse}
        />
      </div>
    </>
  );
}

export default OAuthSignIn;
