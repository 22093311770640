/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants';
import { HomeState } from '../interface/homeInterface';
import {
  getRecommendationsHome,
  getAllAchievementsHome,
  getAllAchievements,
  sevenDayCoursesGet,
  sevenDayCoursesSearch,
  getUserSessionsHistory,
} from '../thunks/homeThunk';

const initialState: HomeState = {
  recommendedCoursesHomeInfo: [],
  recommendedDeepPrimers: [],
  recommendedPowerPrimers: [],
  achievementsInfoHome: [],
  achievementsInfo: [],
  sessionsHistory: [],
  coursesSeven: {
    list: [],
    search: {
      courses: [],
      totalCourses: 0,
      status: undefined,
      error: undefined,
    },
  },
  totalCoursesSeven: 0,
  loading: {
    recommendations: undefined,
    achievements: undefined,
    achievementsInfo: undefined,
    sessionsHistory: undefined,
    history: undefined,
    sevenCourses: undefined,
  },
  errors: {
    recommendations: undefined,
    sessionsHistory: undefined,
    achievements: undefined,
    achievementsInfo: undefined,
    history: undefined,
    sevenCourses: undefined,
  },
};

export const courseSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRecommendationsHome.pending, (state) => {
        state.loading = { ...state.loading, recommendations: THUNK_STATUS.LOADING };
        state.errors = { ...state.errors, recommendations: undefined };
      })
      .addCase(getRecommendationsHome.fulfilled, (state, action) => {
        state.loading = { ...state.loading, recommendations: THUNK_STATUS.SUCCEEDED };
        state.errors = { ...state.errors, recommendations: undefined };
        const { courses, deep_primers: deepPrimers, power_primers: powerPrimers } = action.payload;
        state.recommendedCoursesHomeInfo = courses;
        state.recommendedDeepPrimers = deepPrimers;
        state.recommendedPowerPrimers = powerPrimers;
      })
      .addCase(getRecommendationsHome.rejected, (state, action) => {
        state.loading = { ...state.loading, recommendations: THUNK_STATUS.REJECTED };
        state.errors = { ...state.errors, recommendations: action.error.message };
      })
      .addCase(getAllAchievementsHome.pending, (state) => {
        state.loading = { ...state.loading, achievements: THUNK_STATUS.LOADING };
        state.errors = { ...state.errors, achievements: undefined };
      })
      .addCase(getAllAchievementsHome.fulfilled, (state, action) => {
        state.loading = { ...state.loading, achievements: THUNK_STATUS.SUCCEEDED };
        state.errors = { ...state.errors, achievements: undefined };
        state.achievementsInfoHome = action.payload['hydra:member'];
      })
      .addCase(getAllAchievementsHome.rejected, (state, action) => {
        state.loading = { ...state.loading, achievements: THUNK_STATUS.REJECTED };
        state.errors = { ...state.errors, achievements: action.error.message };
      })
      .addCase(getAllAchievements.pending, (state) => {
        state.loading = { ...state.loading, achievementsInfo: THUNK_STATUS.LOADING };
        state.errors = { ...state.errors, achievementsInfo: undefined };
      })
      .addCase(getAllAchievements.fulfilled, (state, action) => {
        state.loading = { ...state.loading, achievementsInfo: THUNK_STATUS.SUCCEEDED };
        state.errors = { ...state.errors, achievementsInfo: undefined };
        state.achievementsInfo = action.payload.achievements;
      })
      .addCase(getAllAchievements.rejected, (state, action) => {
        state.loading = { ...state.loading, achievementsInfo: THUNK_STATUS.REJECTED };
        state.errors = { ...state.errors, achievementsInfo: action.error.message };
      })
      .addCase(getUserSessionsHistory.pending, (state) => {
        state.loading = { ...state.loading, sessionsHistory: THUNK_STATUS.LOADING };
        state.errors = { ...state.errors, history: undefined };
      })
      .addCase(getUserSessionsHistory.fulfilled, (state, action) => {
        state.loading = { ...state.loading, sessionsHistory: THUNK_STATUS.SUCCEEDED };
        state.errors = { ...state.errors, history: undefined };
        state.sessionsHistory = action.payload['hydra:member'];
      })
      .addCase(getUserSessionsHistory.rejected, (state, action) => {
        state.loading = { ...state.loading, sessionsHistory: THUNK_STATUS.REJECTED };
        state.errors = { ...state.errors, history: action.error.message };
      })
      .addCase(sevenDayCoursesGet.rejected, (state, action) => {
        state.loading = { ...state.loading, sevenCourses: THUNK_STATUS.REJECTED };
        state.errors = { ...state.errors, sevenCourses: action.error.message };
      })
      .addCase(sevenDayCoursesGet.fulfilled, (state, action) => {
        state.loading = { ...state.loading, sevenCourses: THUNK_STATUS.SUCCEEDED };
        state.errors = { ...state.errors, sevenCourses: undefined };
        state.coursesSeven = { ...state.coursesSeven, list: action.payload.array };
        state.totalCoursesSeven = action.payload.totalItems;
      })
      .addCase(sevenDayCoursesSearch.pending, (state) => {
        state.coursesSeven.search.status = THUNK_STATUS.LOADING;
        state.coursesSeven.search.error = undefined;
      })
      .addCase(sevenDayCoursesSearch.rejected, (state, action) => {
        state.coursesSeven.search.status = THUNK_STATUS.REJECTED;
        state.coursesSeven.search.error = action.error.message;
      })
      .addCase(sevenDayCoursesSearch.fulfilled, (state, action) => {
        state.coursesSeven.search = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          courses: action.payload,
          totalCourses: action.payload.length,
        };
      });
  },
});

export default courseSlice.reducer;
