import React, { useState, useCallback, ChangeEvent } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Search from './search';

interface HeadlineProps {
  className?: string;
  caption: string;
  midControl?: React.ReactElement | null;
  onChangeKeyword?: any;
  currentChannelId?: number;
}

function Headline({
  caption,
  className,
  midControl,
  onChangeKeyword,
  currentChannelId,
}: HeadlineProps) {
  const [searchKeyword, setSearchKeyword] = useState('');

  const debouncedSearchTrigger = debounce((value: string, channelId?: number) => {
    if (onChangeKeyword) {
      onChangeKeyword(value, channelId);
    }
  }, 500);

  const handleChangeKeyword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(e.target.value);
      debouncedSearchTrigger(e.target.value, currentChannelId);
    },
    [currentChannelId],
  );

  return (
    <div className={classNames('row', 'align-items-center', className)}>
      <div className="col-12 col-sm-6">
        <h1 className="headline-decor text-truncate">{caption}</h1>
      </div>
      <div className="col-12 col-sm-6 d-flex justify-content-end">
        {midControl}
        <Search
          className="search--light"
          value={searchKeyword}
          onInputChange={handleChangeKeyword}
        />
      </div>
    </div>
  );
}

Headline.defaultProps = {
  className: '',
  midControl: null,
  onChangeKeyword: null,
  currentChannelId: null,
};

export default Headline;
