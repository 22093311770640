import React, { useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useAppDispatch } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import AddCourseToChannel from '../../../containers/channels/modals/addCourseToChannel';
import { swapElement } from '../../../helpers';

interface SelectCoursesProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
}

function SelectCourses({ field, form, label }: SelectCoursesProps) {
  const dispatch = useAppDispatch();

  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;

  const [data, setData] = useState(value ? [...value] : []);

  useEffect(() => {
    setData(value);
  }, [value]);

  const reArrangeList = (action: string, courseId: string) => {
    const courses = [...data];
    const itemIndex = courses.findIndex((item: any) => item.id === courseId);
    const nextIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;
    const orderedCourses = swapElement(courses, itemIndex, nextIndex).map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setData(orderedCourses);
    setFieldValue(name, orderedCourses);
  };

  const deleteCourseFromList = (id: number) => {
    const newData = data.filter((courseItem: any) => courseItem.id !== id);
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const addCourseToList = (selectedCourse: any) => {
    const newData = [...data, selectedCourse];
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const handleOpenAddCourseModal = () => {
    dispatch(modalActions.setVisibleModal('AddCourseToChannelModal'));
  };

  return (
    <>
      <h6 className="text-center">{label || 'Upgrades'}</h6>
      <ul className="list-group list-group--channels mb-2">
        {data?.map((sc: any, index: number) => (
          <li className="list-group-item" key={sc.id}>
            <span className="order-icons">
              <i
                className={`fa-solid fa-arrow-up ${index === 0 ? 'disabled' : ''}`}
                role="button"
                tabIndex={-1}
                aria-label={`Move up ${sc.title}`}
                onKeyUp={() => (index === 0 ? {} : reArrangeList('up', sc.id))}
                onClick={() => (index === 0 ? {} : reArrangeList('up', sc.id))}
              />
              <i
                className={`fa-solid fa-arrow-down ${index === data.length - 1 ? 'disabled' : ''}`}
                role="button"
                tabIndex={-1}
                aria-label={`Move down ${sc.title}`}
                onKeyUp={() => (index === data.length - 1 ? {} : reArrangeList('down', sc.id))}
                onClick={() => (index === data.length - 1 ? {} : reArrangeList('down', sc.id))}
              />
            </span>
            <span>{sc?.title}</span>
            <i
              className="fa fa-times"
              role="button"
              tabIndex={-1}
              aria-label={`Remove ${sc?.title}`}
              onKeyUp={() => deleteCourseFromList(sc.id)}
              onClick={() => deleteCourseFromList(sc.id)}
            />
          </li>
        ))}
      </ul>

      <button
        className="btn btn-primary w-100 radius-8"
        type="button"
        onClick={handleOpenAddCourseModal}
      >
        <i className="fa fa-plus" />
        &nbsp; Add Upgrades
      </button>
      <AddCourseToChannel selectedCourses={data} onSelectCourse={addCourseToList} />
    </>
  );
}

SelectCourses.defaultProps = {
  label: '',
};

export default SelectCourses;
