// import { Course } from './courseInterface';
import { PaginationInterface } from './paginationInterface';

export interface Pagination {
  current: string;
  first: string;
  last: string;
  next: string;
  previous: string;
}

export enum PrimerType {
  DEEP = 'deep-primer',
  POWER = 'power-primer',
}

export interface PageParameters {
  page: number;
  itemsPerPage: number;
  filters?: {
    [key: string]: string;
  };
}

export interface ChannelsSession {
  session: string;
  type: string;
}

export interface ChannelChannel {
  channelTarget: string;
  position: number;
}

export interface ChannelCourses {
  course: string;
  position: number;
}

export interface ChannelInterface {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  tags: string[];
  isSleep: boolean;
  isPublished: boolean;
  courses: string[];
  channels: string[];
  channelSessions?: ChannelsSession[] | undefined;
  deepPrimer: ChannelsSession[] | [];
  powerPrimer: ChannelsSession[] | [];
  file: any;
  media?: any;
  category: string;
}

export interface AddNewChannelInterface {
  id?: string;
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  tags: string[];
  isSleep: boolean;
  isPublished: boolean;
  powerPrimer: [];
  deepPrimer: [];
  courses:
    | {
        course: string;
        position: number;
      }[]
    | undefined;
  channels:
    | {
        channelTarget: string;
        position: number;
      }[]
    | undefined;
  file: any;
  channelSessions: ChannelsSession[];
  category: string;
}

export interface ChannelState {
  channelsArray: ChannelInterface[];
  channelsScroll: ChannelInterface[];
  channelsScrollPagination: PaginationInterface;
  primers: {
    status: string | undefined;
    error: string | undefined;
    deep: any[];
    power: any[];
    pagination: {
      deep: PaginationInterface;
      power: PaginationInterface;
    };
  };
  allCourses: any[];
  allCoursesPagination: PaginationInterface;
  pagination: Pagination;
  status: string | undefined;
  error: string | undefined;
  totalItems: number;
  modalOpen: boolean | undefined;
  modalName: string | undefined;
}

export interface ChannelCreate {}
