import React from 'react';

interface Props {
  label?: string;
  id: string;
  placeholder?: string;
  type: string;
  description?: string;
  value?: number | string | undefined;
  min?: string;
  onValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  endAdornment?: React.ReactElement | null;
}

function Input({
  label = 'Input Label',
  id,
  placeholder = 'Placeholder',
  type,
  value,
  min,
  description = 'description',
  endAdornment,
  onValueChange,
}: Props) {
  const renderInput = () => {
    const input = (
      <input
        type={type}
        className={`form-control${!endAdornment ? ' shadow' : ''}`}
        id={id}
        aria-describedby={description}
        placeholder={placeholder}
        value={value}
        onChange={onValueChange}
        min={min}
      />
    );
    return endAdornment ? (
      <div className="input-group radius-10">
        {input}
        <span className="input-group-text bg-color-gray-200" id="basic-addon2">
          {endAdornment}
        </span>
      </div>
    ) : (
      input
    );
  };

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={id} className="form-label">
          <b>{label}</b>
        </label>
      )}
      {renderInput()}
    </div>
  );
}

Input.defaultProps = {
  label: '',
  placeholder: '',
  description: 'description',
  onValueChange: null,
  value: undefined,
  min: '2',
  endAdornment: null,
};

export default Input;
