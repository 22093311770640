import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appStoreLink, googlePlayLink, THUNK_STATUS } from '../constants';
import AudioPlayer from '../components/audio/player';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { SessionInterface } from '../redux/interface/entitiesInterface';
import { getShareAudioSession, saveSessionUserPoint } from '../redux/thunks/playerThunk';
import { actions } from '../redux/slices/playerSlice';
import logo from '../assets/img/logo-purpule.svg';
import appStoreLogo from '../assets/img/app-store.png';
import googlePlayLogo from '../assets/img/google-play.png';

import { URL_ROUTES } from '../constants/routes';
import { mobileDetect } from '../utils/mobileDetect';

interface ISharePlayer {
  sessionToken: string;
}

function SharePlayer({ sessionToken }: ISharePlayer) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sharedSession: sharedSessionState } = useAppSelector((state) => state.player.loading);
  const sharedSession = useAppSelector((state) => state.player.sharedSession);

  const tracklist = useAppSelector((state) => state.player.tracklist);

  useEffect(() => {
    dispatch(getShareAudioSession({ token: sessionToken }));
  }, [sessionToken]);

  const handleSavePoint = (
    { currentTime }: { timeString: string; currentTime: number },
    narrator: number,
    currentSession: SessionInterface | undefined | null,
  ) => {
    if (!currentSession) {
      return;
    }
    const paramsToSend = {
      session: currentSession,
      status: 1,
      played: currentTime,
      narrator: currentSession.narrators[narrator],
    };
    dispatch(saveSessionUserPoint(paramsToSend));
  };

  const handleSessionEnded = React.useCallback(
    (narratorIdx: number, currentSession: SessionInterface | undefined | null) => {
      if (currentSession) {
        dispatch(actions.setNextTrackToPlayable());

        dispatch(
          saveSessionUserPoint({
            session: currentSession,
            status: 2,
            narrator: currentSession.narrators[narratorIdx],
          }),
        );
      }
      dispatch(actions.nextSession({ nextButtonPressed: false }));
    },
    [tracklist],
  );

  const handleGoToSignUp = () => {
    navigate(URL_ROUTES.SignUp, { state: { from: 'sharedPlayer' } });
  };

  return (
    <div
      className="p-5 d-flex flex-column h-100 justify-content-between"
      style={{ minHeight: '100vh' }}
    >
      <div>
        <div className="d-flex mb-5 justify-content-center">
          <img className="d-block img-fluid" src={logo} alt="Logo" />
        </div>

        {sharedSessionState === THUNK_STATUS.LOADING ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <span className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </span>
          </div>
        ) : null}

        {sharedSessionState === THUNK_STATUS.SUCCEEDED && sharedSession ? (
          <AudioPlayer
            session={sharedSession}
            onSessionEnded={handleSessionEnded}
            onUserListenProgress={handleSavePoint}
            courseMode
            disableNextSessionButton
            disableChangeNarratorButton
            disableShareButton
            disableFavoriteButton
            isShareLinkSession
          />
        ) : null}

        {sharedSessionState === THUNK_STATUS.REJECTED ? (
          <div className="d-flex justify-content-center pt-5">
            <p className="text-danger fw-bolder">Shared link has expired.</p>
          </div>
        ) : null}

        <div className="mt-5 d-flex justify-content-center">
          {mobileDetect.mobile() ? null : (
            <button
              type="button"
              className="btn btn-primary px-5 d-flex align-items-center"
              data-bs-dismiss="modal"
              id="shared-player-sign-up-btn"
              onClick={handleGoToSignUp}
            >
              <i className="fa-regular me-1 fa-bell" />
              Sign Up for Primed Mind
            </button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center flex-wrap mt-5">
        <a href={appStoreLink} className="m-3" target="_blank" rel="noreferrer" id="app-store-link">
          <img src={appStoreLogo} alt="AppStore" title="App Store" style={{ maxWidth: 200 }} />
        </a>
        <a
          href={googlePlayLink}
          className="m-3"
          target="_blank"
          rel="noreferrer"
          id="play-store-link"
        >
          <img
            src={googlePlayLogo}
            alt="GooglePlay"
            title="Google Play"
            style={{ maxWidth: 200 }}
          />
        </a>
      </div>
    </div>
  );
}

export default SharePlayer;
