import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { CheckoutPage } from '../interface/checkoutPageInterface.js';

export const fetchCheckoutPages = createAsyncThunk(
  'checkoutPage/fetchCheckoutPages',
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(url);
      const pages = response.data;

      const checkoutPages = response.data['hydra:member'].map(
        (checkoutPage: CheckoutPage & { '@id': string }) => ({
          ...checkoutPage,
          id: checkoutPage['@id'].slice(checkoutPage['@id'].lastIndexOf('/') + 1),
        }),
      );

      return {
        totalItems: response.data['hydra:totalItems'],
        checkoutPages,
        pagination: {
          current: pages?.['@id'],
          first: pages?.['hydra:first'],
          last: pages?.['hydra:last'],
          next: pages?.['hydra:next'],
          previous: pages?.['hydra:previous'],
        },
      };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const createCheckoutPage = createAsyncThunk(
  'checkoutPage/createCheckoutPage',
  async (
    customCheckout: Omit<CheckoutPage, 'id' | 'uuid' | 'stripeId' | 'subscription'> & {
      subscription: string | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await apiClient.postRequest('/api/custom_checkouts', customCheckout);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const updateCheckoutPage = createAsyncThunk(
  'checkoutPage/updateCheckoutPage',
  async (
    customCheckout: { name: string; title: string | undefined; id: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await apiClient.putRequest(
        `/api/custom_checkouts/${customCheckout.id}`,
        customCheckout,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteCheckoutPage = createAsyncThunk(
  'checkoutPage/deleteCheckoutPage',
  async (customCheckout: CheckoutPage, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(
        `/api/custom_checkouts/${customCheckout.id}`,
        customCheckout,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
