import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch } from '../../redux/hooks';
import Input from '../../components/formik/input';
import {
  SubscriptionsInfoSingle,
  SubscriptionsInfoTeam,
} from '../../redux/interface/subscriptionInterface';
import { postSubscriptionInfo } from '../../redux/thunks/subscriptionThunk';

interface SubscriptionTypesProps {
  singleSubscriptionDetails: SubscriptionsInfoSingle[];
  teamSubscriptionDetails: SubscriptionsInfoTeam[];
  setSingleSubscriptionDetails: React.Dispatch<React.SetStateAction<SubscriptionsInfoSingle[]>>;
  setTeamSubscriptionDetails: React.Dispatch<React.SetStateAction<SubscriptionsInfoTeam[]>>;
}

const validationSchemaTeam = Yup.object().shape({
  description: Yup.string().required('Discription  is required'),
  price: Yup.string().required('Price is required'),
  stripe_id: Yup.string().required('Stripe is required'),
});

const validationSchemaSingle = Yup.object().shape({
  descriptionMonthly: Yup.string().required('Discription Monthly is required'),
  apple_idMonthly: Yup.string().required('Apple Monthly tunes is required'),
  priceMonthly: Yup.string().required('Price Monthly is required'),
  android_idMonthly: Yup.string().required('Anroid Play Monthly is required'),
  appleDiscountIdMonthly: Yup.string().nullable(),
  androidDiscountIdMonthly: Yup.string().nullable(),

  descriptionYearly: Yup.string().required('Discription Yearly is required'),
  apple_idYearly: Yup.string().required('Apple Yearly tunes is required'),
  priceYearly: Yup.string().required('Price Yearly is required'),
  android_idYearly: Yup.string().required('Anroid Yearly Play is required'),
  stripe_idYearly: Yup.string().required('Stripe Yearly is required'),
  appleDiscountIdYearly: Yup.string().nullable(),
  androidDiscountIdYearly: Yup.string().nullable(),

  descriptionLifetime: Yup.string().required('Discription  Lifetime is required'),
  apple_idLifetime: Yup.string().required('Apple Lifetime tunes is required'),
  priceLifetime: Yup.string().required('Price  Lifetime is required'),
  android_idLifetime: Yup.string().required('Anroid Play Lifetime is required'),
  stripe_idLifetime: Yup.string().required('Stripe Lifetime is required'),
  appleDiscountIdLifetime: Yup.string().nullable(),
  androidDiscountIdLifetime: Yup.string().nullable(),
});

function SubscriptionTypesForm({
  singleSubscriptionDetails,
  teamSubscriptionDetails,
  setSingleSubscriptionDetails,
  setTeamSubscriptionDetails,
}: SubscriptionTypesProps) {
  const dispatch = useAppDispatch();
  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
      <div className="content_decor" />
      <div className="row pb-3 pb-md-4 align-items-center">
        <div className="col-12">
          <h1 className="headline-decor">Subscription Types</h1>
        </div>
      </div>

      <div className="container-fluid">
        <Formik
          initialValues={{
            descriptionMonthly: '',
            priceMonthly: 0,
            stripe_idMonthly: '',
            apple_idMonthly: '',
            android_idMonthly: '',
            appleDiscountIdMonthly: '',
            androidDiscountIdMonthly: '',

            descriptionYearly: '',
            priceYearly: 0,
            stripe_idYearly: '',
            apple_idYearly: '',
            android_idYearly: '',
            appleDiscountIdYearly: '',
            androidDiscountIdYearly: '',

            descriptionLifetime: '',
            priceLifetime: 0,
            stripe_idLifetime: '',
            apple_idLifetime: '',
            android_idLifetime: '',
            appleDiscountIdLifetime: '',
            androidDiscountIdLifetime: '',
          }}
          validationSchema={validationSchemaSingle}
          onSubmit={(values) => {
            const subscriptions = [
              {
                id: singleSubscriptionDetails[0]?.id,
                frequency: 'monthly',
                description: values.descriptionMonthly,
                price: values.priceMonthly,
                stripe_id: values.stripe_idMonthly,
                apple_id: values.apple_idMonthly,
                android_id: values.android_idMonthly,
                apple_discount_id: values.appleDiscountIdMonthly,
                android_discount_id: values.androidDiscountIdMonthly,
              },
              {
                id: singleSubscriptionDetails[1]?.id,
                frequency: 'yearly',
                description: values.descriptionYearly,
                price: values.priceYearly,
                stripe_id: values.stripe_idYearly,
                apple_id: values.apple_idYearly,
                android_id: values.android_idYearly,
                apple_discount_id: values.appleDiscountIdYearly,
                android_discount_id: values.androidDiscountIdYearly,
              },
              {
                id: singleSubscriptionDetails[2]?.id,
                frequency: 'lifetime',
                description: values.descriptionLifetime,
                price: values.priceLifetime,
                stripe_id: values.stripe_idLifetime,
                apple_id: values.apple_idLifetime,
                android_id: values.android_idLifetime,
                apple_discount_id: values.appleDiscountIdLifetime,
                android_discount_id: values.androidDiscountIdLifetime,
              },
            ];

            const subscriptionsToSubmit = [
              {
                ...subscriptions[0],
                apple_discount_id: values.appleDiscountIdMonthly,
                android_discount_id: values.androidDiscountIdMonthly,
              },
              {
                ...subscriptions[1],
                apple_discount_id: values.appleDiscountIdYearly,
                android_discount_id: values.androidDiscountIdYearly,
              },
              {
                ...subscriptions[2],
                apple_discount_id: values.appleDiscountIdLifetime,
                android_discount_id: values.androidDiscountIdLifetime,
              },
            ];

            setSingleSubscriptionDetails([
              {
                ...subscriptions[0],
                appleDiscountId: values.appleDiscountIdMonthly,
                androidDiscountId: values.androidDiscountIdMonthly,
              },
              {
                ...subscriptions[1],
                appleDiscountId: values.appleDiscountIdYearly,
                androidDiscountId: values.androidDiscountIdYearly,
              },
              {
                ...subscriptions[2],
                appleDiscountId: values.appleDiscountIdLifetime,
                androidDiscountId: values.androidDiscountIdLifetime,
              },
            ]);
            dispatch(
              postSubscriptionInfo({
                type: 'single',
                subscriptions: subscriptionsToSubmit,
              }),
            );
          }}
        >
          {({ handleSubmit, setValues }) => {
            useEffect(() => {
              if (singleSubscriptionDetails && singleSubscriptionDetails[0]?.description) {
                setValues({
                  descriptionMonthly: singleSubscriptionDetails[0]?.description,
                  priceMonthly: singleSubscriptionDetails[0]?.price,
                  stripe_idMonthly: singleSubscriptionDetails[0]?.stripe_id,
                  apple_idMonthly: singleSubscriptionDetails[0]?.apple_id,
                  android_idMonthly: singleSubscriptionDetails[0]?.android_id,
                  appleDiscountIdMonthly: singleSubscriptionDetails[0]?.appleDiscountId,
                  androidDiscountIdMonthly: singleSubscriptionDetails[0]?.androidDiscountId,

                  descriptionYearly: singleSubscriptionDetails[1]?.description,
                  priceYearly: singleSubscriptionDetails[1]?.price,
                  stripe_idYearly: singleSubscriptionDetails[1]?.stripe_id,
                  apple_idYearly: singleSubscriptionDetails[1]?.apple_id,
                  android_idYearly: singleSubscriptionDetails[1]?.android_id,
                  appleDiscountIdYearly: singleSubscriptionDetails[1]?.appleDiscountId,
                  androidDiscountIdYearly: singleSubscriptionDetails[1]?.androidDiscountId,

                  descriptionLifetime: singleSubscriptionDetails[2]?.description,
                  priceLifetime: singleSubscriptionDetails[2]?.price,
                  stripe_idLifetime: singleSubscriptionDetails[2]?.stripe_id,
                  apple_idLifetime: singleSubscriptionDetails[2]?.apple_id,
                  android_idLifetime: singleSubscriptionDetails[2]?.android_id,
                  appleDiscountIdLifetime: singleSubscriptionDetails[2]?.appleDiscountId,
                  androidDiscountIdLifetime: singleSubscriptionDetails[2]?.androidDiscountId,
                });
              }
            }, [singleSubscriptionDetails]);

            return (
              <form>
                <div className="row">
                  <div className="col-12 p-0 mb-4">
                    <div className="bg-color-gray-200 radius-16">
                      <div className="border-bottom-gray-100 p-3 p-xl-4">
                        <h4 className="m-0">Single</h4>
                      </div>
                      <div className="container-fluid p-3 p-xl-4">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="m-0">Monthly</h4>
                          </div>

                          <div className="row">
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="descriptionMonthly"
                                description="description"
                                placeholder="description"
                                label="Description *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="number"
                                min="0"
                                name="priceMonthly"
                                description="price"
                                placeholder="price"
                                label="Price *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="apple_idMonthly"
                                description="Apple iTunes IDe"
                                placeholder="Apple iTunes ID"
                                label="Apple iTunes ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="android_idMonthly"
                                description="Android Play ID"
                                placeholder="Android Play ID"
                                label="Android Play ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="stripe_idMonthly"
                                description="stripe"
                                placeholder="stripe"
                                label="Stripe ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="appleDiscountIdMonthly"
                                description="Apple Discount ID"
                                placeholder="Apple Discount ID"
                                label="Apple Discount ID"
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <Field
                                component={Input}
                                type="text"
                                name="androidDiscountIdMonthly"
                                description="Android Discount ID"
                                placeholder="Android Discount ID"
                                label="Android Discount ID"
                              />
                            </div>
                          </div>

                          <hr className="w-75 m-auto d-block color-black mt-4" />
                        </div>

                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="m-0">Yearly</h4>
                          </div>

                          <div className="row">
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="descriptionYearly"
                                description="descriptionYearly"
                                placeholder="description"
                                label="Description *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="number"
                                min="0"
                                name="priceYearly"
                                description="price"
                                placeholder="price"
                                label="Price *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="apple_idYearly"
                                description="Apple iTunes ID"
                                placeholder="Apple iTunes ID"
                                label="Apple iTunes ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="android_idYearly"
                                description="Android Play ID"
                                placeholder="Android Play ID"
                                label="Android Play ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="stripe_idYearly"
                                description="stripe"
                                placeholder="stripe"
                                label="Stripe ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="appleDiscountIdYearly"
                                description="Apple Discount ID"
                                placeholder="Apple Discount ID"
                                label="Apple Discount ID"
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <Field
                                component={Input}
                                type="text"
                                name="androidDiscountIdYearly"
                                description="Android Discount ID"
                                placeholder="Android Discount ID"
                                label="Android Discount ID"
                              />
                            </div>
                          </div>

                          <hr className="w-75 m-auto d-block color-black mt-4" />
                        </div>

                        <div className="mb-4 mb-lg-5">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="m-0">Lifetime</h4>
                          </div>

                          <div className="row">
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="descriptionLifetime"
                                description="description"
                                placeholder="description"
                                label="Description *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="number"
                                min="0"
                                name="priceLifetime"
                                description="price"
                                placeholder="price"
                                label="Price *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="apple_idLifetime"
                                description="Apple iTunes IDe"
                                placeholder="Apple iTunes ID"
                                label="Apple iTunes ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="android_idLifetime"
                                description="Android Play ID"
                                placeholder="Android Play ID"
                                label="Android Play ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="stripe_idLifetime"
                                description="stripe"
                                placeholder="stripe"
                                label="Stripe ID *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="appleDiscountIdLifetime"
                                description="Apple Discount ID"
                                placeholder="Apple Discount ID"
                                label="Apple Discount ID"
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <Field
                                component={Input}
                                type="text"
                                name="androidDiscountIdLifetime"
                                description="Android Discount ID"
                                placeholder="Android Discount ID"
                                label="Android Discount ID"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            className="subscription-page__submit-btn d-block btn btn-primary px-5 m-auto w-50"
                            type="button"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            <i className="fa fa-check" />
                            Save
                          </button>
                          <button
                            className="subscription-page__submit-btn d-block btn btn-outline-dark px-5 m-auto w-50"
                            type="button"
                            onClick={() =>
                              setValues({
                                descriptionMonthly: '',
                                priceMonthly: 0,
                                stripe_idMonthly: '',
                                apple_idMonthly: '',
                                android_idMonthly: '',
                                appleDiscountIdMonthly: '',
                                androidDiscountIdMonthly: '',

                                descriptionYearly: '',
                                priceYearly: 0,
                                stripe_idYearly: '',
                                apple_idYearly: '',
                                android_idYearly: '',
                                appleDiscountIdYearly: '',
                                androidDiscountIdYearly: '',

                                descriptionLifetime: '',
                                priceLifetime: 0,
                                stripe_idLifetime: '',
                                apple_idLifetime: '',
                                android_idLifetime: '',
                                appleDiscountIdLifetime: '',
                                androidDiscountIdLifetime: '',
                              })
                            }
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
        <Formik
          initialValues={{
            frequency: 'yearly',
            description: '',
            price: 0,
            stripe_id: '',
          }}
          validationSchema={validationSchemaTeam}
          onSubmit={(values) => {
            const teamArray = [{ ...values, id: teamSubscriptionDetails[0]?.id }];
            setTeamSubscriptionDetails(teamArray);
            dispatch(
              postSubscriptionInfo({
                type: 'team',
                subscriptions: teamArray,
              }),
            );
          }}
        >
          {({ handleSubmit, setValues }) => {
            useEffect(() => {
              if (teamSubscriptionDetails && teamSubscriptionDetails[0]?.description) {
                setValues({
                  frequency: 'yearly',
                  description: teamSubscriptionDetails[0]?.description,
                  price: teamSubscriptionDetails[0]?.price,
                  stripe_id: teamSubscriptionDetails[0]?.stripe_id,
                });
              }
            }, [teamSubscriptionDetails]);

            return (
              <form>
                <div className="row">
                  <div className="col-12 p-0 mb-4">
                    <div className="bg-color-gray-200 radius-16">
                      <div className="border-bottom-gray-100 p-3 p-xl-4">
                        <h4 className="m-0">Team</h4>
                      </div>
                      <div className="container-fluid p-3 p-xl-4">
                        <div className="mb-4">
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="m-0">Yearly</h4>
                          </div>

                          <div className="row">
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="text"
                                name="description"
                                description="description"
                                placeholder="description"
                                label="Description *"
                              />
                            </div>
                            <div className="col-12 col-sm-6 mb-3">
                              <Field
                                component={Input}
                                type="number"
                                min="0"
                                name="price"
                                description="price"
                                placeholder="price"
                                label="Price *"
                              />
                            </div>
                            <div className="col-12 col-sm-6">
                              <Field
                                component={Input}
                                type="text"
                                name="stripe_id"
                                description="stripe"
                                placeholder="stripe"
                                label="Stripe ID *"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            className="subscription-page__submit-btn d-block btn btn-primary px-5 m-auto w-50"
                            type="button"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            <i className="fa fa-check" />
                            Save
                          </button>
                          <button
                            className="subscription-page__submit-btn d-block btn btn-outline-dark px-5 m-auto w-50"
                            type="button"
                            onClick={() =>
                              setValues({
                                frequency: 'yearly',
                                description: '',
                                price: 0,
                                stripe_id: '',
                              })
                            }
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default SubscriptionTypesForm;
