/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import {addNewTeam, getAllTeams} from '../../../redux/thunks/teamThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface NewTeam {
  name: string;
  label: string;
  email: string;
  subscriptionTime: string;
}

interface Props {
  newTeam: NewTeam;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter the team email address.'),
  name: Yup.string().required('Please enter team name'),
  subscriptionTime: Yup.string().required('Subscription time is required'),
});

function AddNewTeam({ newTeam }: Props) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.team);

  const dispatch = useAppDispatch();

  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_new_team"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={{
          name: newTeam?.name,
          label: newTeam?.label,
          email: newTeam?.email,
          subscriptionTime: newTeam?.subscriptionTime,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(
            addNewTeam({
              owner: {
                name: values.name,
                email: values.email,
                type: 'free',
              },
              subscription: values.subscriptionTime,
              label: values.label,
              type: 'free',
            }),
          );
        }}
      >
        {({ handleSubmit, setValues, setTouched }) => {
          useEffect(() => {
            if (status === 'succeeded' && modalName === 'addNewTeam') {

              dispatch(getAllTeams('/api/teams'))

              hideModal({ modalId: '#closeModal_newTeam' });
              setTouched({});
              setValues({
                name: '',
                label: '',
                email: '',
                subscriptionTime: '',
              });
            }
          }, [modalOpen]);

          return (
            <form>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setTouched({});
                        setValues({
                          name: '',
                          label: '',
                          email: '',
                          subscriptionTime: '',
                        });
                      }}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Add new Team</h4>
                    </div>
                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="label"
                        description="Team name"
                        placeholder="Team name"
                        label="Team name *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="email"
                        name="email"
                        description="email"
                        placeholder="E-mail of team’s member"
                        label="Email *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="name"
                        description="name"
                        placeholder="Name"
                        label="Name *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Dropdown}
                        name="subscriptionTime"
                        label="Subscription plan *"
                        placeholder="Select plan"
                        options={[{ name: 'Lifetime', value: 'lifetime' }]}
                      />
                    </div>
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_newTeam"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden ">Loading...</span>
                              </span>
                            ) : (
                              <div
                                role="button"
                                onClick={() => handleSubmit()}
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Invite team
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setTouched({});
                              setValues({
                                name: '',
                                label: '',
                                email: '',
                                subscriptionTime: '',
                              });
                            }}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddNewTeam;
