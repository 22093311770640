import React from 'react';

function FAQ() {
  return (
    <div className="faq">
      <div className="row">
        <div className="col text-center">
          <h3 className="title-with-decor text-white">
            <span>
              <span className="px-5">FAQ</span>
            </span>
          </h3>
        </div>
      </div>
      <div className="row justify-content-center mt-4 ">
        <div className="col-auto">
          <div className="faq__accordion accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse"
                  aria-expanded="true"
                  aria-controls="collapse"
                >
                  <div className="icon">
                    <i className="fa-solid fa-arrow-right collapsed" />
                    <i className="fa-solid fa-arrow-down" />
                  </div>
                  How does a Primed Mind subscription help me?
                </button>
              </h2>
              <div
                id="collapse"
                className="accordion-collapse collapse"
                aria-labelledby="heading"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  With a Primed Mind subscription you have the ability to shift your mindset at a
                  moment&apos;s notice. With over 400+ mindset audios you&apos;re able to generate
                  the exact mindset you need to activate your best life in just minutes a day.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="icon">
                    <i className="fa-solid fa-arrow-right collapsed" />
                    <i className="fa-solid fa-arrow-down" />
                  </div>
                  How do subscriptions work?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Your subscription unlocks full access to all Primed Mind content. When you
                  purchase a subscription you agree to an automatic renewal over a set period of
                  time (monthly or yearly). You can cancel your automatic renewals anytime and
                  continue to access the content until it expires.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div className="icon">
                    <i className="fa-solid fa-arrow-right collapsed" />
                    <i className="fa-solid fa-arrow-down" />
                  </div>
                  Can I get help if I need support with the app?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Yes! If you need anything at all, please send a message to support@primedmind.com.
                  We have team members on hand ready to help you with any questions you might have.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div className="icon">
                    <i className="fa-solid fa-arrow-right collapsed" />
                    <i className="fa-solid fa-arrow-down" />
                  </div>
                  How can I cancel my subscription?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  When you purchase a Primed Mind subscription via the webapp, you can manage it in
                  the Billing section in your profile. From there you can cancel your subscription
                  at any time.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div className="icon">
                    <i className="fa-solid fa-arrow-right collapsed" />
                    <i className="fa-solid fa-arrow-down" />
                  </div>
                  Am I eligible for a free trial?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  First time Primed Mind users are eligible for a free trial when subscribing to the
                  yearly membership option. That means you&apos;ll have full access to all of our
                  features and can enjoy a first-hand experience of the app before committing to the
                  yearly subscription. Each user is eligible for this free trial only once. If
                  you&apos;ve started the free trial previously, you&apos;ll be billed immediately
                  when subscribing to the yearly membership.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center my-4 ">
        <div className="col-auto">
          <p className="text-white text-alignment" style={{ width: '575px' }}>
            When choosing a plan with a trial payment will be charged when the trial period ends.
            All subscriptions automatically renew unless canceled at least 24 hours before the end
            of the current period. Any unused portion of a free trial period, if offered, will be
            forfeited when you purchase any other in-app products. Subscriptions can be managed in
            Accounts and Settings after purchases.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
