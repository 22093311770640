/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ccIcon from '../../assets/img/credit-card-icon.svg';

interface PayMethodMutedProps {
  handleAddCardClick: () => void;
}

function PayMethodMuted({ handleAddCardClick }: PayMethodMutedProps) {
  return (
    <div className="col-auto my-4">
      <div
        className="credit-card"
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <button
          type="button"
          className="add-credit-card btn btn-primary"
          onClick={handleAddCardClick}
          style={{ position: 'absolute', left: 0, zIndex: 101, margin: '0 50px' }}
        >
          <i className="fa-regular fa-plus icon-size-20" /> Add Credit Card
        </button>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%', // Adjust this value to change the height of the blurred area
            background: 'rgba(245, 245, 245, 0.5)', // Light gray with transparency
            backdropFilter: 'blur(4px)', // Adjust the blur intensity
            zIndex: 100,
          }}
        />
        <div
          className="credit-card__content p-4"
          style={{
            background: 'linear-gradient(128.29deg, #353436 27.94%, #fbfbfb 103.2%)',
          }}
        >
          <div className="credit-card__type mb-3" style={{ height: '60px' }} />
          <div className="credit-card__number">**** **** **** ****</div>

          <div className="credit-card__icon-wrapper">
            <img src={ccIcon} alt="" />
          </div>
        </div>
        <div
          className="credit-card__info p-4"
          style={{
            filter: 'grayscale(100%)',
          }}
        >
          <div className="row g-0">
            <div className="col-9">
              <div className="credit-card__info-label">Cardholder&quot;s name</div>
              <div className="credit-card__info-value">****</div>
            </div>
            <div className="col-3 d-flex flex-column align-items-end">
              <div className="credit-card__info-label">Exp date</div>
              <div className="credit-card__info-value">**/**/****</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayMethodMuted;
