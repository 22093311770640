/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutPage, CheckoutPageState } from '../interface/checkoutPageInterface';
import {
  createCheckoutPage,
  deleteCheckoutPage,
  fetchCheckoutPages,
  updateCheckoutPage,
} from '../thunks/checkoutPageThunk';
import { toast } from '../../helpers/toaster';

const initialState: CheckoutPageState = {
  checkoutPages: [],
  selectedCheckoutPage: undefined,
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const checkoutPageSlice = createSlice({
  name: 'checkoutPages',
  initialState,
  reducers: {
    selectCheckoutPage: (state, action: PayloadAction<CheckoutPage>) => ({
      ...state,
      selectedCheckoutPage: action.payload,
    }),
    unselectCheckoutPage: (state) => ({
      ...state,
      selectedCheckoutPage: undefined,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCheckoutPages.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchCheckoutPages.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        checkoutPages: action.payload.checkoutPages,
        totalItems: action.payload.totalItems,
      }))
      .addCase(fetchCheckoutPages.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(createCheckoutPage.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
        modalOpen: true,
        modalName: 'checkoutPageForm',
      }))
      .addCase(createCheckoutPage.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        modalOpen: false,
        modalName: 'checkoutPageForm',
      }))
      .addCase(createCheckoutPage.rejected, (state, action) => {
        const errorMessage = 'Error creating custom checkout';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: action.error.message,
        };
      })
      .addCase(updateCheckoutPage.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
        modalOpen: true,
        modalName: 'checkoutPageForm',
      }))
      .addCase(updateCheckoutPage.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        modalOpen: false,
        modalName: 'checkoutPageForm',
      }))
      .addCase(updateCheckoutPage.rejected, (state, action) => {
        const errorMessage = 'Error updating custom checkout page.';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: action.error.message,
        };
      })
      .addCase(deleteCheckoutPage.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
        modalOpen: true,
        modalName: 'promptDeleteCheckout',
      }))
      .addCase(deleteCheckoutPage.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        checkoutPages: action.payload,
        modalOpen: false,
        modalName: 'promptDeleteCheckout',
      }))
      .addCase(deleteCheckoutPage.rejected, (state, action) => {
        const errorMessage = 'Error deleting custom checkout page.';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: action.error.message,
        };
      });
  },
});

export const { actions } = checkoutPageSlice;

export default checkoutPageSlice.reducer;
