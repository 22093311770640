import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { TeamState } from '../interface/teamInterface';
import { toast } from '../../helpers/toaster';
import {
  getAllTeams,
  addNewTeam,
  deleteSingleTeam,
  getAllTeamMembers,
  addNewTeamMember,
  addNewTeamMemberCsvs,
  deleteSingleTeamMember,
  editTeam,
  fetchTeamInfo,
} from '../thunks/teamThunk';

const initialState: TeamState = {
  allTeamsInfo: [],
  presentTeam: {
    id: '',
    owner: {
      name: '',
      type: '',
    },
    type: '',
    seats: '',
  },
  allTeamMembersInfo: [],
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllTeams.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getAllTeams.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        pagination: action.payload.pagination,
        allTeamsInfo: action.payload.teamsInfo,
        totalItems: action.payload.totalItems,
      }))
      .addCase(getAllTeams.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(fetchTeamInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchTeamInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        presentTeam: action.payload,
      }))
      .addCase(fetchTeamInfo.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(addNewTeam.pending, (state) => ({
        ...state,
        modalName: 'addNewTeam',
        modalOpen: true,
        status: 'loading',
      }))
      .addCase(addNewTeam.fulfilled, (state) => {
        toast('Created team successfully', 'success');
        return {
          ...state,
          modalOpen: false,
          status: 'succeeded',
        };
      })
      .addCase(addNewTeam.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Adding new team failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(editTeam.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(editTeam.fulfilled, (state, action) => {
        toast('Created team successfully', 'success');

        const indexToUpdate = findIndex(
          state.allTeamsInfo,
          (team) => team.id === action.payload.id,
        );
        const newUpdatedTeam = [...state.allTeamsInfo];
        newUpdatedTeam[indexToUpdate] = action.payload;
        return {
          ...state,
          status: 'succeeded',
          presentTeam: action.payload,
          allTeamsInfo: newUpdatedTeam,
        };
      })
      .addCase(editTeam.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Editing team failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteSingleTeam.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteSingleTeam.fulfilled, (state, action) => {
        toast('Deleted team successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          allTeamsInfo: state.allTeamsInfo.filter((team) => team.id !== action.payload.teamId),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(deleteSingleTeam.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Deleting team failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(getAllTeamMembers.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getAllTeamMembers.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        allTeamMembersInfo: action.payload.teamMembersInfo,
        pagination: action.payload.pagination,
        totalItems: action.payload.totalItems,
      }))
      .addCase(getAllTeamMembers.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(addNewTeamMember.pending, (state) => ({
        ...state,
        modalName: 'addNewTeamMember',
        modalOpen: true,
        status: 'loading',
      }))
      .addCase(addNewTeamMember.fulfilled, (state, action) => {
        toast('Adding new team member successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          allTeamMembersInfo: [
            ...state.allTeamMembersInfo,
            {
              userType: 'User',
              formattedTimeSpent: '0s',
              createdAt: new Date().toISOString(),
              ...(action as any).meta.arg,
            },
          ],
          totalItems: state.totalItems + 1,
          modalOpen: false,
        };
      })
      .addCase(addNewTeamMember.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Adding new team member failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteSingleTeamMember.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteSingleTeamMember.fulfilled, (state, action) => {
        toast('Deleting team member successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          allTeamMembersInfo: state.allTeamMembersInfo.filter(
            (team) => `${team.id}` !== action.payload.userId,
          ),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(deleteSingleTeamMember.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Deleting team member failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(addNewTeamMemberCsvs.pending, (state) => ({
        ...state,
        modalName: 'addNewTeamMemberCSV',
        modalOpen: true,
        status: 'loading',
      }))
      .addCase(addNewTeamMemberCsvs.fulfilled, (state) => {
        toast('Invitation sent successfully', 'success');
        return {
          ...state,
          status: 'succeeded',
          modalOpen: false,
        };
      })
      .addCase(addNewTeamMemberCsvs.rejected, (state, action:any) => {
        const errorMessage = action?.payload || 'Sending invites failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default teamSlice.reducer;
