import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import adminUsersReducer from './slices/adminUsersSlice';
import subscriptionReducer from './slices/subscriptionsSlice';
import companiesReducer from './slices/companiesSlice';
import marketingReducer from './slices/marketingSlice';
import appUsersReducer from './slices/appUsersSlice';
import teamReducer from './slices/teamSlice';
import discountReducer from './slices/discountSlice';
import currentUserReducer from './slices/currentUserSlice';
import sessionReducer from './slices/sessionSlice';
import courseReducer from './slices/courseSlice';
import playerReducer from './slices/playerSlice';
import channelReducer from './slices/channelsSlice';
import userSettingsReducer from './slices/userSettingsSlice';
import modalReducer from './slices/modalSlice';
import paginationReducer from './slices/paginationSlice';
import homeReducer from './slices/homeSlice';
import entitiesSlice from './slices/entitiesSlice';
import billingReducer from './slices/billingSlice';
import checkoutPageReducer from './slices/checkoutPageSlice';

export const store = configureStore({
  reducer: {
    adminUsers: adminUsersReducer,
    subscriptions: subscriptionReducer,
    companies: companiesReducer,
    appUsers: appUsersReducer,
    marketing: marketingReducer,
    team: teamReducer,
    discount: discountReducer,
    currentUser: currentUserReducer,
    session: sessionReducer,
    course: courseReducer,
    player: playerReducer,
    channel: channelReducer,
    entities: entitiesSlice,
    userSettings: userSettingsReducer,
    modal: modalReducer,
    pagination: paginationReducer,
    home: homeReducer,
    billing: billingReducer,
    checkoutPages: checkoutPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['adminUsers/createSession/fulfilled'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
