/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { generatePath, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import subscriptionImage1 from '../assets/img/subscription-avarage-1.png';
import subscriptionImage2 from '../assets/img/subscription-avarage-2.png';
import subscriptionImage3 from '../assets/img/subscription-avarage-3.png';
import { actions } from '../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useServices } from '../redux/servicesLayer';
import { breakpointOrder, breakpoints } from '../constants/mediaQueries';
import { URL_ROUTES } from '../constants/routes';
import { SubscriptionInterface } from '../redux/interface/entitiesInterface';
import FAQ from '../components/faq';

interface PropsCTA {
  yearlyPlan: SubscriptionInterface | undefined;
  handleTryFreeAndSubscribe: () => void;
}

function SubscriptionCTA({ yearlyPlan, handleTryFreeAndSubscribe }: PropsCTA) {
  return (
    <div className="try-7-free text-white text-center px-5 py-4 rounded-3">
      <div className="px-5">
        <div className="px-5">
          <div className="lead">
            Try 7 days for Free with the yearly plan,
            {` then $${yearlyPlan?.price}/year`}
          </div>
          <div className="py-1">{`($ ${
            Math.trunc((yearlyPlan?.price ? yearlyPlan.price / 12 : 0) * 100) / 100
          }/month)`}</div>
          <div>Cancel anytime.</div>

          <button
            onClick={handleTryFreeAndSubscribe}
            type="button"
            className="d-flex btn btn-md btn-primary px-5 m-auto mt-3 align-items-center"
          >
            <i className="fa-regular fa-bell me-2" />
            Try Free And Subscribe
          </button>
        </div>
      </div>
    </div>
  );
}

function PlanCard({
  plan,
  handlePlanSelection,
  bestSave,
  isSelectedPlan,
}: {
  plan: any;
  handlePlanSelection: (plan: any) => void;
  bestSave: number;
  isSelectedPlan: boolean;
}) {
  const msg = plan.frequency === 'yearly' ? 'First 7 Days Free' : 'Billed Monthly';
  const frequency = plan.frequency === 'yearly' ? 'Yearly' : 'Monthly';

  return (
    <div className="col-auto" onClick={() => handlePlanSelection(plan)}>
      <div className="price-item">
        {plan.frequency === 'yearly' && (
          <div className="price-item__best-value py-2">{`Best Value - Save ${bestSave}%`}</div>
        )}
        <div
          className={`price-item__inner ${
            isSelectedPlan && 'selected'
          } d-flex flex-column justify-content-between overflow-hidden`}
        >
          <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
            <h2 className="price-item__title text-center text-white">{frequency}</h2>
            <p className="lead price-item__value text-center text-white m-0">$ {plan.price}</p>
            {plan.frequency === 'yearly' && (
              <p className="text-center text-white">{plan.amountPerMonth}</p>
            )}
          </div>

          <div className="price-item__bottom text-center bg-white p-2">{msg}</div>
        </div>
      </div>
    </div>
  );
}

const responsive = breakpointOrder.map((breakpoint) => ({
  breakpoint: breakpoints[breakpoint],
  settings: {
    slidesToShow: 1,
    adaptiveHeight: true,
  },
}));

function Subscription() {
  const dispatch = useAppDispatch();

  const [opened, setOpened] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionInterface>();
  const [bestSave, setBestSave] = useState(0);
  const [yearlyPlan, setYearlyPlan] = useState<SubscriptionInterface>();
  const { openSubOnly } = useAppSelector(
    (state) => state.modal.info?.subscriptionModal || { openSubOnly: false },
  );
  const showModal = useAppSelector((state) => state.modal.visible === 'subscriptionModal');
  const { singleSubscriptions: subscriptions } = useServices();

  const { pathname } = useLocation();

  const handleCloseModal = useCallback(() => {
    dispatch(actions.closeModal());
    if (!opened) {
      setOpened(true);
      if (!openSubOnly) {
        if (pathname === '/account/billing') {
          return;
        }
        dispatch(actions.setVisibleModal('advertisementModal'));
      }
    }
  }, []);

  const handleSelectPlan = (plan: any) => {
    setSelectedPlan(plan);
    window.location.href = `${window.location.origin}${generatePath(URL_ROUTES.Checkout, {
      subscribeType: 'single',
      subscribeId: plan?.id.toString(),
    })}`;
  };

  useEffect(() => {
    const getBestSave = () => {
      const monthlyPlan = subscriptions.find((plan) => plan.frequency === 'monthly');
      const yearlySubPlan = subscriptions.find((plan) => plan.frequency === 'yearly');
      setSelectedPlan(yearlySubPlan);
      setYearlyPlan(yearlySubPlan);
      const monthlyYearlyFeeNum = monthlyPlan?.price ? monthlyPlan.price * 12 : 0;
      const yearlyFeeNum = yearlySubPlan?.price;

      if (monthlyYearlyFeeNum && yearlyFeeNum) {
        const savingPercentage = Math.round(
          ((Number(monthlyYearlyFeeNum) - Number(yearlyFeeNum)) / Number(monthlyYearlyFeeNum)) *
            100,
        );
        setBestSave(savingPercentage);
      }
    };
    getBestSave();
  }, [subscriptions]);

  const filteredPlans = subscriptions.filter(
    (item) => item.frequency === 'monthly' || item.frequency === 'yearly',
  );

  const handleTryFreeAndSubscribe = useCallback(() => {
    window.location.href = `${window.location.origin}${generatePath(URL_ROUTES.Checkout, {
      subscribeType: 'single',
      subscribeId: yearlyPlan?.id.toString(),
    })}`;
    dispatch(actions.closeModal());
  }, [yearlyPlan]);

  return (
    <Modal show={showModal} className="modal-xl modal-subscription" centered>
      <div className="subscribe-landing rounded-4">
        <Modal.Header className="pb-0 p-4 d-flex flex-column w-100 ">
          <div className="w-100 d-flex justify-content-end">
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn-close bg-color-white icon-size-12"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="modal-subscription__logo mb-4 ">
                <img src={logo} alt="Primed Mind logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <h3 className="text-white">Supercharge Your Growth</h3>
              <p className="text-white lead">Unlock Primed Mind</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-hidden" style={{ borderRadius: '20px' }}>
          <div className="green-line-decor my-3 mb-5" />

          <div className="row align-items-center justify-content-center">
            {filteredPlans.map((plan) => (
              <PlanCard
                key={plan.id}
                plan={plan}
                handlePlanSelection={handleSelectPlan}
                bestSave={bestSave}
                isSelectedPlan={plan.id === selectedPlan?.id}
              />
            ))}
          </div>
          <div className="row align-items-center justify-content-center mt-5 mb-5">
            <div className="col-auto" style={{ marginLeft: '40px' }}>
              <ul>
                <li className="d-flex text-white mb-3">
                  <div className="me-2 ">
                    <i className="fa-regular fa-square-check fa-lg" />
                  </div>
                  <div className="pb-3">
                    <h4 className="pb-2 m-0">Unlock the full Primed Mind Experience</h4>
                    <small>Get instant access to our complete library of 400+ Primers.</small>
                  </div>
                </li>
                <li className="d-flex text-white mb-3">
                  <div className="me-2">
                    <i className="fa-regular fa-square-check fa-lg" />
                  </div>
                  <div className="pb-3">
                    <h4 className="pb-2 m-0">Level-Up Your Life with Mindset Upgrades</h4>
                    <small>Our multi-day Mindset Upgrade install powerful mental programs</small>
                  </div>
                </li>
                <li className="d-flex text-white mb-3">
                  <div className="me-2">
                    <i className="fa-regular fa-square-check fa-lg" />
                  </div>
                  <div className="pb-3">
                    <h4 className="pb-2 m-0">Track Your Progress & Achievement</h4>
                    <small>
                      Start your streak today and track your progress with achievements and levels
                    </small>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-white p-2 position-relative pt-4 pb-4">
            <div className="row justify-content-center">
              <div className="col-auto text-center">
                <p className="join-card">
                  Join 250,000 Users who are using Primed Mind to activate their
                  <br className="lead" />
                  best life in just minutes a day.
                </p>
              </div>
            </div>
            <div className="row g-5 justify-content-center text-center mb-3">
              <div className="col-auto">
                <img src={subscriptionImage1} width="125" alt="" />
              </div>
              <div className="col-auto">
                <img src={subscriptionImage2} width="125" alt="" />
              </div>
              <div className="col-auto">
                <img src={subscriptionImage3} width="125" alt="" />
              </div>
            </div>
          </div>
          <div className="trial-works pt-5 pb-5 ">
            <div className="row">
              <div className="col text-center">
                <h3 className="text-white title-with-decor">
                  Here&apos;s How <span>Your Free</span> Trial Works
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="trial-works-list text-white mt-4 mb-4">
                  <div className="trial-works-list__item">
                    <div className="trial-works-list__icon">
                      <i className="fa-regular fa-lock fa-lg" />
                    </div>
                    <div className="trial-works-list__text">
                      <h3>Today</h3>
                      <small>Get instant access to the full Primed Mind experience</small>
                    </div>
                  </div>
                  <div className="trial-works-list__item">
                    <div className="trial-works-list__icon">
                      <i className="fa-regular fa-bell  fa-lg" />
                    </div>
                    <div className="trial-works-list__text">
                      <h3>Day 5</h3>
                      <small>We&apos;ll remind you with an email that your trial is ending</small>
                    </div>
                  </div>
                  <div className="trial-works-list__item">
                    <div className="trial-works-list__icon">
                      <i className="fa-regular fa-star  fa-lg" />
                    </div>
                    <div className="trial-works-list__text">
                      <h3>Day 7</h3>
                      <small>You&apos;ll be charged today, cancel anytime before</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto">
                <SubscriptionCTA
                  yearlyPlan={yearlyPlan}
                  handleTryFreeAndSubscribe={handleTryFreeAndSubscribe}
                />
              </div>
            </div>
          </div>

          <div className="comments bg-white p-5">
            <div className="row">
              <div className="col text-center">
                <h3 className="title-with-decor">
                  Wh<span>at Users Are Sayi</span>ng:
                </h3>
              </div>
            </div>

            <div className="comments__slider mt-4 row">
              <Slider
                dots
                infinite
                speed={300}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                responsive={responsive}
                variableWidth
                adaptiveHeight
              >
                <div className="comments__slider-item py-4 px-5">
                  <div className="comments__slider-item-top d-flex justify-content-between">
                    <h3>Marcel Manea</h3>
                    <div className="comments__slider-item-stars">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      The best app ever. I keep using it for a long time now, and I still have many
                      options, plenty of topics to work on. It covers most of the areas of life and
                      helps to improve your health, focus, mood, sleep, discipline and so on.
                    </p>
                  </div>
                </div>
                <div className="comments__slider-item py-4 px-5">
                  <div className="comments__slider-item-top d-flex justify-content-between">
                    <h3>Leigh-Ann Beamer</h3>
                    <div className="comments__slider-item-stars">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      So great! I love this app! I use it in the morning to kick off my day and at
                      night for sleep, and occasionally during the day to stop and reset when things
                      get really crazy.
                    </p>
                  </div>
                </div>
                <div className="comments__slider-item py-4 px-5">
                  <div className="comments__slider-item-top d-flex justify-content-between">
                    <h3>Sean Fernandez</h3>
                    <div className="comments__slider-item-stars">
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                      <i className="fa-solid fa-star" />
                    </div>
                  </div>
                  <div className="mt-3">
                    <p>
                      Excellent and I love how it is specific to YOUR needs and reaching peak
                      performance in YOUR desired abilities.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className="faq p-5">
            <FAQ />
            <div className="row justify-content-center">
              <div className="col-auto text-white">
                <a
                  className="text-white text-decoration-underline"
                  href="https://primedmind.com/termsofservice"
                >
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  className="text-white text-decoration-underline"
                  href="https://primedmind.com/privacypolicy"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="row justify-content-center align-items-center mt-4">
              <div className="col-auto">
                <SubscriptionCTA
                  yearlyPlan={yearlyPlan}
                  handleTryFreeAndSubscribe={handleTryFreeAndSubscribe}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-4 ">
              <div className="col-auto text-white">
                <span
                  className="text-white text-decoration-underline cursor-pointer"
                  onClick={handleCloseModal}
                >
                  No thanks, I&apos;ll subscribe later
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default Subscription;
