import { ChangeEvent, useEffect, useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAppUsers } from '../../redux/thunks/appUsersThunk';
import { handlePagination, setParamValue } from '../../helpers';
import AddNewAppUser from './modals/addAppUser';
import { AppUser } from '../../redux/interface/appUsersInterface';

const BASE_URL = '/api/users/app-users';

function AppUsers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // local state
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);

  // redux store
  const status = useAppSelector((state) => state.appUsers.status);
  const appUser = useAppSelector((state) => state.appUsers.data);

  // pagination
  const totalAppUsers = useAppSelector((state) => state.appUsers.totalItems);
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.appUsers.pagination,
  );

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };
  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'search', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  // fetching app users
  useEffect(() => {
    if (
      Math.ceil(totalAppUsers / numberOfRows) !== 0 &&
      Math.ceil(totalAppUsers / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(fetchAppUsers(currentUrl));
  }, [currentUrl]);

  const showUserPlan = (user: AppUser) => {
    if (user?.isFree) {
      return 'Free';
    }
    if (!user?.isFree && user?.latestBillingPlan?.isTrial) {
      return 'Trial';
    }
    return 'Paid';
  };

  return (
    <>
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
        <div className="content_decor" />
        <div className="row pb-3 pb-md-4 align-items-center">
          <div className="col-12 col-sm-6">
            <h1 className="headline-decor">App Users</h1>
          </div>
          <div className="col-12 col-sm-6 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary px-md-3 px-lg-5"
              data-bs-toggle="modal"
              data-bs-target="#add_new_user"
            >
              <i className="fa fa-plus" />
              Add New User
            </button>
          </div>
        </div>

        <div className="container-fluid content-inner">
          <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <Search value={searchInput} onInputChange={handleFilterUsers} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-13 p-0">
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th className="col-2">
                        <div className="d-flex align-items-center ">
                          Name
                          <button className="btn-icon d-inline color-gray-600 ms-2" type="button">
                            <i className="fa-regular fa-angle-down" />
                          </button>
                        </div>
                      </th>
                      <th className="col-2">Email</th>
                      <th className="col-2">$ Revenue</th>
                      <th className="col-2">Time Spent</th>
                      <th className="col-2">% Account Usage</th>
                      <th className="col-2">Type</th>
                      <th className="col-1">Actions</th>
                    </tr>
                  </thead>
                  {status === 'loading' ? (
                    <tbody>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 text-truncate-column">
                          <Skeleton width={120} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 ">
                          <Skeleton width={50} height={30} />
                        </td>
                        <td className="col-3 actions-cell">
                          <Skeleton width={50} height={30} />
                          <Skeleton width={50} height={30} />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {appUser.map((user) => (
                        <tr key={user.id}>
                          <td className="font-medium">{user.name}</td>
                          <td>{user?.email}</td>
                          <td>{user?.totalRevenue}</td>
                          <td>{user?.formattedTimeSpent}</td>
                          <td>{user?.usage}</td>
                          <td className="font-medium">{showUserPlan(user)}</td>
                          <td className="actions-cell">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() =>
                                navigate('/app-user-explore', {
                                  state: {
                                    userId: user?.id,
                                  },
                                })
                              }
                              type="button"
                            >
                              <i className="fas fa-folder" />
                              Explore
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
              </div>
            </div>
            <div className="col-6 pe-0">
              <div className="d-flex justify-content-end">
                <Pagination
                  onPageChange={handlePageChange}
                  totalCount={totalAppUsers || 0}
                  currentPage={currentPage}
                  pageSize={numberOfRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewAppUser />
    </>
  );
}
export default AppUsers;
