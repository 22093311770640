import { createSlice } from '@reduxjs/toolkit';
import { CompaniesState } from '../interface/companiesInterface';
import { postCompaniesInfo, getCompaniesInfo, editCompaniesInfo } from '../thunks/companiesThunk';
import { toast } from '../../helpers/toaster';

const initialState: CompaniesState = {
  companiesInfo: {
    id: '',
    name: '',
    billingEmail: '',
    address: '',
    supportEmail: '',
    website: '',
    stripeId: '',
  },
  status: undefined,
  error: undefined,
};

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postCompaniesInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(postCompaniesInfo.fulfilled, (state, action) => {
        toast('posted companies info successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          companiesInfo: action.payload,
        };
      })
      .addCase(postCompaniesInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error creating company';
        toast('Error creating company', 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(getCompaniesInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getCompaniesInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        companiesInfo: action.payload['hydra:member'][0],
      }))
      .addCase(getCompaniesInfo.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(editCompaniesInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(editCompaniesInfo.fulfilled, (state, action) => {
        toast('Edited companies info successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          companiesInfo: action.payload,
        };
      })
      .addCase(editCompaniesInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error editing companies info';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default companySlice.reducer;
