import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames';
import { URL_ROUTES } from '../constants/routes';
import logo from '../assets/img/logo-purpule.svg';
import apiClient from '../helpers/api.js';
import ErrorAlert from '../components/errorAlert';
import SuccessAlert from '../components/successAlert';
import OnBoardingLogoBox from '../components/onBoardinLogoBox';

function SubCancellation() {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const success = '';
  const [loading, setLoading] = useState(false);

  const onSuccess = async (token: string) => {
    try {
      const response = await apiClient.postNoAuthRequest(
        `/api/stripe/cancel-subscription/${token}`,
      );

      if (response.status === 200) {
        window.location.replace('https://elliotroe.typeform.com/to/leRmat7V');
      }
    } catch (err: any) {
      setError('Subscription cancellation failed! Please try again later.');
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    setError('');
    try {
      const { data } = await apiClient.postRequest('/api/user/cancel-subscription-token');
      if (data.status === 200) {
        onSuccess(data.token);
      }
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  return (
    <div className={classNames('container-fluid onboarding vh-100')}>
      <div className="row h-100">
        <div className={classNames('d-none d-md-flex col-md-6 p-0')}>
          <OnBoardingLogoBox />
        </div>
        <div className={classNames('col-12 col-md-6 p-0 mt-md-5')}>
          <Container className="px-4 mt-5 d-flex flex-column justify-content-around">
            <Row>
              <Col className="col-xl-8 offset-xl-2">
                <div className="onboarding__logo d-flex flex-column justify-content-center align-items-center w-80 m-auto mb-4">
                  <img src={logo} alt="PrimedMind Logo" />
                </div>
                <ErrorAlert errorMessage={error} />
                {success && <SuccessAlert message={success} />}
                <Row className="text-center lh-base">
                  <Col>
                    <h2 className="lh-base">You are about to Unsubscribe from this plan!</h2>
                    <p className="lh-base">
                      Are you sure? You will no longer have access to the Primed Mind library of
                      over 400 mindset coaching sessions.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <div className="my-3 d-flex align-items-center justify-content-between">
                    <Button
                      className="btn-outline-secondary"
                      onClick={() => navigate(URL_ROUTES.Home)}
                      disabled={!!success}
                    >
                      Stay Subscribed
                    </Button>

                    <Button
                      variant="danger"
                      onClick={handleCancelSubscription}
                      disabled={!!success || loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border"
                            style={{ width: '1em', height: '1em', marginRight: '10px' }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </span>
                          Yes, Unsubscribe
                        </>
                      ) : (
                        'Yes, Unsubscribe'
                      )}
                    </Button>
                  </div>
                </Row>

                <Row className="my-5">
                  <Col>
                    <p className="text-center">
                      For more information, please read our{' '}
                      <Link to={URL_ROUTES.TermsOfService} className="color-gray-600">
                        Terms &amp; Conditions
                      </Link>{' '}
                      and{' '}
                      <Link to={URL_ROUTES.PrivacyPolicy} className="color-gray-600">
                        Privacy Policy
                      </Link>
                      .
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default SubCancellation;
