import React from 'react';

interface SuccessAlertProps {
  message: string;
}

function SuccessAlert({ message }: SuccessAlertProps) {
  return (
    <div className="alert alert-success alert-dismissible d-flex align-items-center fade show">
      <i className="bi-check-circle-fill" />
      {message}
    </div>
  );
}

export default SuccessAlert;
