import React from 'react';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { store } from './redux/store';
import App from './App';
import AuthLayer from './redux/authLayer';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/sass/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/css/custom.css';

Sentry.init({
  dsn: 'https://afd9fe48f6ab2224e61ae789b4f06bec@o4506414896513024.ingest.sentry.io/4506416258220032',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});
const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
};

TagManager.initialize(tagManagerArgs);
const container = document.getElementById('root')!;
const root = createRoot(container);
const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID || '';

root.render(
  <Provider store={store}>
    <ToastContainer
      theme="colored"
      position="top-right"
      autoClose={2000}
      pauseOnFocusLoss={false}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
    <GoogleOAuthProvider clientId={googleAppId}>
      <AuthLayer>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthLayer>
    </GoogleOAuthProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
