import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../components/formik/input';
import DragProfileImage from '../components/formik/dragProfileImage';
import { actions as currentUserActions } from '../redux/slices/currentUserSlice';
import { actions as modalActions } from '../redux/slices/modalSlice';
import { THUNK_LOADING, THUNK_STATUS } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { patchCurrentUser, verifyUser } from '../redux/thunks/currentUserThunk';
import ErrorAlert from '../components/errorAlert';
import { toast } from '../helpers/toaster';
import ConfirmDeleteAccount from './confirmDeleteAccount';

const validationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter your email address.'),
  avatar: Yup.string(),
});

function EditAdminProfile() {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.modal.visible === 'editProfile');
  const {
    patchUserInfo: { status, error, message: successMessage },
    email,
    name,
    media,
    userId,
  } = useAppSelector((state) => state.currentUser);
  const handleCloseModal = () => dispatch(modalActions.closeModal());
  const handleClickLogout = () => {
    dispatch(currentUserActions.userLogout());
  };

  const handleConfirmDelete = () => dispatch(modalActions.setVisibleModal('confirmDeleteAccount'));

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      toast(successMessage || 'User information updated.', 'success');
      dispatch(verifyUser());
      dispatch(modalActions.closeModal());
    }
  }, [status]);

  return (
    <>
      <Modal show={showModal} centered>
        <Modal.Header className="pb-0 p-4">
          <button
            type="button"
            className="btn-close icon-size-12"
            aria-label="Close"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body className="pt-0 ps-5 pe-5">
          <Formik
            enableReinitialize
            initialValues={{ name, email, avatar: media?.url }}
            validationSchema={validationSchema}
            onSubmit={({ avatar, ...values }) => {
              const valuesToSend =
                avatar !== media?.url ? { ...values, userId, avatar } : { ...values, userId };
              dispatch(patchCurrentUser(valuesToSend));
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="container mb-5">
                  <div className="row">
                    <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
                    <Field name="avatar" component={DragProfileImage} />
                    <div className="col-8 pe-0 d-flex flex-column justify-content-around align-items-start">
                      <h5>{name}</h5>
                    </div>
                  </div>
                </div>
                <Field
                  component={Input}
                  label="First Name"
                  type="text"
                  name="name"
                  description="First name"
                  placeholder="First name"
                />

                <Field
                  component={Input}
                  label="Email"
                  type="email"
                  name="email"
                  description="email"
                  placeholder="youremail@example.com"
                />

                <div className="container pt-3">
                  <div className="row">
                    <div className="col-6 px-1 ps-0">
                      <button
                        type="button"
                        className="btn btn-outline-dark w-100"
                        data-bs-dismiss="modal"
                        onClick={handleCloseModal}
                      >
                        X Cancel
                      </button>
                    </div>
                    <div className="col-6 px-1 pe-0">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={status === THUNK_LOADING}
                      >
                        {status === THUNK_LOADING ? (
                          <span
                            className="spinner-border"
                            style={{ width: '1em', height: '1em' }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </span>
                        ) : (
                          'Save'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column pb-5">
          <div className="mb-2">
            <button type="button" onClick={handleClickLogout} className="btn btn-text-danger">
              Logout
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={handleConfirmDelete}
              className="btn modal-edit-profile__delete-btn color-danger"
            >
              Delete account
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ConfirmDeleteAccount />
    </>
  );
}

export default EditAdminProfile;
