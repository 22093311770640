import { sampleSize } from 'lodash';
import {
  PaymentCredential,
  Invoice,
  CreditCardType,
} from '../redux/interface/userSettingsInterface';

import course1 from '../assets/img/courses/course-1.png';
import course2 from '../assets/img/courses/course-2.png';
import course3 from '../assets/img/courses/course-3.png';
import course4 from '../assets/img/courses/course-4.png';
import course5 from '../assets/img/courses/course-5.png';
import course6 from '../assets/img/courses/course-6.png';
import course7 from '../assets/img/courses/course-7.png';
import course8 from '../assets/img/courses/course-8.png';
import course9 from '../assets/img/courses/course-9.png';
import course10 from '../assets/img/courses/course-10.png';
import course11 from '../assets/img/courses/course-11.png';
import course12 from '../assets/img/courses/course-12.png';
import course13 from '../assets/img/courses/course-13.png';
import course14 from '../assets/img/courses/course-14.png';
import course15 from '../assets/img/courses/course-15.png';
import course16 from '../assets/img/courses/course-16.png';
import course17 from '../assets/img/courses/course-17.png';
import course18 from '../assets/img/courses/course-18.png';
import course19 from '../assets/img/courses/course-19.png';
import course20 from '../assets/img/courses/course-20.png';
import course21 from '../assets/img/courses/course-21.png';
import course22 from '../assets/img/courses/course-22.png';
import course23 from '../assets/img/courses/course-23.png';
import course24 from '../assets/img/courses/course-24.png';
import course25 from '../assets/img/courses/course-25.png';
import monthlyLogo from '../assets/img/monthly-logo.png';
import yearlyLogo from '../assets/img/yearly-logo.png';
import lifetimeLogo from '../assets/img/lifetime-logo.png';
import {
  ChannelInterface,
  CourseInterface,
  MediaInterface,
  MimeType,
  SessionInterface,
} from '../redux/interface/entitiesInterface';

const mediaMock: MediaInterface = {
  id: '1',
  type: 'audio',
  name: 'audio 1',
  fileName: 'audio1.mp3',
  fileSize: 3000,
  mimeType: MimeType.AudioMpeg,
  disk: 'gcs',
  url: 'https://storage.googleapis.com/primed-mind/sessions/campaign_1_1661240788.mp3',
};

const imageMock: MediaInterface = {
  id: '10',
  type: 'image',
  name: 'smiling-young-woman-doing-crunches-exercise-on-flo-2021-09-03-14-35-14-utc.png',
  fileName: 'images/smiling-young-woman-doing-crunches-exercise-on-flo-2021-09-03-14-35-14-utc.png',
  fileSize: 3200000,
  mimeType: MimeType.ImagePng,
  disk: 'gcs',
  url: 'https://storage.googleapis.com/primed-mind/images/smiling-young-woman-doing-crunches-exercise-on-flo-2021-09-03-14-35-14-utc.png',
};

export const channelMock: ChannelInterface = {
  id: 3,
  title: 'Athletics',
  subtitle: 'Find your focus',
  description: 'Something athletically cool channel description',
  slug: 'channel_slug_3',
  tags: ['Night'],
  isSleep: false,
  isPublished: true,
  courses: [],
  channels: [],
  deepPrimer: [],
  powerPrimer: [],
  media: mediaMock,
};

const mediaMock2: MediaInterface = {
  id: '2',
  type: 'audio',
  name: 'file_example12_MP3_1MG.mp3',
  fileName: 'sessions/mixkit-romantic-01-752.mp3',
  fileSize: 3800000,
  mimeType: MimeType.AudioMpeg,
  disk: 'gcs',
  url: 'https://storage.googleapis.com/primed-mind/sessions/mixkit-romantic-01-752.mp3',
};

export const sessionMock: SessionInterface = {
  id: 12,
  title: 'Campaign 12',
  description: 'Cool description 12',
  isFree: true,
  tags: ['Day', 'Night'],
  image: imageMock,
  narrators: [
    {
      id: 1,
      name: 'narrato',
      duration: '00:01:37',
      durationInSeconds: 97,
      humanReadableDuration: '97s',
      media: mediaMock2,
    },
  ],
  sessionUsers: [],
};

export const courses: CourseInterface[] = [
  {
    id: 1,
    media: { ...imageMock, url: course1 },
    title: 'Name of course 1',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 2,
    media: { ...imageMock, url: course2 },
    title: 'Name of course 2',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 3,
    media: { ...imageMock, url: course3 },
    title: 'Name of course 3',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 4,
    media: { ...imageMock, url: course4 },
    title: 'Name of course 4',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 5,
    media: { ...imageMock, url: course5 },
    title: 'Name of course 5',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 6,
    media: { ...imageMock, url: course6 },
    title: 'Name of course 6',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 7,
    media: { ...imageMock, url: course7 },
    title: 'Name of course 7',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 8,
    media: { ...imageMock, url: course8 },
    title: 'Name of course 8',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 9,
    media: { ...imageMock, url: course9 },
    title: 'Name of course 9',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 10,
    media: { ...imageMock, url: course10 },
    title: 'Name of course 10',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 11,
    media: { ...imageMock, url: course11 },
    title: 'Name of course 11',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 12,
    media: { ...imageMock, url: course12 },
    title: 'Name of course 12',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 13,
    media: { ...imageMock, url: course13 },
    title: 'Name of course 13',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 14,
    media: { ...imageMock, url: course14 },
    title: 'Name of course 14',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 15,
    media: { ...imageMock, url: course15 },
    title: 'Name of course 15',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 16,
    media: { ...imageMock, url: course16 },
    title: 'Name of course 16',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 17,
    media: { ...imageMock, url: course17 },
    title: 'Name of course 17',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 18,
    media: { ...imageMock, url: course18 },
    title: 'Name of course 18',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 19,
    media: { ...imageMock, url: course19 },
    title: 'Name of course 19',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 20,
    media: { ...imageMock, url: course20 },
    title: 'Name of course 20',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 21,
    media: { ...imageMock, url: course21 },
    title: 'Name of course 21',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 22,
    media: { ...imageMock, url: course22 },
    title: 'Name of course 22',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 23,
    media: { ...imageMock, url: course23 },
    title: 'Name of course 23',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 24,
    media: { ...imageMock, url: course24 },
    title: 'Name of course 24',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
  {
    id: 25,
    media: { ...imageMock, url: course25 },
    title: 'Name of course 25',
    description: 'Lorem ipsum.',
    subtitle: 'Lorem ipsum.',
    tags: [],
    isDayCourse: false,
    sessions: [sessionMock],
  },
];

export const generateCourseList = (limit = 3) => sampleSize(courses, limit);

export const channels = [
  {
    name: 'Mindset Coaching',
    id: 'mindset-coaching',
    image: { ...imageMock, url: course25 },
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-brain-circuit',
  },
  {
    name: 'Dailies',
    id: 'dailies',
    image: { ...imageMock, url: course3 },
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-calendar',
  },
  {
    name: 'Conquer Anything',
    id: 'conquer',
    imgSrc: course21,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-mountains',
  },
  {
    name: 'Calm',
    id: 'calm',
    imgSrc: course6,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-leaf',
  },
  {
    name: 'Challenge Yourself',
    id: 'challenge',
    imgSrc: course13,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-trophy',
  },
  {
    name: 'Feel Good',
    id: 'feel-good',
    imgSrc: course10,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-face-smile',
  },
  {
    name: 'Play + Compete',
    id: 'play-compete',
    imgSrc: course1,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-bullseye-arrow',
  },
  {
    name: 'Get Fit',
    id: 'get-fit',
    imgSrc: course17,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-dumbbell',
  },
  {
    name: 'Study',
    id: 'study',
    imgSrc: course18,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-book',
  },
  {
    name: 'Work',
    id: 'work',
    imgSrc: course14,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-briefcase',
  },
  {
    name: 'Socialize',
    id: 'socialize',
    imgSrc: course7,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-user-group',
  },
  {
    name: 'Sleep',
    id: 'sleep',
    imgSrc: course17,
    course1: sampleSize(courses, 6),
    course2: sampleSize(courses, 6),
    icon: 'fa-moon',
  },
];

export const paymentMethods: PaymentCredential[] = [
  {
    id: 1,
    last4Digit: '1234',
    brand: CreditCardType.VISA,
    name: 'John Smith',
    expiryMonth: 3,
    expiryYear: 24,
    isPrimary: true,
  },
  {
    id: 2,
    last4Digit: '4321',
    brand: CreditCardType.MASTERCARD,
    name: 'John Smith',
    expiryMonth: 2,
    expiryYear: 25,
    isPrimary: false,
  },
  {
    id: 3,
    last4Digit: '2233',
    brand: CreditCardType.MASTERCARD,
    name: 'John R. Smith',
    expiryMonth: 12,
    expiryYear: 22,
    isPrimary: false,
  },
  {
    id: 4,
    last4Digit: '2121',
    brand: CreditCardType.VISA,
    name: 'John Smith',
    expiryMonth: 3,
    expiryYear: 24,
    isPrimary: false,
  },
];

export const invoices: Invoice[] = [
  { id: 1255, date: '28/06/2022', amount: 100 },
  { id: 1145, date: '28/05/2022', amount: 100 },
  { id: 1102, date: '28/04/2022', amount: 100 },
  { id: 1094, date: '28/03/2022', amount: 100 },
  { id: 1080, date: '28/02/2022', amount: 100 },
  { id: 1011, date: '28/01/2022', amount: 100 },
];

export const plans = [
  { name: 'Monthly', price: '19.99', logo: monthlyLogo, features: [0, 1] },
  { name: 'Yearly', price: '149.99', logo: yearlyLogo, features: [0, 1, 2, 3], isCurrent: true },
  { name: 'Lifetime', price: '149.99', logo: lifetimeLogo, features: [0, 1, 2, 3, 4] },
];

export const planExtras = [
  { frequency: 'monthly', logo: monthlyLogo, features: [0, 1] },
  { frequency: 'yearly', logo: yearlyLogo, features: [0, 1, 2, 3] },
  { frequency: 'lifetime', logo: lifetimeLogo, features: [0, 1, 2, 3, 4] },
];

export const SleepChannel = {
  id: 0,
  title: 'Sleep',
  subtitle: 'Basically How to Sleep',
  description: 'Sleep',
  slug: 'sleep',
  tags: ['Day', 'Night'],
  isSleep: true,
  isPublished: true,
  media: {
    id: '3',
    type: 'Media',
    name: 'file_example1_MP3_1MG.mp3',
    fileName: 'sessions/campaign_1_1661240788.mp3',
    fileSize: 1087849,
    mimeType: MimeType.AudioMpeg,
    disk: 'gcs',
    url: 'https:/storage.googleapis.com/primed-mind/sessions/campaign_1_1661240788.mp3',
  },
  courses: [],
  channels: [],
  deepPrimer: [
    {
      id: 14,
      title: 'Campaign 14',
      description: 'Cool description 14',
      duration: '00:23:15',
      type: 'deep-primer',
      isFree: true,
      tags: ['Day', 'Night'],
      image: imageMock,
      media: {
        id: '2',
        type: 'Media',
        name: 'file_example14_MP3_1MG.mp3',
        fileName: 'sessions/mixkit-feel-alive-953.mp3',
        fileSize: 3200000,
        mimeType: MimeType.AudioMpeg,
        disk: 'gcs',
        url: 'https:/storage.googleapis.com/primed-mind/sessions/mixkit-feel-alive-953.mp3',
      },
    },
  ],
  powerPrimer: [
    {
      id: 1,
      title: 'Campaign 1',
      description: 'Cool description 1',
      duration: '00:10:27',
      type: 'power-primer',
      isFree: true,
      tags: ['Day', 'Night'],
      image: imageMock,
      media: {
        id: '2',
        type: 'Media',
        name: 'file_example1_MP3_1MG.mp3',
        fileName: 'sessions/campaign_1_1661240788.mp3',
        fileSize: 1087849,
        mimeType: MimeType.AudioMpeg,
        disk: 'gcs',
        url: 'https:/storage.googleapis.com/primed-mind/sessions/campaign_1_1661240788.mp3',
      },
    },
    {
      id: 4,
      title: 'Campaign 4',
      description: 'Cool description 4',
      duration: '00:25:00',
      type: 'power-primer',
      isFree: true,
      tags: ['Day', 'Night'],
      image: imageMock,
      media: {
        id: '2',
        type: 'Media',
        name: 'file_example4_MP3_1MG.mp3',
        fileName: 'sessions/mixkit-games-worldbeat-466.mp3',
        fileSize: 3300000,
        mimeType: MimeType.AudioMpeg,
        disk: 'gcs',
        url: 'https:/storage.googleapis.com/primed-mind/sessions/mixkit-games-worldbeat-466.mp3',
      },
    },
  ],
};

export const mockAchievements = [
  {
    id: 1,
    title: 'Levels',
    achieved: true,
    media: {
      name: 'elite_perfomer_double_dip.png',
      fileName: 'awards/elite_perfomer_double_dip.png',
      fileSize: 38095,
      mimeType: 'image/png',
      disk: 'gcp',
      url: 'https://storage.googleapis.com/primed-mind/awards/elite_perfomer_primed_life.png',
    },
    awards: [
      {
        id: 2,
        title: 'Level 0',
        media: {
          name: 'elite_perfomer_double_dip.png',
          fileName: 'awards/elite_perfomer_double_dip.png',
          fileSize: 38095,
          mimeType: 'image/png',
          disk: 'gcp',
          url: 'https://storage.googleapis.com/primed-mind/awards/elite_perfomer_plus_subscriber.png',
        },
        achieved: true,
      },
      {
        id: 3,
        title: 'Level 1',
        media: {
          name: 'elite_perfomer_double_dip.png',
          fileName: 'awards/elite_perfomer_double_dip.png',
          fileSize: 38095,
          mimeType: 'image/png',
          disk: 'gcp',
          url: 'https://storage.googleapis.com/primed-mind/awards/elite_perfomer_explore_category-5.png',
        },
        achieved: false,
      },
    ],
  },
  {
    id: 4,
    title: 'Beginner',
    achieved: true,
    media: {
      name: 'elite_perfomer_double_dip.png',
      fileName: 'awards/elite_perfomer_double_dip.png',
      fileSize: 38095,
      mimeType: 'image/png',
      disk: 'gcp',
      url: 'https://storage.googleapis.com/primed-mind/awards/elite_perfomer_double_dip.png',
    },
    awards: [
      {
        id: 5,
        title: 'First Primer',
        media: {
          name: 'elite_perfomer_double_dip.png',
          fileName: 'awards/elite_perfomer_double_dip.png',
          fileSize: 38095,
          mimeType: 'image/png',
          disk: 'gcp',
          url: 'https://storage.googleapis.com/primed-mind/awards/beginner_share_primer.png',
        },
        achieved: false,
      },
      {
        id: 6,
        title: 'Level 1',
        media: {
          name: 'elite_perfomer_double_dip.png',
          fileName: 'awards/elite_perfomer_double_dip.png',
          fileSize: 38095,
          mimeType: 'image/png',
          disk: 'gcp',
          url: 'https://storage.googleapis.com/primed-mind/awards/beginner_first_upgrade.png',
        },
        description: '',
        achieved: true,
      },
    ],
  },
];
