/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface DragAudioProps {
  setAudio: React.Dispatch<React.SetStateAction<any>>;
  file?: any;
  selectedFile: any;
  setSelectedFile: React.Dispatch<React.SetStateAction<any>>;
  from?: any;
}

function DragAudio({ setAudio, selectedFile, setSelectedFile, file }: DragAudioProps) {
  useEffect(() => {
    setSelectedFile(file);
  }, [file]);
  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setAudio(acceptedFiles[0]);
      setSelectedFile(acceptedFiles[0]);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'audio/*': [],
    },
  });

  return (
    <div {...getRootProps()} className="form-control radius-10 dropzone-div text-truncate">
      <input {...getInputProps()} className="dropzone-input mw-100 mh-100 w-100 h-100 shadow" />
      <span>{selectedFile?.name ? selectedFile?.name : 'Drag and drop new audio here'}</span>
    </div>
  );
}

DragAudio.defaultProps = {
  file: '',
  from: '',
};

export default DragAudio;
