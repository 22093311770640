import React, { useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useAppDispatch } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import AddChannelToChannel from '../../../containers/channels/modals/addChannelToChannel';
import { swapElement } from '../../../helpers';

interface SelectChannelProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
}

function SelectChannel({ field, form, label }: SelectChannelProps) {
  const dispatch = useAppDispatch();

  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;

  const [data, setData] = useState(value ? [...value] : []);

  useEffect(() => {
    setData(value);
  }, [value]);

  const reArrangeList = (action: string, courseId: string) => {
    const channels = [...data];
    const itemIndex = channels.findIndex((item: any) => item.id === courseId);
    const nextIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;
    const orderedChannels = swapElement(channels, itemIndex, nextIndex).map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setData(orderedChannels);
    setFieldValue(name, orderedChannels);
  };

  const deleteChannelFromList = (id: number) => {
    const newData = data.filter((courseItem: any) => courseItem.id !== id);
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const addChannelToList = (selectedCourse: any) => {
    const newData = [...data, selectedCourse];
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const handleOpenAddChannelModal = () => {
    dispatch(modalActions.setVisibleModal('AddChannelToChannelModal'));
  };

  return (
    <>
      <h6 className="text-center">{label || 'Channels'}</h6>
      <ul className="list-group list-group--channels mb-2">
        {data?.map((schannel: any, index: number) => (
          <li className="list-group-item" key={schannel.id}>
            <span className="order-icons">
              <i
                className={`fa-solid fa-arrow-up ${index === 0 ? 'disabled' : ''}`}
                role="button"
                tabIndex={-1}
                aria-label={`Move up ${schannel.title}`}
                onKeyUp={() => (index === 0 ? {} : reArrangeList('up', schannel.id))}
                onClick={() => (index === 0 ? {} : reArrangeList('up', schannel.id))}
              />
              <i
                className={`fa-solid fa-arrow-down ${index === data.length - 1 ? 'disabled' : ''}`}
                role="button"
                tabIndex={-1}
                aria-label={`Move down ${schannel.title}`}
                onKeyUp={() =>
                  index === data.length - 1 ? {} : reArrangeList('down', schannel.id)
                }
                onClick={() =>
                  index === data.length - 1 ? {} : reArrangeList('down', schannel.id)
                }
              />
            </span>
            <span>{schannel?.title}</span>
            <i
              className="fa fa-times"
              role="button"
              tabIndex={-1}
              aria-label={`Remove ${schannel?.title}`}
              onKeyUp={() => deleteChannelFromList(schannel.id)}
              onClick={() => deleteChannelFromList(schannel.id)}
            />
          </li>
        ))}
      </ul>

      <button
        className="btn btn-primary w-100 radius-8"
        type="button"
        onClick={handleOpenAddChannelModal}
      >
        <i className="fa fa-plus" />
        &nbsp; Add Channels
      </button>
      <AddChannelToChannel
        currentChannelId={form.values?.id ? form.values?.id : -1}
        selectedChannels={data}
        onSelectChannel={addChannelToList}
      />
    </>
  );
}

SelectChannel.defaultProps = {
  label: '',
};

export default SelectChannel;
