import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { shuffle } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import Skeleton from 'react-loading-skeleton';
import AudioPlayer from '../../components/audio/player';
import AudioTracklist from '../../components/audio/tracklist';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getChannelInfo, getCourseInfo, getSessionInfo } from '../../redux/thunks/entitiesThunk';
import { saveSessionUserPoint, getRecentAudioSessions } from '../../redux/thunks/playerThunk';
import { actions } from '../../redux/slices/playerSlice';
import { THUNK_STATUS, defaultMetatags } from '../../constants';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { getRecommendationsHome } from '../../redux/thunks/homeThunk';
import {
  SessionInterface,
  CourseInterface,
  ChannelInterface,
} from '../../redux/interface/entitiesInterface';
import ShareAudio, { MODAL_NAME as SHARE_SESSION_MODAL } from '../../components/modal/shareAudio';

interface EntityTypeParams {
  type: string;
  ref: string;
  info: any;
  list: SessionInterface[];
}

interface SavePointParams {
  session: SessionInterface;
  course?: CourseInterface;
  channel?: any;
}

let savePointParams: SavePointParams;

function PlayerPage() {
  const params = useParams();
  const { sessionId, listType, parentId } = params;
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [parentType, setParentType] = useState<string>('');
  const playabls = useAppSelector((state) => state.player.playables);
  const playables = useMemo(() => playabls, [playabls]);
  const [listTitle, setListTitle] = useState<string>('');
  const [parentInfo, setParentInfo] = useState<CourseInterface | ChannelInterface>();
  const { course: courseLoadState, channel: channelLoadState } = useAppSelector(
    (state) => state.player.loading,
  );

  const session = useAppSelector((state) =>
    state.player.loading.session === THUNK_STATUS.SUCCEEDED ? state.player.session : null,
  );
  const tracklist = useAppSelector((state) => state.player.tracklist);
  const currentTrack = useAppSelector((state) => state.player.currentTrack);

  useEffect(() => {
    if (listType !== 'upgrade') {
      dispatch(getRecentAudioSessions());
    }
  }, []);

  useEffect(() => {
    dispatch(getSessionInfo(Number(sessionId))).then((responseSession: PayloadAction<any>) => {
      let courseProm;
      if (listType) {
        switch (listType) {
          case 'channel': {
            const ref = searchParams.get('ref');
            courseProm = dispatch(getChannelInfo(Number(parentId))).then(
              (responseChannel: PayloadAction<any>) => {
                setListTitle(responseChannel.payload?.title);
                if (ref && ['powerPrimer', 'deepPrimer'].includes(ref)) {
                  dispatch(
                    actions.setPlayables(Array(responseChannel.payload[ref].length).fill(true)),
                  );
                  return {
                    type: 'channel',
                    ref,
                    info: responseChannel.payload,
                    list: responseChannel.payload[ref],
                  };
                }
                return {
                  type: 'channel',
                  ref: 'upgrade',
                  info: responseChannel.payload,
                  list: responseChannel.payload.course,
                };
              },
            );
            break;
          }
          case 'upgrade':
            courseProm = dispatch(getCourseInfo(Number(parentId))).then(
              (responseCourse: PayloadAction<any>) => {
                setListTitle(responseCourse.payload?.title);
                const playablesSetup = responseCourse.payload?.sessions.map(
                  ({ playable: pl }: { playable: boolean }) => pl,
                );
                if (Number(sessionId) !== responseCourse.payload?.sessions[0].id) {
                  const courseIdx = responseCourse.payload.sessions.findIndex(
                    ({ id: sessId }: { id: number }) => Number(sessionId) === sessId,
                  );
                  if (courseIdx > -1) {
                    playablesSetup[courseIdx] = true;
                  }
                }
                dispatch(actions.setPlayables(playablesSetup));

                return {
                  type: 'upgrade',
                  ref: '',
                  info: responseCourse.payload,
                  list: responseCourse.payload.sessions,
                };
              },
            );
            break;
          default: {
            const ref = searchParams.get('ref') || '';
            if (ref) {
              setListTitle(
                `Recommendations for ${ref === 'powerPrimer' ? 'Power' : 'Deep'} Primers`,
              );

              courseProm = dispatch(getRecommendationsHome()).then(
                (responseRecomm: PayloadAction<any>) => {
                  const indexRef = ref === 'powerPrimer' ? 'power_primers' : 'deep_primers';
                  dispatch(
                    actions.setPlayables(Array(responseRecomm.payload[indexRef].length).fill(true)),
                  );
                  return {
                    type: 'recommended',
                    ref,
                    info: responseRecomm.payload,
                    list: responseRecomm.payload[indexRef],
                  };
                },
              );
            } else {
              setListTitle('Sessions');
              dispatch(actions.setPlayables(Array(tracklist.length).fill(true)));
              courseProm = Promise.resolve({
                type: 'sessions',
                ref: '',
                info: tracklist,
                list: tracklist,
              });
            }
            break;
          }
        }
      } else if (responseSession?.payload?.courses) {
        const [courseLoad] = responseSession.payload.courses;
        courseProm = dispatch(getCourseInfo(courseLoad.id)).then(
          (responseCourse: PayloadAction<any>) => {
            dispatch(
              actions.setPlayables(
                responseCourse.payload?.sessions.map(
                  ({ playable: pl }: { playable: boolean }) => pl,
                ),
              ),
            );
            return {
              type: 'upgrade',
              ref: '',
              info: responseCourse.payload,
              list: responseCourse.payload.sessions,
            };
          },
        );
      }
      courseProm?.then(({ type, info, list }: EntityTypeParams) => {
        dispatch(actions.setTracklist(list));

        setParentInfo(info);
        setParentType(type);

        const startTrackIdx = list.findIndex((item) => item.id === Number(sessionId));
        dispatch(actions.setCurrentTrackOrder(startTrackIdx));

        const sess = responseSession.payload;

        savePointParams = {
          session: sess,
        };

        if (type === 'channel') {
          savePointParams.channel = info;
        } else if (type === 'upgrade') {
          savePointParams.course = info;
        }

        if (sess) {
          dispatch(
            saveSessionUserPoint({
              ...savePointParams,
              status: 0,
              narrator: sess.narrators[0],
              channel: { ...savePointParams?.channel, id: Number(parentId) },
            }),
          );
        }
      });
    });
  }, [sessionId]);

  const handleSessionEnded = useCallback(
    (narratorIdx: number, currentSession: SessionInterface | undefined | null) => {
      if (currentSession) {
        dispatch(actions.setNextTrackToPlayable());

        dispatch(
          saveSessionUserPoint({
            ...savePointParams,
            session: currentSession,
            status: 2,
            narrator: currentSession.narrators[narratorIdx],
            channel: { ...savePointParams?.channel, id: Number(parentId) },
          }),
        );
      }
      dispatch(actions.nextSession({ nextButtonPressed: false }));
    },
    [tracklist],
  );

  const handleSavePoint = (
    { currentTime }: { timeString: string; currentTime: number },
    narrator: number,
    currentSession: SessionInterface | undefined | null,
  ) => {
    if (!currentSession) {
      return;
    }

    const paramsToSend = {
      ...savePointParams,
      session: currentSession,
      status: 1,
      played: currentTime,
      narrator: currentSession.narrators[narrator],
      channel: { ...savePointParams?.channel, id: Number(parentId) },
    };
    dispatch(saveSessionUserPoint(paramsToSend));
  };

  const handleSaveFavorite = (
    narrator: number,
    isFavorite: boolean,
    currentSession: SessionInterface | undefined | null,
  ) => {
    if (!currentSession) {
      return;
    }
    const paramsToSend = {
      ...savePointParams,
      session: currentSession,
      narrator: currentSession.narrators[narrator],
      isFavorite,
      channel: { ...savePointParams?.channel, id: Number(parentId) },
    };
    dispatch(saveSessionUserPoint(paramsToSend));
  };

  const handleChangeSession = (selectedSession: SessionInterface, trackOrder: number) => {
    dispatch(actions.setSession(selectedSession));
    dispatch(actions.setCurrentTrackOrder(trackOrder));
    dispatch(actions.setLoopMode(false));
  };

  const handleShareSession = (
    narrator: number,
    selectedSession: SessionInterface | undefined | null,
  ) => {
    if (!selectedSession) {
      return;
    }
    dispatch(
      modalActions.setInfo({
        [SHARE_SESSION_MODAL]: {
          ...savePointParams,
          narrator: selectedSession.narrators[narrator],
          session: selectedSession,
        },
      }),
    );
    dispatch(modalActions.setVisibleModal(SHARE_SESSION_MODAL));
  };

  const handleShuffle = () => {
    const newList = shuffle(tracklist);
    dispatch(actions.setSession(newList[0]));
    dispatch(actions.setCurrentTrackOrder(0));
    dispatch(actions.setTracklist(newList));
  };

  const handlePlayDenied = (currentSession: SessionInterface) => {
    const currentSessionIdx = tracklist.findIndex((sess) => sess.id === currentSession.id);
    dispatch(actions.setPlayable({ idx: currentSessionIdx + 1, value: true }));
    dispatch(modalActions.setInfo({ subscriptionModal: { openSubOnly: true } }));
    dispatch(modalActions.setVisibleModal('subscriptionModal'));
  };

  return (
    <div className="container-fluid player-bage flex-grow-1 p-0 content content--player-page">
      <Helmet>
        <title>{`${session?.title ? `${session.title} - ${listTitle || ''} ` : ''}${
          defaultMetatags.title
        }`}</title>
        <meta
          name="description"
          content={session?.description ? session?.description : defaultMetatags.title}
        />
      </Helmet>
      <AudioPlayer
        session={session}
        courseMode={parentType === 'upgrade'}
        disableNextSessionButton={
          currentTrack + 1 <= playables.length && !playables[currentTrack + 1]
        }
        onSessionEnded={handleSessionEnded}
        onShuffleClicked={handleShuffle}
        onUserListenProgress={handleSavePoint}
        onFavoriteSession={handleSaveFavorite}
        onShareSession={handleShareSession}
        onPlayDenied={handlePlayDenied}
      />
      <div className="py-3 py-md-4 px-3 px-md-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between pe-lg-3">
              <h4 className="m-0">{listTitle || <Skeleton width={100} height={20} />}</h4>
            </div>

            <div className="table-responsive mt-4">
              <AudioTracklist
                listLoading={
                  courseLoadState === THUNK_STATUS.LOADING ||
                  channelLoadState === THUNK_STATUS.LOADING
                }
                playableMap={playables}
                parentInfo={parentInfo}
                parentType={parentType}
                sessions={tracklist}
                courseMode={parentType === 'upgrade'}
                currentlyPlayed={session}
                onSelectSession={handleChangeSession}
              />
            </div>
          </div>
        </div>
      </div>
      {session && <ShareAudio sessionPlayed={session} />}
    </div>
  );
}

export default PlayerPage;
