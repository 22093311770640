import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {
  ChannelInterface,
  CourseInterface,
  SessionInterface,
} from '../../redux/interface/entitiesInterface';
import logo from '../../assets/img/logo.svg';

import elliotRoePlayer from '../../assets/img/newPhotos/Elliot Roe 3.png';
import adrienneCarterPlayer from '../../assets/img/newPhotos/Adrienne Carter 3.png';
import christianWeilharterPlayer from '../../assets/img/newPhotos/Christian Weilharter 3.png';
import sammHunterPlayer from '../../assets/img/newPhotos/Samm Hunter 3.png';
import stephenBakerPlayer from '../../assets/img/newPhotos/Stephen Baker 3.png';

import { useAuth } from '../../redux/authLayer';
import { Narrator } from '../../redux/interface/sessionInterface';

interface AudioTracklistProps {
  listLoading: boolean;
  courseMode?: boolean;
  playableMap: boolean[];
  sessions: SessionInterface[];
  parentInfo: CourseInterface | ChannelInterface | undefined;
  parentType: string | undefined;
  currentlyPlayed: SessionInterface | null;
  onSelectSession: (selected: SessionInterface, trackOrder: number) => void;
}

export const narratorPngImages = [
  { name: 'Elliot Roe', avatar: elliotRoePlayer },
  { name: 'Christian Weilharter', avatar: christianWeilharterPlayer },
  { name: 'Adrienne Carter', avatar: adrienneCarterPlayer },
  { name: 'Samm Hunter', avatar: sammHunterPlayer },
  { name: 'Stephen Baker', avatar: stephenBakerPlayer },
];

function AudioTracklist({
  sessions,
  listLoading,
  playableMap,
  courseMode,
  currentlyPlayed,
  onSelectSession,
}: AudioTracklistProps) {
  const { isFree } = useAuth();

  if (listLoading) {
    return (
      <table className="table playlist-table text-nowrap">
        <thead>
          <tr>
            <td className="font-medium">
              <Skeleton width={24} height={24} />
            </td>
            <td className="font-medium">
              <Skeleton width={100} height={24} />
            </td>
            <td className="font-medium">
              <Skeleton width={150} height={24} />
            </td>
            <td className="font-medium">
              <Skeleton width={100} height={24} />
            </td>
            {/* <td>&nbsp;</td> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Skeleton width={20} height={20} />
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <Skeleton width={150} height={20} />
            </td>
            {/* <td>
              <Skeleton width={20} height={20} />
            </td> */}
          </tr>
          <tr>
            <td>
              <Skeleton width={20} height={20} />
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <Skeleton width={150} height={20} />
            </td>
            {/* <td>
              <Skeleton width={20} height={20} />
            </td> */}
          </tr>
          <tr>
            <td>
              <Skeleton width={20} height={20} />
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <Skeleton width={150} height={20} />
            </td>
            {/* <td>
              <Skeleton width={20} height={20} />
            </td> */}
          </tr>
          <tr>
            <td>
              <Skeleton width={20} height={20} />
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <Skeleton width={150} height={20} />
            </td>
            {/* <td>
              <Skeleton width={20} height={20} />
            </td> */}
          </tr>
          <tr>
            <td>
              <Skeleton width={20} height={20} />
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <div className="playlist-table__course d-flex w-100">
                <Skeleton width={50} height={50} className="me-md-2" />
                <Skeleton width={100} height={24} />
              </div>
            </td>
            <td>
              <Skeleton width={150} height={20} />
            </td>
            {/* <td>
              <Skeleton width={20} height={20} />
            </td> */}
          </tr>
        </tbody>
      </table>
    );
  }

  const renderAvatars = (narrators: Narrator[]) =>
    narrators.map((narrator) => {
      const avatarToUse = narratorPngImages.find((narr) => narr.name === narrator.name);

      const avatar = avatarToUse ? (
        <img
          src={avatarToUse.avatar}
          alt={avatarToUse.name}
          className="avatar me-2"
          style={{ background: 'white' }}
        />
      ) : (
        <div className="avatar me-2">{narrator.name?.charAt(0)}</div>
      );

      return (
        <div className="d-flex align-items-center gap-1" key={narrator.name}>
          {avatar} {narrator.name}
        </div>
      );
    });

  return (
    <table className="table playlist-table text-nowrap">
      <thead>
        <tr>
          <th className="font-medium">#</th>
          <th className="font-medium">Title</th>
          <th className="font-medium">Narrator</th>
          <th className="font-medium">Time</th>
          {/* <th>&nbsp;</th> */}
        </tr>
      </thead>
      <tbody>
        {sessions &&
          sessions?.map((session, idx) => {
            const isCurrent = session?.id === currentlyPlayed?.id;
            const { narrators } = session;
            const [firstNarrator] = narrators;
            const inaccessible = isFree && !session.isFree;

            const renderListItemBullet = () => {
              if (isCurrent) {
                return (
                  <i
                    className={classNames(
                      'fa-regular icon-size-20',
                      inaccessible ? 'fa-lock' : 'fa-volume',
                    )}
                  />
                );
              }
              return inaccessible || (courseMode && !playableMap[idx]) ? (
                <i className={classNames('fa-regular icon-size-20 fa-lock')} />
              ) : (
                idx + 1
              );
            };

            return (
              <tr
                className={classNames(
                  isCurrent && 'playing',
                  inaccessible && 'inaccessible',
                  courseMode && !playableMap[idx] && 'disabled',
                )}
                key={JSON.stringify(session)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(courseMode && !playableMap[idx]
                  ? {}
                  : { role: 'button', onClick: () => onSelectSession(session, idx) })}
              >
                <td>{renderListItemBullet()}</td>
                <td>
                  <div className="d-flex align-items-center justify-content-start playlist-table__course">
                    {session?.image?.url ? (
                      <img src={session?.image?.url} alt="" style={{ objectFit: 'cover' }} />
                    ) : (
                      <div className="d-flex onboarding__card rounded-3 bg-secondary default-image-div">
                        <img src={logo} alt="Primed Mind logo" />
                      </div>
                    )}
                    <span className="text-truncate">{session.title}</span>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-5">{renderAvatars(narrators)}</div>
                </td>
                <td>{firstNarrator.duration}</td>
                {/* <td>
                  <button type="button" className="btn">
                    <i className="fa-regular fa-ellipsis icon-size-20 cursor-pointer" />
                  </button>
                </td> */}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

AudioTracklist.defaultProps = {
  courseMode: false,
};

export default AudioTracklist;
