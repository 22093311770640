import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../components/formik/input';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { THUNK_LOADING, THUNK_STATUS } from '../constants';
import { URL_ROUTES } from '../constants/routes';
import ErrorAlert from '../components/errorAlert';
import { requestResetPassword } from '../redux/thunks/currentUserThunk';
import TncPpLinks from '../components/TncPpLinks';
import LockIcon from '../assets/img/lock-icon.png';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter your email address.'),
});

function RestorePassword() {
  const dispatch = useAppDispatch();
  const {
    status,
    error,
    message: successMessage,
  } = useAppSelector((state) => state.currentUser.resetPass);

  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      {status === THUNK_STATUS.SUCCEEDED && successMessage ? (
        <div className="onboarding__form-box d-flex flex-column w-100 pt-4 pt-xl-5 mt-auto">
          <div className="mb-3 d-flex justify-content-center w-100 mb-4">
            <div className="onboarding__lock-icon-box d-flex align-items-center justify-content-center">
              <img src={LockIcon} alt="" />
            </div>
          </div>

          <h3 className="mb-3 mb-lg-4 text-center color-black">Restore Password</h3>

          <p className="text-center">
            We have sent an email to your email with a link to reset your password. Follow this link
            and enter a new password
          </p>
        </div>
      ) : (
        <>
          <div className="py-4 d-flex w-100">
            <Link to={URL_ROUTES.Login} className="btn btn-link text-decoration-none">
              <i className="fa-regular fa-angle-left color-primary me-1" />
              <span className="color-black">Back</span>
            </Link>
          </div>
          <div className="onboarding__form-box d-flex flex-column w-100 mt-auto">
            <h3 className="mb-3 mb-lg-4 text-center color-black">Welcome to Primed Mind!</h3>
            <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={({ email }) => {
                dispatch(requestResetPassword(email));
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={Input}
                    type="email"
                    label="Email"
                    name="email"
                    description="email"
                    placeholder="Your Email"
                  />

                  <div className="mb-3">
                    <button
                      type="submit"
                      className="d-block btn btn-primary px-5 w-75 m-auto"
                      disabled={status === THUNK_LOADING}
                    >
                      {status === THUNK_LOADING ? (
                        <span
                          className="spinner-border"
                          style={{ width: '1em', height: '1em' }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      ) : (
                        <>
                          Continue <i className="fa-regular fa-arrow-right ms-1" />
                        </>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}
      <TncPpLinks />
    </div>
  );
}

export default RestorePassword;
