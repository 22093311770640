import React from 'react';
import classNames from 'classnames';
import { generatePath, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import mobileLogo from '../assets/img/logo-mobile.svg';
import NavItem from './navItem';
import { useAuth } from '../redux/authLayer';
import { URL_ROUTES } from '../constants/routes';
import { useServices } from '../redux/servicesLayer';

function Sidebar() {
  const { isEndUser, isSuperuser, isTeamOwner, isTeamMember } = useAuth();
  const { pathname } = useLocation();
  const { mainChannels: channels } = useServices();

  return (
    <div className="col-auto sidebar p-0">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-2 px-md-3 p-4 min-vh-100">
        <div className="sidebar__logo d-flex justify-content-center w-100">
          <img className="d-none d-md-block" src={logo} alt="Logo" />
          <img className="d-md-none" src={mobileLogo} alt="Logo" />
        </div>
        <hr className="w-100" />
        <div className="d-flex flex-column align-items-center align-items-md-start w-100 sidebar__menu">
          <ul
            className="nav nav-pills nav--side-menu d-flex flex-column mb-md-auto mb-0 align-items-center align-items-md-start w-100"
            id="menu"
          >
            {isEndUser && (
              <>
                <NavItem
                  path={URL_ROUTES.Home}
                  icon="fa-house"
                  name="Home"
                  linkClass={classNames('navItemHome', pathname === URL_ROUTES.Home && 'active')}
                />
                <NavItem
                  path={URL_ROUTES.SevenDayCourses}
                  icon="fa-book-open"
                  name="Upgrades"
                  linkClass={classNames(
                    'navItem7DayCourses',
                    pathname === URL_ROUTES.SevenDayCourses && 'active',
                  )}
                />
                <li className="nav-item">
                  <a
                    href="#submenuChannels"
                    data-bs-toggle="collapse"
                    className="navItemChannels nav-link nav-link--submenu align-middle d-flex align-items-center"
                  >
                    <i className="fa-regular fa-desktop me-md-2" />{' '}
                    <span className="ms-1 d-none d-md-inline">Explore</span>{' '}
                    <i className="fa-regular fa-angle-down ms-2 ms-md-auto" />
                  </a>
                  <ul
                    className="collapse nav nav--side-menu flex-column ps-2 mt-1"
                    id="submenuChannels"
                    data-bs-parent="#menu"
                  >
                    {channels
                      .filter(({ isSleep }) => !isSleep)
                      .map((channel) => (
                        <NavItem
                          key={JSON.stringify(channel)}
                          path={generatePath(URL_ROUTES.Explore, {
                            channelSlug: channel.slug,
                            channelId: channel.id.toString(),
                          })}
                          name={channel.title}
                          linkClass={classNames(
                            pathname ===
                              generatePath(URL_ROUTES.Explore, {
                                channelSlug: channel.slug,
                                channelId: channel.id.toString(),
                              }) && 'active',
                          )}
                        />
                      ))}
                  </ul>
                </li>
                <NavItem
                  path={URL_ROUTES.Sleep}
                  icon="fa-moon"
                  name="Sleep"
                  linkClass={classNames('navItemSleep', pathname === URL_ROUTES.Sleep && 'active')}
                />
              </>
            )}
            {isSuperuser && (
              <>
                <NavItem
                  path={URL_ROUTES.AppUsers}
                  icon="fa-user"
                  name="App Users"
                  linkClass={classNames(pathname === URL_ROUTES.AppUsers && 'active')}
                />
                <NavItem
                  path={URL_ROUTES.Channels}
                  icon="fa-wifi"
                  name="Channels"
                  linkClass={classNames(pathname === URL_ROUTES.Channels && 'active')}
                />
                <NavItem
                  path={URL_ROUTES.Upgrades}
                  icon="fa-book-open"
                  name="Upgrades"
                  linkClass={classNames(pathname === URL_ROUTES.Upgrades && 'active')}
                />
                <NavItem
                  path={URL_ROUTES.Sessions}
                  icon="fa-display"
                  name="Sessions"
                  linkClass={classNames(pathname === URL_ROUTES.Sessions && 'active')}
                />
                <NavItem
                  path={URL_ROUTES.Teams}
                  icon="fa-user-group"
                  name="Teams"
                  linkClass={classNames(pathname === URL_ROUTES.Teams && 'active')}
                />
              </>
            )}
          </ul>
          {((isEndUser && isTeamMember && isTeamOwner) ||
            (isEndUser && !isTeamMember) ||
            isSuperuser) && (
            <>
              <hr className="w-100" />
              <ul
                className="nav nav-pills d-flex flex-column nav--side-menu mb-md-auto mb-0 align-items-center align-items-md-start w-100"
                id="adminMenu"
              >
                <li className="nav-item">
                  <a
                    href="#submenuAdmin"
                    data-bs-toggle="collapse"
                    className="nav-link nav-link--submenu align-middle d-flex align-items-center"
                  >
                    <i
                      className={`fa-regular me-md-2 fa-${
                        isEndUser ? 'lock-keyhole' : 'microphone'
                      }`}
                    />{' '}
                    <span className="ms-1 d-none d-md-inline">
                      {isSuperuser ? 'Admin' : 'Profile'}
                    </span>{' '}
                    <i className="fa-regular fa-angle-down ms-2 ms-md-auto" />
                  </a>
                  <ul
                    className={classNames(
                      'collapse nav nav--side-menu flex-column ps-2 mt-1',
                      [`${URL_ROUTES.Billing}`].includes(pathname) && 'show',
                    )}
                    id="submenuAdmin"
                    data-bs-parent="#adminMenu"
                  >
                    {isEndUser && (
                      <>
                        <NavItem
                          refresh
                          path={URL_ROUTES.Billing}
                          icon="fa-scroll"
                          name="Billing"
                          linkClass={classNames(
                            [`${URL_ROUTES.Billing}`].includes(pathname) && 'active',
                          )}
                        />
                        {/*
                        <NavItem
                          path={URL_ROUTES.SubscriptionPlans}
                          searching="?lmref=true"
                          icon="fa-regular me-2 fa-bell"
                          name="Subscription"
                          linkClass={classNames(
                            [`${URL_ROUTES.SubscriptionPlans}`].includes(pathname) && 'active',
                          )}
                        />
                        */}

                        {isTeamOwner && (
                          <NavItem
                            path={URL_ROUTES.TeamUsers}
                            icon="fa-user"
                            name="Team"
                            linkClass={classNames(pathname === URL_ROUTES.TeamUsers && 'active')}
                          />
                        )}
                      </>
                    )}
                    {isSuperuser && (
                      <>
                        <NavItem
                          path={URL_ROUTES.AdminUsers}
                          icon="fa-user-group"
                          name="Admin Users"
                          linkClass={classNames(pathname === URL_ROUTES.AdminUsers && 'active')}
                        />
                        <NavItem
                          path={URL_ROUTES.CompanyDetails}
                          icon="fa-clipboard"
                          name="Subscriptions"
                          linkClass={classNames(pathname === URL_ROUTES.CompanyDetails && 'active')}
                        />
                        <NavItem
                          path={URL_ROUTES.Marketing}
                          icon="fa-wave-pulse"
                          name="Marketing"
                          linkClass={classNames(pathname === URL_ROUTES.Marketing && 'active')}
                        />
                        <NavItem
                          path={URL_ROUTES.CheckOutPages}
                          icon="fa-cart-shopping"
                          name="Check-out pages"
                          linkClass={classNames(pathname === URL_ROUTES.CheckOutPages && 'active')}
                        />
                      </>
                    )}
                  </ul>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
