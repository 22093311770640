/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';

import { format } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRaneProps {
  range: any;
  setRange: React.Dispatch<React.SetStateAction<any>>;
  endAdornment?: React.ReactElement | null;
}

function DateRangeComp({ range, setRange, endAdornment }: DateRaneProps) {
  const [open, setOpen] = useState<boolean>(false);

  const refOne = useRef<any>(null);

  const hideOnEscape = (e: any) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: any) => {
    if (refOne.current && !refOne?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);

  return (
    <div>
      <div className="input-group radius-10">
        <input
          value={`${format(range[0].startDate, 'MM/dd/yyyy')} to ${format(
            range[0].endDate,
            'MM/dd/yyyy',
          )}`}
          readOnly
          className="form-control shadow mt-2 border-radius-0"
          onClick={() => setOpen((open) => !open)}
        />
        <span
          className="input-group-text shadow bg-color-white border-radius-0  mt-2"
          id="basic-addon2"
        >
          {endAdornment}
        </span>
      </div>
      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={(item) => setRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            minDate={range[0].startDate}
          />
        )}
      </div>
    </div>
  );
}

DateRangeComp.defaultProps = {
  endAdornment: null,
};

export default DateRangeComp;
