import { THUNK_STATUS } from '../../constants';

export enum MimeType {
  AudioMpeg = 'audio/mpeg',
  ImagePng = 'image/png',
}

export enum EntityType {
  Session = 'session',
  Course = 'upgrade',
  Channel = 'channel',
}

export interface MediaInterface {
  id: string;
  type: string;
  name: string;
  fileName: string;
  fileSize: number;
  mimeType: MimeType;
  disk: string;
  url: string;
}

export interface NarratorInterface {
  id: number;
  name: string;
  duration: string;
  durationInSeconds: number;
  humanReadableDuration: string;
  media: MediaInterface;
}

export interface BaseSessionInterface {
  id: number;
  title: string;
  description: string;
  isFree: boolean;
  tags: string[];
  narrators: NarratorInterface[];
  narrator?: NarratorInterface;
  sessionUsers?: any[];
  playable?: boolean;
  image: MediaInterface;
  isFavorite?: boolean;
}

export interface CourseInterface {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  tags: string[];
  isDayCourse: boolean;
  sessions: BaseSessionInterface[];
  media: MediaInterface;
  imgSrc?: string; // to use this for now since fixtures don't have image media
  completedSessions?: number;
}

export interface ChannelInterface {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  tags: string[];
  isSleep: boolean;
  isPublished: boolean;
  media: MediaInterface;
  courses: CourseInterface[];
  channels: ChannelInterface[];
  deepPrimer: BaseSessionInterface[];
  powerPrimer: BaseSessionInterface[];
}

export interface SessionInterface extends BaseSessionInterface {
  course?: CourseInterface;
  channel?: ChannelInterface;
}

export interface CardEntityTypeReq extends Omit<EntityType, EntityType.Channel> {}

export enum SubscriptionFrequency {
  Year = 'yearly',
  Month = 'monthly',
  Life = 'lifetime',
}

export enum SubscriptionType {
  Single = 'single',
  Team = 'team',
}

export interface SubscriptionInterface {
  id: number;
  type: SubscriptionType;
  frequency: SubscriptionFrequency;
  description: string;
  price: number;
  discountedPrice?: number;
  stripe_id?: any;
  apple_id?: any;
  android_id?: any;
  trialDays: number;
  title?: string;
  name?: string;
  repetitions?: number;
  uuid?: string;
  seats?: number;
  productPrice?: number;
}

interface BasicEntitiesState {
  loading: { [key: string]: THUNK_STATUS | undefined };
  course: CourseInterface | null;
  session: SessionInterface | null;
  channel: ChannelInterface | null;
  sharedSession?: SessionInterface | null;
}

export interface EntitiesState extends BasicEntitiesState {
  courses: CourseInterface[];
  channels: ChannelInterface[];
  subscriptions: SubscriptionInterface[];
  sleepChannel: ChannelInterface | null;
  search: {
    sessions: SessionInterface[];
    totalSessions: number;
    status: THUNK_STATUS | undefined;
    error: string | Error | undefined;
  };
}

export interface PlayerState extends BasicEntitiesState {
  currentTrack: number;
  tracklist: SessionInterface[];
  recentSessions: SessionInterface[];
  volume: number;
  loopMode: boolean;
  currentNarrator: number;
  playables: boolean[];
  urlToShare: string;
}

export interface SessionUserInterface extends BasicEntitiesState {
  isFavorite: boolean | null;
  narrator: NarratorInterface;
  played: number;
  session: SessionInterface;
}
