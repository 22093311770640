import { useNavigate } from 'react-router-dom';
import { ChannelInterface } from '../../redux/interface/channelsInterface';

interface Props {
  channel: ChannelInterface;
  handleDelete: (id: string) => void;
  handlePublish: (course: ChannelInterface) => void;
}

function Channel({ handleDelete, handlePublish, channel }: Props) {
  const navigate = useNavigate();
  const handleDeleting = () => {
    handleDelete(channel.id);
  };
  const handlePublishing = () => {
    handlePublish(channel);
  };
  return (
    <tr key={channel?.id}>
      <td className="font-medium text-truncate-column">{channel.title}</td>
      <td className="text-truncate-column">{channel.subtitle}</td>
      <td className="text-truncate-column">
        {channel?.tags?.map((t) => (
          <div className="tag" key={`${t}${channel?.id}`}>
            <span className="tag__label">{t}</span>
          </div>
        ))}
      </td>
      <td className="actions-cell">
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          onClick={() =>
            navigate('/edit-channel', {
              state: {
                id: channel?.id,
                title: channel?.title,
                subtitle: channel?.subtitle,
                description: channel?.description,
                slug: channel?.slug,
                tags: channel?.tags,
                isSleep: channel?.isSleep,
                isPublished: channel?.isPublished,
                courses: channel?.courses,
                channels: channel?.channels,
                deepPrimer: channel?.deepPrimer,
                powerPrimer: channel?.powerPrimer,
                file: channel?.media,
                category: channel?.category,
              },
            })
          }
        >
          <i className="fas fa-edit" />
          Edit
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_channel"
          type="button"
          onClick={handleDeleting}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
        {channel?.isPublished ? (
          <button
            className="btn btn-warning btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#unpublish_channel"
            type="button"
            onClick={handlePublishing}
          >
            <i className="fa-solid fa-cloud-arrow-down" />
            Unpublish
          </button>
        ) : (
          <button
            className="btn btn-warning btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#publish_channel"
            type="button"
            onClick={handlePublishing}
          >
            <i className="fa-solid fa-cloud-arrow-up" />
            Publish
          </button>
        )}
      </td>
    </tr>
  );
}

export default Channel;
