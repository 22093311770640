import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient, { errorMessageThrower } from '../../helpers/api.js';
import { sessionUsersToSessions } from '../../helpers/entityHelpers';
import {
  ChannelInterface,
  CourseInterface,
  NarratorInterface,
  SessionInterface,
} from '../interface/entitiesInterface';

interface saveSessionUserPointProps {
  session: SessionInterface;
  narrator: NarratorInterface;
  course?: CourseInterface;
  channel?: ChannelInterface;
  status?: number;
  isFavorite?: boolean;
  played?: number;
}

export const saveSessionUserPoint = createAsyncThunk(
  'playstream/record',
  async ({
    session,
    course,
    channel,
    narrator,
    isFavorite,
    ...otherParams
  }: saveSessionUserPointProps) => {
    const params: { [any: string]: any } = {
      session_id: session.id,
      narrator_id: narrator.id,
      ...otherParams,
    };
    if (course) {
      params.course_id = course.id;
    }
    if (channel) {
      params.channel_id = channel.id;
    }
    if (isFavorite) {
      params.isFavorite = isFavorite;
    }
    try {
      const response = await apiClient.postRequest('/api/session_users', params);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getRecentAudioSessions = createAsyncThunk(
  'playstream/get-recent-sessions',
  async () => {
    try {
      const response = await apiClient.getRequest('/api/session_users/recent');
      return sessionUsersToSessions(response.data['hydra:member'], true);
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

interface generateShareAudioUrlProps {
  sessionId: number;
}

export const generateShareAudioUrl = createAsyncThunk(
  'player/share-link',
  async ({ sessionId }: generateShareAudioUrlProps, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/social/share', {
        session_id: sessionId,
      });
      return response?.data?.share_url;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message);
    }
  },
);

interface getShareAudioSessionProps {
  token: string;
}

export const getShareAudioSession = createAsyncThunk(
  'player/share-session',
  async ({ token }: getShareAudioSessionProps, { rejectWithValue }) => {
    try {
      const response = await apiClient.getNoAuthRequest(`/api/social/share/${token}`);
      return response?.data?.session;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message);
    }
  },
);
