/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AnyObject {
  [any: string]: {
    [any: string]: any;
  };
}

interface modalState {
  secondaryVisible: string;
  visible: string;
  secondaryInfo: AnyObject;
  info: AnyObject;
}

const initialState: modalState = {
  secondaryVisible: '',
  visible: '',
  secondaryInfo: {},
  info: {},
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setVisibleModal: (state, action: PayloadAction<string>) => ({
      ...state,
      visible: action.payload,
    }),
    setSecondaryVisibleModal: (state, action: PayloadAction<string>) => ({
      ...state,
      secondaryVisible: action.payload,
    }),
    closeModal: (state) => {
      const { visible, info } = state;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [visible]: modalInfo, ...newInfo } = info;

      return {
        ...state,
        visible: '',
        info: newInfo,
      };
    },
    setInfo: (state, action: PayloadAction<AnyObject>) => ({
      ...state,
      info: {
        ...state.info,
        ...action.payload,
      },
    }),
    setSecondaryInfo: (state, action: PayloadAction<AnyObject>) => ({
      ...state,
      secondaryInfo: {
        ...state.secondaryInfo,
        ...action.payload,
      },
    }),
    resetSecondaryInfo: (state) => ({
      ...state,
      secondaryInfo: {},
    }),
    clearInfoForModal: (state, action: PayloadAction<string>) => ({
      ...state,
      info: {
        ...state.info,
        [action.payload]: {},
      },
    }),
  },
});

export const { actions } = modalSlice;

export default modalSlice.reducer;
