import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { CompaniesInfo } from '../interface/companiesInterface';

export const postCompaniesInfo = createAsyncThunk(
  'companies/postCompanyInfo',
  async (detail: CompaniesInfo, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/companies', detail);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getCompaniesInfo = createAsyncThunk('companies/getInfo', async () => {
  const response = await apiClient.getRequest('/api/companies');
  return response.data;
});

export const editCompaniesInfo = createAsyncThunk(
  'companies/updateInfo',
  async (detail: CompaniesInfo, { rejectWithValue }) => {
    try {
      const response = await apiClient.putRequest(`/api/companies/${detail.id}`, detail);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
