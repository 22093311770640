/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants/index';
import { UserSettingsState } from '../interface/userSettingsInterface';
import {
  addNewCard,
  getCardList,
  getTeamMemberList,
  addTeamMember,
  editTeamMember,
  deleteTeamMember,
  uploadTeamCSV,
  deleteCard,
  editCard,
  inquireMoreSeats,
  updateSeats,
  subscribePaidAccount,
  unsubscribePaidAccount,
} from '../thunks/userSettingsThunk';

const initialState: UserSettingsState = {
  loading: {
    cards: undefined,
    teamMembers: undefined,
    deleteMember: undefined,
    inquireSeats: undefined,
  },
  adOpened: false,
  cardForm: { status: undefined, error: undefined, message: undefined },
  updateSeats: { status: undefined, error: undefined, message: undefined },
  unsubscribe: { status: undefined, error: undefined, message: undefined },
  subscribe: { status: undefined, error: undefined, message: undefined },
  cards: [],
  teamMembers: {
    list: [],
    form: { status: undefined, error: undefined, message: undefined },
    metas: { first: '', last: '', previous: '', next: '', total: 0 },
  },
  addSeats: {
    seats: 0,
    price: 0,
    seatPrice: 0,
    actualSeatPrice: 0,
  },
  CSVForm: { status: undefined, error: undefined, message: undefined },
  modalOpen: false,
  modalName: '',
};

const pendingCardFormState = {
  status: THUNK_STATUS.LOADING,
  error: undefined,
  message: 'Please wait while verifying your card.',
};

export const userSettingsSlice = createSlice({
  name: 'useSettings',
  initialState,
  reducers: {
    beginCardFormPending: (state) => ({
      ...state,
      cardForm: { ...pendingCardFormState },
    }),
    removeCardFormPending: (state) => ({
      ...state,
      cardForm: { ...initialState.cardForm },
    }),
    clearCardFormStatuses: (state) => ({ ...state, cardForm: { ...initialState.cardForm } }),
    doneAdOpened: (state) => ({
      ...state,
      adOpened: true,
    }),
    clearInquireSeats: (state) => ({
      ...state,
      loading: { ...state.loading, inquireSeats: undefined },
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(addNewCard.pending, (state) => {
        state.cardForm = { ...pendingCardFormState };
      })
      .addCase(addNewCard.fulfilled, (state) => {
        state.cardForm = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Card successfully saved.',
        };
      })
      .addCase(addNewCard.rejected, (state, action) => {
        state.cardForm = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(editCard.pending, (state) => {
        state.cardForm = { ...pendingCardFormState };
      })
      .addCase(editCard.fulfilled, (state) => {
        state.cardForm = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Card successfully saved.',
        };
      })
      .addCase(editCard.rejected, (state, action) => {
        state.cardForm = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(deleteCard.pending, (state) => {
        state.modalName = 'deleteCard';
        state.modalOpen = true;
        state.cardForm = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: 'Please wait while we delete.',
        };
      })
      .addCase(deleteCard.fulfilled, (state) => {
        state.cardForm = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Card successfully deleted.',
        };
        state.modalOpen = false;
      })
      .addCase(deleteCard.rejected, (state, action) => {
        state.cardForm = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(getCardList.pending, (state) => {
        state.loading = { ...state.loading, cards: THUNK_STATUS.LOADING };
      })
      .addCase(getCardList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, cards: THUNK_STATUS.SUCCEEDED };
        const { data } = action.payload;
        state.cards = data;
      })
      .addCase(getCardList.rejected, (state) => {
        state.loading = { ...state.loading, cards: THUNK_STATUS.REJECTED };
      })
      .addCase(getTeamMemberList.pending, (state) => {
        state.loading = { ...state.loading, teamMembers: THUNK_STATUS.LOADING };
      })
      .addCase(getTeamMemberList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, teamMembers: THUNK_STATUS.SUCCEEDED };
        const {
          'hydra:member': list,
          'hydra:totalItems': total,
          'hydra:view': views,
        } = action.payload;

        let metas = { total, first: '', last: '', previous: '', next: '' };
        if (views) {
          metas = {
            ...metas,
            first: views['hydra:first'] || '',
            last: views['hydra:last'] || '',
            previous: views['hydra:previous'] || '',
            next: views['hydra:next'] || '',
          };
        }

        state.teamMembers = {
          ...state.teamMembers,
          list,
          metas,
        };
      })
      .addCase(getTeamMemberList.rejected, (state) => {
        state.loading = { ...state.loading, teamMembers: THUNK_STATUS.REJECTED };
      })
      .addCase(addTeamMember.pending, (state) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.LOADING,
            error: undefined,
            message: undefined,
          },
        };
      })
      .addCase(addTeamMember.fulfilled, (state) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.SUCCEEDED,
            error: undefined,
            message: 'Team member successfully added.',
          },
        };
      })
      .addCase(addTeamMember.rejected, (state, action) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.REJECTED,
            error: action.error as Error,
            message: action.error.message,
          },
        };
      })
      .addCase(editTeamMember.pending, (state) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.LOADING,
            error: undefined,
            message: undefined,
          },
        };
      })
      .addCase(editTeamMember.fulfilled, (state) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.SUCCEEDED,
            error: undefined,
            message: 'Team member successfully updated.',
          },
        };
      })
      .addCase(editTeamMember.rejected, (state, action) => {
        state.teamMembers = {
          ...state.teamMembers,
          form: {
            status: THUNK_STATUS.REJECTED,
            error: action.error as Error,
            message: action.error.message,
          },
        };
      })
      .addCase(deleteTeamMember.pending, (state) => {
        state.loading = { ...state.loading, deleteMember: THUNK_STATUS.LOADING };
      })
      .addCase(deleteTeamMember.fulfilled, (state) => {
        state.loading = { ...state.loading, deleteMember: THUNK_STATUS.SUCCEEDED };
      })
      .addCase(deleteTeamMember.rejected, (state) => {
        state.loading = { ...state.loading, deleteMember: THUNK_STATUS.REJECTED };
      })
      .addCase(uploadTeamCSV.pending, (state) => {
        state.CSVForm = { status: THUNK_STATUS.LOADING, error: undefined, message: undefined };
      })
      .addCase(uploadTeamCSV.fulfilled, (state, action) => {
        state.CSVForm = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: action.payload?.message || 'Team member(s) successfully added.',
        };
      })
      .addCase(uploadTeamCSV.rejected, (state, action) => {
        state.CSVForm = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(inquireMoreSeats.pending, (state) => {
        state.loading = { ...state.loading, inquireSeats: THUNK_STATUS.LOADING };
      })
      .addCase(inquireMoreSeats.fulfilled, (state, action) => {
        state.loading = { ...state.loading, inquireSeats: THUNK_STATUS.SUCCEEDED };
        state.addSeats = action.payload;
      })
      .addCase(inquireMoreSeats.rejected, (state) => {
        state.loading = { ...state.loading, inquireSeats: THUNK_STATUS.REJECTED };
      })
      .addCase(updateSeats.pending, (state) => {
        state.updateSeats = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(updateSeats.fulfilled, (state) => {
        state.updateSeats = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Checkout successful! Max seats for your team is now updated.',
        };
        window.setTimeout(() => {
          window.location.href = window.location.origin;
        }, 4000);
      })
      .addCase(updateSeats.rejected, (state, action) => {
        state.updateSeats = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(subscribePaidAccount.pending, (state) => {
        state.subscribe = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(subscribePaidAccount.fulfilled, (state) => {
        state.subscribe = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Congratulations! You are now subscribed to our premium account!',
        };
      })
      .addCase(subscribePaidAccount.rejected, (state, action) => {
        state.subscribe = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      })
      .addCase(unsubscribePaidAccount.pending, (state) => {
        state.unsubscribe = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(unsubscribePaidAccount.fulfilled, (state) => {
        state.unsubscribe = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: 'Successfully unsubscribed.',
        };
      })
      .addCase(unsubscribePaidAccount.rejected, (state, action) => {
        state.unsubscribe = {
          status: THUNK_STATUS.REJECTED,
          error: action.error as Error,
          message: action.error.message,
        };
      });
  },
});

export const { actions } = userSettingsSlice;

export default userSettingsSlice.reducer;
