import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSleepChannel } from '../../redux/thunks/entitiesThunk';
import { THUNK_STATUS } from '../../constants';
import ChannelDetails from './channelDetails';

function Sleep() {
  const dispatch = useAppDispatch();
  const loadingChannelState = useAppSelector((state) => state.entities.loading.sleepChannel);
  const isLoading = loadingChannelState === THUNK_STATUS.LOADING;
  const channel = useAppSelector((state) => state.entities.sleepChannel);

  useEffect(() => {
    dispatch(getSleepChannel());
  }, []);

  return <ChannelDetails channel={channel} isLoading={isLoading} />;
}

export default Sleep;
