import React, { useState } from 'react';
import { Badge, BadgeCategory } from '../../constants';
import BadgeCategoryDisplay from './badgeCategoryDisplay';
import BadgeDisplay from './badgeDisplay';

interface DashboardAwardBadgesProps {
  badges: BadgeCategory[];
}

function DashboardAwardBadges({ badges }: DashboardAwardBadgesProps) {
  const [selectedCategory, setSelectedCategory] = useState<BadgeCategory | null>(null);
  const handleSelectedCategory = (category: BadgeCategory | null = null) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <h3 className="m-0">Achievements</h3>
      </div>

      {selectedCategory ? (
        <div className="badge-category-selected border-gray-400 radius-16 shadow px-2 py-3 d-flex flex-column flex-grow-1">
          <button
            type="button"
            className="back-to-category"
            onClick={() => handleSelectedCategory()}
          >
            <i className="fa-regular fa-chevron-left" /> {selectedCategory.title}
          </button>
          <div className="d-flex flex-wrap">
            {selectedCategory.awards.map((badge: Badge) => {
              const { title, media, id } = badge;
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <BadgeDisplay key={JSON.stringify({ title, media, id })} {...badge} noTitle />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="border-gray-400 radius-16 shadow d-flex flex-wrap flex-grow-1 px-2 py-3">
          <div>
            {badges.map((badgeCat: BadgeCategory) => {
              const { title, media, id } = badgeCat;
              return (
                <BadgeCategoryDisplay
                  key={JSON.stringify({ title, media, id })}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...badgeCat}
                  onSelected={handleSelectedCategory}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardAwardBadges;
