import { Link, generatePath } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CheckoutPage } from '../../redux/interface/checkoutPageInterface';
import { URL_ROUTES } from '../../constants/routes';

interface Props {
  checkoutPage: CheckoutPage;
  handleDelete: (checkoutPage: CheckoutPage) => void;
  handleEdit: (checkoutPage: CheckoutPage) => void;
}

function CustomCheckoutPageRow({ checkoutPage, handleDelete, handleEdit }: Props) {
  const [checkoutPageRoute, setCheckoutPageRoute] = useState(`/checkout/${checkoutPage.uuid}`);

  useEffect(() => {
    const path = generatePath(URL_ROUTES.CustomCheckOutPage, {
      uuid: checkoutPage.uuid,
    });
    setCheckoutPageRoute(path);
  }, []);

  const handleDeleteCheckoutPage = () => {
    handleDelete(checkoutPage);
  };

  const handleEditCheckoutPage = () => {
    handleEdit(checkoutPage);
  };

  return (
    <tr>
      <td className="font-medium text-truncate-column">{checkoutPage.name}</td>
      <td className="text-truncate-column text-capitalize">{checkoutPage?.subscription?.type}</td>
      <td className="text-truncate-column">{checkoutPage?.subscription?.description}</td>
      <td className="text-truncate-column">{checkoutPage?.title}</td>
      <td className="actions-cell">
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#create_checkout_page"
          onClick={handleEditCheckoutPage}
        >
          <i className="fas fa-edit" />
          Edit
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_checkout_page"
          type="button"
          onClick={handleDeleteCheckoutPage}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
        <Link
          className="btn btn-outline-primary btn-light btn-icon btn-sm"
          to={checkoutPageRoute}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <i className="fa-regular fa-eye me-1" />
        </Link>
      </td>
    </tr>
  );
}

export default CustomCheckoutPageRow;
