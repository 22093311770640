import React, { useState, ChangeEvent } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { editTeam } from '../../../redux/thunks/teamThunk';
import Input from '../../../components/input';

interface Props {
  teamId: string;
}

function AddSeats({ teamId }: Props) {
  const dispatch = useAppDispatch();
  const [seats, setSeats] = useState('');

  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_seats_team"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5">
            <div className="text-center font-light">
              <h4 className="mb-4">Add team seats</h4>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 px-1 ps-0">
                  <div className="mb-3">
                    <Input
                      type="number"
                      label="Number of seats"
                      id="seats"
                      value={seats}
                      onValueChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSeats(e?.target?.value)
                      }
                      min="0"
                    />
                    <button
                      type="button"
                      id="closeModal_newTeamMemberCSV"
                      className="btn btn-primary w-100"
                      data-bs-dismiss="modal"
                      onClick={() => dispatch(editTeam({ id: teamId, seats: parseInt(seats, 10) }))}
                    >
                      <i className="fa fa-check" />
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSeats;
