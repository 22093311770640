import React from 'react';
import { PaymentCredential, CreditCardType } from '../../redux/interface/userSettingsInterface';
import ccIcon from '../../assets/img/credit-card-icon.svg';
import visaIcon from '../../assets/img/visa-icon.svg';
import mastercardIcon from '../../assets/img/mastercard-icon.svg';

const cardImages = {
  [CreditCardType.VISA]: visaIcon,
  [CreditCardType.MASTERCARD]: mastercardIcon,
};

function CreditCardInfo({ name, last4Digit, expiryMonth, expiryYear, brand }: PaymentCredential) {
  return (
    <div className="credit-card">
      <div className="credit-card__content p-4">
        <div className="credit-card__type mb-3">
          <img src={cardImages[brand]} alt="" />
        </div>
        <div className="credit-card__number">{`**** **** **** ${last4Digit}`}</div>

        <div className="credit-card__icon-wrapper">
          <img src={ccIcon} alt="" />
        </div>
      </div>
      <div className="credit-card__info p-4">
        <div className="row g-0">
          <div className="col-9">
            <div className="credit-card__info-label">Cardholder&quot;s name</div>
            <div className="credit-card__info-value">{name}</div>
          </div>
          <div className="col-3 d-flex flex-column align-items-end">
            <div className="credit-card__info-label">Exp date</div>
            <div className="credit-card__info-value">{`${String(expiryMonth).padStart(
              2,
              '0',
            )}/${expiryYear}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditCardInfo;
