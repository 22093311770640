import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import DashboardAwardBadges from '../../components/cards/dashboardAwardBadges';
import DashboardScore from '../../components/cards/dashboardScore';
import CourseSlider from '../../components/cards/courseSlider';
import SubscriptionBanner, {
  SubscriptionBannerSize,
} from '../../components/cards/subscriptionBanner';
import {
  getRecommendationsHome,
  getAllAchievements,
  getUserSessionsHistory,
} from '../../redux/thunks/homeThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AudioPlayer from '../../components/audio/player';
import { actions as playerActions } from '../../redux/slices/playerSlice';
import { defaultMetatags, THUNK_STATUS } from '../../constants';
import { SessionInterface } from '../../redux/interface/entitiesInterface';
import { saveSessionUserPoint } from '../../redux/thunks/playerThunk';
import { useAuth } from '../../redux/authLayer';

function Dashboard() {
  const dispatch = useAppDispatch();
  const { isFree } = useAuth();
  const sessionToPlay: SessionInterface | null = useAppSelector((state) => state.player.session);
  const {
    recommendations: recommendStatus,
    achievementsInfo: achieveStatus,
    history: historyStatus,
  } = useAppSelector((state) => state.home.loading);
  const currentUser = useAppSelector((state) => state.currentUser);

  const {
    recommendedCoursesHomeInfo,
    recommendedPowerPrimers,
    recommendedDeepPrimers,
    achievementsInfo,
    sessionsHistory,
  } = useAppSelector((state) => state.home);

  useEffect(() => {
    if (sessionsHistory?.length !== 0 && sessionsHistory[0]?.narrator) {
      dispatch(playerActions.setSession(sessionsHistory[0].session));
      dispatch(
        playerActions.setCurrentNarrator(
          sessionsHistory[0].session.narrators.findIndex(
            (n) => n.id === sessionsHistory[0].narrator.id,
          ),
        ),
      );
    }
  }, [sessionsHistory]);

  useEffect(() => {
    dispatch(getRecommendationsHome());
    dispatch(getAllAchievements());
    dispatch(getUserSessionsHistory());
  }, []);

  const handleSaveFavorite = (narrator: number, isFavorite: boolean) => {
    if (!sessionToPlay) {
      return;
    }
    const paramsToSend = {
      session: sessionToPlay,
      narrator: sessionToPlay.narrators[narrator],
      isFavorite,
    };
    dispatch(saveSessionUserPoint(paramsToSend));
  };

  const handleSessionEnded = (narrator: number) => {
    if (!sessionToPlay) {
      return;
    }
    dispatch(
      saveSessionUserPoint({
        session: sessionToPlay,
        status: 2,
        narrator: sessionToPlay.narrators[narrator],
      }),
    );
  };
  const handleSavePoint = (
    { currentTime }: { timeString: string; currentTime: number },
    narrator: number,
  ) => {
    if (!sessionToPlay) {
      return;
    }

    if (currentTime === 0) {
      return;
    }

    dispatch(
      saveSessionUserPoint({
        session: sessionToPlay,
        status: 1,
        played: currentTime,
        narrator: sessionToPlay?.narrators[narrator],
      }),
    );
  };

  const renderMetas = () => (
    <Helmet>
      <title>{defaultMetatags.title}</title>
      <meta name="description" content={defaultMetatags.description} />
    </Helmet>
  );

  if (
    recommendStatus === THUNK_STATUS.LOADING ||
    achieveStatus === THUNK_STATUS.LOADING ||
    historyStatus === THUNK_STATUS.LOADING
  ) {
    return (
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
        {renderMetas()}
        <div className="content_decor" />
        <div className="row mt-4">
          <div className="col-12 col-xl-8 d-flex flex-column">
            <div className="mb-4">
              <Skeleton height={293} />
            </div>

            <div className="mb-4 d-flex flex-column flex-grow-1">
              <Skeleton height={370} />
            </div>
          </div>
          <div className="col-12 col-xl-4 d-flex flex-column">
            <div className="mb-4">
              <Skeleton height={410} />
            </div>
            <div className="mb-4 flex-grow-1">
              <Skeleton height={250} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
      {renderMetas()}
      <div className="content_decor" />
      <div className="row mt-4">
        <div className="col-12 col-xl-8 d-flex flex-column">
          {isFree && (
            <div className="mb-4">
              <SubscriptionBanner size={SubscriptionBannerSize.MEDIUM} />
            </div>
          )}

          <div className="mb-4 d-flex flex-column flex-grow-1">
            {achievementsInfo ? <DashboardAwardBadges badges={achievementsInfo} /> : null}
          </div>
        </div>
        <div className="col-12 col-xl-4 d-flex flex-column">
          {!isFree && <div className="mb-4" style={{ height: 28 }} />}
          <div className="mb-4">
            <DashboardScore
              streakDays={currentUser?.streakLevel[0]?.level?.current}
              scorePercentage={currentUser?.streakLevel[0]?.level?.progress_per}
              bestStreakDays={currentUser?.streakLevel[0]?.streak?.best}
            />
          </div>
          {sessionsHistory?.length !== 0 ? (
            <div className="mb-4 flex-grow-1">
              <AudioPlayer
                compactMode
                session={sessionToPlay}
                onSessionEnded={handleSessionEnded}
                onFavoriteSession={handleSaveFavorite}
                onUserListenProgress={handleSavePoint}
              />
            </div>
          ) : null}
        </div>
      </div>
      <CourseSlider headline="Upgrades" items={recommendedCoursesHomeInfo} siblingTypes="upgrade" />
      <CourseSlider
        headline="Power Primers"
        items={recommendedPowerPrimers}
        siblingTypes="session"
        parentType="recommended"
        refer="powerPrimer"
      />
      <CourseSlider
        headline="Deep Primers"
        items={recommendedDeepPrimers}
        siblingTypes="session"
        parentType="recommended"
        refer="deepPrimer"
      />
    </div>
  );
}

export default Dashboard;
