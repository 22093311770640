import apiClient from '../../helpers/api.js';
import { Narrator } from '../interface/sessionInterface.js';

class sessions {
  static async createSessions(data: any) {
    const formData: any = new FormData();

    Object.keys(data).map((attr) => {
      if (data[attr] !== null && attr === 'tags') {
        return data[attr].forEach((tag: string) => {
          formData.append(`${attr}[]`, tag);
        });
      }
      if (data[attr] !== null && attr === 'narrators') {
        return data[attr].forEach((narrator: Narrator, index: number) => {
          formData.append(`${attr}[${index}]`, narrator?.name);
          formData.append(`audios[${index}]`, narrator?.audio);
        });
      }
      return data[attr] !== null && formData.append(attr, data[attr]);
    });
    const response = await apiClient.postRequest('/api/sessions', formData);

    return response;
  }

  static async EditSessions(data: any) {
    const formData: any = new FormData();

    Object.keys(data).map((attr) => {
      if (data[attr] !== null && attr === 'tags') {
        return data[attr].forEach((tag: string) => {
          formData.append(`${attr}[]`, tag);
        });
      }
      if (data[attr] !== null && attr === 'narrators') {
        return data[attr].forEach((narrator: Narrator, index: number) => {
          formData.append(`${attr}[${index}]`, narrator?.name);
          formData.append(`audios[${index}]`, narrator?.audio);
          if (narrator?.audio !== '') {
            formData.append(`narrator_ids[${index}]`, narrator?.id);
          }
        });
      }
      return data[attr] !== null && formData.append(attr, data[attr]);
    });
    const response = apiClient.postRequest('/api/sessions', formData);

    return response;
  }
}

export default sessions;
