/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import DropdownFormik from '../../components/formik/dropdown';
import InputFormik from '../../components/formik/input';
import UpdateAdvertisement from './modals/updateDiscount';
import DateRangeComp from '../../components/dateRangeComp';
import { showModal } from '../../helpers/closeModalApiSuccess';
import { DeviceType } from '../../redux/interface/discountInterface';

interface DiscountInfo {
  id?: string;
  startAt: string;
  endAt: string;
  discount: number;
  status: string;
  type: string;
  device: DeviceType;
}

interface DiscountProps {
  discount: DiscountInfo;
  setDiscount: React.Dispatch<React.SetStateAction<any>>;
}

const devices: DeviceType = ['ios', 'android', 'web'];

const generalDiscountValidation = Yup.object().shape({
  status: Yup.string(),
  type: Yup.string()
    .required()
    .when('status', {
      is: 'true',
      then: Yup.string().required().required('Type of discount is required'),
    }),
  discount: Yup.number()
    .required()
    .when('status', {
      is: 'true',
      then: Yup.number().required('Discount percent is required'),
    }),
  device: Yup.array().test(
    'at-least-one',
    'Please select at least one device.',
    (value: any) => value && value.length > 0,
  ),
});

function DiscountSetup({ discount, setDiscount }: DiscountProps) {
  const [range, setRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const handleChangeIt = (event: ChangeEvent<HTMLInputElement>, newDiscount: DiscountInfo) => {
    if (event.target.checked) {
      setDiscount({
        ...newDiscount,
        device: discount.device ? [...discount.device, event.target.value] : [event.target.value],
      });
    } else {
      const remDevice = discount.device.filter((d) => d !== event.target.value);
      setDiscount({
        ...newDiscount,
        device: remDevice,
      });
    }
  };

  useEffect(() => {
    const formattedStartDate = format(range[0].startDate, 'MM/dd/yyyy hh:mm:ss');
    const formattedEndDate = format(range[0].endDate, 'MM/dd/yyyy hh:mm:ss');
    setDiscount({
      ...discount,
      startAt: formattedStartDate,
      endAt: formattedEndDate,
    });
  }, [range]);

  return (
    <>
      <div className="row">
        <div className="col-12 p-0 mb-4">
          <div className="bg-color-gray-200 radius-16 p-3 p-xl-4">
            <Formik
              enableReinitialize
              initialValues={{
                id: discount.id,
                startAt: format(new Date(), 'MM/dd/yyyy hh:mm:ss'),
                endAt: format(addDays(new Date(), 7), 'MM/dd/yyyy hh:mm:ss'),
                discount: discount.discount,
                status: discount.status,
                type: discount.type,
                device: discount.device,
              }}
              validationSchema={generalDiscountValidation}
              onSubmit={(values) => {
                setDiscount(values);
                showModal({ modalId: '#update_advertisement' });
              }}
            >
              {({ handleSubmit, values, errors }) => (
                <form>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <h4 className="m-0">Discount settings</h4>
                    </div>
                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                      <h5 className="mb-3">General discount</h5>
                    </div>

                    <div className="row">
                      <div className="col-6 col-lg-6 mb-4 mb-lg-0">
                        <div className="mb-3 form-group">
                          <span className="form-label">
                            <b>Select New Date Range for discount *</b>
                          </span>
                          <DateRangeComp
                            range={range}
                            setRange={setRange}
                            endAdornment={
                              <a
                                type="button"
                                className="color-secondary text-decoration-none"
                                onClick={() => {
                                  setRange([
                                    {
                                      startDate: new Date(),
                                      endDate: addDays(new Date(), 7),
                                      key: 'selection',
                                    },
                                  ]);
                                }}
                              >
                                <i className="fa fa-refresh" aria-hidden="true" />
                                Reset date
                              </a>
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <Field
                            component={InputFormik}
                            type="number"
                            name="discount"
                            label="Discount percentage (%) *"
                            description="Discount percentage (%)"
                            min="0"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-lg-6 mb-4 mb-lg-0">
                        <Field
                          component={DropdownFormik}
                          name="type"
                          label="Type of discount *"
                          options={[
                            { name: 'Monthly', value: 'monthly' },
                            { name: 'Yearly', value: 'yearly' },
                            { name: 'Lifetime', value: 'lifetime' },
                          ]}
                          description="discountType"
                          placeholder="Select a Discount Type"
                        />
                        <Field
                          component={DropdownFormik}
                          name="status"
                          label="Status"
                          options={[
                            { name: 'Active', value: 'true' },
                            { name: 'Inactive', value: 'false' },
                          ]}
                          description="status"
                          placeholder="Select a status"
                        />
                      </div>
                    </div>

                    <div className="col-12 d-flex mb-4">
                      {devices?.map((d) => (
                        <div className="form-check me-4" key={d}>
                          <input
                            type="checkbox"
                            className="form-check-input "
                            style={{
                              backgroundColor: '#3f0c65 !important',
                              color: '$white !important',
                            }}
                            id="device"
                            name="device"
                            value={d}
                            checked={discount.device && discount.device.includes(d)}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              handleChangeIt(event, values);
                            }}
                          />
                          <p className="form-check-label">
                            {d === 'ios' ? 'iOS' : d === 'android' ? 'Android' : 'Web'}
                          </p>
                        </div>
                      ))}
                    </div>
                    {errors.device && (
                      <div
                        className="invalid-feedback mb-4"
                        style={{ display: 'block', marginTop: '-1.5rem' }}
                      >
                        {errors.device}
                      </div>
                    )}
                    <div className="d-flex justify-content-start">
                      <button
                        className="btn btn-primary px-5"
                        type="button"
                        onClick={() => handleSubmit()}
                      >
                        <i className="fa fa-check" />
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <UpdateAdvertisement discount={discount} />
    </>
  );
}

export default DiscountSetup;
