import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { NarratorInterface } from '../../redux/interface/entitiesInterface';

export interface ChangeNarratorProps {
  onChangeNarrator: (narratorIdx: number) => void;
  narrators: NarratorInterface[];
  selectedNarrator: number;
}

export const MODAL_NAME = 'changeNarrator';

function ChangeNarrator({ selectedNarrator, onChangeNarrator, narrators }: ChangeNarratorProps) {
  const dispatch = useAppDispatch();
  const [narratorIndex, setNarratorIndex] = useState<number>(selectedNarrator);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const handleConfirmNarrator = () => {
    onChangeNarrator(narratorIndex);
    handleCloseModal();
  };

  return (
    <Modal show={showModal} centered>
      <div className="modal-header pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12"
          aria-label="Close"
          onClick={handleCloseModal}
        />
      </div>
      <div className="modal-body ps-5 pe-5">
        <div className="text-center font-light">
          <h4 className="mb-4">Change Narrator</h4>
        </div>

        <div className="mb-4">
          <select
            className="form-control form-select shadow"
            defaultValue={selectedNarrator}
            onChange={(e) => setNarratorIndex(Number(e.target.value))}
          >
            {narrators.map((narrator, idx) => (
              <option key={JSON.stringify({ narrator, idx })} value={idx}>
                {narrator.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 ps-0">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handleConfirmNarrator}
              >
                <i className="fa-regular fa-upload me-1" />
                Save
              </button>
            </div>
            <div className="col-6 px-1 pe-0">
              <button type="button" className="btn btn-light w-100" onClick={handleCloseModal}>
                <i className="fa-regular fa-xmark me-1" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeNarrator;
