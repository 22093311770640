import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getCompaniesInfo,
  editCompaniesInfo,
  postCompaniesInfo,
} from '../../redux/thunks/companiesThunk';
import CompanyDetailsForm from './companyDetailsForm';

function CompanyDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCompaniesInfo());
  }, []);

  const companiesInfo = useAppSelector((state) => state.companies);

  const [id, setId] = useState<string>();

  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    billingEmail: '',
    address: '',
    supportEmail: '',
    website: '',
    stripeId: '',
  });

  useEffect(() => {
    if (companiesInfo?.status === 'succeeded') {
      if (companiesInfo?.companiesInfo?.id) {
        setId(companiesInfo?.companiesInfo?.id);
      }
      if (companiesInfo) {
        setCompanyDetails({
          name: companiesInfo?.companiesInfo?.name,
          billingEmail: companiesInfo?.companiesInfo?.billingEmail,
          address: companiesInfo?.companiesInfo?.address,
          supportEmail: companiesInfo?.companiesInfo?.supportEmail,
          website: companiesInfo?.companiesInfo?.website,
          stripeId: companiesInfo?.companiesInfo?.stripeId,
        });
      }
    }
  }, [companiesInfo]);

  const handleClick = () => {
    if (id) {
      dispatch(editCompaniesInfo({ ...companyDetails, id }));
    } else {
      dispatch(postCompaniesInfo(companyDetails));
    }
  };

  const handleNavigate = () => {
    navigate('/subscription-types');
  };

  return (
    <CompanyDetailsForm
      companyDetailss={companyDetails}
      setCompanyDetails={setCompanyDetails}
      handleClick={handleClick}
      handleNavigate={handleNavigate}
    />
  );
}

export default CompanyDetails;
