import React, { useState, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth } from './redux/authLayer';
import ServicesLayer, { ServicesContext } from './redux/servicesLayer';
import Sidebar from './components/sidebar';
import Header from './components/header';
import EditAdminProfile from './modals/editAdminProfile';
import { URL_ROUTES } from './constants/routes';
import { actions } from './redux/slices/modalSlice';
import { actions as userSettingActions } from './redux/slices/userSettingsSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import Dashboard from './containers/dashboard/Dashboard';
import SevenDayCourses from './containers/dashboard/sevenDayCourses';
// import BillingSubscriptions from './containers/userSettings/billingSubscriptions';
import Channels from './containers/dashboard/channels';
import Billing from './containers/userSettings/billing';
import PlayerPage from './containers/dashboard/playerPage';
import TeamUsers from './containers/userSettings/teamUsers';
import Sleep from './containers/dashboard/sleep';
import Subscription from './modals/subscription';
import Advertisement from './modals/advertisment';
import UserAppTour from './components/userAppTour';
import { setCurrentUserOnboarded } from './redux/thunks/currentUserThunk';

function UserApp() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { userId } = useAppSelector((state) => state.currentUser);
  const adOpened = useAppSelector((state) => state.userSettings.adOpened);
  const [marketingClosed, setMarketingClosed] = useState<boolean>(false);
  const { isOnboarded, isFree } = useAuth();
  const [tourOpened, setTourOpened] = useState<boolean>(!isOnboarded);
  const [doneTour, setDoneTour] = useState<boolean>(false);
  const { marketingInfo } = useAppSelector((state) => state.marketing.webmarketingAddsState);
  const marketing = useMemo(() => marketingInfo, [marketingInfo]);

  useEffect(() => {
    if (pathname === '/account/billing') {
      return;
    }

    if (marketing && !adOpened) {
      dispatch(actions.setVisibleModal('advertisementModal'));
      dispatch(userSettingActions.doneAdOpened());
    }
  }, [marketing]);

  const onCloseAdvertisement = () => {
    setMarketingClosed(true);
    if (!isOnboarded && !doneTour) {
      setTourOpened(true);
    }
  };

  const handleCloseTour = () => {
    setDoneTour(true);
    if (userId !== null) {
      dispatch(setCurrentUserOnboarded(userId));
    }
    setTourOpened(false);
    if (!isOnboarded) {
      dispatch(actions.setVisibleModal('subscriptionModal'));
    }
  };

  return (
    <ServicesLayer>
      <ServicesContext.Consumer>
        {({ isPageDarkMode }) => (
          <div className={classNames('container-fluid', isPageDarkMode(pathname) && 'dark')}>
            <div className="row flex-nowrap">
              <Sidebar />
              <div className="col p-0 d-flex flex-column overflow-hidden">
                <Header />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path={URL_ROUTES.SevenDayCourses} element={<SevenDayCourses />} />
                  {/*
                  <Route path={URL_ROUTES.SubscriptionPlans} element={<BillingSubscriptions />} />
                  */}
                  <Route path={URL_ROUTES.Billing} element={<Billing />} />
                  <Route path={URL_ROUTES.Sleep} element={<Sleep />} />
                  <Route path={URL_ROUTES.Explore} element={<Channels />} />
                  <Route path={URL_ROUTES.Player} element={<PlayerPage />} />
                  <Route path={URL_ROUTES.PlayerNoParentId} element={<PlayerPage />} />
                  <Route path={URL_ROUTES.TeamUsers} element={<TeamUsers />} />
                </Routes>
              </div>
            </div>
            <EditAdminProfile />
            {!tourOpened && marketingInfo && !marketingClosed && (
              <Advertisement onModalClose={onCloseAdvertisement} />
            )}
            {isFree && <Subscription />}
            <UserAppTour opened={tourOpened} onRequestClose={handleCloseTour} />
          </div>
        )}
      </ServicesContext.Consumer>
    </ServicesLayer>
  );
}

export default UserApp;
