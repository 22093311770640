/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import { useEffect, useState, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Input from '../../components/formik/input';
import { useAppDispatch } from '../../redux/hooks';
import SavePublishChannel from './modals/savePublishChannel';
import SaveUnpublishChannel from './modals/saveUnpublishedChannel';
import { filterTags } from '../../helpers/filterTag';
import DragImage from '../../components/dragImage';
import { actions as channelActions } from '../../redux/slices/channelsSlice';
import Dropdown from '../../components/formik/dropdown';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { PrimerType } from '../../redux/interface/channelsInterface';
import SelectPrimer from '../../components/formik/channelFields/selectPrimer';
import SelectCourses from '../../components/formik/channelFields/selectCourses';
import SelectChannel from '../../components/formik/channelFields/selectChannel';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the title name'),
  subtitle: Yup.string().required('Please enter subtitle'),
  slug: Yup.string().required('Slug is required'),
  category: Yup.string().required('Category is required'),
});

interface stateType {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  slug: string;
  tags: string[];
  isSleep: boolean;
  isPublished: boolean;
  courses: [];
  channels: [];
  deepPrimer: [];
  powerPrimer: [];
  file: any;
  category: string;
}

function EditChannel() {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [pic, setPic] = useState<string | ArrayBuffer | null | undefined>('');

  const {
    id,
    title,
    subtitle,
    description,
    slug,
    tags,
    isSleep,
    isPublished,
    courses,
    channels,
    powerPrimer,
    deepPrimer,
    file,
    category,
  } = useLocation().state as stateType;

  const [editChannel, setEditChannel] = useState<any>({
    id,
    title,
    subtitle,
    description,
    slug,
    tags,
    isSleep,
    isPublished,
    courses: [],
    channels: [],
    channelSessions: [],
    file,
    category,
  });

  useEffect(() => {}, [editChannel]);

  const [save, setSave] = useState<string>('');

  useEffect(() => {
    setSelectedFile(editChannel?.file);
  }, [id]);

  const [newTag, setNewTag] = useState<any>();
  const [filteredTags, setFilteredTags] = useState<any>();

  useEffect(() => {
    dispatch(channelActions.clearPrimers());
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor">
                <div className="row pb-3 pb-md-4 align-items-center">
                  <div className="col-12 col-sm-6">
                    <h1 className="headline-decor">Edit Channel - {title}</h1>
                  </div>
                </div>

                <div className="container-fluid content-inner py-3 py-lg-4 px-3 px-lg-4 px-xxl-5">
                  <Formik
                    initialValues={{
                      id: '',
                      title: '',
                      subtitle: '',
                      description: '',
                      slug: '',
                      tags: [''],
                      isSleep: false,
                      isPublished: false,
                      category: '',
                      deepPrimer: [],
                      powerPrimer: [],
                      courses: [],
                      channels: [],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      const preSelectedApiPower = values.powerPrimer.map(
                        (pp: any, index: number) => ({
                          session: `/api/sessions/${pp?.session?.id}`,
                          type: 'power-primer',
                          position: index + 1,
                        }),
                      );

                      const preSelectedApiDeep = values.deepPrimer.map(
                        (dp: any, index: number) => ({
                          session: `/api/sessions/${dp?.session?.id}`,
                          type: 'deep-primer',
                          position: index + 1,
                        }),
                      );

                      const preSelectedApiChannel = values.channels.map(
                        (dc: any, index: number) => ({
                          channelTarget: `/api/channels/${dc?.id}`,
                          position: index + 1,
                        }),
                      );

                      const preSelectedApiCourses = values.courses.map(
                        (dc: any, index: number) => ({
                          course: `/api/courses/${dc?.id}`,
                          position: index + 1,
                        }),
                      );

                      setEditChannel({
                        ...values,
                        file: pic,
                        channelSessions: [...preSelectedApiDeep, ...preSelectedApiPower],
                        courses: preSelectedApiCourses,
                        channels: preSelectedApiChannel,
                      });

                      if (save === 'publish') {
                        dispatch(modalActions.setVisibleModal('SavePublishChannelModal'));
                      }
                      if (save === 'unpublish') {
                        dispatch(modalActions.setVisibleModal('SaveUnpublishChannelModal'));
                      }
                    }}
                  >
                    {({ handleSubmit, setValues, values }) => {
                      useEffect(() => {
                        setValues({
                          id,
                          title,
                          subtitle,
                          description,
                          slug,
                          tags,
                          isSleep,
                          isPublished,
                          category,
                          deepPrimer,
                          powerPrimer,
                          courses,
                          channels,
                        });
                      }, [id]);
                      useEffect(() => {
                        setFilteredTags(filterTags(newTag, values?.tags));
                      }, [newTag, values?.tags]);

                      const handleRemove = (t: string) => {
                        const fileteredTags = values?.tags?.filter((tag: string) => tag !== t);
                        setValues({
                          ...values,
                          tags: fileteredTags,
                        });
                      };

                      return (
                        <div className="row">
                          <div className="col-12">
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 col-xl-3">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    <Field
                                      component={Input}
                                      type="text"
                                      name="title"
                                      description="title"
                                      placeholder="Dailies"
                                      label="Title *"
                                    />
                                  </div>
                                  <div className="col-12 mb-3 mb-xl-0">
                                    <Field
                                      component={Input}
                                      type="text"
                                      name="subtitle"
                                      description="subtitle"
                                      placeholder="Quick Confidence"
                                      label="Subtitle *"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    <Field
                                      component={Input}
                                      type="text"
                                      name="slug"
                                      description="slug"
                                      placeholder="Channel slug"
                                      label="Channel slug *"
                                    />
                                  </div>
                                  <div className="col-12 mb-3 mb-xl-0">
                                    <div className="mb-3">
                                      <p className="form-label">
                                        <b>Image *</b>
                                      </p>
                                      <DragImage
                                        file={selectedFile}
                                        setImage={setPic}
                                        image={pic}
                                        setSelectedFile={setSelectedFile}
                                        selectedFile={selectedFile}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-lg-0 d-flex flex-column ">
                                <p className="form-label">Description</p>
                                <textarea
                                  className="form-control shadow flex-grow-1"
                                  id="description"
                                  placeholder="Description"
                                  value={values.description}
                                  onChange={(e) =>
                                    setValues({ ...values, description: e.target.value })
                                  }
                                />
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3 d-flex mb-3 mb-lg-0 flex-column flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="form-label">Tags</p>
                                </div>
                                <div className="form-control tags-field @@classNameName">
                                  {values?.tags?.map((t) => (
                                    <div className="tag" key={`${t}${values?.id}`}>
                                      <span className="tag__label">{t}</span>
                                      <button
                                        style={{
                                          border: 'none',
                                          margin: 0,
                                          padding: 0,
                                          background: 'none',
                                        }}
                                        type="button"
                                        onClick={() => handleRemove(t)}
                                      >
                                        <i className="fa-regular fa-xmark" />
                                      </button>
                                    </div>
                                  ))}
                                  <div
                                    className="d-inline-block input "
                                    contentEditable
                                    onInput={(e) => setNewTag(e.currentTarget.textContent)}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <Field
                                    component={Dropdown}
                                    id="category"
                                    name="category"
                                    label="Awards *"
                                    placeholder="Select Awards"
                                    options={[
                                      { name: 'None', value: 'None' },
                                      { name: 'Competitors', value: 'competitors' },
                                      { name: 'Work', value: 'work' },
                                      { name: 'Quick Shift', value: 'quick-shift' },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form-check me-4">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSleep"
                                name="isSleep"
                                checked={values?.isSleep}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  setValues({ ...values, isSleep: event.target.checked });
                                }}
                              />
                              <p className="form-check-label">Sleep Channel</p>
                            </div>
                            <div className="row">
                              <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                                <Field
                                  name="deepPrimer"
                                  component={SelectPrimer}
                                  primerType={PrimerType.DEEP}
                                />
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                                <Field
                                  name="powerPrimer"
                                  component={SelectPrimer}
                                  primerType={PrimerType.POWER}
                                />
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-lg-0">
                                <Field name="courses" component={SelectCourses} />
                              </div>
                              <div className="col-12 col-lg-6 col-xl-3">
                                <Field name="channels" component={SelectChannel} />
                              </div>
                            </div>

                            <div className="row justify-content-end align-items-end">
                              <div className="col-12 col-lg-6 col-xl-3">
                                <div className="dropdown">
                                  <button
                                    className="btn btn-primary w-100 dropdown-toggle"
                                    type="button"
                                    id="chanenel-edit-dropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Save channel
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => {
                                          setSave('publish');
                                          handleSubmit();
                                        }}
                                      >
                                        Save & Publish
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => {
                                          setSave('unpublish');
                                          handleSubmit();
                                        }}
                                      >
                                        Save as unpublished
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SavePublishChannel
        channel={{
          ...editChannel,
          channels: undefined,
          courses: undefined,
          channelUpgrades: editChannel.courses,
          subChannels: editChannel.channels,
          tags: filteredTags,
          isPublished: true,
          file: pic,
        }}
        type="edit"
      />
      <SaveUnpublishChannel
        channel={{
          ...editChannel,
          channels: undefined,
          courses: undefined,
          channelUpgrades: editChannel.courses,
          subChannels: editChannel.channels,
          tags: filteredTags,
          isPublished: false,
          file: pic,
        }}
        type="edit"
      />
    </>
  );
}

export default EditChannel;
