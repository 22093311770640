import React, { ChangeEvent } from 'react';
import Input from '../../components/input';

interface CompaniesInfo {
  id?: string;
  name: string;
  billingEmail: string;
  address: string;
  supportEmail: string;
  website: string;
  stripeId: string;
}
interface CompanyDetailsProps {
  companyDetailss: CompaniesInfo;
  setCompanyDetails: React.Dispatch<React.SetStateAction<CompaniesInfo>>;
  handleClick: () => void | undefined;
  handleNavigate: () => void | undefined;
}
function CompanyDetailsForm({
  companyDetailss,
  setCompanyDetails,
  handleClick,
  handleNavigate,
}: CompanyDetailsProps) {
  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
      <div className="content_decor" />
      <div className="row pb-3 pb-md-4 align-items-center">
        <div className="col-12">
          <h1 className="headline-decor">Subscriptions</h1>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-3 p-xl-4 bg-color-gray-200 radius-16">
            <div className="row border-bottom-gray-400 mb-4 align-items-center">
              <div className="col-6 mb-2">
                <h4 className="m-0 text-truncate">Company Details</h4>
              </div>
              <div className="col-6 ms-auto mb-2 d-flex">
                <button
                  className="btn btn-link text-decoration-none color-secondary font-medium ms-auto"
                  type="button"
                  onClick={() => handleNavigate()}
                >
                  <i className="fa-regular fa-gear me-2" />
                  Manage Subscriptions
                </button>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <p className="form-label mb-3">Company name</p>
                  <Input
                    type="text"
                    id="name"
                    value={companyDetailss?.name}
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCompanyDetails({ ...companyDetailss, name: event.target.value })
                    }
                    placeholder="Company name"
                  />
                </div>

                <div className="mb-3">
                  <p className="form-label mb-3">Address</p>
                  <Input
                    type="text"
                    id="address"
                    value={companyDetailss?.address}
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCompanyDetails({ ...companyDetailss, address: event.target.value })
                    }
                    aria-describedby="address"
                    placeholder="Address"
                  />
                </div>

                <div className="mb-3">
                  <p className="form-label mb-3">Website</p>
                  <Input
                    type="text"
                    id="website"
                    value={companyDetailss?.website}
                    onValueChange={(e) =>
                      setCompanyDetails({ ...companyDetailss, website: e.target.value })
                    }
                    aria-describedby="website"
                    placeholder="Website"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <p className="form-label mb-3">Billing e-mail</p>
                  <Input
                    type="text"
                    id="billing_email"
                    value={companyDetailss?.billingEmail}
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCompanyDetails({ ...companyDetailss, billingEmail: event.target.value })
                    }
                    aria-describedby="billing_email"
                    placeholder="Billing e-mail"
                  />
                </div>
                <div className="mb-3">
                  <p className="form-label mb-3">Support e-mail</p>
                  <Input
                    type="text"
                    id="support_email"
                    value={companyDetailss?.supportEmail}
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCompanyDetails({ ...companyDetailss, supportEmail: event.target.value })
                    }
                    aria-describedby="support_email"
                    placeholder="Support e-mail"
                  />
                </div>
                <div className="mb-3">
                  <p className="form-label mb-3">Stripe ID</p>
                  <Input
                    type="text"
                    id="stripe_id"
                    value={companyDetailss?.stripeId}
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setCompanyDetails({ ...companyDetailss, stripeId: event.target.value })
                    }
                    aria-describedby="stripe_id"
                    placeholder="Stripe ID"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  className="d-block btn btn-primary px-5"
                  type="button"
                  onClick={() => handleClick()}
                >
                  <i className="fa fa-check" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsForm;
