import apiClient from '../../helpers/api.js';

class adminMarketing {
  static async createAdminMarketing(data: any) {
    const formData = new FormData();

    Object.keys(data).map((attr) => data[attr] !== null && formData.append(attr, data[attr]));
    const response = apiClient.postRequest('/api/marketings', formData);

    return response;
  }

  static async editAdminMarketing(data: any) {
    const formData = new FormData();

    Object.keys(data).map((attr) => data[attr] !== null && formData.append(attr, data[attr]));
    const response = apiClient.postRequest('/api/marketings', formData);

    return response;
  }
}

export default adminMarketing;
