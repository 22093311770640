/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface DragImageProps {
  setImage: React.Dispatch<React.SetStateAction<any>>;
  file?: any;
  image?: any;
  selectedFile: any;
  setSelectedFile: React.Dispatch<React.SetStateAction<any>>;
  from?: string;
}

function DragImage({ setImage, file, image, selectedFile, setSelectedFile, from }: DragImageProps) {
  useEffect(() => {
    setSelectedFile(file);
  }, [file]);

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      if (from === 'sessions') {
        setImage(acceptedFiles[0]);
      } else {
        setImage(e?.target?.result);
      }
      setSelectedFile(acceptedFiles[0]);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'image/*': [],
    },
  });

  return (
    <>
      {(selectedFile || image) && (
        <img src={selectedFile.url || image.result || image} alt="Session" className="mb-2 w-100" />
      )}
      <div {...getRootProps()} className="form-control radius-10 shadow text-truncate">
        <input {...getInputProps()} className="text-truncate" />
        <span>{selectedFile?.name ? selectedFile?.name : 'Drag and drop new image here'}</span>
      </div>
    </>
  );
}

DragImage.defaultProps = {
  file: '',
  from: '',
  image: '',
};

export default DragImage;
