import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { saveDiscountInfo } from '../../../redux/thunks/discountThunk';
import { DeviceType } from '../../../redux/interface/discountInterface';

interface DiscountProps {
  discount: {
    id?: string;
    startAt: string;
    endAt: string;
    discount: number;
    status: string | boolean;
    type: string;
    device: DeviceType;
  };
}

function UpdateAdvertisement({ discount }: DiscountProps) {
  const dispatch = useAppDispatch();
  const handleUpdate = () => {
    dispatch(saveDiscountInfo(discount));
  };
  return (
    <div
      className="modal fade modal-edit-profile"
      id="update_advertisement"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h4 className="mb-4">Update Advertisement?</h4>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-dismiss="modal"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateAdvertisement;
