/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import DragCSVFileInput from '../../../components/dragCSVFile';
import { addNewTeamMemberCsvs } from '../../../redux/thunks/teamThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface AddNewTeamMemberCSVProps {
  teamId: string;
}

function AddNewMemberCSV({ teamId }: AddNewTeamMemberCSVProps) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.team);

  const dispatch = useAppDispatch();
  const [csv, setCsv] = useState<any>();
  useEffect(() => {
    if (status === 'succeeded' && modalName === 'addNewTeamMemberCSV') {
      hideModal({ modalId: '#closeModal_newTeam' });
    }
  }, [modalOpen]);
  const csvData = [
    ['Name', 'Email'],
    ['User 1', 'user1@primedmind.com'],
    ['User 2', 'user2@primedmind.com'],
    ['User 3', 'user3@primedmind.com'],
  ];
  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_new_member_csv"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5">
            <div className="text-center font-light">
              <p className="color-gray-600">
                Download the CSV file template, fill it in with the information about the users you
                want to add, and then upload the file back to the box below.
              </p>
            </div>

            <button className="btn btn-primary w-100 mb-4" type="button">
              <CSVLink
                data={csvData}
                filename="template.csv"
                className="text-black text-decoration-none"
              >
                Download CSV file template
              </CSVLink>
            </button>
            <DragCSVFileInput setCsv={setCsv} />
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <div className="mb-3">
                    <button
                      type="button"
                      id="closeModal_newTeamMemberCSV"
                      className="btn btn-primary w-100"
                      disabled={status === 'loading'}
                    >
                      {status === 'loading' ? (
                        <span
                          className="spinner-border"
                          style={{ width: '1em', height: '1em' }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      ) : (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(addNewTeamMemberCsvs({ file: csv, team_id: teamId }));
                          }}
                          role="button"
                          style={{ width: '100%', boxSizing: 'border-box' }}
                        >
                          <i className="fa fa-check" />
                          Send
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-dismiss="modal"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewMemberCSV;
