/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { deleteCard } from '../../../redux/thunks/userSettingsThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { THUNK_STATUS } from '../../../constants';

interface Props {
  id: number;
}

function DeleteCard({ id }: Props) {
  const dispatch = useAppDispatch();
  const handleConfirmDelete = () => {
    dispatch(deleteCard(id));
  };

  const { status } = useAppSelector((state) => state.userSettings.cardForm);

  const { modalOpen, modalName } = useAppSelector((state) => state.userSettings);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED && modalName === 'deleteCard') {
      hideModal({ modalId: '#closeModal_deleteCard' });
    }
  }, [modalOpen]);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="delete_credit_card"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => hideModal({ modalId: '#closeModal_deleteCard' })}
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Are you sure you want to delete this card?</h5>

              <p className="color-gray-600">
                In order to add it again you will need to re-enterall the data.
              </p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 pe-0">
                  <button
                    id="closeModal_deleteCard"
                    type="button"
                    className="btn btn-danger w-100"
                    disabled={status === 'loading'}
                  >
                    {status === 'loading' ? (
                      <span
                        className="spinner-border"
                        style={{ width: '1em', height: '1em' }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    ) : (
                      <div
                        onClick={() => handleConfirmDelete()}
                        role="button"
                        style={{ width: '100%', boxSizing: 'border-box' }}
                      >
                        <i className="fa fa-check" />
                        Yes, Delete
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-light w-100"
                    data-bs-dismiss="modal"
                    onClick={() => hideModal({ modalId: '#closeModal_deleteCard' })}
                  >
                    No, Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteCard;
