import React from 'react';
import logo from '../assets/img/logo.svg';

function OnBoardingLogoBox() {
  return (
    <div className="onboarding__logo-box d-flex flex-column align-items-center bg-color-secondary flex-grow-1 p-md-3">
      <div className="onboarding__logo d-flex flex-column justify-content-center align-items-center w-100 m-auto">
        <img src={logo} alt="" />
        <p className="color-white d-none d-md-block mt-5">
          Activate your best life in just minutes a day.
        </p>
      </div>
    </div>
  );
}

export default OnBoardingLogoBox;
