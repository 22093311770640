interface Props {
  numberOfRows: number;
  onSelection: Function;
}

function ShowRows({ numberOfRows, onSelection }: Props) {
  const handleSelection = (e: any) => onSelection(e.target.value);

  return (
    <select
      className="form-control form-select form-select__table-rows "
      value={numberOfRows}
      onChange={handleSelection}
    >
      <option value={10}>Show 10</option>
      <option value={20}>Show 20</option>
      <option value={50}>Show 50</option>
      <option value={100}>Show 100</option>
    </select>
  );
}

export default ShowRows;
