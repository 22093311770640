/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import AppUserResetPassword from './appUserResetPassword';
import AppUserBlockAccount from './appUserBlockAccount';
import { updateAppUser } from '../../../redux/thunks/appUsersThunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import AppUserDeleteAccount from './appUserDeleteAccount';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface AppUserSettingInfo {
  emailSetting: string;
  nameSetting: string;
  typeSetting: string;
  discountAppliedSetting: boolean;
  revenueSetting: number;
  listensSetting: number;
  usageSetting: number;
}

interface AppUserSettingProps {
  userId: number;
  exploreAppSetting: AppUserSettingInfo;
  setExploreAppSetting: React.Dispatch<React.SetStateAction<AppUserSettingInfo>>;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter the user email address.'),
  type: Yup.string().required('Account type is required'),
});

function AppUserSettings({ userId, exploreAppSetting, setExploreAppSetting }: AppUserSettingProps) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.appUsers);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'deleteSucceeded') {
      navigate(-1);
    }
  }, [status]);
  return (
    <>
      <div
        tabIndex={-1}
        className="modal fade modal-edit-profile"
        id="user_settings"
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <Formik
          initialValues={{
            email: exploreAppSetting?.emailSetting || '',
            name: exploreAppSetting?.nameSetting || '',
            type: exploreAppSetting?.typeSetting?.toLowerCase() || '',
            discountApplied: exploreAppSetting?.discountAppliedSetting || false,
            revenue: exploreAppSetting?.revenueSetting || 0,
            listens: exploreAppSetting?.listensSetting || 0,
            usage: exploreAppSetting?.usageSetting || 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setExploreAppSetting({
              emailSetting: values?.email,
              nameSetting: values?.name,
              typeSetting: values?.type,
              discountAppliedSetting: values?.discountApplied,
              revenueSetting: values?.revenue,
              listensSetting: values?.listens,
              usageSetting: values?.usage,
            });
            dispatch(
              updateAppUser({
                name: values?.name,
                email: values?.email,
                type: values?.type,
                id: userId.toString(),
                discountApplied: values?.discountApplied,
              }),
            );
          }}
        >
          {({ handleSubmit, setValues, setTouched, values }) => {
            useEffect(() => {
              setValues({
                email: exploreAppSetting?.emailSetting,
                name: exploreAppSetting?.nameSetting,
                type: exploreAppSetting?.typeSetting?.toLowerCase(),
                discountApplied: exploreAppSetting?.discountAppliedSetting,
                revenue: exploreAppSetting?.revenueSetting,
                listens: exploreAppSetting?.listensSetting,
                usage: exploreAppSetting?.usageSetting,
              });
            }, [exploreAppSetting]);

            useEffect(() => {
              if (status === 'succeeded' && modalName === 'editAppUser') {
                hideModal({ modalId: '#closeModal_editAppUser' });
              }
            }, [modalOpen]);

            return (
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setTouched({})}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h5 className="mb-3">Settings</h5>
                      <p className="color-gray-600">{exploreAppSetting.nameSetting}</p>
                    </div>

                    <form>
                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="email"
                          description="email"
                          placeholder="Email"
                          label="Email *"
                        />
                      </div>

                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="name"
                          description="name"
                          placeholder="Name"
                          label="Name"
                        />
                      </div>

                      <div className="mb-3 w-100">
                        <button
                          className="d-block btn btn-primary m-auto px-5 "
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          data-bs-target="#reset_password_to_email"
                        >
                          Reset Password
                        </button>
                      </div>

                      <div className="mb-3">
                        <Field
                          component={Dropdown}
                          name="type"
                          label="Account type"
                          placeholder="Select Type"
                          options={[
                            { name: 'Single', value: 'single' },
                            { name: 'Team', value: 'team' },
                          ]}
                        />
                      </div>

                      {values?.discountApplied && (
                        <div className="w-100">
                          <button
                            className="d-block btn btn-link text-decoration-none color-secondary m-auto px-5 "
                            type="button"
                            onClick={() =>
                              setValues({
                                ...values,
                                discountApplied: false,
                              })
                            }
                          >
                            Discount Applied
                            <i className="fa-regular fa-xmark fa-xl ms-2" />
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row mb-3">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_editAppUser"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </span>
                            ) : (
                              <div
                                onClick={(e) => {
                                  handleSubmit();
                                }}
                                role="button"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Save
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setTouched({});
                            }}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            className="d-block mt-2 btn btn-link-danger  text-decoration-none color-secondary m-auto px-5 border-0"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-target="#block_account"
                          >
                            Block account
                          </button>
                        </div>
                        <div className="col-12">
                          <button
                            className="d-block mt-2 btn btn-link-danger  text-decoration-none color-secondary m-auto px-5 border-0"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-target="#delete_account"
                          >
                            Delete account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      <AppUserResetPassword />
      <AppUserBlockAccount userId={userId} />
      <AppUserDeleteAccount userId={userId} />
    </>
  );
}

export default AppUserSettings;
