interface ErrorAlertProps {
  errorMessage?: string;
  supportEmail?: string;
}

function ErrorAlert({ errorMessage, supportEmail }: ErrorAlertProps) {
  return errorMessage ? (
    <div className="alert alert-danger alert-dismissible d-flex align-items-center fade show">
      <i className="bi-exclamation-octagon-fill" />
      <span>
        {errorMessage}{' '}
        {supportEmail && (
          <a href={`mailto:${supportEmail}`} className="text-secondary font-weight-bold">
            {supportEmail}
          </a>
        )}
      </span>
    </div>
  ) : null;
}

ErrorAlert.defaultProps = {
  errorMessage: '',
  supportEmail: '',
};

export default ErrorAlert;
