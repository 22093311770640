import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { postsSubscriptionsInfo } from '../interface/subscriptionInterface';

export const postSubscriptionInfo = createAsyncThunk(
  'subscriptions/companies',
  async (detail: postsSubscriptionsInfo, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('api/subscriptions/store', detail);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getSubscriptionsInfo = createAsyncThunk('subscriptions/getInfo', async () => {
  const response = await apiClient.getRequest('/api/subscription/list?device=all');
  return response.data;
});

export const fetchCustomCheckoutPageInfo = createAsyncThunk(
  'subscription/fetchCustomCheckoutPageInfo',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(`/api/stripe/custom-checkout/${uuid}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
