/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import { EditSession, Narrator } from '../../../redux/interface/sessionInterface';
import { updateSession, deleteNarrator } from '../../../redux/thunks/sessionThunk';
import DragFormikAudio from '../../../components/formik/dragAudio';
import DragImage from '../../../components/dragImage';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { filterTags } from '../../../helpers/filterTag';
import EditNarrator from './editEditNarrator';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the session name'),
  description: Yup.string().required('Please enter description'),
  type: Yup.string().required('Type is required'),
  isFree: Yup.number().required('Audio type is required'),
});
interface Props {
  sessionToEdit: EditSession;
}

function EditSessions({ sessionToEdit }: Props) {
  const dispatch = useAppDispatch();

  const [newTag, setNewTag] = useState<string | null>('');
  const [filteredTags, setFilteredTags] = useState<any>();
  const [audio, setAudio] = useState<any>('');
  const [image, setImage] = useState<any>('');
  const [selectedImageFile, setSelectedImageFile] = useState<File>();

  const [narratorId, setNarratorId] = useState<number>(1);
  const [editNarrator, setEditNarrator] = useState<Narrator>();

  const [narrators, setNarrators] = useState<Narrator[]>([]);

  const [more, setMore] = useState(false);

  const [selectedNarratoAudioFile, setSelectedNarratorAudioFile] = useState<File>();
  const handleNarratorDelete = (id: any) => {
    const filteredNarrators = narrators.filter((narrator: Narrator) => narrator.id !== id);
    setNarrators(filteredNarrators);
  };

  const [err, setErr] = useState(false);

  const content = useRef<HTMLDivElement>(null);

  const { status, modalOpen, modalName } = useAppSelector((state) => state.session);

  useEffect(() => {
    const formattedNarrators = sessionToEdit?.narrators?.map((narrator: any) => ({
      name: narrator?.name,
      id: narrator?.id,
      audio: '',
    }));
    setNarrators(formattedNarrators);
  }, [sessionToEdit]);

  useEffect(() => {
    if (narrators && editNarrator) {
      const restOfNarrator = narrators.filter(
        (narrator: Narrator) => narrator.id !== editNarrator?.id,
      );

      setNarrators(restOfNarrator.concat(editNarrator));
    }
  }, [editNarrator]);

  return (
    <>
      <div
        className="modal fade modal-edit-profile"
        id="edit_session"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <Formik
          initialValues={{
            id: '',
            title: '',
            description: sessionToEdit?.description,
            isFree: sessionToEdit?.isFree,
            type: '',
            tags: sessionToEdit?.tags,
            media: '',
            image: '',
            audio: '',
            narratorName: '',
            narratorAudio: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const { media, ...restOfValues } = values;
            setErr(false);
            setMore(false);
            dispatch(
              updateSession({
                ...restOfValues,
                narrators,
                tags: filteredTags,
                image,
              }),
            );
          }}
        >
          {({ handleSubmit, setValues, setTouched, values }) => {
            useEffect(() => {
              if (status === 'succeeded' && modalName === 'editSession') {
                hideModal({ modalId: '#closeModal_editSession' });
                setTouched({});
                if (content.current) {
                  content.current.textContent = '';
                }
              }
            }, [modalOpen]);

            useEffect(() => {
              setValues({
                ...values,
                id: sessionToEdit?.id,
                title: sessionToEdit?.title,
                description: sessionToEdit?.description,
                isFree: sessionToEdit?.isFree,
                type: sessionToEdit?.type,
                tags: sessionToEdit?.tags,
                image: sessionToEdit?.image,
              });
              setSelectedImageFile(sessionToEdit?.image);
            }, [sessionToEdit]);

            const handleRemove = (t: string) => {
              const fileteredTags = values?.tags?.filter((tag) => tag !== t);
              setValues({
                ...values,
                tags: fileteredTags,
              });
            };

            useEffect(() => {
              setFilteredTags(filterTags(newTag, values?.tags));
            }, [newTag, values?.tags]);

            return (
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        if (content.current) {
                          setErr(false);
                          setNarrators([]);
                          content.current.textContent = '';
                          setMore(false);
                        }
                      }}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Edit Session</h4>
                    </div>

                    <form>
                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="title"
                          description="title"
                          placeholder="Title"
                          label="Session Name *"
                        />
                      </div>

                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="description"
                          description="description"
                          placeholder="Description"
                          label="Description *"
                        />
                      </div>

                      <div className="mb-3 flex-nowrap">
                        <p className="form-label">
                          <b>Audios</b>
                        </p>
                        {narrators.length !== 0 ? (
                          <div className="table-responsive scrolling">
                            <table className="table no-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Narrator Name</th>
                                  <th className="text-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {narrators &&
                                  narrators.map((narrator: Narrator) => (
                                    <tr key={narrator?.id}>
                                      <td>{narrator?.name}</td>
                                      <td className="actions-cell justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm justify-content-center"
                                          onClick={() =>
                                            setEditNarrator({
                                              name: narrator?.name,
                                              id: narrator?.id,
                                              audio: narrator?.audio,
                                            })
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                          data-bs-target="#edit_narrator"
                                        >
                                          <i className="fa-solid fa-edit ml-4" />
                                        </button>
                                        {narrators.length > 1 && (
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-sm justify-content-center"
                                            onClick={() => {
                                              if (narrator?.id) {
                                                dispatch(deleteNarrator(narrator?.id));
                                              }
                                              handleNarratorDelete(narrator?.id);
                                            }}
                                          >
                                            <i className="fa-solid fa-trash-can ml-2 " />
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm mb-2"
                            onClick={() => setMore(!more)}
                          >
                            <i className="fa-solid fa-music" />
                            Add audio narrator
                          </button>
                        </div>
                        {more ? (
                          <div className="bg-color-white radius-16 p-4">
                            {err ? (
                              <p className="text-danger fs-50">*Both fields are required</p>
                            ) : null}
                            <div>
                              <Field
                                component={Dropdown}
                                label=" Narrator *"
                                placeholder="Select Narrator"
                                name="narratorName"
                                options={[
                                  { name: 'Elliot Roe', value: 'Elliot Roe' },
                                  { name: 'Christian Weilharter', value: 'Christian Weilharter' },
                                  { name: 'Adrienne Carter', value: 'Adrienne Carter' },
                                  { name: 'Samm Hunter', value: 'Samm Hunter' },
                                  { name: 'Stephen Baker', value: 'Stephen Baker' },
                                ]}
                              />
                            </div>
                            <div>
                              <Field
                                component={DragFormikAudio}
                                name="narratorAudio"
                                label="Narrator Audio (.mp3)*"
                                setSelectedFile={setSelectedNarratorAudioFile}
                                selectedFile={selectedNarratoAudioFile}
                              />
                            </div>
                            <div className="d-flex justify-content-between">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                  if (values?.narratorName !== '' && values?.narratorAudio !== '') {
                                    setNarrators([
                                      ...narrators,
                                      {
                                        name: values?.narratorName,
                                        audio: values?.narratorAudio,
                                        id: narratorId,
                                      },
                                    ]);
                                    setNarratorId(narratorId + 1);
                                    setValues({
                                      ...values,
                                      narratorName: '',
                                      narratorAudio: '',
                                    });
                                    setSelectedNarratorAudioFile(undefined);
                                    setErr(false);
                                    setMore(false);
                                  } else {
                                    setErr(true);
                                  }
                                }}
                              >
                                <i className="fa fa-check" />
                                save
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-dark btn-sm"
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    narratorName: '',
                                    narratorAudio: '',
                                  });
                                  setErr(false);
                                  setSelectedNarratorAudioFile(undefined);
                                  setMore(false);
                                }}
                              >
                                <i className="fa fa-times" />
                                cancel
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <p className="form-label">
                          <b>Image *</b>
                        </p>
                        <DragImage
                          selectedFile={selectedImageFile}
                          setSelectedFile={setSelectedImageFile}
                          setImage={setImage}
                          image={image}
                          from="sessions"
                        />
                      </div>

                      <div>
                        <Field
                          component={Dropdown}
                          name="type"
                          label="Category *"
                          placeholder="Select Category"
                          options={[
                            { name: 'Deep primer', value: 'deep-primer' },
                            { name: 'Power primer', value: 'power-primer' },
                            { name: 'Upgrades', value: 'upgrade' },
                            { name: 'Uncategorized', value: 'session' },
                          ]}
                        />
                      </div>

                      <div className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="form-label">Tags</p>
                        </div>
                        <p className="color-gray-600">
                          Enter the tags you want to add to the session separated by a comma
                        </p>
                        <div className="form-control tags-field @@classNameName">
                          {values?.tags?.map((t) => (
                            <div className="tag" key={`${t}${values?.id}`}>
                              <span className="tag__label">{t}</span>
                              <button
                                style={{
                                  border: 'none',
                                  margin: 0,
                                  padding: 0,
                                  background: 'none',
                                }}
                                type="button"
                                onClick={() => handleRemove(t)}
                              >
                                <i className="fa-regular fa-xmark" />
                              </button>
                            </div>
                          ))}
                          <div
                            className="d-inline-block input"
                            ref={content}
                            contentEditable="true"
                            onInput={(e) => setNewTag(e.currentTarget.textContent)}
                          />
                        </div>
                      </div>

                      <div>
                        <div>
                          <Field
                            label="Select Type *"
                            component={Dropdown}
                            name="isFree"
                            placeholder="Select audio type"
                            options={[
                              { name: 'Free Audio', value: 1 },
                              { name: 'Paid audio', value: 0 },
                            ]}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 px-1 ps-0">
                          <div className="mb-3">
                            <button
                              type="button"
                              id="closeModal_editSession"
                              className="btn btn-primary w-100"
                              disabled={status === 'loading'}
                            >
                              {status === 'loading' ? (
                                <span
                                  className="spinner-border"
                                  style={{ width: '1em', height: '1em' }}
                                  role="status"
                                >
                                  <span className="visually-hidden">Loading...</span>
                                </span>
                              ) : (
                                <div
                                  onClick={() => handleSubmit()}
                                  role="button"
                                  style={{ width: '100%', boxSizing: 'border-box' }}
                                >
                                  <i className="fa fa-check" />
                                  Save
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              if (content.current) {
                                setMore(false);
                                setErr(false);
                                setNarrators([]);
                                content.current.textContent = '';
                              }
                            }}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      <EditNarrator narratorTOEdit={editNarrator} setNarratorToEdit={setEditNarrator} />
    </>
  );
}

export default EditSessions;
