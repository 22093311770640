/* eslint-disable  @typescript-eslint/no-unused-vars */
import { ChangeEvent, MouseEventHandler, useEffect } from 'react';

interface SingleTeamProps {
  handleDelete: (teamId: string) => void;
  handleView: (teamId: string, seats: string, teamName: string) => void;
  teamId: string;
  t: any;
}
function SingleTeam({ handleDelete, handleView, teamId, t }: SingleTeamProps) {
  const handleDeleting = () => {
    handleDelete(teamId);
  };
  const handleViewing = (seats: string, teamName: string) => {
    handleView(teamId, seats, teamName);
  };

  const showUserPlan = (team: any) => {
    if (team?.owner?.isFree) {
      return 'Free';
    }
    if (!team?.owner?.isFree && team?.owner?.latestBillingPlan?.isTrial) {
      return 'Trial';
    }
    return 'Paid';
  };
  return (
    <tr key={`${teamId}${t?.owner?.name}`}>
      <td className="font-medium">{t?.label || t?.owner?.name}</td>
      <td className="font-medium">{t?.totalMembers}</td>
      <td>{t?.monthlyRevenue}</td>
      <td>{t?.totalRevenue}</td>
      <td>{t?.usage}</td>
      <td className="font-medium">{showUserPlan(t)}</td>
      <td className="actions-cell">
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          onClick={() => handleViewing(t?.seats, t?.label || t?.owner?.name)}
        >
          <i className="fa fa-eye" />
          View
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_team"
          type="button"
          onClick={handleDeleting}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
      </td>
    </tr>
  );
}

export default SingleTeam;
