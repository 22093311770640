import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { generatePath } from 'react-router-dom';
import Input from '../../../components/formik/input';
import { actions } from '../../../redux/slices/userSettingsSlice';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { THUNK_STATUS, THUNK_LOADING } from '../../../constants';
import AdminUserConfirmAddSeats from './adminUserConfirmAddSeats';
import { URL_ROUTES } from '../../../constants/routes';
import { useServices } from '../../../redux/servicesLayer';

export const MODAL_NAME = 'adminUserMemberCountForm';

const validationSchema = Yup.object().shape({
  seats: Yup.number().required('Please provide number of additional seats'),
});

function AdminUserMemberCountForm() {
  const dispatch = useAppDispatch();
  const { currentSubscription } = useServices();
  const status = useAppSelector((state) => state.userSettings.loading.inquireSeats);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  useEffect(() => {
    dispatch(actions.clearInquireSeats());
    return () => {
      dispatch(actions.clearInquireSeats());
    };
  }, []);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      dispatch(modalActions.setVisibleModal('adminUserConfirmAddSeats'));
    }
  }, [status]);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  return (
    <>
      <Modal show={showModal} centered>
        <Formik
          initialValues={{ seats: 1 }}
          validationSchema={validationSchema}
          onSubmit={({ seats }) => {
            // dispatch(inquireMoreSeats(seats));
            window.location.href = `${window.location.origin}${generatePath(URL_ROUTES.Checkout, {
              subscribeType: currentSubscription?.type,
              subscribeId: currentSubscription?.id.toString(),
            })}?mode=addTeam&seat=${seats}`;
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header className="pb-0 p-4">
                <button
                  type="button"
                  className="btn-close icon-size-12 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                />
              </Modal.Header>
              <Modal.Body className="ps-5 pe-5">
                <div className="text-center font-light">
                  <h5 className="mb-3">Subscribe</h5>
                  <p className="color-gray-600">Help us create the best plan for you</p>
                </div>
                <Field
                  component={Input}
                  label="Number of Users"
                  type="number"
                  min={1}
                  name="seats"
                  description="Seats"
                  placeholder="Number of Users"
                />
              </Modal.Body>
              <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
                <button
                  className="btn btn-primary px-5"
                  type="submit"
                  disabled={status === THUNK_LOADING}
                >
                  {status === THUNK_LOADING ? (
                    <span
                      className="spinner-border"
                      style={{ width: '1em', height: '1em' }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  ) : (
                    <>
                      <i className="fa-regular fa-loader me-1" /> Load Plans
                    </>
                  )}
                </button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
      <AdminUserConfirmAddSeats />
    </>
  );
}

export default AdminUserMemberCountForm;
