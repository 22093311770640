import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import Input from '../../../components/formik/input';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { THUNK_LOADING, THUNK_STATUS } from '../../../constants';
import { useAuth } from '../../../redux/authLayer';
import ErrorAlert from '../../../components/errorAlert';
import { addTeamMember, editTeamMember } from '../../../redux/thunks/userSettingsThunk';
import { toast } from '../../../helpers/toaster';

export const MODAL_NAME = 'adminUserForm';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter name.'),
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter email address.'),
});

interface AdminUserFormProps {
  onSubmitSuccess?: () => any;
}

function AdminUserForm({ onSubmitSuccess }: AdminUserFormProps) {
  const dispatch = useAppDispatch();
  const { teamOwner: team } = useAuth();
  const {
    status,
    error,
    message: successMessage,
  } = useAppSelector((state) => state.userSettings.teamMembers.form);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);
  const editMode = useAppSelector(
    (state) =>
      state.modal?.info[MODAL_NAME] && Object.keys(state.modal.info[MODAL_NAME]).length > 0,
  );
  const editUserInfo = useAppSelector((state) => state.modal.info[MODAL_NAME]);

  const initialValues = editMode
    ? { name: editUserInfo.name, email: editUserInfo.email }
    : { name: '', email: '' };

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      toast(successMessage || 'Saved.', 'success');
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
      dispatch(modalActions.closeModal());
    }
  }, [status]);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  return (
    <Modal show={showModal} centered>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ name, email }) => {
          if (team) {
            if (editMode) {
              dispatch(editTeamMember({ name, email, team, userId: editUserInfo?.userId }));
            } else {
              dispatch(addTeamMember({ name, email, team }));
            }
          }
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header className="pb-0 p-4">
              <button
                type="button"
                className="btn-close icon-size-12"
                aria-label="Close"
                onClick={handleCloseModal}
              />
            </Modal.Header>
            <Modal.Body className="pt-0 ps-5 pe-5">
              <div className="text-center font-light">
                <h5 className="mb-3">{`${!editMode ? 'Add New' : 'Edit'} User`}</h5>
              </div>
              <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
              <Field
                component={Input}
                label="Name"
                type="text"
                name="name"
                description="Name"
                placeholder="Full Name"
              />
              <Field
                component={Input}
                label="Email"
                type="email"
                name="email"
                description="email"
                placeholder="Email Address"
              />
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-6 px-1 ps-0">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={status === THUNK_LOADING}
                    >
                      {status === THUNK_LOADING ? (
                        <span
                          className="spinner-border"
                          style={{ width: '1em', height: '1em' }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      ) : (
                        <>
                          <i className="fa-regular fa-floppy-disk" />
                          &nbsp;Save
                        </>
                      )}
                    </button>
                  </div>
                  <div className="col-6 px-1 pe-0">
                    <button
                      type="button"
                      className="btn btn-light w-100"
                      data-bs-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      {' '}
                      <i className="fa-regular fa-xmark me-1" />
                      &nbsp;Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

AdminUserForm.defaultProps = {
  onSubmitSuccess: () => {},
};

export default AdminUserForm;
