/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants/index';
import { condenseChannel } from '../../helpers/entityHelpers';
import { toast } from '../../helpers/toaster';
import { PlayerState, SessionInterface } from '../interface/entitiesInterface';
import { getCourseInfo, getSessionInfo, getChannelInfo } from '../thunks/entitiesThunk';
import {
  generateShareAudioUrl,
  getRecentAudioSessions,
  getShareAudioSession,
} from '../thunks/playerThunk';

const initialState: PlayerState = {
  loading: {
    course: undefined,
    session: undefined,
    channel: undefined,
    recentSessions: undefined,
    urlToShare: undefined,
    sharedSession: undefined,
  },
  course: null,
  session: null,
  channel: null,
  currentTrack: 0,
  tracklist: [],
  recentSessions: [],
  volume: Number(localStorage.getItem('playerVolume')) || 65,
  loopMode: localStorage.getItem('loopMode') === 'true',
  currentNarrator: 0,
  playables: [],
  urlToShare: '',
  sharedSession: null,
};

export const playerSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setVolume: (state, action: PayloadAction<number>) => {
      localStorage.setItem('playerVolume', action.payload.toString());
      return {
        ...state,
        volume: action.payload,
      };
    },
    setLoopMode: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('loopMode', action.payload.toString());
      return {
        ...state,
        loopMode: action.payload,
      };
    },
    setTracklist: (state, action: PayloadAction<SessionInterface[]>) => ({
      ...state,
      tracklist: action.payload,
    }),
    setSession: (state, action: PayloadAction<SessionInterface>) => ({
      ...state,
      session: action.payload,
      currentNarrator: 0,
    }),
    setCurrentNarrator: (state, action: PayloadAction<number>) => ({
      ...state,
      currentNarrator: action.payload,
    }),
    clearPlayer: (state) => ({
      ...state,
      tracklist: [],
      session: null,
      course: null,
      channel: null,
      recentSessions: [],
      currentTrack: 0,
    }),
    setCurrentTrackOrder: (state, action: PayloadAction<number>) => ({
      ...state,
      currentTrack: action.payload,
    }),
    nextSession: (state, action) => {
      const { loopMode, tracklist } = state;
      let { currentTrack } = state;
      if (state.currentTrack < tracklist.length - 1) {
        currentTrack += 1;
      }
      if (loopMode && !action.payload.nextButtonPressed) {
        currentTrack = state.currentTrack;
      }

      return {
        ...state,
        currentTrack,
        session: state.tracklist[currentTrack],
        currentNarrator: 0,
      };
    },
    previousSession: (state) => {
      const { loopMode, tracklist } = state;
      let { currentTrack } = state;
      if (state.currentTrack > 0) {
        currentTrack -= 1;
      } else if (loopMode) {
        currentTrack = tracklist.length - 1;
      }

      return {
        ...state,
        currentTrack,
        session: state.tracklist[currentTrack],
        currentNarrator: 0,
      };
    },
    setPlayables: (state, action) => ({
      ...state,
      playables: action.payload,
    }),
    setNextTrackToPlayable: (state) => {
      const { currentTrack, playables } = state;
      const newPlayables = [...playables];
      if (playables.length >= currentTrack) {
        newPlayables[currentTrack + 1] = true;
        return {
          ...state,
          playables: newPlayables,
        };
      }
      return state;
    },
    setPlayable: (state, action: PayloadAction<{ idx: number; value: boolean }>) => {
      const playables = [...state.playables];
      const { idx, value } = action.payload;
      if (playables.length >= idx) {
        playables[idx] = value;
        return {
          ...state,
          playables,
        };
      }
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSessionInfo.pending, (state) => {
        state.loading = { ...state.loading, session: THUNK_STATUS.LOADING };
      })
      .addCase(getSessionInfo.fulfilled, (state, action) => {
        state.loading = { ...state.loading, session: THUNK_STATUS.SUCCEEDED };
        const { payload: session } = action;
        state.session = session;
      })
      .addCase(getSessionInfo.rejected, (state) => {
        state.loading = { ...state.loading, session: THUNK_STATUS.REJECTED };
      })
      .addCase(getCourseInfo.pending, (state) => {
        state.loading = { ...state.loading, course: THUNK_STATUS.LOADING };
      })
      .addCase(getCourseInfo.fulfilled, (state, action) => {
        state.loading = { ...state.loading, course: THUNK_STATUS.SUCCEEDED };
        state.course = action.payload;
      })
      .addCase(getCourseInfo.rejected, (state) => {
        state.loading = { ...state.loading, course: THUNK_STATUS.REJECTED };
      })
      .addCase(getChannelInfo.pending, (state) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.LOADING };
      })
      .addCase(getChannelInfo.fulfilled, (state, action) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.SUCCEEDED };
        state.channel = condenseChannel(action.payload);
      })
      .addCase(getChannelInfo.rejected, (state) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.REJECTED };
      })
      .addCase(getRecentAudioSessions.pending, (state) => {
        state.loading = { ...state.loading, recentSessions: THUNK_STATUS.LOADING };
      })
      .addCase(getRecentAudioSessions.fulfilled, (state, action) => {
        state.loading = { ...state.loading, recentSessions: THUNK_STATUS.SUCCEEDED };
        state.recentSessions = action.payload;
      })
      .addCase(getRecentAudioSessions.rejected, (state) => {
        state.loading = { ...state.loading, recentSessions: THUNK_STATUS.REJECTED };
      })
      .addCase(generateShareAudioUrl.pending, (state) => {
        state.loading = { ...state.loading, urlToShare: THUNK_STATUS.LOADING };
      })
      .addCase(generateShareAudioUrl.fulfilled, (state, action) => {
        state.loading = { ...state.loading, urlToShare: THUNK_STATUS.SUCCEEDED };
        state.urlToShare = action.payload;
      })
      .addCase(generateShareAudioUrl.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'There is an issue in generating the URL to share.';
        toast(errorMessage, 'error');
        state.loading = { ...state.loading, urlToShare: THUNK_STATUS.REJECTED };
      })
      .addCase(getShareAudioSession.pending, (state) => {
        state.loading = { ...state.loading, sharedSession: THUNK_STATUS.LOADING };
      })
      .addCase(getShareAudioSession.fulfilled, (state, action) => {
        state.loading = { ...state.loading, sharedSession: THUNK_STATUS.SUCCEEDED };
        state.sharedSession = action.payload;
      })
      .addCase(getShareAudioSession.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'There is an issue in fetching the audio.';
        toast(errorMessage, 'error');
        state.loading = { ...state.loading, sharedSession: THUNK_STATUS.REJECTED };
      });
  },
});

export const { actions } = playerSlice;

export default playerSlice.reducer;
