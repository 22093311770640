import React from 'react';
import Tour, { ReactourStepContentArgs } from 'reactour';

interface UserAppTourProps {
  opened: boolean;
  onRequestClose: () => any;
}

const appTourConfig = [
  {
    selector: '.navItemHome',
    content: ({ goTo, close }: ReactourStepContentArgs) => (
      <>
        <h5>Home Page</h5>
        <p>
          This is your hub for resuming previous programs, getting personalized recommendations and
          discovering our latest updates.
        </p>
        <div className="tourTooltip__footer">
          <button type="button" className="btn" onClick={close}>
            Skip
          </button>
          <button type="button" className="btn btn-primary" onClick={() => goTo(1)}>
            Next
          </button>
        </div>
      </>
    ),
  },
  {
    selector: '.navItem7DayCourses',
    content: ({ goTo, close }: ReactourStepContentArgs) => (
      <>
        <h5>Upgrades</h5>
        <p>This is your hub to access the entire library of mindset boosting Upgrades.</p>
        <div className="tourTooltip__footer">
          <button type="button" className="btn" onClick={close}>
            Skip
          </button>
          <button type="button" className="btn btn-primary" onClick={() => goTo(2)}>
            Next
          </button>
        </div>
      </>
    ),
  },
  {
    selector: '.navItemChannels',
    content: ({ goTo, close }: ReactourStepContentArgs) => (
      <>
        <h5>Explore</h5>
        <p>
          Explore our entire library of 400+ Primers and discover the perfect mindset for this
          moment.
        </p>
        <div className="tourTooltip__footer">
          <button type="button" className="btn" onClick={close}>
            Skip
          </button>
          <button type="button" className="btn btn-primary" onClick={() => goTo(3)}>
            Next
          </button>
        </div>
      </>
    ),
  },
  {
    selector: '.navItemSleep',
    content: ({ close }: ReactourStepContentArgs) => (
      <>
        <h5>Sleep</h5>
        <p>
          Fall asleep fast and get deep, restorative sleep so you wake up feeling refreshed each
          morning.
        </p>
        <div className="tourTooltip__footer">
          <button type="button" className="btn btn-primary" onClick={close}>
            Let&apos;s Go
          </button>
        </div>
      </>
    ),
  },
];

function UserAppTour({ opened, onRequestClose }: UserAppTourProps) {
  return (
    <Tour
      isOpen={opened}
      steps={appTourConfig}
      onRequestClose={onRequestClose}
      showNumber={false}
      showNavigationNumber={false}
      showNavigation={false}
      showCloseButton={false}
      className="tourTooltip"
      showButtons={false}
      disableDotsNavigation={false}
    />
  );
}

export default UserAppTour;
