import React from 'react';
import { toast as toastify } from 'react-toastify';

interface ToastInterface {
  message: string;
  title: string;
}

function Toast({ title, message }: ToastInterface) {
  return (
    <>
      <span className="toast">{title}</span>

      <span>{message}</span>
    </>
  );
}
export const toast = (message: string, type: string) => {
  switch (type) {
    case 'success':
      return toastify.success(Toast({ message, title: 'Success' }));

    case 'error':
      return toastify.error(Toast({ message, title: 'Error' }));

    case 'info':
      return toastify.info(Toast({ message, title: 'Info' }));

    default:
      return toastify.warning(Toast({ message, title: 'Warning' }));
  }
};
