import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchMarketingInfo } from '../../redux/thunks/marketingThunk';
import DiscountSetup from './discountSetup';
import MarketingForm from './marketingForm';
import { fetchDiscountInfo } from '../../redux/thunks/discountThunk';
import { DeviceType } from '../../redux/interface/discountInterface';

interface MarketingInfo {
  id?: string;
  title: string;
  description: string;
  advantages: string;
  cta: string;
  link: string;
  image: any;
  type: string;
  status: number | boolean;
}

interface DiscountInfo {
  id?: string;
  startAt: string;
  endAt: string;
  discount: number;
  status: string;
  type: string;
  device: DeviceType;
}
function Marketing() {
  const [imageMobile, setImageMobile] = useState<string>('');
  const [imageWeb, setImageWeb] = useState<string>('');

  const marketingData = useAppSelector((state) => state.marketing.info);
  const discountInfo = useAppSelector((state) => state.discount.discountInfo);
  const [mobileId, setMobileId] = useState<any>();
  const [webId, setWebId] = useState<any>();

  const [marketingWeb, setMarketingWeb] = useState<Omit<MarketingInfo, 'type'>>({
    id: '',
    title: '',
    description: '',
    advantages: '',
    cta: '',
    link: '',
    image: '',
    status: false,
  });
  const [marketingMobile, setMarketingMobile] = useState<Omit<MarketingInfo, 'type'>>({
    id: '',
    title: '',
    description: '',
    advantages: '',
    cta: '',
    link: '',
    image: '',
    status: false,
  });
  const [discount, setDiscount] = useState<DiscountInfo>({
    id: '',
    startAt: '',
    endAt: '',
    discount: 20,
    status: '',
    type: '',
    device: [],
  });

  useEffect(() => {
    const mobileVersion = marketingData?.find((info) => info?.type === 'mobile');

    const webVersion = marketingData?.find((info) => info?.type === 'web');

    if (mobileVersion) {
      setImageMobile(mobileVersion?.media?.url);
      setMarketingMobile({
        id: mobileVersion?.id,
        title: mobileVersion?.title,
        description: mobileVersion?.description,
        advantages: mobileVersion?.advantages,
        cta: mobileVersion?.cta,
        link: mobileVersion?.link,
        image: mobileVersion?.media?.url,
        status: mobileVersion?.status,
      });
    }
    if (webVersion) {
      setImageWeb(webVersion?.media?.url);
      setMarketingWeb({
        id: webVersion?.id,
        title: webVersion?.title,
        description: webVersion?.description,
        advantages: webVersion?.advantages,
        cta: webVersion?.cta,
        link: webVersion?.link,
        image: webVersion?.media?.url,
        status: webVersion?.status,
      });
    }
  }, [marketingData]);

  useEffect(() => {
    const mobileVersion = marketingData?.find((info) => info?.type === 'mobile');

    const webVersion = marketingData?.find((info) => info?.type === 'web');

    if (mobileVersion) {
      setMobileId(mobileVersion?.id);
    }
    if (webVersion) {
      setWebId(webVersion?.id);
    }
  }, [marketingData]);

  useEffect(
    () =>
      setDiscount({
        id: discountInfo?.id,
        startAt: discountInfo?.startAt,
        endAt: discountInfo?.endAt,
        discount: discountInfo?.discount,
        status: `${discountInfo?.status}`,
        type: discountInfo?.type,
        device: discountInfo?.device,
      }),
    [discountInfo],
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchMarketingInfo());
  }, []);

  useEffect(() => {
    dispatch(fetchDiscountInfo());
  }, []);

  return (
    <div className=" py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
      <div className="content_decor" />
      <div className="row pb-3 pb-md-4 align-items-center">
        <div className="col-12">
          <h1 className="headline-decor">Marketing</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-xl-4 bg-color-gray-200  color-primary-dark radius-16">
          <div className="row mb-4">
            <div className="mb-4">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    id="mobile-version-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#mobile_marketing"
                    type="button"
                  >
                    Mobile version pop up
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    id="web-version-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#web_marketing"
                    type="button"
                  >
                    Web version pop up
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content bg-color-gray-200" id="myTabContent">
            <div className="tab-pane fade show active" id="mobile_marketing" role="tabpanel">
              <MarketingForm
                onMarketingSuccessfulSave={(info) => setMarketingMobile(info)}
                setImg={setImageMobile}
                id={mobileId}
                img={imageMobile}
                marketing={marketingMobile}
                formType="mobile"
              />
            </div>
            <div className="tab-pane fade" id="web_marketing" role="tabpanel">
              <MarketingForm
                onMarketingSuccessfulSave={(info) => setMarketingWeb(info)}
                setImg={setImageWeb}
                id={webId}
                img={imageWeb}
                marketing={marketingWeb}
                formType="web"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 p-0 mb-4">
          <div className="bg-color-gray-200 radius-16 p-3 p-xl-4">
            <div className="row">
              <DiscountSetup setDiscount={setDiscount} discount={discount} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketing;
