/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import playerVoice from '../../assets/img/player-bubble.gif';
import { secondsToMinSecFormat } from '../../helpers/convertTime';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SessionInterface } from '../../redux/interface/entitiesInterface';
import { actions } from '../../redux/slices/playerSlice';
import ChangeNarrator from '../modal/changeNarrator';
import { useAuth } from '../../redux/authLayer';
import NarratorAvatar from '../cards/narratorAvatar';
import VolumeBar from './volumeBar';

interface AudioPlayerProps {
  compactMode?: boolean;
  courseMode?: boolean;
  session: SessionInterface | null | undefined;
  onUserListenProgress: (
    playBackTime: { timeString: string; currentTime: number },
    narrator: number,
    session: SessionInterface | undefined | null,
  ) => void | undefined | null;
  onSessionEnded: (
    narrator: number,
    session: SessionInterface | undefined | null,
  ) => void | undefined | null;
  onFavoriteSession?: (
    narrator: number,
    favorited: boolean,
    session: SessionInterface | undefined | null,
  ) => void | undefined | null;
  onShareSession?: (
    narrator: number,
    session: SessionInterface | undefined | null,
  ) => void | undefined | null;
  onShuffleClicked?: () => void | undefined | null;
  onPlayDenied?: (session: SessionInterface) => void | undefined | null;
  disableNextSessionButton?: boolean;
  disableChangeNarratorButton?: boolean;
  disableShareButton?: boolean;
  disableFavoriteButton?: boolean;
  isShareLinkSession?: boolean;
}

const TIME_UPDATE = 10000;

function AudioPlayer({
  session,
  onSessionEnded,
  onUserListenProgress,
  onFavoriteSession,
  onShareSession,
  onShuffleClicked,
  compactMode,
  courseMode,
  disableNextSessionButton,
  onPlayDenied,
  disableChangeNarratorButton,
  disableShareButton,
  disableFavoriteButton,
  isShareLinkSession,
}: AudioPlayerProps) {
  const audioElement = useRef<HTMLAudioElement>(null);
  const { isFree } = useAuth();
  const [playtime, setPlaytime] = useState<number>(0);
  const [disableControls, setDisableControls] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [favorited, setFavorited] = useState<boolean>(false);
  const [volumeBarIsVisible, setVolumeBarIsVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { volume, loopMode, currentNarrator } = useAppSelector((state) => state.player);

  const setVolume = (value: number) => dispatch(actions.setVolume(value));

  const setLoopMode = (value: boolean) => dispatch(actions.setLoopMode(value));

  const currentSession = useMemo(() => session, [session]);

  useEffect(() => {
    if (!audioElement.current) {
      return () => {};
    }

    let currTime = (audioElement.current as HTMLAudioElement).currentTime;
    const triggerListenProgress = () => {
      let timeString: string;
      if (audioElement.current) {
        timeString = secondsToMinSecFormat((audioElement.current as HTMLAudioElement).currentTime);
        currTime = (audioElement.current as HTMLAudioElement).currentTime;
      } else {
        timeString = secondsToMinSecFormat(currTime);
      }
      onUserListenProgress({ timeString, currentTime: currTime }, currentNarrator, currentSession);
    };
    let interval: ReturnType<typeof setInterval> | null = null;
    triggerListenProgress();
    if (isPlaying) {
      interval = setInterval(() => triggerListenProgress(), TIME_UPDATE);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      triggerListenProgress();
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlaying, currentSession]);

  const onPlayback = (play: boolean) => {
    const audioEl = audioElement.current;
    if (audioEl) {
      setIsPlaying(play);
      if (!play) {
        audioEl.pause();
      } else {
        audioEl.play();
      }
    }
  };

  // useEffect(() => {
  //   let autoPaused: boolean = false;
  //   const audioEl = audioElement.current;
  //   const handleWindowBlur = () => {
  //     if (audioEl && !audioEl.paused) {
  //       // audioEl.pause();
  //       // autoPaused = true;
  //     }
  //   };

  //   const handleWindowFocus = () => {
  //     if (audioEl && audioEl.paused && autoPaused) {
  //       audioEl.play();
  //       autoPaused = false;
  //     }
  //   };

  //   window.addEventListener('blur', handleWindowBlur);
  //   window.addEventListener('focus', handleWindowFocus);

  //   return () => {
  //     dispatch(actions.clearPlayer());
  //     window.removeEventListener('blur', handleWindowBlur);
  //     window.removeEventListener('focus', handleWindowFocus);
  //   };
  // }, []);

  useEffect(() => {
    const audioEl = audioElement.current;
    audioEl?.pause();

    if (isFree && currentSession && !currentSession.isFree && !isShareLinkSession) {
      if (onPlayDenied) {
        onPlayDenied(currentSession);
      }
      setDisableControls(true);
      return () => {};
    }

    setDisableControls(false);
    audioEl?.load();

    setFavorited(currentSession?.isFavorite || false);

    const onDataLoaded = (ev: Event) => {
      if (!compactMode || isPlaying) {
        const promisePlay = (ev?.currentTarget as HTMLAudioElement)?.play();

        if (promisePlay !== undefined) {
          promisePlay
            .then(() => {
              setIsPlaying(true);
            })
            .catch(() => {
              setIsPlaying(false);
            });
        }
      }
    };

    const onPlayerTimeUpdate = (ev: Event) => {
      const aud = ev?.currentTarget as HTMLAudioElement;
      setPlaytime(Math.round((aud.currentTime / aud.duration) * 100));
    };

    const onPlayerTrackEnded = () => {
      if (loopMode) {
        setIsPlaying(true);
        onPlayback(true);
      } else {
        setIsPlaying(false);
        onPlayback(false);
      }
      onSessionEnded(currentNarrator, currentSession);
    };

    if (audioEl) {
      audioEl.volume = volume / 100;
      audioEl.addEventListener('loadeddata', onDataLoaded);
      audioEl.addEventListener('timeupdate', onPlayerTimeUpdate);
      audioEl.addEventListener('ended', onPlayerTrackEnded);
    }
    return () => {
      audioEl?.removeEventListener('loadeddata', onDataLoaded);
      audioEl?.removeEventListener('timeupdate', onPlayerTimeUpdate);
      audioEl?.removeEventListener('ended', onPlayerTrackEnded);
    };
  }, [currentSession]);

  const onTogglePlayback = () => {
    if (isPlaying) {
      onPlayback(false);
    } else {
      onPlayback(true);
    }
  };

  const handlePreviousTrack = () => dispatch(actions.previousSession());
  const handleNextTrack = () => dispatch(actions.nextSession({ nextButtonPressed: true }));
  const handleToggleLoopMode = () => setLoopMode(!loopMode);
  const handleFavoriteSession = () => {
    setFavorited(!favorited);
    if (onFavoriteSession) {
      onFavoriteSession(currentNarrator, !favorited, currentSession);
    }
  };
  const handleShareClick = () => onShareSession && onShareSession(currentNarrator, currentSession);

  const onScrubTimeline = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setPlaytime(ev.currentTarget.valueAsNumber || 0);
  };

  const onChangeTimeline = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const audioEl = audioElement?.current;
    if (audioEl) {
      audioEl.currentTime = (ev.currentTarget.valueAsNumber / 100) * audioEl.duration;
    }
  };

  const onVolumeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(ev.currentTarget?.valueAsNumber || 0);
    const audioEl = audioElement?.current;
    if (audioEl) {
      const vol = ev.currentTarget?.valueAsNumber;
      audioEl.volume = vol / 100;
    }
  };

  const handleChangeNarrator = (narratorIdx: number) => {
    dispatch(actions.setCurrentNarrator(narratorIdx));
    const audioEl = audioElement?.current;
    if (audioEl) {
      audioEl.pause();
      audioEl.load();
      audioEl.play();
    }
  };

  const renderAudio = () => (
    <audio ref={audioElement}>
      {' '}
      {/* eslint-disable-line jsx-a11y/media-has-caption */}
      <source src={currentSession?.narrators[currentNarrator]?.media?.url} />
    </audio>
  );

  const renderSlider = () => (
    <div className="player__track-rogress w-100">
      <input
        min={0}
        max={100}
        type="range"
        step={1}
        value={playtime || 0}
        onInput={onScrubTimeline}
        onChange={onChangeTimeline}
        className="player__range-input"
        style={{
          background: `linear-gradient(to right, #74F2A4 0%, #20d25e ${
            playtime + 0.1
          }%, #dae4ed ${playtime}%, #dae4ed 100%)`,
        }}
      />
    </div>
  );

  const renderControls = () => (
    <>
      {compactMode ? (
        <button
          type="button"
          className={classNames('btn player-widget__btn', favorited && 'active')}
          onClick={handleFavoriteSession}
        >
          <i className="fa-regular fa-heart" />
        </button>
      ) : (
        <button
          type="button"
          className={classNames('btn player__btn', loopMode && 'active')}
          onClick={handleToggleLoopMode}
        >
          <i className="fa-regular fa-repeat" />
        </button>
      )}
      <button
        type="button"
        className={classNames('btn btn-prev', compactMode ? 'player-widget__btn' : 'player__btn')}
        onClick={handlePreviousTrack}
      >
        <i
          className={classNames(
            'fa-regular',
            compactMode ? 'fa-arrow-rotate-left' : 'fa-backward-step',
          )}
        />
      </button>
      <button
        type="button"
        className={classNames(
          'btn',
          compactMode
            ? 'player-widget__btn player-widget__btn--play'
            : 'btn player__btn player__btn--play',
        )}
        onClick={onTogglePlayback}
        disabled={disableControls}
      >
        <i className={`fa-duotone fa-${isPlaying ? 'pause' : 'play'}`} />
      </button>
      <button
        type="button"
        className={classNames('btn btn-next', compactMode ? 'player-widget__btn' : 'player__btn')}
        onClick={handleNextTrack}
        disabled={courseMode && disableNextSessionButton}
      >
        <i
          className={classNames(
            'fa-regular',
            compactMode ? 'fa-arrow-rotate-right' : 'fa-forward-step',
          )}
        />
      </button>
      {compactMode ? (
        <button
          type="button"
          className={classNames('btn player-widget__btn', loopMode && 'active')}
          onClick={handleToggleLoopMode}
        >
          <i className="fa-regular fa-arrows-repeat" />
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-shuffle player__btn"
          onClick={onShuffleClicked}
          disabled={courseMode}
        >
          <i className="fa-regular fa-shuffle" />
        </button>
      )}
    </>
  );

  const narratorsToDisplay = currentSession?.narrators.filter(
    (narrator) => narrator?.name !== currentSession.narrators[currentNarrator]?.name,
  );
  const [clicked, setClicked] = useState(false);
  const [animateOutNarrator, setAnimateOutNarrator] = useState('');

  const renderNarrators = () => {
    if (!narratorsToDisplay) {
      return null;
    }

    if (!currentSession) {
      return null;
    }

    return [currentSession.narrators[currentNarrator], ...narratorsToDisplay].map(
      (narrator, index) => {
        const radius = index === 0 ? '50px' : '35px';
        const renderClassname = () => {
          if (clicked === true && index === 0) {
            return 'animateIn';
          }

          if (!clicked) {
            return '';
          }

          if (index === 1) {
            return 'animateOut';
          }

          return '';
        };

        const renderClassnameLong = () => {
          const animIdx = narratorsToDisplay.findIndex((n) => n.name === animateOutNarrator);

          if (clicked === true && index === 0) {
            return animIdx > 0 ? 'animateInLong' : 'animateIn';
          }

          if (!clicked) {
            return '';
          }

          if (index === 1 && index === animIdx + 1) {
            return 'animateOut';
          }

          if (index === animIdx + 1) {
            return 'animateOut';
          }

          return '';
        };

        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className={
              currentSession?.narrators.length > 2 ? renderClassnameLong() : renderClassname()
            }
            onAnimationEnd={() => setClicked(false)}
            key={narrator?.name}
            onClick={() => {
              if (index === 0) {
                return;
              }

              setClicked(true);

              if (
                currentSession?.narrators[currentNarrator] &&
                currentSession?.narrators[currentNarrator].name
              ) {
                setAnimateOutNarrator(currentSession?.narrators[currentNarrator].name);
              }

              const nextIndex = currentSession.narrators.findIndex(
                (n) => n.name === narrator?.name,
              );
              handleChangeNarrator(nextIndex);
            }}
          >
            <NarratorAvatar
              isPlayer
              narrator={narrator}
              style={{
                cursor: index === 0 ? '' : 'pointer',
                position: 'relative',
                marginRight: -12,
                width: radius,
                height: radius,
                zIndex: 10 - index,
              }}
            />
          </div>
        );
      },
    );
  };

  if (compactMode) {
    return (
      <div className="card player-widget align-items-center">
        <div className="player-widget__info-wrapper py-3" style={{ width: '100%' }}>
          <img className="player-widget__voice-animation mb-2" src={playerVoice} alt="" />
          <div
            className="d-flex align-items-center justify-content-center gap-5"
            style={{ width: '100%' }}
          >
            <div className="d-flex align-items-end">{renderNarrators()}</div>

            <div className="player-widget__info">
              <div>{currentSession?.title}</div>
              <div>
                <small>{currentSession?.narrators[currentNarrator]?.name}</small>
              </div>
            </div>
          </div>
        </div>
        {renderAudio()}
        <div className="player-widget__controls py-3">
          {renderSlider()}
          {renderControls()}
        </div>
      </div>
    );
  }

  return (
    <div
      className="player-page__player player d-flex flex-column justify-content-end align-items-center"
      style={{ position: 'relative', overflow: 'visible' }}
    >
      <div style={{ position: 'absolute', zIndex: 3, bottom: 30, right: -10 }}>
        <VolumeBar
          volume={volume}
          onVolumeChange={onVolumeChange}
          isVisible={volumeBarIsVisible}
          setIsVisible={setVolumeBarIsVisible}
        />
      </div>
      <img className="player__voice-animation" src={playerVoice} alt="" />

      <div className="d-flex w-100 flex-column position-relative mt-auto">
        {renderAudio()}
        {renderSlider()}
        <div className="player__panel d-flex flex-column justify-content-between pt-4 pb-3 px-3 px-md-5">
          <div className="row g-0 w-100">
            <div className="col-9 col-xl-4 order-1 order-xl-0 d-flex align-items-center">
              {disableChangeNarratorButton || !currentSession ? null : (
                <div style={{ marginRight: 30 }} className="d-flex align-items-end">
                  {renderNarrators()}
                </div>
              )}

              <div className="player__track-info">
                <div
                  className="text-truncate font-regular"
                  style={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {currentSession?.title}
                </div>
                <div className="text-truncate " style={{ maxWidth: 150 }}>
                  <small>{currentSession?.narrators[currentNarrator]?.name}</small>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 order-0 order-xl-1 d-flex justify-content-center">
              <div className="player__panel-controls d-flex mb-3">{renderControls()}</div>
            </div>
            <div className="col-2 col-xl-4 order-2 d-flex justify-content-end align-items-center">
              <div className="d-flex pe-3 d-none d-md-flex">
                {disableShareButton ? null : (
                  <button
                    type="button"
                    id="session-share-button"
                    className="btn player__btn"
                    onClick={handleShareClick}
                  >
                    <i className="fa-regular fa-arrow-up-from-bracket" />
                  </button>
                )}
                {disableFavoriteButton ? null : (
                  <button
                    type="button"
                    className={classNames('btn player__btn', favorited && 'active')}
                    onClick={handleFavoriteSession}
                  >
                    <i className="fa-regular fa-heart" />
                  </button>
                )}
              </div>

              <button
                type="button"
                className={classNames('btn player__btn', favorited && 'active')}
                onClick={() => {
                  setVolumeBarIsVisible(true);
                }}
              >
                <i className="fa-regular fa-volume" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {currentSession && (
        <ChangeNarrator
          narrators={currentSession.narrators}
          onChangeNarrator={handleChangeNarrator}
          selectedNarrator={currentNarrator}
        />
      )}
    </div>
  );
}

AudioPlayer.defaultProps = {
  compactMode: false,
  courseMode: false,
  disableNextSessionButton: false,
  disableChangeNarratorButton: false,
  disableShareButton: false,
  disableFavoriteButton: false,
  isShareLinkSession: false,
  onFavoriteSession: () => {},
  onShareSession: () => {},
  onShuffleClicked: () => {},
  onPlayDenied: () => {},
};

export default AudioPlayer;
