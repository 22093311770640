import React, { useEffect, useState } from 'react';
import { swapElement } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getChannelsList, updateChannelsOrder } from '../../../redux/thunks/entitiesThunk';

function ReOrderChannels() {
  const dispatch = useAppDispatch();

  const channelsEntities = useAppSelector((state) => state.entities.channels);
  const [data, setData] = useState(channelsEntities ? [...channelsEntities] : []);

  useEffect(() => {
    dispatch(getChannelsList());
  }, []);

  useEffect(() => {
    setData(channelsEntities);
  }, [channelsEntities]);

  const reArrangeList = (action: string, courseId: number) => {
    const channelsCopy = [...data];
    const itemIndex = channelsCopy.findIndex((item: any) => item.id === courseId);
    const nextIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;
    const orderedChannels = swapElement(channelsCopy, itemIndex, nextIndex).map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setData(orderedChannels);
  };

  const handleSaveOrder = () => {
    const sortedData = data.map((channel, index) => ({ id: channel.id, position: index + 1 }));
    dispatch(updateChannelsOrder(sortedData));
  };

  return (
    <div
      className="modal modal-lg fade modal-edit-profile"
      id="modal-re-order-channels"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h2 className="mb-3">Re-order Channels</h2>
            </div>
            <ul className="list-group list-group--channels mb-2">
              {data.map((channel, index) => (
                <li
                  className="list-group-item p-2 border rounded-2 my-2 d-flex justify-content-between"
                  key={channel.id}
                >
                  <p>{channel.title}</p>
                  <span className="order-icons">
                    <i
                      className={`fa-solid fa-arrow-up ${index === 0 ? 'disabled' : ''}`}
                      role="button"
                      tabIndex={-1}
                      aria-label={`Move up ${channel.title}`}
                      onKeyUp={() => (index === 0 ? {} : reArrangeList('up', channel.id))}
                      onClick={() => (index === 0 ? {} : reArrangeList('up', channel.id))}
                    />
                    <i
                      className={`fa-solid fa-arrow-down ${
                        index === data.length - 1 ? 'disabled' : ''
                      }`}
                      role="button"
                      tabIndex={-1}
                      aria-label={`Move down ${channel.title}`}
                      onKeyUp={() =>
                        index === data.length - 1 ? {} : reArrangeList('down', channel.id)
                      }
                      onClick={() =>
                        index === data.length - 1 ? {} : reArrangeList('down', channel.id)
                      }
                    />
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-auto px-1 pe-2">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100 px-5"
                    data-bs-dismiss="modal"
                  >
                    <i className="fa fa-times" />
                    Close
                  </button>
                </div>
                <div className="col-auto px-1 pe-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 px-5"
                    data-bs-dismiss="modal"
                    onClick={handleSaveOrder}
                  >
                    <i className="fa-regular fa-floppy-disk me-2" />
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReOrderChannels;
