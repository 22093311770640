import React from 'react';
import { useServices } from '../../redux/servicesLayer';
import BadgeProgress from './badgeProgress';

function BadgeProgressBar() {
  const { progressAwards } = useServices();
  return (
    <div className="user-progress flex-grow-1 d-none d-lg-flex">
      <div className="row p-0 p-0 m-0 w-100">
        {progressAwards.map((datum, idx) => (
          <div
            key={JSON.stringify(datum)}
            className={`col-4 border-right-gray-400${idx === 0 ? ' ps-0' : ''}`}
          >
            {/*  eslint-disable-next-line react/jsx-props-no-spreading */}
            <BadgeProgress {...datum} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BadgeProgressBar;
