import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { SessionsState } from '../interface/sessionInterface';
import {
  createSessions,
  fetchSession,
  deleteSession,
  updateSession,
  deleteNarrator,
} from '../thunks/sessionThunk';
import { toast } from '../../helpers/toaster';

const initialState: SessionsState = {
  sessionsInfo: [],
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSession.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchSession.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        sessionsInfo: action.payload.sessions,
        pagination: action.payload.pagination,
        totalItems: action.payload.totalItems,
      }))
      .addCase(fetchSession.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(createSessions.pending, (state) => ({
        ...state,
        modalName: 'addNewSession',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(createSessions.fulfilled, (state, action) => {
        toast('Creating session successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          sessionsInfo: [...state.sessionsInfo, action.payload.date],
          totalItems: state.totalItems + 1,
          modalOpen: false,
        };
      })
      .addCase(createSessions.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Creating Session failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(updateSession.pending, (state) => ({
        ...state,
        modalName: 'editSession',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(updateSession.fulfilled, (state, action) => {
        toast('Updating sessions successfull', 'success');
        const indexToUpdate = findIndex(
          state.sessionsInfo,
          (session) => session.id === action.payload.data.id,
        );
        const newUpdatedSession = [...state.sessionsInfo];
        newUpdatedSession[indexToUpdate] = action.payload.data;
        return {
          ...state,
          modalOpen: false,
          status: 'succeeded',
          error: undefined,
          sessionsInfo: newUpdatedSession,
        };
      })
      .addCase(updateSession.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Updating Session failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteSession.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteSession.fulfilled, (state, action) => {
        toast('Deleting sessions successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          sessionsInfo: state.sessionsInfo.filter(
            (session) => session.id !== action.payload.sessionId,
          ),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(deleteSession.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Deleting Session failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteNarrator.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteNarrator.fulfilled, (state) => {
        toast('Deleting narrator successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
        };
      })
      .addCase(deleteNarrator.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Deleting narrator failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default sessionSlice.reducer;
