import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import DeleteChannel from './modals/deleteChannel';
import PublishChannel from './modals/publishChannel';
import UnpublishChannel from './modals/unpublishChannel';

import { getChannels } from '../../redux/thunks/channelsThunk';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import { handlePagination, setParamValue } from '../../helpers';
import Channel from './channel';
import { ChannelInterface } from '../../redux/interface/channelsInterface';
import ReOrderChannels from './modals/reOrderChannels';

const BASE_URL = '/api/channels';

function Channels() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const channels = useAppSelector((state) => state.channel.channelsArray);
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [channelToDelete, setChannelToDelete] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const totalChannels = useAppSelector((state) => state.channel.totalItems);
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.channel.pagination,
  );
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);
  const status = useAppSelector((state) => state.channel.status);

  const [channelToPublish, setChannelToPublish] = useState<ChannelInterface>({
    id: '',
    title: '',
    subtitle: '',
    description: '',
    slug: '',
    category: '',
    tags: [],
    isSleep: false,
    isPublished: false,
    courses: [],
    channels: [],
    file: '',
    channelSessions: [],
    powerPrimer: [],
    deepPrimer: [],
  });

  const handleDeleteChannel = (channelId: string) => setChannelToDelete(channelId);

  const handlePublishing = (channel: ChannelInterface) => setChannelToPublish(channel);

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'title', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (
      Math.ceil(totalChannels / numberOfRows) !== 0 &&
      Math.ceil(totalChannels / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(getChannels(currentUrl));
  }, [currentUrl]);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor" />
              <div className="row pb-3 pb-md-4 align-items-center d-flex justify-content-between">
                <div className="col-4">
                  <h1 className="headline-decor">Channels</h1>
                </div>
                <div className="col-auto d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary px-md-3 px-lg-5 mx-5"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-re-order-channels"
                    disabled={!channels[0]?.id}
                  >
                    <i className="fa-regular fa-gear me-2" />
                    Re-order channels
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary px-md-3 px-lg-5"
                    onClick={() =>
                      navigate('/create-new-channel', {
                        state: {
                          channels,
                        },
                      })
                    }
                  >
                    <i className="fa fa-plus" />
                    Create new channel
                  </button>
                </div>
              </div>

              <div className="container-fluid content-inner">
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <Search value={searchInput} onInputChange={handleFilterUsers} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 p-0">
                    <div className="table-responsive">
                      <table className="table text-nowrap ">
                        <thead>
                          <tr>
                            <th className="col-3">
                              <div className="d-flex align-items-center col-3">
                                Title
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-3">
                              <div className="d-flex align-items-center col-3">
                                Subtitle
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-3">Tags</th>
                            <th className="col-3">Actions</th>
                          </tr>
                        </thead>
                        {status === 'loading' ? (
                          <tbody>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {channels &&
                              channels?.map((channel) => (
                                <Channel
                                  key={channel.id}
                                  channel={channel}
                                  handleDelete={handleDeleteChannel}
                                  handlePublish={handlePublishing}
                                />
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        onPageChange={handlePageChange}
                        totalCount={totalChannels || 0}
                        currentPage={currentPage}
                        pageSize={numberOfRows}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteChannel id={channelToDelete} status={status} />
      <PublishChannel channelToPublish={channelToPublish} />
      <UnpublishChannel channelToUnpublish={channelToPublish} />
      <ReOrderChannels />
    </>
  );
}

export default Channels;
