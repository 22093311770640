/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Input from '../../../components/formik/input';
// import Dropdown from '../../../components/formik/dropdown';
import { createAppUser } from '../../../redux/thunks/appUsersThunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import AppUsersInviteConfirmation from './appUsersInviteConfirmation';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter the user email address.'),
});

function AddNewAppUser() {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.appUsers);
  const dispatch = useAppDispatch();

  const handleConfirmation = () => {};

  return (
    <>
      <div
        tabIndex={-1}
        className="modal fade modal-edit-profile"
        id="add_new_user"
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <Formik
          initialValues={{
            name: '',
            email: '',
            isActive: true,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(
              createAppUser({
                name: values.name,
                email: values.email,
                isActive: true,
              }),
            );
          }}
        >
          {({ handleSubmit, resetForm }) => {
            useEffect(() => {
              if (status === 'succeeded' && modalName === 'addNewAppUser') {
                hideModal({ modalId: '#closeModal_addAppUser' });
                resetForm();
              }
            }, [modalOpen]);

            return (
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      typeof="button"
                      onClick={() => resetForm()}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Add new User</h4>
                    </div>

                    <form>
                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="email"
                          name="email"
                          description="email"
                          placeholder="E-mail id"
                          label="Email *"
                        />
                      </div>

                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="name"
                          description="name"
                          placeholder="Name"
                          label="Name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row mb-3">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_addAppUser"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </span>
                            ) : (
                              <div
                                onClick={() => handleSubmit()}
                                role="button"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Invite
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => resetForm()}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      <AppUsersInviteConfirmation handleConfirmation={handleConfirmation} status={status} />
    </>
  );
}

export default AddNewAppUser;
