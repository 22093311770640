/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient, { errorMessageThrower } from '../../helpers/api.js';
import { TeamInfo } from '../interface/currentUserInterface';
import { TeamUsersURLPager } from '../interface/userSettingsInterface.js';

export const addNewCard = createAsyncThunk(
  'user-settings/add-card',
  async ({
    cardToken,
    paymentMethodToken,
    saveCard,
  }: {
    cardToken: string;
    paymentMethodToken: string;
    saveCard: boolean;
  }) => {
    try {
      const response = await apiClient.postRequest('/api/stripe/add-card', {
        card_token: cardToken,
        payment_method_token: paymentMethodToken,
        is_primary: true,
        is_save: saveCard,
      });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const deleteCard = createAsyncThunk('user-settings/delete-card', async (id: number) => {
  try {
    const response = await apiClient.deleteRequest(`/api/stripe/delete-card/${id}`);
    return response.data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const editCard = createAsyncThunk(
  'user-settings/edit-card',
  async ({
    name,
    id,
    expiry_month,
    expiry_year,
  }: {
    name: string;
    expiry_month: string;
    id: string;
    expiry_year: string;
  }) => {
    try {
      const response = await apiClient.postRequest(`/api/stripe/edit-card/${id}`, {
        name,
        is_primary: true,
        expiry_month,
        expiry_year,
      });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const generateStripeCustomer = createAsyncThunk(
  'user-settings/stripe-customer',
  async () => {
    try {
      const response = await apiClient.getRequest('/api/stripe/customer');
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getCardList = createAsyncThunk('user-settings/stripe-get-cards', async () => {
  try {
    const response = await apiClient.getRequest('/api/stripe/card/list');
    return response.data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const getTeamMemberList = createAsyncThunk(
  'user-settings/admin-user/list',
  async ({ teamId, ...others }: TeamUsersURLPager) => {
    try {
      const params = { ...others };
      const response = await apiClient.getRequest(
        `/api/teams/${teamId}/users?${new URLSearchParams(params).toString()}`,
      );
      return {
        ...response.data,
        'hydra:member': response.data['hydra:member'].map((user: any) => ({
          ...user,
          userId: user.id,
        })),
      };
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const addTeamMember = createAsyncThunk(
  'user-settings/admin-user/new',
  async ({ name, email, team }: { name: string; email: string; team: TeamInfo }) => {
    try {
      const response = await apiClient.postRequest(`/api/teams/${team.id}/members`, {
        name,
        email,
      });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const editTeamMember = createAsyncThunk(
  'user-settings/admin-user/edit',
  async ({
    name,
    email,
    team,
    userId,
  }: {
    name: string;
    email: string;
    team: TeamInfo;
    userId: number;
  }) => {
    try {
      const response = await apiClient.putRequest(`/api/teams/${team.id}/members/${userId}`, {
        name,
        email,
      });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const deleteTeamMember = createAsyncThunk(
  'user-settings/admin-user/delete',
  async ({ team, userId }: { team: TeamInfo; userId: number }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/teams/${team.id}/members/${userId}`);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const uploadTeamCSV = createAsyncThunk(
  'user-settings/admin-user/upload-csv',
  async ({ team, file }: { team: TeamInfo; file: File }) => {
    try {
      const response = await apiClient.postFormDataRequest('/api/invite-team', {
        file,
        team_id: team.id,
      });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const inquireMoreSeats = createAsyncThunk(
  'user-settings/admin-user/get-seats-price',
  async (seatparams: { seats: number; uuid?: string }) => {
    try {
      const response = await apiClient.postRequest('/api/user/seat/price', seatparams);
      const {
        price,
        average_seat_price: seatPrice,
        actual_average_seat_price: actualSeatPrice,
      } = response.data;
      return { seats: seatparams.seats, price, seatPrice, actualSeatPrice };
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const updateSeats = createAsyncThunk(
  'user-settings/admin-user/execute-update-seats',
  async (data: any) => {
    try {
      const response = await apiClient.postRequest('/api/stripe/additional-seats', data);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const unsubscribePaidAccount = createAsyncThunk(
  'user-settings/admin-user/execute-unsubscription',
  async () => {
    try {
      const response = await apiClient.postRequest('/api/stripe/cancel-subscription');
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const subscribePaidAccount = createAsyncThunk(
  'user-settings/admin-user/execute-subscription',
  async ({ subscriptionId, data = {} }: { subscriptionId: number; data?: any }) => {
    try {
      const response = await apiClient.postRequest(
        `/api/stripe/subscription/${subscriptionId}`,
        data,
      );
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);
