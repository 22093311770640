/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ChannelInterface } from '../../../redux/interface/channelsInterface';
import { updateChannels } from '../../../redux/thunks/channelsThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface Props {
  channelToPublish: ChannelInterface;
}

function PublishChannel({ channelToPublish }: Props) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.channel);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (status === 'succeeded' && modalName === 'updateChannel') {
      hideModal({ modalId: '#closeModal_updateAsPublished' });
    }
  }, [modalOpen]);
  return (
    <div
      className="modal fade modal-edit-profile"
      id="publish_channel"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Publish Channel?</h5>
              <p className="color-gray-600">Are you sure you want to publish this channel?</p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    id="closeModal_updateAsPublished"
                    className="btn btn-warning w-100"
                    disabled={status === 'loading'}
                  >
                    {status === 'loading' ? (
                      <span
                        className="spinner-border"
                        style={{ width: '1em', height: '1em' }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    ) : (
                      <div
                        role="button"
                        onClick={() => {
                          const { id } = channelToPublish;

                          dispatch(
                            updateChannels({
                              id,
                              isPublished: true,
                            }),
                          );
                        }}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                      >
                        Publish
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishChannel;
