import React, { useEffect } from 'react';
import { useBilling } from '../../containers/userSettings/billing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices/modalSlice';
import PayMethodInfo from './payMethodInfo';
import PayMethodMuted from './payMethodMuted';

function PayMethodSlider() {
  const dispatch = useAppDispatch();
  const handleAddCardClick = () => dispatch(actions.setVisibleModal('addCard'));
  const { paymentMethods } = useBilling();
  const { cards, loading } = useAppSelector((state) => state.userSettings);

  const { secondaryVisible } = useAppSelector((state) => state.modal);

  useEffect(() => {
    if (cards.length === 0 && loading?.cards === 'succeded') {
      dispatch(actions.setVisibleModal('subscriptionModal'));
      // handleAddCardClick();
    }
    if (
      cards.length !== 0 &&
      loading?.cards === 'succeded' &&
      secondaryVisible === 'confirmSubscription'
    ) {
      dispatch(actions.setVisibleModal('confirmSubscription'));
    }
  }, [cards]);

  const renderPaymentMethod = () => {
    if (paymentMethods.length > 0) {
      return (
        <>
          <button
            type="button"
            className="add-credit-card position-fixed-x btn btn-primary"
            onClick={handleAddCardClick}
          >
            <i className="fa-regular fa-plus icon-size-20" />
          </button>
          <div className="card-wrap row flex-nowrap overflow-auto pb-3">
            {paymentMethods.map((payMethod) => (
              <PayMethodInfo key={payMethod.id} {...payMethod} /> // eslint-disable-line react/jsx-props-no-spreading
            ))}
          </div>
        </>
      );
    }

    return <PayMethodMuted handleAddCardClick={handleAddCardClick} />;
  };

  return <div className="credit-cards-container mb-4">{renderPaymentMethod()}</div>;
}

export default PayMethodSlider;
