import React from 'react';
import { ProgressAward } from '../../redux/interface/currentUserInterface';
import BadgeDisplay from './badgeDisplay';

export interface BadgeProgressProps extends ProgressAward {}

const colorToUseByPercent = (score: number) => {
  if (score >= 75) {
    return 'green';
  }
  if (score >= 50) {
    return 'yellow';
  }
  if (score >= 25) {
    return 'orange';
  }
  return 'red';
};

function BadgeProgress(badgeProgress: BadgeProgressProps) {
  const { progress, media: url, ...badge } = badgeProgress;
  const { category } = badge;
  return (
    <div className="user-progress__item d-flex w-100 pe-3">
      <div className="d-none d-xl-flex">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <BadgeDisplay {...{ ...badge, media: { url } }} forProgress />
      </div>
      <div className="user-progress__info d-flex flex-grow-1 flex-column justify-content-center ms-2">
        <div className="progress">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            className={`progress-bar bg-${colorToUseByPercent(progress)}`}
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
        <div className="d-flex w-100 justify-content-between color-gray-600 mt-2">
          <span className="me-3">{category}</span>
          <span className="font-medium">{`${progress}%`}</span>
        </div>
      </div>
    </div>
  );
}

export default BadgeProgress;
