import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { deleteSingleTeamMember } from '../../../redux/thunks/teamThunk';

interface Props {
  memberToDelete: string;
  teamId: string;
}

function DeleteTeamMember({ memberToDelete, teamId }: Props) {
  const dispatch = useAppDispatch();
  return (
    <div
      className="modal fade modal-edit-profile"
      id="delete_team_member"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h4 className="mb-4">Delete Team Member?</h4>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      dispatch(deleteSingleTeamMember({ userId: memberToDelete, teamId }))
                    }
                  >
                    <i className="fa fa-check" />
                    Yes, Delete!
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-dismiss="modal"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteTeamMember;
