import { narratorPngImages } from '../audio/tracklist';
import { Narrator } from '../../redux/interface/sessionInterface';

interface NarratorAvatarProps {
  narrator: Narrator;
  style: { [key: string]: string | number };
  isPlayer?: boolean;
}

function getAvatar(narrator: Narrator, isPlayer: boolean | undefined) {
  if (isPlayer) {
    return narratorPngImages.find((narr) => narr.name === narrator.name);
  }
  return narratorPngImages.find((narr) => narr.name === narrator.name);
}

function NarratorAvatar({ narrator, style, isPlayer }: NarratorAvatarProps) {
  if (!narrator) {
    return null;
  }

  const avatarToUse = getAvatar(narrator, isPlayer);

  if (!avatarToUse) {
    return null;
  }

  return (
    <img
      src={avatarToUse.avatar}
      alt={avatarToUse.name}
      className="avatar"
      style={{ ...style, ...(!isPlayer ? { background: 'white' } : {}) }}
    />
  );
}

NarratorAvatar.defaultProps = {
  isPlayer: false,
};

export default NarratorAvatar;
