interface KeyNumbers {
  [key: string]: number;
}

export const breakpoints: KeyNumbers = {
  xxl: 1444,
  xl: 1200,
  lg: 860,
  md: 600,
  sm: 480,
};

export const breakpointOrder = ['xxl', 'xl', 'lg', 'md', 'sm'];

export const slidersResponsive: KeyNumbers = { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1 };

export const rowSizes: KeyNumbers = { xl: 3, lg: 4, md: 6, sm: 12 };
