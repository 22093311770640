/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createAdminUser } from '../../../redux/thunks/adminUsersThunk';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import { ROLES } from '../../../constants';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter the team email address.'),
  name: Yup.string().required('Please enter team name'),
  userType: Yup.string().required('Access level is required'),
});

function AddNewAdminUser() {
  const dispatch = useAppDispatch();

  const { status, modalOpen, modalName } = useAppSelector((state) => state.adminUsers);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_new_admin_user"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={{
          name: '',
          email: '',
          userType: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => dispatch(createAdminUser(values))}
      >
        {({ handleSubmit, resetForm }) => {
          useEffect(() => {
            if (status === 'succeeded' && modalName === 'addNewAdminUser') {
              hideModal({ modalId: '#closeModal_addAdminUser' });
              resetForm();
            }
          }, [modalOpen]);

          return (
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header pb-0 p-4">
                  <button
                    type="button"
                    className="btn-close icon-size-12 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  />
                </div>
                <div className="modal-body ps-5 pe-5">
                  <div className="text-center font-light">
                    <h4 className="mb-4">Add New User</h4>
                  </div>

                  <form>
                    <Field
                      component={Input}
                      type="text"
                      name="name"
                      description="name"
                      placeholder="Full Name"
                      label="Name *"
                    />

                    <Field
                      component={Input}
                      type="email"
                      name="email"
                      description="email"
                      placeholder="Email Address"
                      label="Email *"
                    />

                    <Field
                      component={Dropdown}
                      name="userType"
                      placeholder="User access level"
                      options={[
                        { name: 'Owner', value: ROLES.OWNER },
                        { name: 'Admin', value: ROLES.ADMIN },
                        { name: 'Support', value: ROLES.SUPPORT },
                      ]}
                      label="Access level *"
                    />
                  </form>
                </div>
                <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 px-1 ps-0">
                        <button
                          type="button"
                          id="closeModal_addAdminUser"
                          className="btn btn-primary w-100"
                          disabled={status === 'loading'}
                        >
                          {status === 'loading' ? (
                            <span
                              className="spinner-border"
                              style={{ width: '1em', height: '1em' }}
                              role="status"
                            >
                              <span className="visually-hidden">Loading...</span>
                            </span>
                          ) : (
                            <div
                              onClick={() => handleSubmit()}
                              role="button"
                              style={{ width: '100%', boxSizing: 'border-box' }}
                            >
                              <i className="fa fa-check" />
                              Save
                            </div>
                          )}
                        </button>
                      </div>
                      <div className="col-6 px-1 pe-0">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          data-bs-dismiss="modal"
                          onClick={() => resetForm()}
                        >
                          <i className="fa fa-times" /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddNewAdminUser;
