import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getChannelInfo } from '../../redux/thunks/entitiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { THUNK_STATUS } from '../../constants';
import ChannelDetails from './channelDetails';

function Channels() {
  const { channelId } = useParams();
  const dispatch = useAppDispatch();
  const loadingChannelState = useAppSelector((state) => state.entities.loading.channel);
  const isLoading = loadingChannelState === THUNK_STATUS.LOADING;
  const channel = useAppSelector((state) => state.entities.channel);

  useEffect(() => {
    dispatch(getChannelInfo(Number(channelId)));
  }, [channelId]);

  return <ChannelDetails channel={channel} isLoading={isLoading} />;
}

export default Channels;
