import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { THUNK_LOADING } from '../../../constants';
import ErrorAlert from '../../../components/errorAlert';
import apiClient from '../../../helpers/api.js';

export const MODAL_NAME = 'confirmUnsubscription';

function ConfirmUnsubscription() {
  const dispatch = useAppDispatch();
  const { confirmUnsubscription: subscription } = useAppSelector((state) => state.modal.info);
  const { status, error } = useAppSelector((state) => state.userSettings.unsubscribe);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const [unsubscriptionError, setUnsubscriptionError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const e = typeof error !== 'string' ? error?.message : error;
    setUnsubscriptionError(e || '');
  }, [error]);

  const handleCloseModal = () => dispatch(modalActions.setVisibleModal('adminUserMemberCountForm'));

  const onSuccess = async (token: string) => {
    try {
      const response = await apiClient.postRequest(`/api/stripe/cancel-subscription/${token}`);

      if (response.status === 200) {
        toast.success('Subscription cancelled successfully!', {
          onClose: () => {
            window.location.href = 'https://elliotroe.typeform.com/to/leRmat7V';
          },
          autoClose: 2000, // Close after 2 seconds
        });
      }
    } catch (err: any) {
      setUnsubscriptionError('Subscription cancellation failed! Please try again later.');
      setLoading(false);
    }
  };

  const handleConfirmSubscribe = async () => {
    setLoading(true);
    setUnsubscriptionError('');
    try {
      const { data } = await apiClient.postRequest('/api/user/cancel-subscription-token');
      if (data.status === 200) {
        onSuccess(data.token);
      }
    } catch (e) {
      setUnsubscriptionError((e as Error).message);
      setLoading(false);
    }
  };

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h5 className="mb-3">You are about to Unsubscribe from the {subscription?.title}!</h5>
          <p className="color-gray-600 text-center">
            Are you sure? You will no longer have access to the Primed Mind library of over 400
            mindset coaching sessions.
          </p>
          <ErrorAlert errorMessage={unsubscriptionError} />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 pe-1">
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={handleCloseModal}
                data-bs-dismiss="modal"
              >
                Stay Subscribed
              </button>
            </div>
            <div className="col-6 px-1 ps-1">
              <button
                type="button"
                className="btn btn-danger w-100"
                onClick={handleConfirmSubscribe}
                disabled={status === THUNK_LOADING || loading}
              >
                {status === THUNK_LOADING || loading ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  'Yes, Unsubscribe'
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmUnsubscription;
