import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Sidebar from './components/sidebar';
import AdminUsers from './containers/adminUsers/adminUsers';
import Marketing from './containers/marketing/marketing';
import EditAdminProfile from './modals/editAdminProfile';
import CompanyDetails from './containers/subscriptions/companyDetails';
import SubscriptionTypes from './containers/subscriptions/subscriptionTypes';
import Teams from './containers/teams/teams';
import Team from './containers/teams/team';
import Sessions from './containers/sessions/sessions';
import AppUsers from './containers/appUsers/appUsers';
import AppUsersExplore from './containers/appUsers/appUsersExolore';
import Courses from './containers/courses/courses';
import Channels from './containers/channels/channels';
import EditChannel from './containers/channels/editChannels';
import CreateNewChannel from './containers/channels/createNewChannel';
import { URL_ROUTES } from './constants/routes';
import CheckoutPages from './containers/checkoutPages';

function AuthenticatedApp() {
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <Sidebar />
        <div className="col p-0 d-flex flex-column overflow-hidden">
          <Header />
          <Routes>
            <Route path={URL_ROUTES.AdminUsers} element={<AdminUsers />} />
            <Route path={URL_ROUTES.Channels} element={<Channels />} />
            <Route path={URL_ROUTES.CreateChannel} element={<CreateNewChannel />} />
            <Route path={URL_ROUTES.EditChannel} element={<EditChannel />} />
            <Route path={URL_ROUTES.Marketing} element={<Marketing />} />
            <Route path={URL_ROUTES.CompanyDetails} element={<CompanyDetails />} />
            <Route path={URL_ROUTES.SubscriptionTypes} element={<SubscriptionTypes />} />
            <Route path={URL_ROUTES.Teams} element={<Teams />} />
            <Route path={URL_ROUTES.Team} element={<Team />} />
            <Route path={URL_ROUTES.Sessions} element={<Sessions />} />
            <Route path={URL_ROUTES.AppUsers} element={<AppUsers />} />
            <Route path={URL_ROUTES.AppUsersExplore} element={<AppUsersExplore />} />
            <Route path={URL_ROUTES.Upgrades} element={<Courses />} />
            <Route path={URL_ROUTES.CheckOutPages} element={<CheckoutPages />} />
          </Routes>
          <EditAdminProfile />
        </div>
      </div>
    </div>
  );
}

export default AuthenticatedApp;
