import { EditSession } from '../../redux/interface/sessionInterface';

interface SessionProps {
  id: string;
  title: string;
  description: string | undefined;
  isFree: boolean;
  type: string;
  tags: string[];
  image: any;
  narrators: any;
  handleEdit: (session: EditSession) => void;
  handleDelete: (sessionId: string) => void;
}
function Session({
  id,
  title,
  description,
  isFree,
  tags,
  type,
  handleEdit,
  handleDelete,
  image,
  narrators,
}: SessionProps) {
  const handleEditing = () => {
    handleEdit({ id, title, description, isFree: isFree ? 1 : 0, tags, type, image, narrators });
  };
  const handleDeleting = () => {
    handleDelete(id);
  };

  const uniqueObject: Record<string, boolean> = tags?.reduce<Record<string, boolean>>(
    (acc, item) => {
      acc[item] = true;
      return acc;
    },
    {},
  );

  const uniqueTags = uniqueObject ? Object.keys(uniqueObject) : [];

  return (
    <tr key={`${id}${title}${tags?.[0]}`}>
      <td className="font-medium">{title}</td>
      <td className="text-truncate-column">{description}</td>
      <td className="font-medium">{isFree ? 'Yes' : 'No'}</td>
      <td className="text-truncate-column">
        {uniqueTags?.map((t) => (
          <div className="tag" key={`${t}${id}`}>
            <span className="tag__label ">{t}</span>
          </div>
        ))}
      </td>
      <td className="actions-cell">
        <button
          className="btn btn-outline-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#edit_session"
          type="button"
          onClick={handleEditing}
        >
          <i className="fas fa-edit" />
          Edit
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_session"
          type="button"
          onClick={handleDeleting}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
      </td>
    </tr>
  );
}

export default Session;
