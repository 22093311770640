import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { updateAppUser } from '../../../redux/thunks/appUsersThunk';

interface AppUserBlockAccountProps {
  userId: number;
}

function AppUserBlockAccount({ userId }: AppUserBlockAccountProps) {
  const dispatch = useAppDispatch();

  return (
    <div
      className="modal fade modal-edit-profile"
      id="block_account"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              data-bs-target="#user_settings"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Are you sure you want to block this account?</h5>
              <p className="color-gray-600">This user no longer have access to the app</p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      dispatch(
                        updateAppUser({
                          id: userId.toString(),
                          isActive: false,
                        }),
                      )
                    }
                  >
                    <i className="fa fa-ban" />
                    Block
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#user_settings"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppUserBlockAccount;
