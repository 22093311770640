import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { actions } from '../redux/slices/modalSlice';
import logo from '../assets/img/logo.svg';
import advertisementWomen from '../assets/img/advertisement-women-bg.png';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useServices } from '../redux/servicesLayer';

interface AdvertisementProps {
  onModalClose: () => any;
}

function Advertisement({ onModalClose }: AdvertisementProps) {
  const dispatch = useAppDispatch();
  const { marketingInfo } = useServices();
  const showModal = useAppSelector((state) => state.modal.visible === 'advertisementModal');
  const handleCloseModal = () => {
    dispatch(actions.closeModal());
    onModalClose();
  };

  if (!marketingInfo) {
    return null;
  }

  const [showBorderDecor, setShowBorderDecor] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowBorderDecor(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal show={showModal} className="modal-lg modal-advertisement" centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 btn-close-white"
          onClick={handleCloseModal}
          aria-label="Close"
        />{' '}
        {/* eslint-disable-line jsx-a11y/control-has-associated-label */}
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 d-flex justify-content-center mb-3">
            <div className="modal-advertisement__logo">
              <img src={logo} alt="Primed Mind logo" />
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div
            className={`col-5 px-5 d-xl-block d-flex justify-content-center modal-advertisement__women-bg m-auto ${
              showBorderDecor ? 'modal-advertisement__border-decor' : ''
            }`}
          >
            <img
              src={marketingInfo?.media ? marketingInfo?.media?.url : advertisementWomen}
              alt=""
              style={{ width: !showBorderDecor ? '185px' : '' }}
            />
          </div>
          <div className="col-12 col-xl-7">
            <div className="d-flex flex-column justify-content-center  px-3 px-xl-5 mt-3">
              <h3 className="mb-2 font-medium color-white text-center">{marketingInfo?.title}</h3>
              <small className="color-white text-center">{marketingInfo?.description}</small>

              <div className="row mt-3">
                <div className="col-12 col-sm-9 offset-sm-2 col-xl-12 offset-xl-0 color-white">
                  {marketingInfo?.advantages?.split(',').map(
                    (advantage) =>
                      advantage.trim() && (
                        <div
                          key={advantage}
                          className="mb-3 d-flex flex-direction-row align-items-start"
                        >
                          <i className="fa-regular fa-square-check color-primary me-2 mt-1" />
                          <small>{advantage.trim()}</small>
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {marketingInfo?.link !== '' && (
          <div className="row">
            <div className="col-12 offset-xl-5 col-xl-7 mb-3 ">
              <a className="d-block btn btn-primary px-5 w-75 m-auto" href={marketingInfo?.link}>
                {marketingInfo.cta}
                <i className="fa-regular fa-arrow-right ms-1 " />
              </a>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Advertisement;
