/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldInputProps, FormikProps } from 'formik';
import { useAppDispatch } from '../../redux/hooks';
import { removeImage } from '../../redux/thunks/marketingThunk';
import { getTouchErrorByField } from '../../helpers/formik';

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  img: string;
  setImg: React.Dispatch<React.SetStateAction<any>>;
  selectedFile: any;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | string>>;
  id?: string;
  valueType?: string;
}

function DragFileInput({ field, form, img, selectedFile, valueType, setSelectedFile, id, setImg }: Props) {
  const dispatch = useAppDispatch();
  const [preview, setPreview] = useState<string>();
  const { setFieldValue } = form;
  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      setFieldValue(field.name, valueType === 'file' ? acceptedFiles[0] : e?.target?.result);
      setSelectedFile(acceptedFiles[0]);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { touched, error } = getTouchErrorByField(field.name, form);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'image/*': [],
    },
  });
  const renderInput = () => {
    let HelpBlock = <div />;
    if (touched && error) {
      HelpBlock = <div style={{ color: 'red' }}>{error as string}</div>;
      return <> {HelpBlock}</>;
    }
  };
  return (
    <div className="position-relative">
      <div className="drag-file h-100 p-3 d-flex flex-column justify-content-between ">
        {preview ? (
          <img src={preview} alt="pic" />
        ) : img ? (
          <img src={img} alt="pic" />
        ) : (
          <>
            <p className="drag-file__description text-center mb-2">
              Add background images that will be added to the ads, the images will be automatically
              cropped to fit the screen size
            </p>

            <div>
              <div className="drag-file__icons container d-flex justify-content-center mb-2">
                <i className="fa-regular fa-folder-arrow-down" />
                <i className="fa-regular fa-pen-to-square" />
                <i className="fa-regular fa-image" />
              </div>

              <h4 className="font-regular text-center m-0 mb-2">Drag a file</h4>
              <p className="text-center mb-3">or Choose a file from your computer</p>
            </div>
          </>
        )}
        <div
          {...getRootProps()}
          className="btn btn-primary px-5 d-block mx-autotext-truncate shadow mt-3"
        >
          <input
            {...getInputProps()}
            style={{
              position: 'absolute',
              clip: 'rect(0, 0, 0, 0)',
              pointerEvents: 'none',
            }}
          />
          {img || selectedFile?.type ? 'Change Media' : 'Upload or Drag Media File Here'}
        </div>
        {renderInput()}
      </div>
      <div className="position-absolute top-0 start-100 translate-middle">
        {(img || selectedFile?.type) && (
          <button
            className="btn-sm btn-dark bg-color-white border-0 w-100"
            type="button"
            onClick={() => {
              setImg('');
              setSelectedFile('');
              setPreview(undefined);
              setFieldValue('image', '');
              if (id) {
                dispatch(removeImage(id));
              }
            }}
          >
            <i className="fa fa-times" />
          </button>
        )}
      </div>
    </div>
  );
}

DragFileInput.defaultProps = {
  id: '',
  valueType: '',
};

export default DragFileInput;
