import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { NewCourse, EditCourses } from '../../redux/interface/courseInterface';
import DeleteCourse from './modals/deleteCourse';
import EditCourse from './modals/editCourse';
import AddNewCourse from './modals/addNewCourse';
import { fetchCourses } from '../../redux/thunks/courseThunk';
import Course from './course';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import { handlePagination, setParamValue } from '../../helpers';
import { actions as channelActions } from '../../redux/slices/channelsSlice';
import { getCoursesChannels } from '../../redux/thunks/channelsThunk';

const BASE_URL = '/api/courses';

let option: any = [];

function Courses() {
  const dispatch = useAppDispatch();

  // local state
  const [newCourse] = useState<NewCourse>({
    title: '',
    subtitle: '',
    tags: [],
    courseSessions: [],
    image: '',
    isDayCourse: false,
  });

  const [courseToEdit, setCourseToEdit] = useState<EditCourses>({
    id: '',
    title: '',
    subtitle: '',
    tags: [],
    option: [],
    courseSessions: [],
    image: '',
    isDayCourse: false,
  });
  const [courseToDelete, setCourseToDelete] = useState('');
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfRows, setNumberOfRows] = useState(10);

  const courses = useAppSelector((state) => state.course.coursesInfo);
  const courseSession = useAppSelector((state) => state.channel.allCourses);
  const allCoursesStatus = useAppSelector((state) => state.channel.allCoursesPagination.status);

  useEffect(() => {
    option = courseSession?.map((s: any) => ({
      value: `/api/sessions/${s.id}`,
      label: s.title,
    }));
  }, [courseSession]);

  const totalCourses = useAppSelector((state) => state.course.totalItems);
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.course.pagination,
  );
  const status = useAppSelector((state) => state.course.status);

  const handleEditCourse = (course: EditCourses) => setCourseToEdit(course);
  const handleDeleteCourse = (courseId: string) => setCourseToDelete(courseId);

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'title', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    dispatch(channelActions.clearCoursesForChannel());
    dispatch(getCoursesChannels({ page: 1, itemsPerPage: 10 }));
    if (
      Math.ceil(totalCourses / numberOfRows) !== 0 &&
      Math.ceil(totalCourses / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(fetchCourses(currentUrl));
  }, [currentUrl, totalCourses]);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor" />
              <div className="row pb-3 pb-md-4 align-items-center">
                <div className="col-12 col-sm-6">
                  <h1 className="headline-decor">Upgrades</h1>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary px-md-3 px-lg-5"
                    data-bs-toggle="modal"
                    data-bs-target="#add_new_course"
                  >
                    <i className="fa fa-plus" />
                    Add New Upgrade
                  </button>
                </div>
              </div>

              <div className="container-fluid content-inner">
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <Search value={searchInput} onInputChange={handleFilterUsers} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 p-0">
                    <div className="table-responsive">
                      <table className="table text-nowrap">
                        <thead>
                          <tr>
                            <th className="col-3">
                              <div className="d-flex align-items-center col-3">
                                Title
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-3">
                              <div className="d-flex align-items-center col-4">
                                Subtitle
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-3">Tags</th>
                            <th className="col-3">Actions</th>
                          </tr>
                        </thead>
                        {status === 'loading' || allCoursesStatus === 'loading' ? (
                          <tbody>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>{' '}
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>{' '}
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {courses &&
                              courses?.map((c) => (
                                <Course
                                  key={c.id}
                                  id={c.id}
                                  title={c.title}
                                  subtitle={c.subtitle}
                                  tags={c.tags}
                                  courseSessions={c.sessions}
                                  option={option}
                                  image={c.media}
                                  isDayCourse={c.isDayCourse}
                                  handleEdit={handleEditCourse}
                                  handleDelete={handleDeleteCourse}
                                />
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        onPageChange={handlePageChange}
                        totalCount={totalCourses || 0}
                        currentPage={currentPage}
                        pageSize={numberOfRows}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteCourse id={courseToDelete} status={status} />
      <EditCourse courseToEdit={courseToEdit} opt={option} />
      <AddNewCourse newCourse={newCourse} opt={option} />
    </>
  );
}

export default Courses;
