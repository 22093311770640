import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../components/formik/input';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { THUNK_LOADING, THUNK_STATUS } from '../constants';
import { URL_ROUTES } from '../constants/routes';
import ErrorAlert from '../components/errorAlert';
import { registerNewUser } from '../redux/thunks/currentUserThunk';
import SuccessAlert from '../components/successAlert';
import TncPpLinks from '../components/TncPpLinks';
import OAuthSignIn from '../components/oAuthSignIn';
import { RegistrationFields } from '../redux/interface/currentUserInterface';
import { pushWithDataLayer } from '../@types/global';
import { useRefferal } from '../customHooks/useRefferal';

type LocationState = {
  from: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your name.'),
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter your email address.'),
  password: Yup.string().required('Please enter your password.'),
  confirm_password: Yup.string()
    .required('Please enter your password.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

function SignUp() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const navigate = useNavigate();

  const {
    status,
    error,
    message: successMessage,
  } = useAppSelector((state) => state.currentUser.register);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      if (locationState?.from === 'sharedPlayer') {
        pushWithDataLayer({
          event: 'sign_up_from_shared_player',
          formType: 'Sign up',
          source: 'From Shared Player',
        });
      }
      const uuid = localStorage.getItem('uuid');
      if (uuid) {
        window.location.replace(`/checkout/${uuid}`);
      } else {
        navigate(URL_ROUTES.Home);
      }
    }
  }, [status]);

  const { referral } = useRefferal();
  const handleSignUp = (values: RegistrationFields) => {
    dispatch(registerNewUser(referral ? { ...values, referral } : { ...values }));
  };

  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      <div className="mt-5" />
      <div className="onboarding__form-box d-flex flex-column w-100 mt-auto">
        <h3 className="mb-3 mb-lg-4 text-center color-black">Welcome to Primed Mind!</h3>
        <div className="mb-3 text-center">
          Already have an account?{' '}
          <Link
            to={URL_ROUTES.Login}
            className="color-secondary text-decoration-none font-semi-bold"
          >
            Log In
          </Link>
        </div>
        <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
        {status === THUNK_STATUS.SUCCEEDED && successMessage && (
          <SuccessAlert message={successMessage} />
        )}
        <Formik
          initialValues={{ name: '', email: '', password: '', confirm_password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSignUp(values)}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} id="sign-up-form">
              <div className="mb-3">
                <Field
                  component={Input}
                  type="text"
                  label="First Name"
                  name="name"
                  description="name"
                  placeholder="Your First Name"
                />
                <Field
                  component={Input}
                  type="text"
                  label="Email"
                  name="email"
                  description="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="mb-4">
                <Field
                  component={Input}
                  type="password"
                  label="Password"
                  name="password"
                  description="password"
                  placeholder="Password"
                />
              </div>
              <div className="mb-4">
                <Field
                  component={Input}
                  type="password"
                  label="Confirm Password"
                  name="confirm_password"
                  description="confirm password"
                  placeholder="Confirm Password"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="d-block btn btn-primary px-5 w-75 m-auto"
                  disabled={status === THUNK_LOADING}
                  id="sign-up-form-button"
                >
                  {status === THUNK_LOADING ? (
                    <span
                      className="spinner-border"
                      style={{ width: '1em', height: '1em' }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  ) : (
                    <>
                      <i className="fa-regular fa-paper-plane me-1" /> Sign up
                    </>
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
        <div className="mb-3">
          <div className="onboarding__or-decor-line d-flex align-items-center justify-content-center w-100">
            {' '}
            <span className="d-block px-1 bg-color-white">or</span>
          </div>
        </div>
        <OAuthSignIn signUp />
        <TncPpLinks />
      </div>
    </div>
  );
}

export default SignUp;
