/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Field, Formik } from 'formik';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import {
  createCheckoutPage,
  fetchCheckoutPages,
  updateCheckoutPage,
} from '../../../redux/thunks/checkoutPageThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { formatNumber } from '../../../helpers';
import { inquireMoreSeats } from '../../../redux/thunks/userSettingsThunk';

interface Props {
  handlePageChange: (value: number, page: string) => void;
}

const subscriptionTeamLifetime = {
  description: 'Lifetime subscription',
  frequency: 'lifetime',
  id: '0',
  type: 'team',
  price: 0,
};

function CreateCheckoutPage({ handlePageChange }: Props) {
  const subscriptions = useAppSelector((state) => state.subscriptions?.subscriptions);
  const addSeats = useAppSelector((state) => state.userSettings.addSeats);
  const subscriptionsWithLifetime = [...subscriptions, subscriptionTeamLifetime];

  const { price: defaultTeamPrice } = useMemo(
    () => subscriptions.find((subscription) => subscription.type === 'team') ?? { price: 0 },
    [subscriptions],
  );

  const [teamPrice, setTeamPrice] = useState(defaultTeamPrice);

  const { status, modalOpen, modalName } = useAppSelector((state) => state.checkoutPages);
  const selectedCheckoutPage = useAppSelector((state) => {
    const { selectedCheckoutPage: selected } = state.checkoutPages;
    if (!selected) {
      return selected;
    }

    const currentSubscription = subscriptionsWithLifetime.find(
      ({ id }) => selected.subscription.id === id,
    );
    const plan =
      !currentSubscription && selected?.subscription?.type === 'team'
        ? '0'
        : currentSubscription?.id;
    return {
      id: selected.id,
      name: selected.name,
      title: selected.title,
      userType: selected?.subscription?.type,
      plan,
      discountType: selected.discountType,
      discountValue: selected.discountValue,
      seats: selected.seats,
      repetitions: selected.repetitions,
      lifetimePrice: selected?.subscription?.price,
      trialDays:
        selected?.trialDays === null ? selected?.subscription.trialDays : selected.trialDays,
    };
  });
  const dispatch = useAppDispatch();
  const editMode = !!selectedCheckoutPage;

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required('Please enter a template name.'),
      title: Yup.string().required('Please provide a custom plan title.'),
      userType: Yup.string().required('Please choose a plan type'),
      plan: Yup.string().required('Please choose a plan'),
      trialDays: Yup.number().required('Please provide a quantity for trial days.'),

      discountType: Yup.string().when('plan', {
        is: (planType: string) => planType !== '0',
        then: Yup.string().required('Please choose a discount type'),
        otherwise: Yup.string(),
      }),
      discountValue: Yup.number()
        .typeError('Please enter a valid discount value')
        .when(['plan', 'discountType'], {
          is: (plan: string) => plan !== '0',
          then: Yup.number()
            .required('Please enter a discount value')
            .positive('Please enter a positive discount value')
            .when('discountType', {
              is: 'amount_off',
              then: Yup.number().test(
                'max-discount',
                'Discount value should not exceed the price of the selected plan',
                // eslint-disable-next-line func-names
                function (value) {
                  const { plan } = this.parent;
                  const selectedPlan = subscriptions.find((p) => `${p.id}` === plan);
                  if (value && selectedPlan) {
                    return teamPrice && selectedPlan?.type && selectedPlan?.type.includes('team')
                      ? value <= teamPrice
                      : value <= selectedPlan.price;
                  }
                  return true;
                },
              ),
            })
            .when('discountType', {
              is: 'percent_off',
              then: Yup.number().test(
                'max-discount',
                'Discount value should not exceed 100%',
                (value) => {
                  if (value && value <= 100) {
                    return true;
                  }
                  return false;
                },
              ),
            })
            .test(
              'decimal-places',
              'Discount value should have at most two decimal places',
              (value) => {
                if (value === undefined || value === null || `${value}` === '') {
                  return true; // Allow empty values
                }

                // Check if the number has more than 2 decimal places
                const decimalPlaces = (value.toString().split('.')[1] || '').length;
                return decimalPlaces <= 2;
              },
            ),
          otherwise: Yup.number()
            .nullable()
            .transform((value, originalValue) => {
              if (originalValue === '' || originalValue === null) {
                return null;
              }
              return value;
            }),
        }),
      seats: Yup.number()
        .nullable()
        .when('userType', {
          is: (uType: string) => uType === 'team',
          then: Yup.number().positive('Please enter number of seats'),
        }),
      repetitions: Yup.number()
        .nullable()
        .when('plan', {
          is: (planType: string) =>
            planType !== '0' &&
            subscriptions.find((sub) => `${sub.id}` === planType)?.frequency !== 'lifetime',
          then: Yup.number().positive('Please enter repetition value.').nullable(true),
        }),
      lifetimePrice: Yup.number()
        .nullable()
        .when('plan', {
          is: (planType: string) => planType === '0',
          then: Yup.number().positive('Please enter lifetime price value.'),
          otherwise: Yup.number(),
        }),
    })
    .defined();

  return (
    <div
      className="modal fade modal-edit-profile"
      id="create_checkout_page"
      tabIndex={-1}
      aria-hidden="true"
    >
      <Formik
        enableReinitialize
        initialValues={
          editMode
            ? selectedCheckoutPage
            : {
                name: '',
                title: '',
                userType: '',
                plan: '',
                trialDays: 0,
                discountValue: 0,
                discountType: '',
                seats: 0,
                repetitions: null,
                lifetimePrice: 0,
              }
        }
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(values) => {
          const params = {
            name: values.name,
            title: values.title,
          };
          if (editMode) {
            dispatch(
              updateCheckoutPage({
                ...params,
                id: selectedCheckoutPage.id,
              }),
            ).then(() => {
              dispatch(fetchCheckoutPages('/api/custom_checkouts'));
            });
          } else {
            const getDiscountType = () => {
              if (values.plan === '0') {
                return 'price';
              }
              return values.discountType;
            };

            const getAmount = () => {
              if (values.plan === '0') {
                return Number(values.lifetimePrice);
              }
              return Number(values.discountValue);
            };

            const getTrialDays = () => {
              const subscription = subscriptionsWithLifetime.find(
                (plan) => `${plan.id}` === `${values.plan}`,
              );

              if (subscription?.frequency === 'lifetime') {
                return 0;
              }

              return Number(values.trialDays);
            };

            dispatch(
              createCheckoutPage({
                ...params,
                trialDays: getTrialDays(),
                subscription: values.plan === '0' ? undefined : `/api/subscriptions/${values.plan}`,
                discountType: getDiscountType(),
                discountValue: getAmount(),
                seats: values.userType === 'team' && values.seats ? Number(values.seats) : null,
                repetitions:
                  Number(values.repetitions) > 0 ? Number(values.repetitions) : undefined,
              }),
            ).then(() => {
              dispatch(fetchCheckoutPages('/api/custom_checkouts'));
            });
          }
        }}
      >
        {({ handleSubmit, values, resetForm, setFieldValue }) => {
          const [selectedPlan, setSelectedPlan] = useState<
            | typeof subscriptions[0]
            | typeof subscriptionTeamLifetime
            | { price: number; frequency: string }
            | null
          >(null);

          useEffect(() => {
            if (status === 'succeeded' && modalName === 'checkoutPageForm') {
              hideModal({ modalId: '#create_checkout_page' });
              resetForm();
              handlePageChange(1, 'PAGE_NUMBER');
            }
            return () => resetForm();
          }, [modalOpen]);

          useEffect(() => {
            if (addSeats?.seatPrice && values?.seats) {
              const newSelectedPlan = {
                ...selectedPlan,
                ...{
                  price: addSeats?.price,
                  frequency: selectedPlan?.frequency || '',
                },
              };
              setSelectedPlan(newSelectedPlan);
              setTeamPrice(addSeats.price);
            }
          }, [addSeats]);

          const debouncedSeatsPriceCall = useCallback(
            debounce((seatsValue: number) => {
              dispatch(inquireMoreSeats({ seats: seatsValue }));
            }, 1000),
            [],
          );
          useEffect(() => {
            setTeamPrice(defaultTeamPrice);
            if (values.userType === 'team' && !!Number(values.plan) && !!values.seats) {
              debouncedSeatsPriceCall(values.seats);
            }
          }, [values.userType, values.plan, values.seats]);

          const planOptions = values?.userType
            ? subscriptions
                .filter(({ type }) => type === values.userType)
                .map(({ description, id, price, frequency }) => {
                  const subscrip = {
                    name: `${description} ($${price})`,
                    value: id,
                  };
                  if (values.userType === 'team' && frequency === 'yearly') {
                    subscrip.name = `${description} ($${teamPrice})`;
                  }
                  return subscrip;
                })
                .concat(values.userType === 'team' ? [{ name: 'Lifetime', value: '0' }] : [])
            : [];

          useEffect(() => {
            setSelectedPlan(
              subscriptionsWithLifetime.find((plan) => `${plan.id}` === `${values.plan}`) || null,
            );
          }, [values.plan]);

          const handleUserTypeChange = () => {
            setFieldValue('plan', '');
          };

          return (
            <form className="modal-dialog modal-dialog-centered" onSubmit={handleSubmit}>
              <div className="modal-content">
                <div className="modal-header pb-0 p-4 border-0">
                  <button
                    type="button"
                    className="btn-close icon-size-12 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetForm()}
                  />
                </div>

                <div className="modal-body ps-5 pe-5">
                  <div className="text-center font-light mb-3">
                    <h5>{editMode ? 'Edit' : 'Add'} Check-out Page</h5>
                  </div>
                  <div className="mb-3">
                    <Field
                      component={Input}
                      type="text"
                      name="name"
                      placeholder="e.g. Christmas Sale"
                      label="Template Name *"
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      component={Input}
                      type="text"
                      name="title"
                      placeholder="Custom Plan Title"
                      label="Custom Plan *"
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      component={Dropdown}
                      name="userType"
                      placeholder="User Type"
                      options={[
                        { name: 'Single', value: 'single' },
                        { name: 'Team', value: 'team' },
                      ]}
                      label="User Type *"
                      disabled={editMode}
                      handleResetPlan={handleUserTypeChange}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      component={Dropdown}
                      name="plan"
                      placeholder="Plan"
                      options={planOptions}
                      label="Plan *"
                      disabled={editMode}
                    />
                  </div>

                  <div
                    className={classnames(
                      'mb-3',
                      (!values.userType || selectedPlan?.frequency === 'lifetime') && 'd-none',
                    )}
                  >
                    <Field
                      component={Input}
                      type="number"
                      name="trialDays"
                      placeholder="Trial Days"
                      label="Trial Days *"
                      disabled={editMode}
                      step={1}
                      min={0}
                    />
                  </div>

                  <div
                    className={classnames(
                      'mb-3',
                      (!values.userType || values.userType === 'single') && 'd-none',
                    )}
                  >
                    <Field
                      component={Input}
                      type="number"
                      name="seats"
                      placeholder="Number of Seats"
                      label="Number of Seats *"
                      min="1"
                      step="1"
                      disabled={editMode}
                    />
                  </div>

                  {values.userType === 'team' && selectedPlan?.frequency === 'lifetime' ? (
                    <div className={classnames('mb-3', !values.userType && 'd-none')}>
                      <Field
                        component={Input}
                        type="number"
                        name="lifetimePrice"
                        placeholder="Lifetime Plan Price"
                        label="Lifetime Plan Price *"
                        disabled={editMode}
                      />
                    </div>
                  ) : (
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column" style={{ maxWidth: '190px' }}>
                          <Field
                            component={Dropdown}
                            name="discountType"
                            placeholder="Discount Type"
                            options={[
                              { name: 'Price ($)', value: 'amount_off' },
                              { name: 'Percentage (%)', value: 'percent_off' },
                            ]}
                            label="Discount Type *"
                            disabled={editMode}
                          />
                        </div>
                        <div className="d-flex flex-column" style={{ maxWidth: '190px' }}>
                          <Field
                            component={Input}
                            type="number"
                            name="discountValue"
                            placeholder="Discount Value"
                            min={0.01}
                            label={`Discount Value (${
                              values.discountType === 'amount_off' ? '$' : '%'
                            }) *`}
                            disabled={
                              editMode || !values.discountType || !values.userType || !values.plan
                            }
                          />
                          {!!values.discountValue &&
                            !!values.plan &&
                            values.discountType === 'amount_off' && (
                              <div
                                className="text-danger ps-2"
                                style={{ fontSize: '0.8rem', color: '#6a1fa8' }}
                              >
                                {`New Plan Price $${formatNumber(
                                  Math.trunc(
                                    ((selectedPlan?.price || 0) - values.discountValue) * 100,
                                  ) / 100,
                                )}`}
                              </div>
                            )}
                          {!!values.discountValue &&
                            values.discountType === 'percent_off' &&
                            !!values.plan && (
                              <div className="text-danger ps-2" style={{ fontSize: '0.8rem' }}>
                                {`New Plan Price $${formatNumber(
                                  Math.trunc(
                                    (selectedPlan?.price || 0) *
                                      ((100 - Number(values.discountValue)) * 0.01) *
                                      100,
                                  ) / 100,
                                )}`}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedPlan?.frequency !== 'lifetime' && (
                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="number"
                        name="repetitions"
                        placeholder="Enter discount term in numbers"
                        label="Terms/Discount Repetition *"
                        min="1"
                        step="1"
                        disabled={editMode}
                      />
                    </div>
                  )}
                </div>
                <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 px-1 ps-0">
                        <button className="btn btn-primary w-100" type="submit">
                          {' '}
                          <i className="fa fa-check me-1" />
                          {editMode ? ' Save' : ' Add New Page'}
                        </button>
                      </div>
                      <div className="col-6 px-1 pe-0">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          data-bs-dismiss="modal"
                          onClick={() => resetForm()}
                        >
                          {' '}
                          <i className="fa-regular fa-close me-1" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {status === 'loading' && (
                  <div
                    className="position-absolute top-50 start-50 translate-middle bg-gray w-100 h-100 d-flex align-items-center justify-content-center z-10"
                    style={{ backgroundColor: '#00000020', borderRadius: '20px' }}
                  >
                    <span className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </div>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateCheckoutPage;
