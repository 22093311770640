import React, { ReactElement } from 'react';
import classNames from 'classnames';
import subscriptionBannerRun from '../../assets/img/subscription-banner-run.png';
import subscriptionBannerMeditate from '../../assets/img/subscription-banner-meditation.png';
import { useAuth } from '../../redux/authLayer';
import { useAppDispatch } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';

export enum SubscriptionBannerSize {
  MEDIUM = 'medium',
  SMALL = 'small',
  LONG = 'long',
}

interface SubscriptionBannerProps {
  size?: SubscriptionBannerSize;
  forceShow?: boolean;
}

function SubscriptionBanner({ size, forceShow }: SubscriptionBannerProps) {
  const { isFree } = useAuth();
  const dispatch = useAppDispatch();

  const topDivClasses = {
    [SubscriptionBannerSize.SMALL]: 'h-100',
    [SubscriptionBannerSize.MEDIUM]: 'pe-sm-5',
    [SubscriptionBannerSize.LONG]: 'overflow-hidden pt-4 w-100',
  };

  const topInnerDivClasses = {
    [SubscriptionBannerSize.SMALL]: 'w-100 p-4 h-100',
    [SubscriptionBannerSize.MEDIUM]: 'w-100 p-4',
    [SubscriptionBannerSize.LONG]: 'bg-color-secondary',
  };

  const isMedium = size === SubscriptionBannerSize.MEDIUM;
  const isSmall = size === SubscriptionBannerSize.SMALL;
  const isLong = size === SubscriptionBannerSize.LONG;

  const currentSize = size || SubscriptionBannerSize.MEDIUM;

  const renderMessageAddDiv = (element: ReactElement) => (
    <div className="w-100 color-white">{element}</div>
  );

  const renderMessage = () => {
    const elements = (
      <>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <h4 {...(isSmall ? { className: 'mb-3' } : {})}>Unlock Primed Mind</h4>
        <p {...(!isLong ? { className: `subscription-banner-${currentSize}__description` } : {})}>
          Activate your Primed Mind Experience to access exclusive Primers and Upgrades.
        </p>
        {/* eslint-enable react/jsx-props-no-spreading */}
      </>
    );

    return !isLong ? renderMessageAddDiv(elements) : elements;
  };

  const renderActionButton = () => (
    <button
      type="button"
      className={`subscription-banner-${size}__btn d-block btn btn-primary px-5 w-${
        isLong ? '75' : '100'
      }`}
      onClick={() => {
        dispatch(modalActions.setVisibleModal('subscriptionModal'));
      }}
    >
      <i className="fa-regular fa-bell me-1" /> Try Free & Subscribe
    </button>
  );

  const renderTextContainer = () => {
    const textBody = (
      <div
        className={classNames(
          'd-flex',
          'flex-column',
          `justify-content-${
            !isLong ? `between col-${isMedium ? 'sm-8' : 'xl-8'}` : 'center col-12'
          }`,
          isLong && 'w-100 py-5 px-4 align-items-center text-center color-white',
        )}
      >
        {renderMessage()}
        {!isSmall && renderActionButton()}
      </div>
    );

    return isLong ? <div className="col-12 col-xl-4">{textBody}</div> : textBody;
  };

  if (!isFree && !forceShow) {
    return null;
  }

  return (
    <div
      className={classNames(
        `subscription-banner-${currentSize}`,
        'radius-16',
        topDivClasses[currentSize],
      )}
    >
      <div
        className={classNames(
          `subscription-banner-${currentSize}__inner`,
          'radius-16',
          topInnerDivClasses[currentSize],
        )}
      >
        <div className={classNames('row', !isLong && 'h-100')}>
          {!isMedium && (
            <div
              className={classNames(
                'd-none',
                'd-xl-block',
                isSmall ? ' col-4' : ' col-4 text-center',
              )}
            >
              <img
                className={isSmall ? 'subscription-banner-small__decor-img' : 'mt-n4'}
                src={subscriptionBannerRun}
                alt="Subscribe now"
              />
            </div>
          )}
          {renderTextContainer()}
          {isSmall && <div className="col-12 mt-auto">{renderActionButton()}</div>}
          {!isSmall && (
            <div
              className={classNames(
                'd-none',
                'col-4',
                `d-${isMedium ? 'sm' : 'xl'}-block`,
                isLong && 'm-auto text-end',
              )}
            >
              <img
                className={isMedium ? 'subscription-banner-medium__decor-img' : 'mt-n4'}
                src={isMedium ? subscriptionBannerRun : subscriptionBannerMeditate}
                alt="Subscribe now"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SubscriptionBanner.defaultProps = {
  size: SubscriptionBannerSize.MEDIUM,
  forceShow: false,
};

export default SubscriptionBanner;
