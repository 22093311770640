import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { deleteAppUser } from '../../../redux/thunks/appUsersThunk';

interface AppUserBlockAccountProps {
  userId: number;
}

function AppUserDeleteAccount({ userId }: AppUserBlockAccountProps) {
  const dispatch = useAppDispatch();

  return (
    <div
      className="modal fade modal-edit-profile"
      id="delete_account"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              data-bs-target="#user_settings"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Are you sure you want to delete this account?</h5>
              <p className="color-gray-600">This user will be removed</p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    data-bs-dismiss="modal"
                    onClick={() => dispatch(deleteAppUser(userId.toString()))}
                  >
                    <i className="fa fa-trash-can" />
                    Delete
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#user_settings"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppUserDeleteAccount;
