import React from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { deleteChannel } from '../../../redux/thunks/channelsThunk';

interface Props {
  id: string;
  status: string | undefined;
}

function DeleteChannel({ id, status }: Props) {
  const dispatch = useAppDispatch();
  const handleConfirmDelete = () => {
    dispatch(deleteChannel(id));
  };
  return (
    <div
      className="modal fade modal-edit-profile"
      id="delete_channel"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Delete Channel?</h5>
              <p className="color-gray-600">Are you sure you want to delete this channel?</p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    disabled={status === 'loading'}
                    onClick={handleConfirmDelete}
                    data-bs-dismiss="modal"
                  >
                    {status === 'loading' ? (
                      <span
                        className="spinner-border"
                        style={{ width: '1em', height: '1em' }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    ) : (
                      <>
                        <i className="fa fa-check" />
                        Yes, Delete!
                      </>
                    )}
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-dismiss="modal"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteChannel;
