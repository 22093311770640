import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  path: string;
  icon?: string;
  name: string;
  liClass?: string;
  linkClass?: string;
  searching?: string;
  refresh?: boolean;
}
function NavItem({ path, icon, name, liClass, linkClass, searching, refresh = false }: Props) {
  return (
    <li className={`nav-item ${liClass}`}>
      <Link
        onClick={() => {
          if (refresh) {
            window.location.replace(path);
          }
        }}
        to={{
          pathname: path,
          search: searching,
        }}
        className={`nav-link align-middle ${linkClass}`}
      >
        {icon && (
          <span className="nav-icon">
            <i className={`fa-regular me-md-2 ${icon}`} />
          </span>
        )}
        <span className="ms-1 d-none d-md-inline">{name}</span>
      </Link>
    </li>
  );
}

NavItem.defaultProps = {
  icon: '',
  liClass: '',
  linkClass: '',
  searching: '',
  refresh: false,
};

export default NavItem;
