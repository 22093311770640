/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDebugValue, useEffect, useState, ChangeEvent, useCallback } from 'react';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { uniq } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AppUserSettings from './modals/appUserSettings';
import AppUserProvideSubscription from './modals/appUserProvideSubscription';
import userPageAvatar from '../../assets/img/pmd_defaultavatar.png';
import {
  fetchAppUsersHistory,
  getUserDetail,
  getUserListenHistory,
  getUserSubscriptionHistory,
} from '../../redux/thunks/appUsersThunk';
import { handlePagination, setParamValue } from '../../helpers';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import { History } from '../../redux/interface/appUsersInterface';

interface stateType {
  userId: number;
  email: string;
  name: string;
  type: string;
  discountApplied: boolean;
  history: History[];
  revenue: string;
  listens: string;
  usage: string;
  device: string;
  source: string;
  status: string;
  isFree: boolean;
}

const BASE_URL = '/api/users';

function AppUsersExplore() {
  const dispatch = useAppDispatch();
  const { userId } = useLocation().state as stateType;

  const [collapseListen, setCollapseListen] = useState(false);
  const [collapseSubscription, setCollapseSubscription] = useState(false);

  const handleLoadAppUser = () => {
    dispatch(getUserDetail(userId));
  };

  useEffect(handleLoadAppUser, []);

  const user = useAppSelector((state) => state.appUsers.singleUserData);
  const [exploreAppSetting, setExploreAppSetting] = useState({
    emailSetting: user?.email || '',
    nameSetting: user?.name || '',
    typeSetting: user?.type || '',
    discountAppliedSetting: user?.discountApplied || false,
    revenueSetting: user?.totalRevenue || 0,
    listensSetting: user?.listens || 0,
    usageSetting: user?.usage || 0,
  });

  const [currentUrl, setCurrentUrl] = useState(`${BASE_URL}/${userId}/histories`);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfRows, setNumberOfRows] = useState(5);
  const [listenHistoryCurrentPage, setListenHistoryCurrentPage] = useState(1);
  const [subscriptionHistoryCurrentPage, setSubscriptionHistoryCurrentPage] = useState(1);

  // redux store
  const appHistory = useAppSelector((state) => state.appUsers.history);

  // pagination
  const historyTotalItems = useAppSelector((state) => state.appUsers.historyTotalItems);
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.appUsers.historyPagination,
  );
  useEffect(() => {
    setExploreAppSetting({
      emailSetting: user?.email || '',
      nameSetting: user?.name || '',
      typeSetting: user?.type || '',
      discountAppliedSetting: user?.discountApplied || false,
      revenueSetting: user?.totalRevenue || 0,
      listensSetting: user?.listens || 0,
      usageSetting: user?.usage || 0,
    });
  }, [user]);
  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}/${userId}/histories?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const handleFilterUsers = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'detail', value: e.target.value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}/${userId}/histories?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: `${BASE_URL}/${userId}/histories`,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  const { listenHistory } = useAppSelector((state) => state.appUsers.listenHistoryState);
  const { subscriptionHistory } = useAppSelector(
    (state) => state.appUsers.subscriptionHistoryState,
  );

  const totalListenHistory = useAppSelector(
    (state) => state.appUsers.listenHistoryState.totalItems,
  );
  const totalSubscriptionHistory = useAppSelector(
    (state) => state.appUsers.subscriptionHistoryState.totalItems,
  );

  // fetching app user history
  useEffect(() => {
    dispatch(fetchAppUsersHistory(currentUrl));
  }, [currentUrl]);

  useEffect(() => {
    dispatch(
      getUserListenHistory(
        `${BASE_URL}/${userId}/sessions?itemsPerPage=5&page=${listenHistoryCurrentPage}`,
      ),
    );
    dispatch(
      getUserSubscriptionHistory(
        `${BASE_URL}/${userId}/invoices?itemsPerPage=5&page=${subscriptionHistoryCurrentPage}`,
      ),
    );
  }, []);

  const fetchMoreDataListenHistory = () => {
    setTimeout(() => {
      dispatch(
        getUserListenHistory(
          `${BASE_URL}/${userId}/sessions?itemsPerPage=${
            5 * (listenHistoryCurrentPage + 1)
          }&page=${1}`,
        ),
      );
      setListenHistoryCurrentPage(listenHistoryCurrentPage + 1);
    }, 500);
  };

  const fetchMoreDataSubscriptionHistory = () => {
    dispatch(
      getUserSubscriptionHistory(
        `${BASE_URL}/${userId}/invoices?itemsPerPage=${
          5 * (subscriptionHistoryCurrentPage + 1)
        }&page=${1}`,
      ),
    );
    setSubscriptionHistoryCurrentPage(subscriptionHistoryCurrentPage + 1);
  };

  const showUserPlan = useCallback(() => {
    if (user === undefined) {
      return <Skeleton width={100} height={20} />;
    }
    if (user?.isFree) {
      return 'Free';
    }
    if (!user?.isFree && user?.latestBillingPlan?.isTrial) {
      return 'Trial';
    }
    if (!user?.isFree && !user?.latestBillingPlan?.isTrial) {
      return 'Paid';
    }

    return '-';
  }, [user]);

  return (
    <>
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
        <div className="content_decor" />
        <div className="pb-3 pb-md-4 align-items-center">
          <h1 className="headline-decor">App Users - {user?.name}</h1>
        </div>

        <div className="container-fluid content-inner mb-4">
          <div className="row p-3 p-xl-4 p-xxl-5">
            <div className="user-page__preview-col d-flex align-items-center col-12 col-lg-5 col-xl-3 p-0 pe-lg-4 pe-xxl-5 mb-4 mb-xl-0 text-center">
              <div>
                <div className="user-page__avatar mb-2 d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={user?.media?.url || userPageAvatar}
                    alt="John Smith"
                    className="w-100"
                  />
                </div>
                <span className="label-regular-1">{user?.name}</span>
                <span className="d-inline-block font-light mb-3 text-truncate w-100">
                  {user?.email}
                </span>
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-2"
                  data-bs-toggle="modal"
                  data-bs-target="#provide_subscription"
                  onClick={() => dispatch(getUserDetail(userId))}
                >
                  Provide a subscription
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#user_settings"
                  onClick={() => dispatch(getUserDetail(userId))}
                >
                  Settings
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-7 col-xl-9 p-0 ps-lg-4 ps-xxl-5">
              <div className="row h-100">
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-3">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3">{user?.totalRevenue}</h2>
                    <span className="label-regular-1">$ Revenue</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-3">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3 text-truncate">
                      {user?.source ? user?.source : '-'}
                    </h2>
                    <span className="label-regular-1">Source</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-3">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3">
                      {user?.formattedTimeSpent || '0s'}
                    </h2>
                    <span className="label-regular-1">Time Spent</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-3 mb-xl-0">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3">{user?.usage}</h2>
                    <span className="label-regular-1">% Usage</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 mb-3 mb-sm-0">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3">{showUserPlan()}</h2>
                    <span className="label-regular-1">Account Type</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                  <div className="d-flex flex-column align-items-center justify-content-center px-3 py-4 bg-color-gray-200 text-center radius-16 h-100">
                    <h2 className="color-secondary font-bold mb-3 text-truncate">
                      {user?.devices
                        ? uniq(user.devices.map((device) => device?.type.toLowerCase())).join(', ')
                        : '-'}
                    </h2>
                    <span className="label-regular-1">Device</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-3 pb-md-4 align-items-center">
          <h3>History</h3>
        </div>

        <div className="container-fluid content-inner overflow-hidden">
          <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
            <Search value={searchInput} onInputChange={handleFilterUsers} />
            <div className="col-12 ps-0" />
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <div className="table-responsive">
                <table className="table no-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Detail</th>
                      <th>Time & Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appHistory &&
                      appHistory.map((histor, index) => (
                        <tr key={`key${histor?.detail}`}>
                          <td>{histor?.type}</td>
                          <td>{histor?.detail}</td>
                          <td>{format(new Date(histor?.updatedAt), 'MM/dd/yyyy')}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <br />

              <h4 className="text-secondary mr-4" style={{ padding: '10px' }}>
                Listen History{' '}
                <a onClick={() => setCollapseListen(!collapseListen)}>
                  {collapseListen ? (
                    <i className="fa fa-arrow-circle-up" style={{ margin: '1px' }} />
                  ) : (
                    <i className="fa fa-arrow-circle-down" style={{ margin: '1px' }} />
                  )}
                </a>
              </h4>

              {collapseListen && (
                <InfiniteScroll
                  dataLength={listenHistory?.length}
                  next={() => fetchMoreDataListenHistory()}
                  hasMore={listenHistoryCurrentPage * 5 < totalListenHistory}
                  loader={
                    <div className="row ">
                      <div className="col d-flex justify-content-center my-5">
                        <div
                          className="spinner-grow spinner-grow-sm text-primary m-1"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow spinner-grow-sm  text-primary m-1"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow spinner-grow-sm  text-primary m-1"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  }
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>The end....</b>
                    </p>
                  }
                  height={300}
                  scrollableTarget="scrollListen"
                  className="scrolling"
                >
                  <div className="table-responsive">
                    <table className="table no-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Detail</th>
                          <th>Narrator</th>
                          <th>Time & Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listenHistory &&
                          listenHistory.map((listenHistoryData: any) => (
                            <tr key={listenHistoryData.id}>
                              <td>
                                {listenHistoryData.session.type === 'upgrade'
                                  ? 'upgrade'
                                  : listenHistoryData.session.type}
                              </td>
                              <td>{listenHistoryData.session.title}</td>
                              <td>{listenHistoryData.narrator.name}</td>
                              <td>
                                {listenHistoryData.playedAt &&
                                  format(new Date(listenHistoryData.playedAt), 'MM/dd/yyyy')}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              )}

              <br />
              <h4 className="text-secondary mr-4" style={{ padding: '10px' }}>
                Subscription History{' '}
                <a onClick={() => setCollapseSubscription(!collapseSubscription)}>
                  {collapseSubscription ? (
                    <i className="fa fa-arrow-circle-up" style={{ margin: '1px' }} />
                  ) : (
                    <i className="fa fa-arrow-circle-down" style={{ margin: '1px' }} />
                  )}
                </a>
              </h4>

              {collapseSubscription && (
                <InfiniteScroll
                  dataLength={subscriptionHistory?.length}
                  next={() => fetchMoreDataSubscriptionHistory()}
                  hasMore={subscriptionHistoryCurrentPage * 5 < totalSubscriptionHistory}
                  loader={
                    <div className="container">
                      <div className="row ">
                        <div className="col d-flex justify-content-center my-5">
                          <div
                            className="spinner-grow spinner-grow-sm text-primary m-1"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow spinner-grow-sm  text-primary m-1"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow spinner-grow-sm  text-primary m-1"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  height={300}
                  scrollableTarget="scrollableDiv"
                  className="scrolling"
                >
                  <div className="table-responsive">
                    <table className="table no-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Invoice No</th>
                          <th>Type</th>
                          <th>Detail</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptionHistory &&
                          subscriptionHistory.map((subscriptionHistoryData: any) => (
                            <tr key={subscriptionHistoryData.id}>
                              <td>{subscriptionHistoryData.invoiceNo}</td>
                              <td>{subscriptionHistoryData.billingPlan?.subscription?.type}</td>
                              <td>
                                {subscriptionHistoryData.billingPlan?.subscription?.description
                                  ? subscriptionHistoryData.billingPlan?.subscription?.description
                                  : subscriptionHistoryData.billingPlan?.mobileMetaData
                                      ?.description}{' '}
                                {subscriptionHistoryData.isTrial ? '(Trial)' : ''}
                              </td>
                              <td>
                                {subscriptionHistoryData.startedAt &&
                                  format(new Date(subscriptionHistoryData.startedAt), 'MM/dd/yyyy')}
                              </td>
                              <td>
                                {subscriptionHistoryData.endedAt
                                  ? format(new Date(subscriptionHistoryData.endedAt), 'MM/dd/yyyy')
                                  : '-'}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              )}
              <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                <div className="col-6 ps-0">
                  <div className="d-flex">
                    <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div className="d-flex justify-content-end">
                    <Pagination
                      onPageChange={handlePageChange}
                      totalCount={historyTotalItems || 0}
                      currentPage={currentPage}
                      pageSize={numberOfRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppUserSettings
        userId={userId}
        exploreAppSetting={exploreAppSetting}
        setExploreAppSetting={setExploreAppSetting}
      />
      <AppUserProvideSubscription userId={userId} onSubscriptionProvided={handleLoadAppUser} />
    </>
  );
}
export default AppUsersExplore;
