import React from 'react';
import classNames from 'classnames';
import { BadgeCategory } from '../../constants';

interface BadgeCategoryDisplayProps extends BadgeCategory {
  onSelected: (badgeCat: BadgeCategory) => any;
}

function BadgeCategoryDisplay(badgeCatProps: BadgeCategoryDisplayProps) {
  const { onSelected, ...badge } = badgeCatProps;
  const { title, media } = badge;
  return (
    <div
      className="badge cat"
      role="button"
      tabIndex={-1}
      onClick={() => onSelected(badge)}
      onKeyUp={() => onSelected(badge)}
    >
      <div className="cattitle">{title}</div>
      <img
        src={media.url}
        alt={title}
        width={100}
        className={classNames('achieved' in badge && badge.achieved !== true && 'unachieved')}
      />
    </div>
  );
}

BadgeCategoryDisplay.defaultProps = {
  forProgress: false,
};

export default BadgeCategoryDisplay;
