import React, { useMemo, useContext, useState } from 'react';
import { ROLES } from '../constants';
import { verifyUser } from './thunks/currentUserThunk';
import { useAppSelector, useAppDispatch } from './hooks';
import { TeamInfo } from './interface/currentUserInterface';

export interface AuthContextType {
  token: string;
  permissions: ROLES[];
  isEndUser: boolean;
  isSuperuser: boolean;
  isTeamOwner: boolean;
  teamOwner: TeamInfo | null;
  isAuthenticated: () => boolean;
  isOnboarded: boolean;
  isFree: boolean;
  linkMinkRefData: string;
  isTeamMember: undefined | boolean;
}

const AuthContext = React.createContext<AuthContextType>({
  token: '',
  permissions: [ROLES.USER],
  isEndUser: true,
  isSuperuser: false,
  isTeamOwner: false,
  isOnboarded: false,
  isFree: true,
  teamOwner: null,
  isAuthenticated: () => false,
  linkMinkRefData: '',
  isTeamMember: undefined,
});

interface AuthLayerProps {
  children: React.ReactNode;
}

function AuthLayer({ children }: AuthLayerProps) {
  const [verified, setVerified] = useState(false);
  const dispatch = useAppDispatch();
  if (
    window.localStorage.getItem('token') &&
    !verified &&
    window.location.pathname !== '/sign-up'
  ) {
    setVerified(true);
    dispatch(verifyUser());
  }

  // const { LinkMink }: any = window;
  // const linkMink = LinkMink(process.env.REACT_APP_LINK_MINK_PUB_KEY);
  // linkMink.initTracking();
  // const linkMinkRefData = linkMink.getReferralData();
  const {
    token,
    roles: permissions,
    teamOwner,
    isOnboarded,
    isFree,
    isTeamMember,
  } = useAppSelector((state) => state.currentUser);

  const authprops = useMemo(() => {
    const isAuthenticated = () => token !== '';

    const isEndUser = permissions.includes(ROLES.USER);
    const isSuperuser = permissions.some((permission) =>
      [ROLES.OWNER, ROLES.ADMIN].includes(permission),
    );
    const isTeamOwner = teamOwner !== null;

    return {
      token,
      permissions,
      isEndUser,
      isSuperuser,
      isAuthenticated,
      isTeamOwner,
      teamOwner,
      isOnboarded,
      isFree,
      isTeamMember,
      linkMinkRefData: '',
    };
  }, [token, permissions]);

  return <AuthContext.Provider value={authprops}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext<AuthContextType>(AuthContext);

export default AuthLayer;
