import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { NewCourse, EditCourses } from '../interface/courseInterface';
import { PageParameters } from '../interface/channelsInterface';

export const createCourses = createAsyncThunk(
  'adminUsers/createCourse',
  async (course: NewCourse, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/courses', course);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchCourses = createAsyncThunk('adminUsers/fetchCourses', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response?.data['hydra:view'];
  const data = {
    totalItems: response.data['hydra:totalItems'],
    courses: response.data['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const fetchCourseChannels = createAsyncThunk(
  'adminUsers/fetchCourseChannels',
  async ({ page, itemsPerPage, filters }: PageParameters = { page: 1, itemsPerPage: 10 }) => {
    const urlparams = new URLSearchParams({
      page: page.toString(),
      itemsPerPage: itemsPerPage.toString(),
      type: 'upgrade',
    });
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((filterKey) => urlparams.append(filterKey, filters[filterKey]));
    }
    const response = await apiClient.getRequest(`/api/courses?${urlparams}`);
    const data = {
      totalItems: response.data['hydra:totalItems'],
      courses: response.data['hydra:member'],
    };
    return data;
  },
);

export const deleteCourse = createAsyncThunk(
  'appUsers/deleteCourse',
  async (courseId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/courses/${courseId}`);
      const data = { data: response.data, courseId };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const manageCourseSessions = createAsyncThunk(
  'adminUsers/manageCoursesSession',
  async (courseId: string) => {
    const response = await apiClient.getRequest(`/courses/${courseId}/sessions`);
    const data = {
      totalItems: response.data['hydra:totalItems'],
      courseSessions: response.data['hydra:member'],
    };
    return data;
  },
);

export const updateCourse = createAsyncThunk(
  'adminUsers/updateCourse',
  async (course: EditCourses, { rejectWithValue }) => {
    try {
      const requestBody = {
        title: course.title,
        subtitle: course.subtitle,
        tags: course.tags,
        courseSessions: course.courseSessions,
        image: course.image,
        isDayCourse: course.isDayCourse,
      };
      const response = await apiClient.putRequest(`/api/courses/${course.id}`, requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
