import { createSlice } from '@reduxjs/toolkit';
import { SubscriptionsState } from '../interface/subscriptionInterface';
import {
  postSubscriptionInfo,
  getSubscriptionsInfo,
  fetchCustomCheckoutPageInfo,
} from '../thunks/subscriptionThunk';
import { toast } from '../../helpers/toaster';

const initialState: SubscriptionsState = {
  subscriptions: [],
  type: undefined,
  status: undefined,
  error: undefined,
  customSubscription: {},
};

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postSubscriptionInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(postSubscriptionInfo.fulfilled, (state, action) => {
        toast('Subscriptions info api call passed', 'success');
        return {
          ...state,
          status: 'succeeded',
          subscriptions: action.payload,
        };
      })
      .addCase(postSubscriptionInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Subscriptions info api call failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(getSubscriptionsInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getSubscriptionsInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        subscriptions: action.payload['hydra:member'],
      }))
      .addCase(getSubscriptionsInfo.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(fetchCustomCheckoutPageInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchCustomCheckoutPageInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        customSubscription: action.payload,
      }))
      .addCase(fetchCustomCheckoutPageInfo.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }));
  },
});

export default subscriptionSlice.reducer;
