/* eslint-disable no-unneeded-ternary */
import classNames from 'classnames';
import React from 'react';

interface Options {
  name: string;
  value: string | number;
}
interface Props {
  id: string;
  label?: string;
  options: Array<Options>;
  onSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  className?: string;
}

function Dropdown({ label, value, options, onSelection, id, className }: Props) {
  return (
    <div className={classNames('mb-3', className)}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <select
        id={id}
        className="form-control form-select shadow pe-4 me-4"
        defaultValue={value}
        onChange={onSelection}
        aria-label={label}
      >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

Dropdown.defaultProps = {
  label: '',
  className: '',
  value: '',
};

export default Dropdown;
