/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants';
import { toast } from '../../helpers/toaster';
import { InvoicesState, BillingInterface, InvoiceDetail } from '../interface/billingInterface';
import { getInvoicesList, getInvoiceDetail } from '../thunks/billingThunk';

const intialStateInvoice: InvoicesState = {
  invoice: [],
  invoiceDetail: {
    id: '',
    to: '',
    from: '',
    created_at: '',
    paid_at: '',
    price: 0,
    discount: 0,
    subscription: '',
    card_brand: '',
    card_last4: '',
    invoice_pdf: '',
    invoice_no: '',
  },
  totalInvoices: 0,
  loading: { list: undefined, details: undefined },
  error: '',
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
};

const billingState: BillingInterface = {
  invoiceState: intialStateInvoice,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState: billingState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInvoicesList.pending, (state, action) => {
        state.invoiceState.loading.list = THUNK_STATUS.LOADING;
        state.invoiceState.error = undefined;
      })
      .addCase(getInvoicesList.fulfilled, (state, action) => {
        state.invoiceState.loading.list = THUNK_STATUS.SUCCEEDED;
        state.invoiceState.error = undefined;
        state.invoiceState.invoice = action.payload.invoices;
        state.invoiceState.totalInvoices = action.payload.totalItems;
      })
      .addCase(getInvoicesList.rejected, (state, action: any) => {
        state.invoiceState.loading.list = THUNK_STATUS.REJECTED;
        const errorMessage =
          action?.payload || 'Issue in fetching Invoices. Please try again later.';
        state.invoiceState.error = errorMessage;
        toast(errorMessage, 'error');
      })
      .addCase(getInvoiceDetail.pending, (state, action) => {
        state.invoiceState.loading.details = THUNK_STATUS.LOADING;
        state.invoiceState.error = undefined;
      })
      .addCase(getInvoiceDetail.fulfilled, (state, action) => {
        state.invoiceState.loading.details = THUNK_STATUS.SUCCEEDED;
        state.invoiceState.error = undefined;
        state.invoiceState.invoiceDetail = action.payload.data;
      })
      .addCase(getInvoiceDetail.rejected, (state, action: any) => {
        state.invoiceState.loading.details = THUNK_STATUS.REJECTED;
        const errorMessage =
          action?.payload || "Couldn't fetch the invoice details. Please try again later.";
        state.invoiceState.error = errorMessage;
        toast(errorMessage, 'error');
      });
  },
});

export default billingSlice.reducer;
