import apiClient from '../../helpers/api.js';

class sendInvitesTeamMemberCSV {
  static async sendInvites(data: any) {
    const formData = new FormData();
    Object.keys(data).map((attr) => data[attr] !== null && formData.append(attr, data[attr]));
    const response = await apiClient.postRequest('/api/invite-team', formData);
    return response;
  }
}

export default sendInvitesTeamMemberCSV;
