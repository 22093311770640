/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface DragCSVProps {
  setCsv: React.Dispatch<React.SetStateAction<any>>;
}

function DragCSVFileInput({ setCsv }: DragCSVProps) {
  const [selectedFile, setSelectedFile] = useState<any>();

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setCsv(acceptedFiles[0]);
      setSelectedFile(acceptedFiles[0]);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'text/csv': [],
    },
  });

  return (
    <div className="drag-csv-file p-4 p-xl-5 text-center">
      {selectedFile?.type ? (
        <p>{selectedFile.name}</p>
      ) : (
        <i className="drag-csv-file__icon fa-light fa-file-csv color-gray-400 mb-3" />
      )}
      <div {...getRootProps()} className="btn me-2 border-0">
        <input
          {...getInputProps()}
          className="d-flex p-2 text-truncate "
          style={{
            position: 'absolute',
            clip: 'rect(0, 0, 0, 0)',
            pointerEvents: 'none',
          }}
        />
        <h5>Drag a file here</h5>
        <p className="m-0 color-gray-600">
          or{' '}
          <button className="btn outline-none text-primary border-0" type="button">
            {selectedFile?.type ? 'Change File' : 'select a file'}
          </button>{' '}
          from your computer
        </p>
      </div>
    </div>
  );
}

export default DragCSVFileInput;
