import React, { useState, ChangeEvent, useEffect } from 'react';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getInvoiceDetail, getInvoicesList } from '../../redux/thunks/billingThunk';
import Pagination from '../pagination';
import Search from '../search';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import InvoiceDetails, { MODAL_NAME } from '../../containers/userSettings/modals/invoiceDetails';
import ShowRows from '../showRows';
import { formatDate, handlePagination, setParamValue } from '../../helpers';
import ShowPeriod from '../showPeriod';
import { useServices } from '../../redux/servicesLayer';
import ConfirmUnsubscription from '../../containers/userSettings/modals/confirmUnsubscription';
import { THUNK_STATUS } from '../../constants';
import { InvoiceDetail, InvoiceItem } from '../../redux/interface/billingInterface';
import { useBilling } from '../../containers/userSettings/billing';

const BASE_URL = '/api/invoice/all';

export const download = async (invoiceDetail: InvoiceDetail, onComplete: () => void) => {
  try {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = window.localStorage.getItem('token');

    const response = await fetch(`${baseURL}/api/invoice/download/${invoiceDetail.id}`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) throw new Error('Network response was not ok.');

    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = `Invoice-${invoiceDetail.invoice_no}.pdf`;
    if (contentDisposition) {
      const matches = contentDisposition.match(/filename="?(.+)"?/);
      if (matches && matches.length > 1) {
        [, filename] = matches;
      }
    }

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onComplete();
  } catch (error) {
    onComplete();
    throw new Error('Error downloading file');
  }
};

function InvoiceList() {
  const dispatch = useAppDispatch();
  const { currentSubscription } = useServices();
  const [search, setSearch] = useState('');
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPeriod, setCurrentPeriod] = useState(0);
  const { invoice, totalInvoices, invoiceDetail, loading } = useAppSelector(
    (state) => state.billing.invoiceState,
  );

  const { list: status } = loading;
  const { current, next, previous, first, last } = useAppSelector(
    (state) => state.billing.invoiceState.pagination,
  );

  const user = useAppSelector((state) => state.currentUser);

  const handleUnsubscriptionClicked = () => {
    dispatch(
      modalActions.setInfo({
        confirmUnsubscription: {
          id: currentSubscription?.id,
          title: currentSubscription?.description,
        },
      }),
    );
    dispatch(modalActions.setVisibleModal('confirmUnsubscription'));
  };

  const { paymentMethods } = useBilling();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'search', value: e.target.value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };
  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  useEffect(() => {
    if (
      Math.ceil(totalInvoices / numberOfRows) !== 0 &&
      Math.ceil(totalInvoices / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(getInvoicesList(currentUrl));
  }, [currentUrl]);

  function addMonths(date: Date, months: number) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  }

  const handlePeriod = (period: number) => {
    setCurrentPeriod(period);
    setCurrentPage(1);
    const endDate = format(new Date(), 'yyyy-MM-dd');
    const startDate = format(addMonths(new Date(), -period), 'yyyy-MM-dd');

    const queryParams = [
      { param: 'filter[startDate]', value: `${startDate}` },
      { param: 'filter[endDate]', value: `${endDate}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;

    setCurrentUrl(newUrl);
  };

  const handleOpenDetails = () => dispatch(modalActions.setVisibleModal(MODAL_NAME));

  const [view, setView] = useState<number>(0);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  if (triggerDownload) {
    download(invoiceDetail, () => {
      setIsLoading({ ...isLoading, [invoiceDetail.id]: false });
    });
    setTriggerDownload(false);
  }

  const lifetime = currentSubscription?.description.toLowerCase() === 'lifetime membership';

  const renderPricePaid = (invoiceItem: InvoiceItem) => {
    if (invoiceItem.isTrial) {
      return `${invoiceItem.currencySymbol}0.00`;
    }

    const pricePaid = `${invoiceItem.currencySymbol}${invoiceItem.amount.toFixed(2)}`;

    if (invoiceItem.pricePaidDiscounted) {
      return (
        <>
          <del>
            {`${invoiceItem.currencySymbol}
            ${invoiceItem.billingPlan.subscription.price.toFixed(2)}`}
          </del>{' '}
          {pricePaid}
        </>
      );
    }

    return pricePaid;
  };

  const renderRenewalPrice = (invoiceItem: InvoiceItem) => {
    if (invoiceItem.renewalPriceDiscounted) {
      return (
        <>
          <del>
            {invoiceItem.currencySymbol}
            {invoiceItem.billingPlan.subscription.price}
          </del>{' '}
          {invoiceItem.renewalPrice}
        </>
      );
    }
    return invoiceItem.renewalPrice;
  };

  const renderSubscriptionTitle = (invoiceItem: InvoiceItem) => {
    if (invoiceItem.isTrial) {
      return 'Trial';
    }

    const title = capitalize(invoiceItem.billingPlan.subscription.frequency);

    if (invoiceItem.billingPlan.subscription.description.includes('Team')) {
      return `Team ${title}`;
    }

    return title;
  };

  if (status === THUNK_STATUS.LOADING) {
    return (
      <div className="container-fluid content-inner">
        <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
          <div className="col-6 ps-0">
            <div className="d-flex">
              <Skeleton width={350} height={43} />
            </div>
          </div>
          <div className="col-6 pe-0">
            <div className="d-flex justify-content-end">
              <Skeleton width={150} height={44} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td className="font-medium">
                      <Skeleton width={150} height={24} />
                    </td>
                    <td className="font-medium">
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="font-medium">
                      <Skeleton width={70} height={24} />
                    </td>
                    <td className="font-medium text-center">
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="font-medium text-center">
                      <Skeleton width={70} height={24} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton width={35} height={24} />
                    </td>
                    <td>
                      <Skeleton width={150} height={24} />
                    </td>
                    <td>
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="text-center">
                      <Skeleton width={38} height={32} style={{ margin: '0 auto' }} />
                    </td>
                    <td className="actions-cell justify-content-center">
                      <Skeleton width={123} height={32} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={35} height={24} />
                    </td>
                    <td>
                      <Skeleton width={150} height={24} />
                    </td>
                    <td>
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="text-center">
                      <Skeleton width={38} height={32} style={{ margin: '0 auto' }} />
                    </td>
                    <td className="actions-cell justify-content-center">
                      <Skeleton width={123} height={32} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={35} height={24} />
                    </td>
                    <td>
                      <Skeleton width={150} height={24} />
                    </td>
                    <td>
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="text-center">
                      <Skeleton width={38} height={32} style={{ margin: '0 auto' }} />
                    </td>
                    <td className="actions-cell justify-content-center">
                      <Skeleton width={123} height={32} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={35} height={24} />
                    </td>
                    <td>
                      <Skeleton width={150} height={24} />
                    </td>
                    <td>
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="text-center">
                      <Skeleton width={38} height={32} style={{ margin: '0 auto' }} />
                    </td>
                    <td className="actions-cell justify-content-center">
                      <Skeleton width={123} height={32} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton width={35} height={24} />
                    </td>
                    <td>
                      <Skeleton width={150} height={24} />
                    </td>
                    <td>
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="text-center">
                      <Skeleton width={38} height={32} style={{ margin: '0 auto' }} />
                    </td>
                    <td className="actions-cell justify-content-center">
                      <Skeleton width={123} height={32} style={{ margin: '0 auto' }} />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="font-regular">
                      <Skeleton width={180} height={24} />
                    </td>
                    <td className="font-semi-bold color-gray-600">
                      <Skeleton width={30} height={24} />
                    </td>
                    <td />
                    <td />
                    <td className="actions-cell d-flex justify-content-end">
                      <Skeleton width={222} height={44} />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                <div className="col-6 ps-0">
                  <div className="d-flex">
                    <Skeleton width={150} height={44} />
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div className="d-flex justify-content-end">
                    <Skeleton width={150} height={44} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const activeInvoices = (invoice as InvoiceItem[]).filter((inv) => inv.status === 'Active');
  const inactiveInvoices = (invoice as InvoiceItem[]).filter((inv) => inv.status !== 'Active');

  const sortedInvoices = activeInvoices.concat(inactiveInvoices);

  return (
    <>
      <style>
        {`
        .tooltip-refunded {
          position: relative;
        }
        .tooltip-refunded .tooltiptext {
          visibility: hidden;
          background-color: black;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 30px; /* Position the tooltip above the text */
          margin-left: -60px; /* Center the tooltip */
          opacity: 0;
          transition: opacity 0.3s;
        }

        .tooltip-refunded:hover .tooltiptext {
          width: 500px;
          visibility: visible;
          opacity: 1;
        }
      `}
      </style>

      <div className="container-fluid content-inner">
        <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
          <div className="col-6 ps-0">
            <div className="d-flex">
              <Search value={search} onInputChange={handleSearch} />
            </div>
          </div>
          <div className="col-6 pe-0">
            <div className="d-flex justify-content-end">
              <ShowPeriod showPeriod={currentPeriod} onSelection={handlePeriod} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="font-medium">Subscription</th>
                    <th className="font-medium">Status</th>
                    <th className="font-medium">Created At</th>
                    <th className="font-medium" style={{ whiteSpace: 'nowrap' }}>
                      Next Actions
                    </th>
                    <th className="font-medium">Price Paid</th>
                    <th className="font-medium">Renewal Price</th>

                    <th className="font-medium">Invoice Number</th>

                    <th className="font-medium text-center" style={{ width: '50px' }}>
                      View
                    </th>
                    <th className="font-medium text-center" style={{ width: '120px' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedInvoices.map((invoiceItem) => {
                    let color: string;

                    switch (invoiceItem.status) {
                      case 'Active':
                        color = 'green';
                        break;
                      case 'Cancelled':
                        color = 'red';
                        break;
                      default:
                        color = 'black';
                    }

                    return (
                      <tr key={JSON.stringify(invoiceItem)}>
                        <td className="font-medium">{renderSubscriptionTitle(invoiceItem)}</td>
                        {invoiceItem.status === 'Refunded' ? (
                          <td className="font-medium">
                            <div className="tooltip-refunded">
                              <p className="mb-0" style={{ color }}>
                                {invoiceItem.status}
                              </p>
                              <span className="tooltiptext">
                                The refund process may take up to 7 working days
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td className="font-medium">
                            <p className="mb-0" style={{ color }}>
                              {invoiceItem.status}
                            </p>
                          </td>
                        )}
                        <td className="font-medium">{formatDate(invoiceItem?.startedAt)}</td>
                        <td className="font-medium" style={{ whiteSpace: 'nowrap' }}>
                          {invoiceItem.nextAction}
                        </td>
                        <td className="font-medium" style={{ whiteSpace: 'nowrap' }}>
                          {renderPricePaid(invoiceItem)}
                        </td>
                        <td className="font-medium" style={{ whiteSpace: 'nowrap' }}>
                          {renderRenewalPrice(invoiceItem)}
                        </td>

                        <td
                          className="font-medium"
                          style={{ whiteSpace: 'nowrap' }}
                        >{`${invoiceItem.invoiceNo}`}</td>

                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm p-0"
                            onClick={() => {
                              if (invoiceItem?.id) {
                                setView(invoiceItem?.id);
                              }
                              handleOpenDetails();
                            }}
                          >
                            <i className="fa-regular fa-eye font-size-20 p-2" />
                          </button>
                        </td>
                        <td className="actions-cell justify-content-center px-1">
                          <button
                            disabled={isLoading[invoiceItem.id]}
                            type="button"
                            className="btn btn-primary btn-sm d-flex gap-2 align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#loaded_invoice"
                            onClick={async () => {
                              if (invoiceItem?.id) {
                                setIsLoading({ ...isLoading, [invoiceItem.id]: true });
                                await dispatch(getInvoiceDetail(invoiceItem.id));
                                setTriggerDownload(true);
                              }
                            }}
                          >
                            <i className="fa-regular fa-download me-1" />
                            Download
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    {currentSubscription ? (
                      <>
                        <td className="font-regular" colSpan={2}>
                          Subscription Details
                        </td>
                        <td className="font-semi-bold color-gray-600">
                          ${user?.latestBillingPlan?.productPrice}
                        </td>
                        {paymentMethods.length === 0 && (
                          <td className="font-semi-bold" style={{ color: 'red' }}>
                            Please add your credit card
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td />
                        <td />
                      </>
                    )}

                    <td />
                    <td />
                    <td />
                    <td />
                    {paymentMethods.length !== 0 && <td />}

                    {currentSubscription &&
                    !lifetime &&
                    user?.latestBillingPlan &&
                    !user?.latestBillingPlan?.cancelled ? (
                      <td className="actions-cell d-flex justify-content-end px-1">
                        <button
                          type="button"
                          className="btn btn-light px-3 d-flex align-items-center"
                          onClick={handleUnsubscriptionClicked}
                        >
                          <i className="fa-regular fa-bell-slash me-2" />
                          Unsubscribe
                        </button>
                      </td>
                    ) : (
                      <td className="actions-cell d-flex justify-content-end px-1" />
                    )}
                  </tr>
                </tfoot>
              </table>

              <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                <div className="col-md-6 ps-0 mb-4">
                  <div className="d-flex">
                    <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                  </div>
                </div>
                <div className="col-md-6 pe-0">
                  <div className="d-flex justify-content-end">
                    <Pagination
                      onPageChange={handlePageChange}
                      totalCount={totalInvoices || 0}
                      currentPage={currentPage}
                      pageSize={numberOfRows}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InvoiceDetails id={view} />
      <ConfirmUnsubscription />
    </>
  );
}
export default InvoiceList;
