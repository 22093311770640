import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useAuth } from './authLayer';
import { URL_ROUTES } from '../constants/routes';
import { useAppDispatch, useAppSelector } from './hooks';
import { fetchMarketingAddInfo } from './thunks/marketingThunk';

import {
  ChannelInterface,
  SubscriptionFrequency,
  SubscriptionInterface,
  SubscriptionType,
} from './interface/entitiesInterface';
import { getSubscriptionPlans, getChannelsList } from './thunks/entitiesThunk';
import { ProgressAward, SubscriptionPaymentType } from './interface/currentUserInterface';
import { webMarketingAddInterface } from './interface/marketingInterface';

export interface ServicesContextType {
  subscriptions: SubscriptionInterface[];
  singleSubscriptions: SubscriptionInterface[];
  teamSubscriptions: SubscriptionInterface[];
  siblingSubscriptions: SubscriptionInterface[];
  progressAwards: ProgressAward[];
  mainChannels: ChannelInterface[];
  currentSubscription: SubscriptionInterface | null;
  currentSubscriptionIndex: number;
  popularSubscription: SubscriptionInterface | null;
  popularSubscriptionIndex: number;
  subscriptionsLoaded: boolean;
  subscriptionPaymentType: SubscriptionPaymentType | null;
  marketingInfo: webMarketingAddInterface | null;
  isPageDarkMode: (pathname: string) => boolean;
}

export const ServicesContext = React.createContext<ServicesContextType>({
  subscriptions: [],
  mainChannels: [],
  singleSubscriptions: [],
  teamSubscriptions: [],
  siblingSubscriptions: [],
  currentSubscription: null,
  currentSubscriptionIndex: -1,
  popularSubscription: null,
  popularSubscriptionIndex: -1,
  subscriptionsLoaded: false,
  marketingInfo: null,
  isPageDarkMode: () => false,
  progressAwards: [],
  subscriptionPaymentType: null,
});

interface ServicesLayerProps {
  children: React.ReactNode;
}

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '');

function ServicesLayer({ children }: ServicesLayerProps) {
  const dispatch = useAppDispatch();
  const { isEndUser, isAuthenticated } = useAuth();
  const { userId, currentPlan, progressAward, subscriptionPaymentType } = useAppSelector(
    (state) => state.currentUser,
  );
  const location = useLocation();

  const authenticated = useMemo(() => isAuthenticated(), [isAuthenticated]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (userId && isEndUser && authenticated) {
      dispatch(getSubscriptionPlans());
      dispatch(getChannelsList());
      dispatch(fetchMarketingAddInfo());
    }
  }, [authenticated]);

  const { subscriptions, channels: mainChannels } = useAppSelector((state) => state.entities);
  const { marketingInfo } = useAppSelector((state) => state.marketing.webmarketingAddsState);

  const isPageDarkMode = (pathname: string) => [URL_ROUTES.Sleep as string].includes(pathname);

  const serviceValues = useMemo(() => {
    const singleSubscriptions = subscriptions.filter(
      (subscription) => subscription.type === 'single',
    );
    const teamSubscriptions = subscriptions.filter((subscription) => subscription.type === 'team');

    const currentSubscription =
      subscriptions.find((subscription) => subscription.id === currentPlan) || null;

    const siblingSubscriptions =
      currentSubscription?.type === SubscriptionType.Team ? teamSubscriptions : singleSubscriptions;

    const currentSubscriptionIndex = siblingSubscriptions.findIndex(
      (subscription) => subscription.id === currentPlan,
    );

    const popularSubscriptionIndex = singleSubscriptions.findIndex(
      (subscription) => subscription.frequency === SubscriptionFrequency.Year,
    );
    const popularSubscription = singleSubscriptions[popularSubscriptionIndex] || null;

    return {
      subscriptions,
      mainChannels,
      singleSubscriptions,
      teamSubscriptions,
      currentSubscription,
      currentSubscriptionIndex,
      popularSubscription,
      popularSubscriptionIndex,
      siblingSubscriptions,
      subscriptionsLoaded: true,
      marketingInfo,
      isPageDarkMode,
      progressAwards: progressAward,
      subscriptionPaymentType,
    };
  }, [subscriptions, mainChannels, marketingInfo]);

  return <ServicesContext.Provider value={serviceValues}>{children}</ServicesContext.Provider>;
}

export const useServices = () => useContext<ServicesContextType>(ServicesContext);

export default ServicesLayer;
