/* eslint-disable consistent-return */

interface Props {
  modalId: string;
}
export const hideModal = ({ modalId }: Props) => {
  const hide = document?.querySelector(modalId as any);
  hide?.setAttribute('data-bs-dismiss', 'modal');
  hide?.click();
  hide?.removeAttribute('data-bs-dismiss');
};

export const showModal = ({ modalId }: Props) => {
  const show = document?.querySelector(modalId as any);

  show?.setAttribute('data-bs-target', `${modalId}`);
  show?.setAttribute('data-bs-toggle', 'modal');
  show?.click();
  show?.removeAttribute('data-bs-target');
  show?.removeAttribute('data-bs-toggle');
};
