interface Props {
  showPeriod: number;
  onSelection: Function;
}

function ShowPeriod({ showPeriod, onSelection }: Props) {
  const handleSelection = (e: any) => onSelection(e.target.value);

  return (
    <select
      className="form-control form-select form-select__table-rows"
      onChange={handleSelection}
      defaultValue={showPeriod || 0}
    >
      <option value={0} disabled>
        All period
      </option>
      <option value={1}>1 month</option>
      <option value={2}>2 Month</option>
      <option value={4}>4 Month</option>
      <option value={6}>6 Month</option>
      <option value={12}>1 Year</option>
    </select>
  );
}

export default ShowPeriod;
