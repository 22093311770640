import axios, { AxiosError } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8082';

const METHODS = {
  GET: 'GET',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

const axiosCreateProps = {
  baseURL,
  headers: { Accept: 'application/ld+json, application/json, multipart/form-data' },
};

const logoutUser = () => {
  localStorage.removeItem('email');
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('uuid');
};

class ApiClient {
  constructor(axiosInst) {
    this.axios = axiosInst;
  }

  addAuthInstance(axiosInst) {
    this.axiosAuth = axiosInst;
  }

  addAuthPatchInstance(axiosInst) {
    this.axiosAuthPatch = axiosInst;
  }

  addAuthFormDataInstance(axiosInst) {
    this.axiosAuthFormData = axiosInst;
  }

  get authInstance() {
    return this.axiosAuth;
  }

  makeRequest = (url, method, data, params) =>
    this.axiosAuth({
      url,
      method,
      data,
      params,
    }).catch((e) => {
      if (e instanceof AxiosError && e.response.status === 401) {
        logoutUser();
        window.location.href = window.location.origin;
      } else {
        throw e;
      }
    });

  makePatchRequest = (url, data, params) =>
    this.axiosAuthPatch({
      url,
      method: METHODS.PATCH,
      data,
      params,
    }).catch((e) => {
      if (e instanceof AxiosError && e.response.status === 401) {
        logoutUser();
        window.location.href = window.location.origin;
      } else {
        throw e;
      }
    });

  makeFormDataRequest = (url, data, params) =>
    this.axiosAuthFormData({
      url,
      method: METHODS.POST,
      data,
      params,
    }).catch((e) => {
      if (e instanceof AxiosError && e.response.status === 401) {
        logoutUser();
        window.location.href = window.location.origin;
      } else {
        throw e;
      }
    });

  makeNoAuthRequest = (url, method, data, params) =>
    this.axios({
      url,
      method,
      data,
      params,
    });

  getRequest = async (url, config, params) => this.makeRequest(url, METHODS.GET, config, params);

  putRequest = async (url, config) => this.makeRequest(url, METHODS.PUT, config);

  patchRequest = async (url, config) => this.makePatchRequest(url, config);

  postRequest = async (url, config) => this.makeRequest(url, METHODS.POST, config);

  postFormDataRequest = async (url, config) => this.makeFormDataRequest(url, config);

  deleteRequest = (url, config) => this.makeRequest(url, METHODS.DELETE, config);

  getNoAuthRequest = async (url, config, params) =>
    this.makeNoAuthRequest(url, METHODS.GET, config, params);

  putNoAuthRequest = async (url, config) => this.makeNoAuthRequest(url, METHODS.PUT, config);

  postNoAuthRequest = async (url, config) => this.makeNoAuthRequest(url, METHODS.POST, config);

  deleteNoAuthRequest = (url, config) => this.makeNoAuthRequest(url, METHODS.DELETE, config);
}

const axiosInst = axios.create(axiosCreateProps);

const apiClient = new ApiClient(axiosInst);

let axiosAuthInst = null;

const addAuthInstanceToAxios = (token) => {
  axiosAuthInst = axios.create({
    baseURL,
    headers: {
      ...axiosCreateProps.headers,
      Authorization: `Bearer ${token}`,
    },
  });
  apiClient.addAuthInstance(axiosAuthInst);

  const axiosPatchInst = axios.create({
    baseURL,
    headers: {
      ...axiosCreateProps.headers,
      'Content-Type': 'application/merge-patch+json',
      Authorization: `Bearer ${token}`,
    },
  });
  apiClient.addAuthPatchInstance(axiosPatchInst);

  const axiosFormDataInst = axios.create({
    baseURL,
    headers: {
      ...axiosCreateProps.headers,
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  apiClient.addAuthFormDataInstance(axiosFormDataInst);
};

const tokenLoaded = window.localStorage.getItem('token');
if (tokenLoaded) {
  addAuthInstanceToAxios(tokenLoaded);
}

const loginUser = (email, token, userId) => {
  window.localStorage.setItem('email', email);
  window.localStorage.setItem('token', token);
  if (userId) {
    window.localStorage.setItem('userId', userId);
  }
  addAuthInstanceToAxios(token);
};

const errorMessageThrower = (err) => {
  if (err instanceof AxiosError) {
    /* eslint-disable no-param-reassign */

    if (err?.response?.data['hydra:description']) {
      err.message = err?.response?.data['hydra:description'];
    } else if (err?.response?.data?.violations) {
      err.message = err?.response?.data?.violations[0].message;
    } else if (err?.response?.data?.detail) {
      err.message = err?.response?.data?.detail;
    } else if (err?.response?.data?.errors) {
      if (typeof err.response.data.errors === 'string') {
        err.message = err.response.data.errors;
      } else {
        const { errors } = err.response.data;
        err.message = Object.values(errors).find((error) => typeof error === 'string');
      }
    } else {
      err.message = err?.response?.data?.message || err.message;
    }

    /* eslint-enable no-param-reassign */
  }
  return err;
};

export { axiosInst, loginUser, logoutUser, errorMessageThrower };

export default apiClient;
