import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import DragCSVFile from '../../../components/formik/dragCSVFile';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { uploadTeamCSV } from '../../../redux/thunks/userSettingsThunk';
import { THUNK_LOADING, THUNK_STATUS } from '../../../constants';
import { useAuth } from '../../../redux/authLayer';
import { toast } from '../../../helpers/toaster';

const teamUserSample = [
  { name: 'John Doe', email: 'john@doe.com' },
  { name: 'Jane Dae', email: 'jane@dae.com' },
];

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required('Please select your CSV file.'),
});

interface AdminUserCSVFormProps {
  onSubmitSuccess?: () => any;
}

function AdminUserCSVForm({ onSubmitSuccess }: AdminUserCSVFormProps) {
  const dispatch = useAppDispatch();
  const { teamOwner: team } = useAuth();
  const showModal = useAppSelector((state) => state.modal.visible === 'adminUserCSVForm');
  const {
    CSVForm: { status, message: successMessage },
  } = useAppSelector((state) => state.userSettings);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED && team?.id) {
      toast(successMessage || 'Users added.', 'success');
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
      dispatch(modalActions.closeModal());
    }
  }, [status]);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Formik
        initialValues={{ file: null }}
        validationSchema={validationSchema}
        onSubmit={({ file }) => {
          if (team && file) {
            dispatch(uploadTeamCSV({ file, team }));
          }
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="ps-5 pe-5">
              <div className="text-center font-light">
                <h5 className="mb-3">Add New User via CSV</h5>
                <p className="color-gray-600">
                  Download the CSV file template, fill it in with the information about the users
                  you want to add, and then upload the file back to the box below.
                </p>
              </div>

              <CSVLink data={teamUserSample} filename="pmd-sample-team-members.csv">
                <button className="btn btn-primary w-100 mb-4" type="button">
                  <i className="fa-regular fa-file-circle-plus me-1" />
                  &nbsp;Download CSV file template
                </button>
              </CSVLink>

              <Field component={DragCSVFile} name="file" />
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-6 px-1 ps-0">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={status === THUNK_LOADING}
                    >
                      {status === THUNK_LOADING ? (
                        <span
                          className="spinner-border"
                          style={{ width: '1em', height: '1em' }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      ) : (
                        <>
                          <i className="fa-regular fa-upload me-1" />
                          &nbsp;Upload
                        </>
                      )}
                    </button>
                  </div>
                  <div className="col-6 px-1 pe-0">
                    <button type="button" className="btn btn-light w-100" data-bs-dismiss="modal">
                      <i className="fa-regular fa-xmark me-1" />
                      &nbsp;Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

AdminUserCSVForm.defaultProps = {
  onSubmitSuccess: () => {},
};

export default AdminUserCSVForm;
