import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate();
  const handleBackAction = () => {
    navigate(-1);
  };
  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      <div className="py-4 d-flex w-100">
        <button
          type="button"
          onClick={handleBackAction}
          className="btn btn-link text-decoration-none"
        >
          <i className="fa-regular fa-angle-left color-primary me-1" />
          <span className="color-black">Back</span>
        </button>
      </div>
      <div className="onboarding__form-box d-flex flex-column w-100 mt-auto">
        <h3 className="mb-3 mb-lg-4 text-center color-black">Privacy Policy</h3>
        <div className="entry-content">
          <div id="u94-6">
            <p id="u94-2">Privacy Policy – Primed Mind</p>
          </div>
          <div id="u143-11">
            <p>
              <span id="u143-3">Primed Mind USA</span>, 138 E 12300 S Unit #781, Draper, UT 84020
            </p>
            <p>
              <span id="u143-7">Last updated:</span>&nbsp;February 10th, 2023
            </p>
          </div>
          <div id="u152-481">
            <p id="u152-2">1. Introduction</p>
            <p id="u152-5">
              Primed Mind USA (“us”, “we”, or “our”) operates the website and the Primed Mind mobile
              application (the “Service”).
            </p>
            <p id="u152-7">
              This page informs you of our policies regarding the collection, use, and disclosure of
              personal data when you use our Service and the choices you have associated with that
              data.
            </p>
            <p id="u152-9">
              We use your data to provide and improve the Service. By using the Service, you agree
              to the collection and use of information in accordance with this policy. Unless
              otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions.
            </p>
            <p id="u152-11">This policy DOES NOT apply to information that:</p>
            <ul id="u152-16" className="list0 nls-None">
              <li id="u152-13">
                We collect offline or on any other Company apps or websites, including websites you
                may access through Primed Mind
              </li>
              <li id="u152-15">You provide to or is collected by any third party</li>
            </ul>
            <p id="u152-20">2. Definition</p>
            <p id="u152-23">
              The following definitions will be used throughout the the following articles for
              better visibility
            </p>
            <p id="u152-26">Personal Data</p>
            <p id="u152-28">
              Personal Data means data about a living individual who can be identified from those
              data (or from those and other information either in our possession or likely to come
              into our possession).
            </p>
            <p id="u152-31">Usage Data</p>
            <p id="u152-33">
              Usage Data is data collected automatically either generated by the use of the Service
              or from the Service infrastructure itself (for example, the duration of a page visit).
            </p>
            <p id="u152-36">Cookies</p>
            <p id="u152-38">Cookies are small pieces of data stored on a User’s device.</p>
            <p id="u152-41">Data Controller</p>
            <p id="u152-43">
              Data Controller means a person who (either alone or jointly or in common with other
              persons) determines the purposes for which and the manner in which any personal data
              are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data
              Controller of your data.
            </p>
            <p id="u152-45">
              For the purpose of this Privacy Policy, we are a Data Controller of your data.
            </p>
            <p id="u152-48">Data Processor (or Service Providers)</p>
            <p id="u152-50">
              Data Processor (or Service Provider) means any person (other than an employee of the
              Data Controller) who processes the data on behalf of the Data Controller.
            </p>
            <p id="u152-52">
              We may use the services of various Service Providers in order to process your data
              more effectively.
            </p>
            <p id="u152-55">Data Subject</p>
            <p id="u152-57">
              Data Subject is any living individual who is the subject of Personal Data.
            </p>
            <p id="u152-60">User</p>
            <p id="u152-62">
              The User is the individual using our Service. The User corresponds to the Data
              Subject, who is the subject of Personal Data.
            </p>
            <p id="u152-67">3. Information Collection And Use</p>
            <p id="u152-70">
              We collect several different types of information for various purposes to provide and
              improve our Service to you.
            </p>
            <p id="u152-73">Types of Data Collected:</p>
            <p id="u152-76">Personal Data</p>
            <p id="u152-78">
              While using our Service, we may ask you to provide us with certain personally
              identifiable information that can be used to contact or identify you (“Personal
              Data”). Personally identifiable information may include, but is not limited to:
            </p>
            <ul id="u152-91" className="list0 nls-None">
              <li id="u152-80">Email address</li>
              <li id="u152-82">Name (if you choose to enter one)</li>
              <li id="u152-84">
                Facebook profile information such as name and email address (if you choose to log in
                through Facebook)
              </li>
              <li id="u152-86">Cookies and Usage Data</li>
              <li id="u152-88">
                Records and copies of your correspondence (including email addresses and information
                around your device), if you contact us.
              </li>
              <li id="u152-90">Your responses to surveys, if you decide to participate</li>
              <li id="u152-92">User Image information (such as user’s profile pictures)</li>
            </ul>
            <p id="u152-94">
              We may use your Personal Data to contact you with newsletters, marketing or
              promotional materials and other information that may be of interest to you, if you
              opted in while signing up in the mobile application. You may opt out of receiving any,
              or all, of these communications from us by following the unsubscribe link or
              instructions provided in any email we send or by contacting us.
            </p>
            <p id="u152-96">
              You may provide information to be published or displayed (“Posted”) on public areas of
              websites that are related to Primed Mind USA through the Webpage or the App itself
              (collectively, “User Contributions”). Your User Contributions are Posted and
              transmitted to others at your own risk. You Post at your own risk and should be aware
              that no security measures are perfect or impenetrable. Additionally, we cannot control
              the actions of third parties with whom you may choose to share your User
              Contributions. Therefore, we cannot and do not guarantee that your User Contributions
              will not be viewed by unauthorized persons.
            </p>
            <p id="u152-99">Usage Data</p>
            <p id="u152-101">
              We may also collect information that your browser sends whenever you visit our Service
              or when you access the Service by or through a mobile device (“Usage Data”).
            </p>
            <p id="u152-103">
              This Usage Data may include information such as your computer’s Internet Protocol
              address (e.g. IP address), browser type, browser version, the pages of our Service
              that you visit, the time and date of your visit, the time spent on those pages, unique
              device identifiers and other diagnostic data.
            </p>
            <p id="u152-105">
              When you access the Service by or through a mobile device, this Usage Data may include
              information such as the type of mobile device you use, your mobile device unique ID,
              the IP address of your mobile device, your mobile operating system, the type of mobile
              Internet browser you use, unique device identifiers and other diagnostic data.
            </p>
            <p id="u152-108">Tracking Cookies Data</p>
            <p id="u152-110">
              We use cookies and similar tracking technologies to track the activity on our Service
              and hold certain information.
            </p>
            <p id="u152-112">
              Cookies are files with small amount of data which may include an anonymous unique
              identifier. Cookies are sent to your browser from a website and stored on your device.
              Tracking technologies also used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>
            <p id="u152-114">
              You can instruct your browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be able to use some
              portions of our Service.
            </p>
            <p id="u152-118">4. Use of Data</p>
            <p id="u152-121">
              Primed Mind USA uses the collected data for various purposes that are closely
              connected to the mobile application
            </p>
            <ul id="u152-134" className="list0 nls-None">
              <li id="u152-123">To provide and maintain our Service</li>
              <li id="u152-125">To notify you about changes to our Service</li>
              <li id="u152-127">
                To allow you to participate in interactive features of our Service when you have
                opted to receive this type of information
              </li>
              <li id="u152-129">To provide customer support</li>
              <li id="u152-131">
                To provide you with news, special offers and general information about other goods,
                services and events which we offer that are similar to those that you have already
                purchased or enquired about unless you have opted not to receive such information
              </li>
              <li id="u152-133">
                Notify you when App updates are available, and of changes to any products or
                services we offer or provide though it
              </li>
            </ul>
            <p id="u152-137">
              The usage information we collect helps us to improve our App and to deliver a better
              and more personalized experience by enabling us:
            </p>
            <ul id="u152-149" className="list0 nls-None">
              <li id="u152-140">
                To further develop content in both length and topic that fits your needs
              </li>
              <li id="u152-142">
                To gather analysis or valuable information so that we can improve our Service
              </li>
              <li id="u152-144">To monitor the usage of our Service</li>
              <li id="u152-146">
                Store information about your preferences, allowing us to customize our App according
                to your individual interests
              </li>
              <li id="u152-148">To detect, prevent and address technical issues</li>
            </ul>
            <p id="u152-153">5. Retention of Data</p>
            <p id="u152-156">
              Primed Mind USA will retain your Personal Data only for as long as is necessary for
              the purposes set out in this Privacy Policy. We will retain and use your Personal Data
              to the extent necessary to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p id="u152-158">
              Primed Mind USA will also retain Usage Data for internal analysis purposes. Usage Data
              is generally retained for a shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of our Service, or we are
              legally obligated to retain this data for longer time periods.
            </p>
            <p id="u152-162">6. Transfer Of Data</p>
            <p id="u152-165">
              Your information, including Personal Data, may be transferred to — and maintained on —
              computers located outside of your state, province, country or other governmental
              jurisdiction where the data protection laws may differ from those from your
              jurisdiction.
            </p>
            <p id="u152-167">
              If you are located outside the USA and by signing up and accepting the Terms of
              Service and this Privacy Policy choose to provide information to us, please note that
              we transfer the data, including Personal Data, to the USA and other locations to
              process it there.
            </p>
            <p id="u152-169">
              Your consent to this Privacy Policy followed by your submission of such information
              represents your agreement to that transfer.
            </p>
            <p id="u152-171">
              Primed Mind USA will take all steps reasonably necessary to ensure that your data is
              treated securely and in accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of your data and other personal
              information.
            </p>
            <p id="u152-175">7. Disclosure Of Data</p>
            <p id="u152-178">Disclosure for Law Enforcement</p>
            <p id="u152-180">
              Under certain circumstances, Primed Mind USA may be required to disclose your Personal
              Data if required to do so by law or in response to valid requests by public
              authorities (e.g. a court or a government agency).
            </p>
            <p id="u152-183">Legal Requirements</p>
            <p id="u152-185">
              Primed Mind USA may disclose your Personal Data in the good faith belief that such
              action is necessary to:
            </p>
            <ul id="u152-204" className="list0 nls-None">
              <li id="u152-187">To comply with a legal obligation</li>
              <li id="u152-189">To protect and defend the rights or property of Primed Mind USA</li>
              <li id="u152-191">
                To prevent or investigate possible wrongdoing in connection with the Service
              </li>
              <li id="u152-193">
                To protect the personal safety of users of the Service or the public
              </li>
              <li id="u152-195">To protect against legal liability</li>
              <li id="u152-201">
                To enforce our rights arising from any contracts entered into between you and us,
                including the App EULA, which are shown here:&nbsp;
                <a
                  className="nonblock"
                  href="https://primedmind.com/termsofservice"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://primedmind.com/termsofservice
                </a>
                &nbsp;and for billing and collection.
              </li>
              <li id="u152-203">
                To a buyer or other successor in the event of a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some or all of the
                Company’s assets, whether as a going concern or as part of bankruptcy, liquidation,
                or similar proceeding, in which personal information held by us about our App users
                is among the assets transferred.
              </li>
            </ul>
            <p id="u152-208">8. Security Of Data</p>
            <p id="u152-211">
              We have implemented measures designed to secure your personal information from
              accidental loss and from unauthorized access, use, alteration, and disclosure. Any
              payment transactions will be encrypted using SSL technology.Unfortunately, the
              transmission of information via the internet and mobile platforms is not completely
              secure.
            </p>
            <p id="u152-213">
              The safety and security of your information also depends on you. Where we have given
              you (or where you have chosen) a password for access to certain parts of our App, you
              are responsible for keeping this password confidential. We ask you not to share your
              password with anyone.
            </p>
            <p id="u152-215">
              Although we do our best to protect your personal information, we cannot guarantee the
              security of your personal information transmitted through our App. Any transmission of
              personal information is at your own risk. We are not responsible for circumvention of
              any privacy settings or security measures we provide.
            </p>
            <p id="u152-219">9. Your Rights</p>
            <p id="u152-222">
              Primed Mind USA aims to take reasonable steps to allow you to correct, amend, delete,
              or limit the use of your Personal Data.
            </p>
            <p id="u152-224">
              Whenever made possible, you can update your Personal Data directly within your account
              settings section. If you are unable to change your Personal Data, please contact us to
              make the required changes.
            </p>
            <p id="u152-226">
              If you wish to be informed what Personal Data we hold about you and if you want it to
              be removed from our systems, please contact us.
            </p>
            <p id="u152-228">In certain circumstances, you have the right:</p>
            <p id="u152-231">To access and receive a copy of the Personal Data we hold about you</p>
            <p id="u152-233">To rectify any Personal Data held about you that is inaccurate</p>
            <p id="u152-235">To request the deletion of Personal Data held about you</p>
            <p id="u152-237">
              You have the right to data portability for the information you provide to Primed Mind
              USA. You can request to obtain a copy of your Personal Data in a commonly used
              electronic format so that you can manage and move it.
            </p>
            <p id="u152-239">
              Please note that we may ask you to verify your identity before responding to such
              requests.
            </p>
            <p id="u152-245">
              California Civil Code Section 1798.83 permits users of our App that are California
              residents to request certain information regarding our disclosure of personal
              information to third parties for their direct marketing purposes. To make such a
              request, please send an email to &nbsp;
              <a className="nonblock" href="mailto:support@primedmind.com">
                support@primedmind.com
              </a>
              .
            </p>
            <p id="u152-249">10. Service Providers</p>
            <p id="u152-252">
              We may employ third party companies and individuals to facilitate our Service
              (“Service Providers”), to provide the Service on our behalf, to perform
              Service-related services or to assist us in analyzing how our Service is used.
            </p>
            <p id="u152-254">
              These third parties have access to your Personal Data only to perform these tasks on
              our behalf and are obligated not to disclose or use it for any other purpose. Our
              third party partners have been chosen with care and are fully compliant with GDPR.
            </p>
            <p id="u152-258">Sign Up</p>
            <p id="u152-260">
              We may use third-party Service Providers to handle the Sign-Up Process.
            </p>
            <p id="u152-263">Facebook</p>

            <p id="u152-265">This Service is provided by Facebook Inc.</p>
            <p id="u152-267">
              If you use Facebook as your method of logging into Primed Mind, it forwards your email
              address and name information to our data storage and your account data will be linked
              to this entry.
            </p>
            <p id="u152-273">Analytics</p>
            <p id="u152-275">
              We may use third-party Service Providers to monitor and analyze the use of our Service
              that is fully described in 4.
            </p>

            <p id="u152-278">Firebase</p>
            <p id="u152-280">Firebase is analytics service provided by Google Inc.</p>
            <p id="u152-285">
              You may opt-out of certain Firebase features through your mobile device settings, such
              as your device advertising settings or by following the instructions provided by
              Google in their Privacy Policy:&nbsp;
              <a
                className="nonblock"
                href="http://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.com/intl/en/policies/privacy/
              </a>
            </p>

            <p id="u152-294">
              We also encourage you to review the Google’s policy for safeguarding your data:&nbsp;
              <a
                className="nonblock"
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
              . For more information on what type of information Firebase collects, please visit
              please visit the Google Privacy Terms web page:&nbsp;
              <a
                className="nonblock"
                href="http://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.google.com/intl/en/policies/privacy/
              </a>
            </p>

            <p id="u152-297">Mixpanel</p>
            <p id="u152-299">Mixpanel is provided by Mixpanel Inc</p>
            <p id="u152-304">
              You can prevent Mixpanel from using your information for analytics purposes by
              opting-out. To opt-out of Mixpanel service, please visit this page:&nbsp;
              <a
                className="nonblock"
                href="https://mixpanel.com/optout/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mixpanel.com/optout/
              </a>
            </p>
            <p id="u152-309">
              For more information on what type of information Mixpanel collects, please visit the
              Terms of Use page of Mixpanel:&nbsp;
              <a
                className="nonblock"
                href="https://mixpanel.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mixpanel.com/terms/
              </a>
            </p>

            <p id="u152-312">Sentry</p>
            <p id="u152-314">Sentry is provided by Sentry Inc.</p>
            <p id="u152-320">
              You can prevent Sentry from using your information for analytics purposes by
              opting-out. To opt-out of Sentry service, please visit this page:&nbsp;
              <a
                className="nonblock"
                href="https://sentry.io/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://sentry.io/privacy/
              </a>
            </p>
            <p id="u152-321" />
            <p id="u152-323">Appsflyer</p>
            <p id="u152-325">Appsflyer is provided by Appsflyer Inc.</p>
            <p id="u152-331">
              You can prevent Appsflyer from using your information for analytics purposes by
              opting-out. To opt-out of Appsflyer service, please visit this page:&nbsp;
              <a
                className="nonblock"
                href="https://www.appsflyer.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.appsflyer.com/privacy-policy/
              </a>
            </p>
            <p id="u152-332" />
            <p id="u152-334">Facebook</p>
            <p id="u152-336">Facebook Analytics is provided by Facebook Inc.</p>
            <p id="u152-341">
              You can prevent Facebook from using your information for analytics purposes by
              opting-out. To opt-out of Facebook service, please visit this page:&nbsp;
              <a
                className="nonblock"
                href="https://www.facebook.com/about/privacy/update"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/about/privacy/update
              </a>
            </p>
            <p id="u152-347">Behavioral Remarketing</p>
            <p id="u152-349">
              Primed Mind USA uses remarketing services to advertise on third party websites to you
              after you visited our Service. We and our third-party vendors use cookies to inform,
              optimize and serve ads based on your past visits to our Service.
            </p>

            <p id="u152-352">Twitter</p>
            <p id="u152-354">Twitter remarketing service is provided by Twitter Inc.</p>
            <p id="u152-359">
              You can opt-out from Twitter’s interest-based ads by following their
              instructions:&nbsp;
              <a
                className="nonblock"
                href="https://support.twitter.com/articles/20170405"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.twitter.com/articles/20170405
              </a>
            </p>
            <p id="u152-364">
              You can learn more about the privacy practices and policies of Twitter by visiting
              their Privacy Policy page:&nbsp;
              <a
                className="nonblock"
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/privacy
              </a>
            </p>
            <p id="u152-365" />
            <p id="u152-367">Facebook</p>
            <p id="u152-369">Facebook remarketing service is provided by Facebook Inc.</p>
            <p id="u152-374">
              You can learn more about interest-based advertising from Facebook by visiting this
              page:&nbsp;
              <a
                className="nonblock"
                href="https://www.facebook.com/help/164968693837950"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/help/164968693837950
              </a>
            </p>
            <p id="u152-379">
              To opt-out from Facebook’s interest-based ads follow these instructions from
              Facebook:&nbsp;
              <a
                className="nonblock"
                href="https://www.facebook.com/help/568137493302217"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/help/568137493302217
              </a>
            </p>
            <p id="u152-393">
              Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising
              established by the Digital Advertising Alliance. You can also opt-out from Facebook
              and other participating companies through the Digital Advertising Alliance in the
              USA&nbsp;
              <a
                className="nonblock"
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.aboutads.info/choices/
              </a>
              , the Digital Advertising Alliance of Canada in Canada&nbsp;
              <a
                className="nonblock"
                href="http://youradchoices.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://youradchoices.ca/
              </a>
              &nbsp;or the European Interactive Digital Advertising Alliance in Europe&nbsp;
              <a
                className="nonblock"
                href="http://www.youronlinechoices.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.youronlinechoices.eu/
              </a>
              , or opt-out using your mobile device settings.
            </p>
            <p id="u152-398">
              For more information on the privacy practices of Facebook, please visit Facebook’s
              Data Policy:&nbsp;
              <a
                className="nonblock"
                href="https://www.facebook.com/privacy/explanation"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/privacy/explanation
              </a>
            </p>

            <p id="u152-403">11. Payments</p>
            <p id="u152-406">
              We may provide paid products and/or services within the Service. In that case, we use
              third-party services for payment processing (e.g. payment processors).
            </p>
            <p id="u152-408">
              We will not store or collect your payment card details. That information is provided
              directly to our third-party payment processors whose use of your personal information
              is governed by their Privacy Policy. These payment processors adhere to the standards
              set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
              requirements help ensure the secure handling of payment information.
            </p>
            <p id="u152-411">The payment processors we work with are:</p>
            <p id="u152-414">Apple Store In-App Payments</p>
            <p id="u152-420">
              Their Privacy Policy can be viewed at&nbsp;
              <a
                className="nonblock"
                href="https://www.apple.com/legal/privacy/en-ww/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.apple.com/legal/privacy/en-ww/
              </a>
            </p>
            <p id="u152-423">Google Play In-App Payments</p>
            <p id="u152-429">
              Their Privacy Policy can be viewed at&nbsp;
              <a
                className="nonblock"
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/policies/privacy/
              </a>
            </p>
            <p id="u152-433">12. Links To Other Sites</p>
            <p id="u152-436">
              Our Service may contain links to other sites that are not operated by us. If you click
              on a third party link, you will be directed to that third party’s site. We strongly
              advise you to review the Privacy Policy of every site you visit.
            </p>
            <p id="u152-438">
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </p>
            <p id="u152-442">13. Children’s Privacy</p>
            <p id="u152-445">
              Our Service does not address anyone under the age of 16 (“Children”).
            </p>
            <p id="u152-447">
              We do not knowingly collect personally identifiable information from anyone under the
              age of 16. If you are a parent or guardian and you are aware that your Children has
              provided us with Personal Data, please contact us. If we become aware that we have
              collected Personal Data from children without verification of parental consent, we
              take steps to remove that information from our servers.
            </p>
            <p id="u152-451">14. Changes To This Privacy Policy</p>
            <p id="u152-454">
              We may update our Privacy Policy from time to time. We will notify you of any changes
              by posting the new Privacy Policy on this page.
            </p>
            <p id="u152-456">
              We will let you know via email and/or a prominent notice on our Service, prior to the
              change becoming effective and update the “effective date” at the top of this Privacy
              Policy.
            </p>
            <p id="u152-458">
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </p>
            <p id="u152-463">15. Contact Us</p>
            <p id="u152-470">
              If you have any questions about this Privacy Policy, please contact us by email
              via&nbsp;
              <a className="nonblock" href="mailto:support@primedmind.com">
                support@primedmind.com
              </a>
              .
            </p>
            <p id="u152-475">
              You can also visit this page on our website:&nbsp;
              <a
                className="nonblock"
                href="https://primedmind.com/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://primedmind.com/privacypolicy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
