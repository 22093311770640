import { PaginationMetas, THUNK_STATUS } from '../../constants';
import { UserInfo } from './currentUserInterface';
import { FormState } from './formStatusInterface';

export enum CreditCardType {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
}

export enum CreditCardPriority {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'third',
}

export interface PaymentCredential {
  id: number;
  name: string;
  brand: CreditCardType;
  last4Digit: string;
  expiryMonth: number;
  expiryYear: number;
  isPrimary: boolean;
}

export interface Invoice {
  id: number;
  date: string;
  amount: number;
}

export interface UserFormInfo {
  userId: number;
  name: string;
  email: string;
}

export interface UserSettingsState {
  loading: { [key: string]: THUNK_STATUS | undefined };
  adOpened: boolean;
  cardForm: FormState;
  updateSeats: FormState;
  cards: PaymentCredential[];
  teamMembers: {
    list: UserInfo[];
    form: FormState;
    metas: PaginationMetas;
  };
  addSeats: {
    seats: number;
    price: number;
    seatPrice: number;
    actualSeatPrice: number;
  };
  CSVForm: FormState;
  unsubscribe: FormState;
  subscribe: FormState;
  modalOpen: boolean | undefined;
  modalName: string | undefined;
}

export interface TeamUsersURLPager {
  teamId: string;
  page: string;
  itemsPerPage: string;
  email?: string;
  name?: string;
}
