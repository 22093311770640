import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { AddNewChannelInterface, PageParameters } from '../interface/channelsInterface';

export const getChannels = createAsyncThunk('channels/fetchAll', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response?.data['hydra:view'];

  const data = {
    totalItems: response.data['hydra:totalItems'],
    channels: response.data['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const getChannelsForChannel = createAsyncThunk(
  'channels/fetchChannelsForChannel',
  async ({ page, itemsPerPage, filters }: PageParameters, { rejectWithValue }) => {
    try {
      const urlparams = new URLSearchParams({
        page: page.toString(),
        itemsPerPage: itemsPerPage.toString(),
      });
      if (filters && Object.keys(filters).length) {
        Object.keys(filters).forEach((filterKey) =>
          urlparams.append(filterKey, filters[filterKey]),
        );
      }
      const response = await apiClient.getRequest(`/api/channels?${urlparams.toString()}`);

      const data = {
        totalItems: response.data['hydra:totalItems'],
        channels: response.data['hydra:member'],
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getDeepPrimerChannels = createAsyncThunk(
  'channels/fetchAllDeepPrimer',
  async ({ page, itemsPerPage, filters }: PageParameters, { rejectWithValue }) => {
    try {
      const urlparams = new URLSearchParams({
        page: page.toString(),
        itemsPerPage: itemsPerPage.toString(),
        type: 'deep-primer',
      });
      if (filters && Object.keys(filters).length) {
        Object.keys(filters).forEach((filterKey) =>
          urlparams.append(filterKey, filters[filterKey]),
        );
      }
      const response = await apiClient.getRequest(`/api/sessions?${urlparams}`);

      const data = {
        totalItems: response.data['hydra:totalItems'],
        deepPrimer: response.data['hydra:member'],
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getPowerPrimerChannels = createAsyncThunk(
  'channels/fetchAllPowerPrimer',
  async ({ page, itemsPerPage, filters }: PageParameters, { rejectWithValue }) => {
    try {
      const urlparams = new URLSearchParams({
        page: page.toString(),
        itemsPerPage: itemsPerPage.toString(),
        type: 'power-primer',
      });
      if (filters && Object.keys(filters).length) {
        Object.keys(filters).forEach((filterKey) =>
          urlparams.append(filterKey, filters[filterKey]),
        );
      }
      const response = await apiClient.getRequest(`/api/sessions?${urlparams}`);

      const data = {
        totalItems: response.data['hydra:totalItems'],
        powerPrimer: response.data['hydra:member'],
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getCoursesChannels = createAsyncThunk(
  'channels/fetchAllCourses',
  async ({ page, itemsPerPage, filters }: PageParameters, { rejectWithValue }) => {
    try {
      const urlparams = new URLSearchParams({
        page: page.toString(),
        itemsPerPage: (itemsPerPage + 1000).toString(),
        type: 'upgrade',
      });
      if (filters && Object.keys(filters).length) {
        Object.keys(filters).forEach((filterKey) =>
          urlparams.append(filterKey, filters[filterKey]),
        );
      }
      const response = await apiClient.getRequest(`/api/sessions?${urlparams}`);

      const data = {
        totalItems: response.data['hydra:totalItems'],
        courses: response.data['hydra:member'],
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteChannel = createAsyncThunk(
  'channels/deleteOne',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/channels/${id}`);
      const data = {
        data: response.data,
        channelId: id,
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const updateChannels = createAsyncThunk(
  'channel/updateChannel',
  async (channel: any, { rejectWithValue }) => {
    try {
      const { id, ...restOfChannel } = channel;
      const response = await apiClient.putRequest(`/api/channels/${id}`, restOfChannel);
      if (Object.keys(restOfChannel).length > 1) {
        if (response?.status === 200) {
          window.history.back();
        }
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const createChannels = createAsyncThunk(
  'channel/createChannel',
  async (channel: AddNewChannelInterface, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/channels', channel);
      if (response?.status === 201) {
        window.history.back();
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
