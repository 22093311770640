/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import { addNewTeamMember } from '../../../redux/thunks/teamThunk';
import { NewTeamMemberType } from '../team';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface AddNewTeamMemberProps {
  newTeamMember: NewTeamMemberType;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter team member email address.'),
  // name: Yup.string().required('Please enter team member name'),
});

function AddNewTeamMember({ newTeamMember }: AddNewTeamMemberProps) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.team);
  const dispatch = useAppDispatch();
  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_new_team_member"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={{
          email: newTeamMember?.email,
          name: newTeamMember?.name,
          team: newTeamMember?.team,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(
            addNewTeamMember({
              email: values?.email,
              name: values?.name,

              team: values?.team,
            }),
          );
        }}
      >
        {({ handleSubmit, setValues, setTouched }) => {
          useEffect(() => {
            if (status === 'succeeded' && modalName === 'addNewTeamMember') {
              hideModal({ modalId: '#closeModal_newTeamMember' });
              setTouched({});
              setValues({
                name: '',
                email: '',
                team: newTeamMember?.team,
              });
            }
          }, [modalOpen]);

          return (
            <form>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setTouched({});
                        setValues({
                          name: '',
                          email: '',
                          team: newTeamMember?.team,
                        });
                      }}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Add new Team</h4>
                    </div>
                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="email"
                        name="email"
                        description="email"
                        placeholder="E-mail of team’s member"
                        label="Email *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="name"
                        description="name"
                        placeholder="Name"
                        label="Name *"
                      />
                    </div>
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_newTeamMember"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden ">Loading...</span>
                              </span>
                            ) : (
                              <div
                                role="button"
                                onClick={() => handleSubmit()}
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Invite Member
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setTouched({});
                              setValues({
                                name: '',
                                email: '',
                                team: newTeamMember?.team,
                              });
                            }}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddNewTeamMember;
