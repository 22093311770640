import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient, { errorMessageThrower } from '../../helpers/api.js';
import {
  UserInfo,
  LoginCredentials,
  OAuthCredentials,
  RegistrationFields,
  ResetPasswordFields,
} from '../interface/currentUserInterface';

export const userLogin = createAsyncThunk(
  'user/invokeLogin',
  async (loginCredentials: LoginCredentials) => {
    try {
      const response = await apiClient.postNoAuthRequest('/api/login', loginCredentials);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const userOAuth = createAsyncThunk(
  'user/invokeOauth',
  async (oauthCredentials: OAuthCredentials) => {
    try {
      const response = await apiClient.postNoAuthRequest('/api/social-auth', oauthCredentials);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const verifyUser = createAsyncThunk('user/verify', async () => {
  const response = await apiClient.getRequest('/api/user');
  return response.data;
});

export const registerNewUser = createAsyncThunk(
  'user/register',
  async (registrationValues: RegistrationFields) => {
    try {
      const response = await apiClient.postNoAuthRequest('/api/registration', registrationValues);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const requestResetPassword = createAsyncThunk(
  'user/reset-password',
  async (email: string) => {
    try {
      const response = await apiClient.postNoAuthRequest('/api/reset/password', { email });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const requestResetPasswordToken = createAsyncThunk(
  'user/reset-password-token',
  async (resetPasswordFields: ResetPasswordFields) => {
    try {
      const response = await apiClient.postNoAuthRequest(
        '/api/reset/password/token',
        resetPasswordFields,
      );
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const patchCurrentUser = createAsyncThunk(
  'user/patch-current-user',
  async (
    {
      userId,
      ...userInfo
    }: Omit<
      UserInfo,
      'roles' | 'createdAt' | 'updatedAt' | 'lastLogin' | 'listens' | 'formattedTimeSpent'
    >,
    { rejectWithValue },
  ) => {
    try {
      const response = await apiClient.patchRequest(`/api/users/${userId}`, userInfo);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteCurrentUser = createAsyncThunk(
  'user/delete-current-user',
  async (userId: number) => {
    try {
      const response = await apiClient.deleteRequest(`/api/users/${userId}`);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const setCurrentUserOnboarded = createAsyncThunk(
  'user/set-onboarded',
  async (userId: number) => {
    try {
      const response = await apiClient.patchRequest(`/api/users/${userId}`, { isOnboarded: true });
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);
