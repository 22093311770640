import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { NewSession, EditSession } from '../interface/sessionInterface.js';
import sessions from '../services/sessions';

export const createSessions = createAsyncThunk(
  'adminUsers/createSession',
  async (session: NewSession, { rejectWithValue }) => {
    try {
      const response = await sessions.createSessions(session);
      return response;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteNarrator = createAsyncThunk(
  'adminUsers/deleteNarrator',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/narrators/${id}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchSession = createAsyncThunk('adminUsers/fetchSession', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response?.data['hydra:view'];
  const data = {
    totalItems: response.data['hydra:totalItems'],
    sessions: response.data['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const deleteSession = createAsyncThunk(
  'adminUsers/deleteSession',
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/sessions/${sessionId}`);
      const data = { data: response.data, sessionId };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const updateSession = createAsyncThunk(
  'adminUsers/updateSession',
  async (session: EditSession, { rejectWithValue }) => {
    try {
      const requestBody = {
        id: session.id,
        title: session.title,
        description: session.description,
        isFree: session.isFree,
        type: session.type,
        tags: session.tags,
        image: session.image,
        narrators: session.narrators,
      };
      const response = sessions.EditSessions(requestBody);
      return response;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
