import { createSlice } from '@reduxjs/toolkit';
import { MarketingState } from '../interface/discountInterface';
import { fetchDiscountInfo, saveDiscountInfo } from '../thunks/discountThunk';
import { toast } from '../../helpers/toaster';

const initialState: MarketingState = {
  discountInfo: {
    startAt: '',
    endAt: '',
    discount: 0,
    type: '',
    status: '',
    device: [],
  },
  status: undefined,
  error: undefined,
};

export const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDiscountInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchDiscountInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        discountInfo: action.payload,
      }))
      .addCase(fetchDiscountInfo.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(saveDiscountInfo.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(saveDiscountInfo.fulfilled, (state, action) => {
        toast('Saved discount info successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          discountInfo: action.payload,
        };
      })
      .addCase(saveDiscountInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Saving discount info failed';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default discountSlice.reducer;
