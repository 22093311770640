import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions as modalActions } from '../redux/slices/modalSlice';
import { THUNK_LOADING, THUNK_STATUS } from '../constants';
import { deleteCurrentUser } from '../redux/thunks/currentUserThunk';
import { toast } from '../helpers/toaster';

export const MODAL_NAME = 'confirmDeleteAccount';

function ConfirmDeleteAccount() {
  const dispatch = useAppDispatch();
  const {
    userId,
    deleteUserInfo: { status },
  } = useAppSelector((state) => state.currentUser);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const handleCloseModal = () => {
    dispatch(modalActions.setVisibleModal('editProfile'));
  };

  const handleConfirmDelete = () => userId && dispatch(deleteCurrentUser(userId));

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      toast('Account Removed. Thank you for using Primed Mind.', 'success');
      dispatch(modalActions.closeModal());
    }
  }, [status]);

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h5 className="mb-3">Warning: Deleting your account</h5>
          <p className="color-gray-600">
            Are you sure you want to delete your account? You won&quot;t be able to recover your
            progress in future.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 pe-1">
              <button
                type="button"
                className="btn btn-danger w-100"
                onClick={handleConfirmDelete}
                disabled={status === THUNK_LOADING}
              >
                {status === THUNK_LOADING ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  'Yes, Delete'
                )}
              </button>
            </div>
            <div className="col-6 px-1 ps-1">
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={handleCloseModal}
                data-bs-dismiss="modal"
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteAccount;
