import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import OnBoardingLogoBox from '../../components/onBoardinLogoBox';
import { useServices } from '../../redux/servicesLayer';
import CheckoutForm from './checkoutForm';
import { SubscriptionType } from '../../redux/interface/entitiesInterface';
import { SubscriptionPaymentType } from '../../redux/interface/currentUserInterface';
import ErrorAlert from '../../components/errorAlert';
import { fetchCustomCheckoutPageInfo } from '../../redux/thunks/subscriptionThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSubscriptionPlans } from '../../redux/thunks/entitiesThunk';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

function CustomCheckout() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { subscriptionPaymentType } = useServices();
  const { uuid } = params;

  const customSubscription = useAppSelector((state) => state.subscriptions.customSubscription);
  const cancelled = useAppSelector((state) => state?.currentUser?.latestBillingPlan?.cancelled);
  const subscriptionInfo = { ...customSubscription, ...customSubscription.subscription };
  useEffect(() => {
    if (uuid) {
      dispatch(fetchCustomCheckoutPageInfo(uuid));
      dispatch(getSubscriptionPlans());
      localStorage.removeItem('uuid');
    }
  }, []);

  const mode = { type: 'checkout', seat: 5 };

  const ineligibleWebAppSubscriber =
    !cancelled &&
    subscriptionPaymentType !== null &&
    [SubscriptionPaymentType.IOS, SubscriptionPaymentType.Android].includes(
      subscriptionPaymentType,
    );

  const renderContent = () =>
    customSubscription.subscription &&
    (ineligibleWebAppSubscriber ? (
      <ErrorAlert
        errorMessage="It looks like you have an active subscription purchased through a mobile device. Please switch to the mobile app to make updates to your subscription. If you are attempting to create or upgrade a subscription and see this message, please email"
        supportEmail="support@primedmind.com"
      />
    ) : (
      <CheckoutForm
        subscriptionInfo={subscriptionInfo}
        subscriptionType={subscriptionInfo.type as SubscriptionType}
        mode={mode}
      />
    ));

  return (
    <Elements stripe={stripe}>
      <div className="container-fluid onboarding">
        <div className="row">
          <div className="d-none d-md-flex col-md-6 p-0">
            <OnBoardingLogoBox />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
              {subscriptionInfo ? (
                renderContent()
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                >
                  <span className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default CustomCheckout;
