/* eslint-disable no-param-reassign */
import { PAGES } from '../constants';

interface SetParams {
  param: string;
  value: string;
}

interface Pagination {
  current: string;
  first: string | undefined;
  last: string | undefined;
  next: string | undefined;
  previous: string | undefined;
  baseURL: string;
  pageNumber: string;
  page: string;
}

export const getParamValue = (url: string, param: string) => {
  const searchParams = url.split('?')[1];
  const queryParams = new URLSearchParams(searchParams);
  const value = queryParams.get(param);
  return value;
};

export const setParamValue = (url: string, paramsToUpdate: SetParams[]) => {
  const searchParams = url.split('?')[1];
  const queryParams = new URLSearchParams(searchParams);
  paramsToUpdate.forEach((query) => {
    if (!query.value) {
      queryParams.delete(query.param);
    } else {
      queryParams.set(query.param, query.value);
    }
  });
  return queryParams.toString();
};

export const handlePagination = (pagination: Pagination) => {
  const { current, first, last, next, previous, baseURL, pageNumber, page } = pagination;
  let currentURL = current;
  switch (page) {
    case PAGES.FIRST:
      if (first) currentURL = first;

      break;
    case PAGES.LAST:
      if (last) currentURL = last;
      break;
    case PAGES.NEXT:
      if (next) currentURL = next;
      break;
    case PAGES.PREVIOUS:
      if (previous) currentURL = previous;
      break;
    case PAGES.PAGE_NUMBER: {
      const queryParams = [{ param: 'page', value: `${pageNumber}` }];
      const newQueryParams = setParamValue(current, queryParams);
      const newURL = `${baseURL}?${newQueryParams}`;
      currentURL = newURL;
      break;
    }
    default:
      currentURL = current;
      break;
  }
  return currentURL;
};

export const swapElement = (list: any[], indexA: number, indexB: number) => {
  const tmp = list[indexA];
  list[indexA] = list[indexB];
  list[indexB] = tmp;
  return list;
};

export const getCookie = (key: string) => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const formatNumberToDecimals = (number: number | string) => {
  const parts = number.toString().split('.');
  if (parts.length === 2) {
    const decimalPart = parts[1].slice(0, 2);
    return `${parts[0]}.${decimalPart}`;
  }
  return number.toString();
};

export const formatNumber = (number: any) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number)) {
    return null;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
};

export function formatDate(dateString: string) {
  // Note: getMonth() returns 0-11, so add 1 for the correct month
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as MM/DD/YYYY
  const formattedDate = `${month.toString().padStart(2, '0')}/${day
    .toString()
    .padStart(2, '0')}/${year}`;

  return formattedDate;
}
