/* eslint-disable no-unused-vars */
import { ChangeEvent } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  value: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function Search({ value, onInputChange, className }: Props) {
  return (
    <div className={classNames('search', className)}>
      <i className="search__icon d-flex fa-light a-regular fa-magnifying-glass" />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        aria-label="Search"
        aria-describedby="basic-addon1"
        value={value}
        onChange={onInputChange}
      />
    </div>
  );
}

Search.defaultProps = {
  className: '',
};

export default Search;
