import { generatePath } from 'react-router-dom';

export enum URL_ROUTES {
  Home = '/',
  Login = '/login',
  SignUp = '/sign-up',
  RestorePassword = '/restore-password',
  ResetPassword = '/reset/password',
  AdminUsers = '/admin-users',
  Marketing = '/marketing',
  ComingSoon = '/coming-soon',
  CompanyDetails = '/subscriptions',
  SubscriptionTypes = '/subscription-types',
  Teams = '/teams',
  Team = '/team',
  Sessions = '/sessions',
  AppUsers = '/app-users',
  AppUsersExplore = '/app-user-explore',
  Upgrades = '/upgrade',
  ManageCourses = '/manage-courses',
  Channels = '/channels',
  EditChannel = '/edit-channel',
  CreateChannel = '/create-new-channel',
  SevenDayCourses = '/upgrades',
  Billing = '/account/billing',
  TeamUsers = '/account/team-users',
  // SubscriptionPlans = '/account/subscription-plans',
  Explore = '/explore/:channelId/:channelSlug',
  Sleep = '/sleep',
  Player = '/play/:sessionId/:listType/:parentId',
  PlayerNoParentId = '/play/:sessionId/:listType',
  PrivacyPolicy = '/privacypolicy',
  TermsOfService = '/termsofservice',
  Checkout = '/checkout/:subscribeType/:subscribeId',
  SharePlay = '/share/play/:sessionToken',
  CheckOutPages = '/custom-checkout-pages',
  CustomCheckOutPage = '/checkout/:uuid',
  CancelSub = '/cancel-subscription/:id',
  ThankYou = '/thank-you',
}

export const PolicyUrls = [URL_ROUTES.PrivacyPolicy, URL_ROUTES.TermsOfService];

export const LoginAreaUrls = [
  URL_ROUTES.Login,
  URL_ROUTES.SignUp,
  URL_ROUTES.ResetPassword,
  URL_ROUTES.RestorePassword,
  ...PolicyUrls,
];

export const darkSections = [
  generatePath(URL_ROUTES.Explore, {
    channelSlug: '',
    channelId: 'sleep',
  }),
];
