import { createAsyncThunk } from '@reduxjs/toolkit';
import { condenseChannel } from '../../helpers/entityHelpers';
import apiClient, { errorMessageThrower } from '../../helpers/api.js';

export const getCourseList = createAsyncThunk('entities/courses/list', async () => {
  try {
    const response = await apiClient.getRequest('/api/courses/details');
    return response.data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const getSessionInfo = createAsyncThunk(
  'entities/session/info',
  async (sessionId: number) => {
    try {
      const response = await apiClient.getRequest(`/api/sessions/${sessionId}`);
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getChannelsList = createAsyncThunk('entities/channels/list', async () => {
  try {
    const response = await apiClient.getRequest('/api/explore');
    return response.data['hydra:member'].map(condenseChannel);
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const updateChannelsOrder = createAsyncThunk(
  'entities/updateChannelsOrder',
  async (data: any) => {
    try {
      const requestBody = { sort: data };
      const response = await apiClient.postRequest('/api/channels/sort', requestBody);
      return response.data['hydra:member'].map(condenseChannel);
    } catch (error) {
      throw errorMessageThrower(error);
    }
  },
);

export const getChannelInfo = createAsyncThunk(
  'entities/channels/info',
  async (channelId: number) => {
    try {
      const response = await apiClient.getRequest(`/api/channels/${channelId}`);
      return condenseChannel(response.data);
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getCourseInfo = createAsyncThunk('entities/courses/info', async (courseId: number) => {
  try {
    const response = await apiClient.getRequest(`/api/courses/${courseId}`);
    return response.data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const getSleepChannel = createAsyncThunk('entities/sleepchannel/info', async () => {
  try {
    const response = await apiClient.getRequest('/api/sleep-channel');
    return condenseChannel(response.data);
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const searchChannelSessions = createAsyncThunk(
  'entities/channels/searchChannelSessions',
  async (query: string) => {
    try {
      const response = await apiClient.getRequest(`/api/channels/search?${query}`);
      return response.data['hydra:member'];
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getSubscriptionPlans = createAsyncThunk('entities/subscriptions/list', async () => {
  try {
    const response = await apiClient.getRequest('/api/subscription/list');
    /* eslint-disable camelcase */
    return response.data['hydra:member'].map(({ discounted_price, ...subscr }: any) => ({
      ...subscr,
      discountedPrice: discounted_price || 0,
      trialDays: subscr.trial_days || 0,
    }));
    /* eslint-disable camelcase */
  } catch (err) {
    throw errorMessageThrower(err);
  }
});
