import { CourseInterface, SessionInterface } from '../redux/interface/entitiesInterface';

export const addTempCourseImage = (courses: CourseInterface[]) =>
  courses.map(({ id, ...course }) => ({
    ...course,
    description: course.subtitle,
    id,
  }));

enum PrimerType {
  Power = 'powerPrimer',
  Deep = 'deepPrimer',
}

export const sessionUsersToSessions = (
  sessionUsersArray: Array<{ session: SessionInterface }>,
  mergeWithParent: boolean = false,
) => {
  if (sessionUsersArray.length) {
    return sessionUsersArray.map(({ session, ...others }: any) =>
      !mergeWithParent ? session : { ...others, ...session },
    );
  }
  return sessionUsersArray;
};

export const condenseChannel = (channel: any) => ({
  ...channel,
  courses: addTempCourseImage(channel.courses || []),
  powerPrimer: sessionUsersToSessions(channel[PrimerType.Power] || []),
  deepPrimer: sessionUsersToSessions(channel[PrimerType.Deep] || []),
});
