import React, { useCallback } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { getTouchErrorByField } from '../../helpers/formik';

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
}

function DragCSVFile({ field, form }: Props) {
  const { touched, error } = getTouchErrorByField(field.name, form);

  const { setFieldValue } = form;

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    setFieldValue(`${field?.name}`, acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'text/csv': [],
    },
  });
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      {...getRootProps()}
      className={classNames(
        'drag-csv-file p-4 p-xl-5 text-center',
        touched && error && ' is-invalid',
      )}
    >
      {touched && error && <div className="invalid-feedback">{error as string}</div>}
      {!field.value ? (
        <>
          <i className="drag-csv-file__icon fa-light fa-file-csv color-gray-400 mb-3" />
          <h5>Drag a file here</h5>
          <p className="m-0 color-gray-600">
            or&nbsp;
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              select a file
              <input {...getInputProps()} />
            </label>
            &nbsp;from your computer
          </p>
        </>
      ) : (
        <>
          <i className="drag-csv-file__icon fa-light fa-file-csv color-gray-400 mb-3" />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <h5>
            File selected: {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              {field.value?.name}
              <input {...getInputProps()} />
            </label>
          </h5>
        </>
      )}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default DragCSVFile;
