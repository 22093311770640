/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createChannels, updateChannels } from '../../../redux/thunks/channelsThunk';
import { AddNewChannelInterface } from '../../../redux/interface/channelsInterface';
import { actions as modalActions } from '../../../redux/slices/modalSlice';

interface Props {
  channel: AddNewChannelInterface;
  type: string;
}

const MODAL_NAME = 'SaveUnpublishChannelModal';

function SaveUnpublishChannel({ channel, type }: Props) {
  const dispatch = useAppDispatch();
  const { status, modalName } = useAppSelector((state) => state.channel);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);
  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const handleClick = () => {
    if (type === 'create') {
      dispatch(createChannels(channel));
    } else {
      dispatch(updateChannels(channel));
    }
  };

  useEffect(() => {
    if (
      status === 'succeeded' &&
      (modalName === 'createChannel' || modalName === 'updateChannel')
    ) {
      dispatch(modalActions.closeModal());
    }
  }, [status]);
  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h5 className="mb-3">Save unpublished Channel?</h5>
          <p className="color-gray-600">Are you sure you want to save unpublished channel?</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 ps-0">
              <button
                type="button"
                onClick={handleClick}
                className="btn btn-primary w-100"
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  <span style={{ display: 'block', width: '100%', boxSizing: 'border-box' }}>
                    Save Unpublished
                  </span>
                )}
              </button>
            </div>
            <div className="col-6 px-1 pe-0">
              <button
                type="button"
                className="btn btn-outline-primary w-100"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveUnpublishChannel;
