/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { ChannelState } from '../interface/channelsInterface';
import { toast } from '../../helpers/toaster';

import {
  getChannels,
  deleteChannel,
  updateChannels,
  createChannels,
  getDeepPrimerChannels,
  getPowerPrimerChannels,
  getCoursesChannels,
  getChannelsForChannel,
} from '../thunks/channelsThunk';
import { THUNK_STATUS } from '../../constants';

const initialState: ChannelState = {
  channelsArray: [
    {
      id: '',
      title: '',
      subtitle: '',
      description: '',
      slug: '',
      tags: [],
      isSleep: false,
      isPublished: false,
      courses: [],
      channels: [],
      deepPrimer: [],
      powerPrimer: [],
      file: '',
      category: '',
    },
  ],
  channelsScroll: [],
  channelsScrollPagination: { current: 1, size: 10 },
  primers: {
    status: undefined,
    error: undefined,
    deep: [],
    power: [],
    pagination: {
      deep: { current: 1, size: 10 },
      power: { current: 1, size: 10 },
    },
  },
  allCourses: [],
  allCoursesPagination: { current: 1, size: 10 },
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    clearPrimers: (state) => ({
      ...state,
      primers: {
        status: undefined,
        error: undefined,
        deep: [],
        power: [],
        pagination: {
          ...initialState.primers.pagination,
        },
      },
    }),
    clearCoursesForChannel: (state) => ({
      ...state,
      allCourses: [],
      allCoursesPagination: {
        ...initialState.allCoursesPagination,
      },
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(getChannels.pending, (state, action) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getChannels.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(getChannels.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        pagination: action.payload.pagination,
        channelsArray: action.payload.channels,
        totalItems: action.payload.totalItems,
      }))
      .addCase(getDeepPrimerChannels.pending, (state, action) => ({
        ...state,
        primers: {
          ...state.primers,
          status: 'loading',
          error: undefined,
          pagination: {
            ...state.primers.pagination,
            deep: {
              current: action.meta.arg?.page || 1,
              size: action.meta.arg?.itemsPerPage || 10,
              filters: action.meta.arg?.filters || undefined,
              status: THUNK_STATUS.LOADING,
            },
          },
        },
      }))
      .addCase(getDeepPrimerChannels.rejected, (state, action: any) => {
        const errorMessage = action?.payload || state.error;
        toast(errorMessage, 'error');
        return {
          ...state,
          primers: {
            ...state.primers,
            status: 'failed',
            error: errorMessage,
            pagination: {
              ...state.primers.pagination,
              deep: {
                ...state.primers.pagination.deep,
                status: THUNK_STATUS.REJECTED,
              },
            },
          },
        };
      })
      .addCase(getDeepPrimerChannels.fulfilled, (state, action) => {
        const pagination = {
          ...state.primers.pagination.deep,
          status: THUNK_STATUS.SUCCEEDED,
          total: action.payload.totalItems,
        };

        const deep =
          pagination.current === 1
            ? action.payload.deepPrimer
            : [...state.primers.deep, ...action.payload.deepPrimer];

        return {
          ...state,
          primers: {
            ...state.primers,
            status: 'succeeded',
            error: undefined,
            deep,
            pagination: {
              ...state.primers.pagination,
              deep: pagination,
            },
          },
        };
      })
      .addCase(getPowerPrimerChannels.pending, (state, action) => ({
        ...state,
        primers: {
          ...state.primers,
          status: 'loading',
          error: undefined,
          pagination: {
            ...state.primers.pagination,
            power: {
              current: action.meta.arg?.page || 1,
              size: action.meta.arg?.itemsPerPage || 10,
              filters: action.meta.arg?.filters || undefined,
              status: THUNK_STATUS.LOADING,
            },
          },
        },
      }))
      .addCase(getPowerPrimerChannels.rejected, (state, action: any) => {
        const errorMessage = action?.payload || state.error;
        toast(errorMessage, 'error');
        return {
          ...state,
          primers: {
            ...state.primers,
            status: 'failed',
            error: errorMessage,
            pagination: {
              ...state.primers.pagination,
              power: {
                ...state.primers.pagination.power,
                status: THUNK_STATUS.REJECTED,
              },
            },
          },
        };
      })
      .addCase(getPowerPrimerChannels.fulfilled, (state, action) => {
        const pagination = {
          ...state.primers.pagination.power,
          status: THUNK_STATUS.SUCCEEDED,
          total: action.payload.totalItems,
        };

        const power =
          pagination.current === 1
            ? action.payload.powerPrimer
            : [...state.primers.power, ...action.payload.powerPrimer];

        return {
          ...state,
          primers: {
            ...state.primers,
            status: 'succeeded',
            error: undefined,
            power,
            pagination: {
              ...state.primers.pagination,
              power: pagination,
            },
          },
        };
      })
      .addCase(getCoursesChannels.pending, (state, action) => ({
        ...state,
        error: undefined,
        allCoursesPagination: {
          current: action.meta.arg?.page || 1,
          size: action.meta.arg?.itemsPerPage || 10,
          filters: action.meta.arg?.filters || undefined,
          status: THUNK_STATUS.LOADING,
        },
      }))
      .addCase(getCoursesChannels.rejected, (state, action: any) => {
        const errorMessage = action?.payload || state.error;
        toast(errorMessage, 'error');
        return {
          ...state,
          error: errorMessage,
          allCoursesPagination: {
            ...state.allCoursesPagination,
            status: THUNK_STATUS.REJECTED,
          },
        };
      })
      .addCase(getCoursesChannels.fulfilled, (state, action) => {
        const allCoursesPagination = {
          ...state.allCoursesPagination,
          status: THUNK_STATUS.SUCCEEDED,
          total: action.payload.totalItems,
        };

        const allCourses =
          allCoursesPagination.current === 1
            ? action.payload.courses
            : [...state.allCourses, ...action.payload.courses];

        return {
          ...state,
          error: undefined,
          allCourses,
          allCoursesPagination,
        };
      })
      .addCase(getChannelsForChannel.pending, (state, action) => ({
        ...state,
        error: undefined,
        channelsScrollPagination: {
          current: action.meta.arg?.page || 1,
          size: action.meta.arg?.itemsPerPage || 10,
          filters: action.meta.arg?.filters || undefined,
          status: THUNK_STATUS.LOADING,
        },
      }))
      .addCase(getChannelsForChannel.rejected, (state, action: any) => {
        const errorMessage = action?.payload || state.error;
        toast(errorMessage, 'error');
        return {
          ...state,
          error: errorMessage,
          channelsScrollPagination: {
            ...state.channelsScrollPagination,
            status: THUNK_STATUS.REJECTED,
          },
        };
      })
      .addCase(getChannelsForChannel.fulfilled, (state, action) => {
        const channelsScrollPagination = {
          ...state.channelsScrollPagination,
          status: THUNK_STATUS.SUCCEEDED,
          total: action.payload.totalItems,
        };

        const channelsScroll =
          channelsScrollPagination.current === 1
            ? action.payload.channels
            : [...state.channelsScroll, ...action.payload.channels];

        return {
          ...state,
          error: undefined,
          channelsScroll,
          channelsScrollPagination,
        };
      })
      .addCase(deleteChannel.pending, (state, action) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteChannel.rejected, (state, action: any) => {
        const errorMessage = action?.payload || state.error;
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteChannel.fulfilled, (state, action) => {
        toast('Channel deleted successfully', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          channelsArray: state.channelsArray.filter(
            (channel) => channel.id !== action.payload.channelId,
          ),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(updateChannels.pending, (state, action) => ({
        ...state,
        modalName: 'updateChannel',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(updateChannels.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error Creating Channel';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(updateChannels.fulfilled, (state, action) => {
        toast('Updated channel successfully', 'success');
        const indexToUpdate = findIndex(
          state.channelsArray,
          (channel) => channel.id === action.payload.id,
        );
        const newUpdatedChannel = [...state.channelsArray];
        newUpdatedChannel[indexToUpdate] = action.payload;
        return {
          ...state,
          modalOpen: false,
          status: 'succeeded',
          error: undefined,
          channelsArray: newUpdatedChannel,
        };
      })
      .addCase(createChannels.pending, (state, action) => ({
        ...state,
        modalName: 'createChannel',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(createChannels.fulfilled, (state, action) => {
        toast('Created channel successfully', 'success');
        return {
          ...state,
          modalOpen: false,
          status: 'succeeded',
          error: undefined,
          channelsArray: [...state.channelsArray, action.payload],
          totalItems: state.totalItems + 1,
        };
      })
      .addCase(createChannels.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error Creating Channel';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export const { actions } = channelsSlice;

export default channelsSlice.reducer;
