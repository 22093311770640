import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Badge } from '../../constants';

interface BadgeDisplayProps extends Badge {
  forProgress?: boolean;
  noTitle?: boolean;
}

function BadgeDisplay(badgeDisplayProps: BadgeDisplayProps) {
  const { title, media, forProgress, noTitle, description } = badgeDisplayProps;
  const iconSize = forProgress ? 67 : 64;

  const renderTooltip = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props}>{description || title}</Tooltip>
  );

  const renderAddTooltip = (component: ReactElement) =>
    description || title ? (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        {component}
      </OverlayTrigger>
    ) : (
      component
    );

  return renderAddTooltip(
    <div className={classNames('badge', forProgress && 'forprogress', noTitle && 'mb-5')}>
      <img
        src={media.url}
        alt={title}
        /* className={classNames(noTitle && 'achieved' in badgeDisplayProps && achieved !== true && 'unachieved')} */
        width={noTitle ? 100 : iconSize}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(!noTitle ? { height: iconSize } : {})}
      />
      {/* !noTitle && <div className="ribbon">{title}</div> */}
    </div>,
  );
}

BadgeDisplay.defaultProps = {
  forProgress: false,
  noTitle: false,
};

export default BadgeDisplay;
