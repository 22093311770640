import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import reactSelect from 'react-select';
import { debounce } from 'lodash';

import { PrimerType, PageParameters } from '../../../redux/interface/channelsInterface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { THUNK_STATUS } from '../../../constants';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { getDeepPrimerChannels, getPowerPrimerChannels } from '../../../redux/thunks/channelsThunk';

interface Props {
  primerType: PrimerType;
  selectedPrimers: any[];
  onSelectPrimer: (primer: any) => void;
}

function AddPrimerToChannel({ primerType, selectedPrimers, onSelectPrimer }: Props) {
  const dispatch = useAppDispatch();

  const isDeepPrimer = primerType === PrimerType.DEEP;
  const prefixLabel = isDeepPrimer ? 'Deep' : 'Power';

  const MODAL_NAME = `Add${prefixLabel}PrimerToChannelModal`;
  const {
    primers: { deep: allDeepPrimer, power: allPowerPrimer, pagination: primerPagination },
  } = useAppSelector((state) => state.channel);
  const [options, setOptions] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({});
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const handleCloseModal = () => {
    setOptions([]);
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    setPagination(isDeepPrimer ? primerPagination.deep : primerPagination.power);
    const primerList = isDeepPrimer ? allDeepPrimer : allPowerPrimer;
    const filtered = primerList.filter(
      (primer) => !selectedPrimers.find((selitem: any) => selitem.id === primer.id),
    );
    setOptions(filtered);
  }, [isDeepPrimer ? allDeepPrimer : allPowerPrimer]);

  useEffect(() => {
    if (showModal) {
      const params: PageParameters = { page: 1, itemsPerPage: 10 };
      if (isDeepPrimer) {
        dispatch(getDeepPrimerChannels(params));
      } else {
        dispatch(getPowerPrimerChannels(params));
      }
    }
  }, [showModal]);

  const onMenuScrollToBottom = (page: number, key?: string) => {
    const params: PageParameters = {
      page,
      itemsPerPage: 10,
    };
    if (key) {
      params.filters = { title: key };
    }
    if (isDeepPrimer) {
      dispatch(getDeepPrimerChannels(params));
    } else {
      dispatch(getPowerPrimerChannels(params));
    }
  };

  const handleInputChange = debounce((key: string, action: { action: string }) => {
    if (action.action === 'input-change' && onMenuScrollToBottom) {
      onMenuScrollToBottom(1, key);
    }
  }, 500);

  const addLabelValueProps = (opts: any[]) =>
    opts.map((option: any) => ({
      ...option,
      label: option?.title,
      value: option?.id,
    }));

  return (
    <Modal show={showModal} centered>
      <Formik
        initialValues={{
          selectedPrimer: options?.length ? options[0].id : null,
        }}
        enableReinitialize
        onSubmit={(values) => {
          onSelectPrimer(options.find((option: any) => option.id === values.selectedPrimer));
          handleCloseModal();
        }}
      >
        {({ handleSubmit, values, setValues }) => {
          const optionsDropdownItems = addLabelValueProps(options);

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header className="pb-0 p-4">
                <button
                  type="button"
                  className="btn-close icon-size-12 "
                  onClick={handleCloseModal}
                  aria-label="Close"
                />
              </Modal.Header>
              <Modal.Body className="ps-5 pe-5">
                <div className="text-center font-light">
                  <h4 className="mb-4">{`Add ${prefixLabel} Primer`}</h4>
                </div>
                <div>
                  <Field
                    component={reactSelect}
                    name="selectedPrimer"
                    placeholder={`Select ${prefixLabel} Primer *`}
                    options={optionsDropdownItems}
                    value={optionsDropdownItems.filter(
                      (option: any) => option?.id === values?.selectedPrimer,
                    )}
                    onMenuScrollToBottom={() => {
                      if (
                        pagination.status !== THUNK_STATUS.LOADING &&
                        pagination?.total &&
                        options.length < pagination.total - 1
                      ) {
                        onMenuScrollToBottom(pagination.current + 1, pagination?.filters?.title);
                      }
                    }}
                    onInputChange={handleInputChange}
                    onChange={(e: any) => {
                      setValues({
                        selectedPrimer: e?.value,
                      });
                    }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-6 px-1 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={values.selectedPrimer === null}
                      >
                        <i className="fa fa-check" />
                        &nbsp;
                        {`Add ${prefixLabel} Primer`}
                      </button>
                    </div>
                    <div className="col-6 px-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        onClick={handleCloseModal}
                      >
                        <i className="fa fa-times" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddPrimerToChannel;
