/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import { NewCourse } from '../../../redux/interface/courseInterface';
import DragImage from '../../../components/formik/dragImage';
import { createCourses } from '../../../redux/thunks/courseThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { filterTags } from '../../../helpers/filterTag';

interface AddNewCourseProps {
  newCourse: NewCourse;
  opt: any;
}

interface SessionOptionInterface {
  value: string | undefined;
  label: string | undefined;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the title name'),
  subtitle: Yup.string().required('Please enter subtitle'),
  image: Yup.string().required('File is required'),
});

function AddNewCourse({ newCourse, opt }: AddNewCourseProps) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.course);
  const dispatch = useAppDispatch();
  const [newTag, setNewTag] = useState<any>();
  const [filteredTags, setFilteredTags] = useState<any>();

  const [selectedFile, setSelectedFile] = useState<File>();
  const content = useRef<HTMLDivElement>(null);
  const [remainingSession, setRemainingSession] = useState<SessionOptionInterface[]>([]);
  const [selectedSession, setSelectedSession] = useState<SessionOptionInterface[]>([]);

  useEffect(() => {
    setRemainingSession(() => {
      const results = opt.filter(
        ({ value: id1 }: any) => !selectedSession.some(({ value: id2 }) => id2 === id1),
      );
      return results;
    });
  }, [selectedSession]);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="add_new_course"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={newCourse}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const finalSession = [...selectedSession.filter((value) => value.value !== '')];
          dispatch(
            createCourses({
              ...values,
              tags: filteredTags,
              courseSessions: finalSession?.map((session: any, index) => ({
                position: index,
                session: session.value,
              })),
            }),
          );
        }}
      >
        {({ handleSubmit, setValues, values, resetForm }) => {
          const resetData = () => {
            resetForm();
            setSelectedSession([]);
            setSelectedFile(undefined);
            if (content.current) {
              content.current.textContent = '';
            }
          };
          useEffect(() => {
            setFilteredTags(filterTags(newTag, []));
          }, [newTag]);

          useEffect(() => {
            if (status === 'succeeded' && modalName === 'addNewCourse') {
              hideModal({ modalId: '#closeModal_addNewCourse' });
              resetData();
            }
          }, [modalOpen]);

          return (
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header pb-0 p-4">
                  <button
                    type="button"
                    className="btn-close icon-size-12 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetData()}
                  />
                </div>
                <div className="modal-body ps-5 pe-5">
                  <div className="text-center font-light">
                    <h4 className="mb-4">Add new Upgrade</h4>
                  </div>

                  <form>
                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="title"
                        description="title"
                        placeholder="Title"
                        label="Title *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="subtitle"
                        description="subtitle"
                        placeholder="Subtitle"
                        label="Subtitle *"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="form-label">Tags</p>
                      </div>
                      <p className="color-gray-600">
                        Enter the tags you want to add to the course separated by a comma
                      </p>
                      <div className="form-control tags-field @@classNameName">
                        <div
                          className="d-inline-block input"
                          ref={content}
                          contentEditable
                          defaultValue=""
                          onInput={(e) => setNewTag(e.currentTarget.textContent)}
                        />
                      </div>
                    </div>

                    {opt && (
                      <div className="mb-3">
                        { /* eslint-disable-next-line jsx-a11y/label-has-for */ }
                        <label className="form-label">Sessions *</label>
                        {selectedSession.map((value, index) => (
                          <div className="row mb-2">
                            <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                              <span className="color-gray-600">{index + 1}</span>
                            </div>
                            <div className="col-10 p-0">
                              <Select
                                options={remainingSession}
                                onChange={(valued) => {
                                  const newObj = {
                                    label: valued?.label,
                                    value: valued?.value,
                                  };
                                  setSelectedSession((pre) => {
                                    const obj = [...pre];
                                    obj[index] = newObj;
                                    return obj;
                                  });
                                }}
                                value={value}
                              />
                            </div>
                            <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                              <span className="cursor-pointer ms-2">
                                <i
                                  className="fa-regular fa-xmark color-black "
                                  onClick={() => {
                                    setSelectedSession((prev) =>
                                      prev.filter((_val, index1) => index1 !== index),
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            onClick={() => {
                              if (selectedSession.length === opt.length) return;
                              setSelectedSession((prev) => [
                                ...prev,
                                { value: '', label: 'Select...' },
                              ]);
                            }}
                          >
                            <i className="fa-regular fa-plus me-1" />
                            Add session
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <Field
                        component={DragImage}
                        name="image"
                        label="Image *"
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                      />
                    </div>

                    <div className="form-check me-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="isDayCourse"
                        name="isDayCourse"
                        checked={values?.isDayCourse}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setValues({ ...values, isDayCourse: event.target.checked });
                        }}
                      />
                      <p className="form-check-label">Categorize as upgrade</p>
                    </div>
                  </form>
                </div>
                <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 px-1 ps-0">
                        <div className="mb-3">
                          <button
                            type="button"
                            id="closeModal_addNewCourse"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </span>
                            ) : (
                              <div
                                onClick={() => handleSubmit()}
                                role="button"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Upgrade
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-6 px-1 pe-0">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          data-bs-dismiss="modal"
                          onClick={() => resetData()}
                        >
                          <i className="fa fa-times" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddNewCourse;
