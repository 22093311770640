import React from 'react';
// import useBreakpoint from 'use-breakpoint';
import {
  CourseInterface,
  SessionInterface,
  ChannelInterface,
  CardEntityTypeReq,
} from '../redux/interface/entitiesInterface';
import CourseCard from './cards/courseCard';
// import GrayBg from '../assets/img/gray.png';
import logo from '../assets/img/logo.svg';
// import { breakpoints, rowSizes } from '../constants/mediaQueries';

interface CourseGridProps {
  siblingTypes?: CardEntityTypeReq;
  data: (CourseInterface | SessionInterface)[];
  parentType?: string;
  parentInfo?: CourseInterface | ChannelInterface | null;
  refer?: string;
  // forSliders?: boolean;
}
function CourseGrid({
  data,
  siblingTypes,
  parentInfo,
  refer,
  parentType,
}: // forSliders,
CourseGridProps) {
  // const { breakpoint } = useBreakpoint(breakpoints);
  // const breakpointIdx = breakpoint?.toString() || 'lg';

  return (
    <div className="row mb-4">
      {data.map((datum) => (
        <div
          // className={`col-12 col-lg-${forSliders ? rowSizes[breakpointIdx] : '4'} mb-4 mb-lg-0`}
          style={{
            width: '350px',
            margin: '0 0.75rem',
            paddingRight: 0,
            paddingLeft: 0,
          }}
          key={JSON.stringify(datum)}
        >
          <CourseCard
            title={datum.title}
            description={datum.description}
            id={datum.id}
            type={siblingTypes}
            parentType={parentType}
            info={datum}
            parentInfo={parentInfo}
            imgSrc={'image' in datum ? datum?.image?.url : datum?.media?.url || logo}
            refer={refer}
            isFree={
              siblingTypes !== 'session' ||
              (siblingTypes === 'session' && 'isFree' in datum && datum.isFree)
            }
          />
        </div>
      ))}
    </div>
  );
}

CourseGrid.defaultProps = {
  siblingTypes: 'session',
  parentType: 'upgrade',
  refer: '',
  // forSliders: false,
  parentInfo: null,
};

export default CourseGrid;
