import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getSubscriptionsInfo } from '../../redux/thunks/subscriptionThunk';
import {
  SubscriptionsInfoTeam,
  SubscriptionsInfoSingle,
} from '../../redux/interface/subscriptionInterface';
import SubscriptionTypesForm from './subscriptionTypesForm';

function SubscriptionTypes() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSubscriptionsInfo());
  }, []);
  const subscriptionTypes = useAppSelector((state) => state.subscriptions);
  const { subscriptions, status } = subscriptionTypes;
  const single = subscriptions?.filter((s) => s.type === 'single');
  const team = subscriptions?.filter((s) => s.type === 'team');

  const [singleSubscriptionDetails, setSingleSubscriptionDetails] = useState<
    SubscriptionsInfoSingle[]
  >([
    {
      frequency: 'monthly',
      description: '',
      price: 0,
      stripe_id: '',
      apple_id: '',
      android_id: '',
      appleDiscountId: '',
      androidDiscountId: '',
    },
    {
      frequency: 'yearly',
      description: '',
      price: 0,
      stripe_id: '',
      apple_id: '',
      android_id: '',
      appleDiscountId: '',
      androidDiscountId: '',
    },
    {
      frequency: 'lifetime',
      description: '',
      price: 0,
      stripe_id: '',
      apple_id: '',
      android_id: '',
      appleDiscountId: '',
      androidDiscountId: '',
    },
  ]);

  useEffect(() => {
    if (single && status === 'succeeded') {
      setSingleSubscriptionDetails(single);
    }
  }, [subscriptionTypes]);

  const [teamSubscriptionDetails, setTeamSubscriptionDetails] = useState<SubscriptionsInfoTeam[]>([
    {
      frequency: 'yearly',
      description: '',
      price: 0,
      stripe_id: '',
    },
  ]);

  useEffect(() => {
    if (team && status === 'succeeded') {
      setTeamSubscriptionDetails(team);
    }
  }, [subscriptionTypes]);

  return (
    <SubscriptionTypesForm
      singleSubscriptionDetails={singleSubscriptionDetails}
      setSingleSubscriptionDetails={setSingleSubscriptionDetails}
      teamSubscriptionDetails={teamSubscriptionDetails}
      setTeamSubscriptionDetails={setTeamSubscriptionDetails}
    />
  );
}

export default SubscriptionTypes;
