/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Dropdown from '../../../components/formik/dropdown';
import Input from '../../../components/formik/input';
import { provideSubscriptionAppUsers } from '../../../redux/thunks/appUsersThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface AppUserProvideSubscriptionProps {
  userId: number;
  onSubscriptionProvided?: () => any;
}

const validationSchema = Yup.object().shape({
  accountType: Yup.string().required('Please select type'),
  subscription: Yup.string().required('Please select subscription time'),
  discountApplied: Yup.string().when('accountType', {
    is: 'single-paid' || 'team-paid',
    then: Yup.string().required('Please select discount'),
  }),
  token: Yup.string().when('accountType', {
    is: 'single-paid' || 'team-paid',
    then: Yup.string().required('Please enter token value'),
  }),
});

function AppUserProvideSubscription({ userId, onSubscriptionProvided }: AppUserProvideSubscriptionProps) {
  const dispatch = useAppDispatch();

  const { status, modalOpen, modalName } = useAppSelector((state) => state.appUsers);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="provide_subscription"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={{
          accountType: '',
          subscription: '',
          discountApplied: '',
          token: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(
            provideSubscriptionAppUsers({
              ...values,
              id: userId.toString(),
            }),
          );
        }}
      >
        {({ handleSubmit, setValues, setTouched, values }) => {
          useEffect(() => {
            if (status === 'succeeded' && modalName === 'provideSubscriptionsAppUser') {
              hideModal({ modalId: '#closeModal_provideSubscription' });
              if (onSubscriptionProvided) {
                onSubscriptionProvided();
              }
            }
          }, [modalOpen]);

          const [show, setShow] = useState<boolean>(true);
          useEffect(() => {
            if (values?.accountType === 'team-free' || values?.accountType === 'single-free') {
              setTouched({});
              setValues({
                ...values,
                discountApplied: '',
                token: '',
              });
              setShow(false);
            } else {
              setShow(true);
            }
          }, [values?.accountType]);
          return (
            <form>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        hideModal({ modalId: '#closeModal_provideSubscription' });
                        setTouched({});
                        setValues({
                          accountType: '',
                          subscription: '',
                          discountApplied: '',
                          token: '',
                        });
                      }}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Provide a subscription</h4>
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Dropdown}
                        label="Subscription type *"
                        id="accountType"
                        value={values?.accountType}
                        name="accountType"
                        placeholder="Select plan"
                        options={[
                          { name: 'Single Free', value: 'single-free' },
                          { name: 'Single Paid', value: 'single-paid' },
                          { name: 'Team Free', value: 'team-free' },
                          { name: 'Team Paid', value: 'team-paid' },
                        ]}
                      />
                    </div>

                    <div>
                      <Field
                        value={values?.subscription}
                        component={Dropdown}
                        label="Subscription time *"
                        id="subscription"
                        name="subscription"
                        placeholder="Select duration"
                        options={[
                          { name: 'Monthly', value: 'monthly' },
                          { name: 'Yearly', value: 'yearly' },
                          { name: 'Lifetime', value: 'lifetime' },
                        ]}
                      />
                    </div>

                    {show ? (
                      <>
                        <div>
                          <Field
                            value={values?.discountApplied}
                            component={Dropdown}
                            label="Discount *"
                            id="discountApplied"
                            name="discountApplied"
                            placeholder="Select discount"
                            options={[
                              { name: 'Discount Applied', value: '1' },
                              { name: 'No', value: '0' },
                            ]}
                          />
                        </div>
                        <div>
                          <Field
                            component={Input}
                            type="text"
                            name="token"
                            id="token"
                            description="token"
                            placeholder="Invoice Ref no."
                            label="Reference no. *"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_provideSubscription"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </span>
                            ) : (
                              <div
                                onClick={() => handleSubmit()}
                                role="button"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Confirm
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              hideModal({ modalId: '#closeModal_provideSubscription' });
                              setTouched({});
                              setValues({
                                accountType: '',
                                subscription: '',
                                discountApplied: '',
                                token: '',
                              });
                            }}
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

AppUserProvideSubscription.defaultProps = {
  onSubscriptionProvided: null,
};

export default AppUserProvideSubscription;
