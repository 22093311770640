import { useNavigate } from 'react-router-dom';

function TermsOfService() {
  const navigate = useNavigate();
  const handleBackAction = () => {
    navigate(-1);
  };
  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      <div className="py-4 d-flex w-100">
        <button
          type="button"
          onClick={handleBackAction}
          className="btn btn-link text-decoration-none"
        >
          <i className="fa-regular fa-angle-left color-primary me-1" />
          <span className="color-black">Back</span>
        </button>
      </div>
      <div className="onboarding__form-box d-flex flex-column w-100 mt-auto">
        <h3 className="mb-3 mb-lg-4 text-center color-black">Terms of Service</h3>

        <div className="entry-content">
          <div id="u94-6">
            <p id="u94-2">Terms of Service – Primed Mind application</p>
          </div>
          <div id="u143-11">
            <p>
              <span id="u143-3">Primed Mind USA</span>, 138 E 12300 S Unit #781, Draper, UT 84020
            </p>
            <p>
              <span id="u143-7">Last updated:</span>&nbsp;December 5th, 2022
            </p>
          </div>
          <div id="u152-375">
            <p id="u152-4">
              Any permitted use of the Primed Mind application offered by Primed Mind USA (the
              “App”) shall be strictly subject to this Terms of Service (the “Agreement”). The
              present Agreement may be amended, modified or replaced as required from time to time.
              By commencing use of the App, users accept the validity of this Agreement in its most
              recent version. The currently valid version of this Agreement dated&nbsp;
              <span id="u152-2">December 5th, 2022</span>&nbsp;shall apply until further notice.
            </p>
            <p id="u152-7">
              Primed Mind USA, an US-american company (referred to as “us”, “we” or the “Company”),
              reserves the right to change or update the terms of this Agreement, or any other of
              our policies and practices. We may update and change the App in our absolute and sole
              discretion, including changes to the subject matter and materials. Any changes or
              updates will be effective immediately, and your continued use of the App will
              constitute agreement. We may or may not update you regarding any such changes.
            </p>
            <p id="u152-10">The terms “you” “your” or “users” refers to the user of the App.</p>
            <p id="u152-13">
              BY USING THE APP YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. YOU (A)
              ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU
              ARE 16 YEARS OF AGE OR OLDER AND ALL REGISTRATION INFORMATION YOU SUBMIT IS TRUTHFUL
              AND ACCURATE; (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS
              TERMS; AND (D) YOUR USE OF THE APP DOES NOT VIOLATE ANY APPLICABLE LAW OR REGULATION.
              IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL OR USE THE APP.
            </p>
            <p id="u152-16">
              PLEASE NOTE THAT THESE TERMS CONTAIN AN ARBITRATION CLAUSE. EXCEPT FOR CERTAIN TYPES
              OF DISPUTES MENTIONED IN THE ARBITRATION CLAUSE, YOU AND THE COMPANY AGREE THAT
              DISPUTES RELATING TO THESE TERMS OR YOUR USE OF THE APP WILL BE RESOLVED BY MANDATORY
              BINDING ARBITRATION, AND YOU AND THE COMPANY WAIVE ANY RIGHT TO PARTICIPATE IN A
              CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
            </p>
            <p id="u152-22">Art. 1 Object of Agreement</p>
            <ol id="u152-46">
              <li id="u152-27">
                The App has been designed to encourage users to achieve maximum performance through
                mental training. The designated goal for users is to become “the best version of
                themselves” as a result of this type of mental training. Results will vary between
                users, and we make no guarantees as to the suitability of your use of the App or to
                any particular result.
              </li>
              <li id="u152-31">
                The object of this User Agreement is the use of the App in its object code form
                including any associated ancillary services provided by Primed Mind USA (such as
                product specifications, manuals, explanations, etc.) by its user.
              </li>
              <li id="u152-35">
                The training texts are currently being prepared by mind coach Elliot Roe and made
                available in English as streaming audio files. Primed Mind USA expressly reserves
                the right to provide texts in other languages as well and to unilaterally replace
                the mind coach by another mind coach with equivalent qualifications and experience.
              </li>
              <li id="u152-39">
                Users expressly acknowledge that use of the App does not constitute any substitute
                for coaching, life and social counseling or availing oneself of the services of a
                psychologist, psychiatrist or psychotherapist whatsoever. IF YOU HAVE ANY DIAGNOSED
                PSYCHOLOGICAL CONDITION OR REASON TO BELIEVE SO YOU MUST CONTACT YOUR TREATING
                PHYSICIAN PRIOR TO USE OF THE APP. You understand that use of the App cannot and
                will not replace any counseling and/or recourse to therapeutic services rendered by
                a duly licensed professional, physician or psychologist in accordance with the
                relevant statutory provisions applicable to their work.
              </li>
              <li id="u152-43">
                We will provide users with an executable copy of the App in digital form. The
                deliverable quality of the App shall be based on its usage specifications and the
                present User Agreement. The method you choose to download the App may incur costs
                that we will not be responsible for.
              </li>
              <li id="u152-45">
                This User Agreement does not cover any installation or configuration services.
              </li>
            </ol>
            <p id="u152-51">Art. 2 Rights Granted with Respect to the App</p>
            <ol id="u152-75">
              <li id="u152-56">
                Users are granted the non-exclusive, non-sublicensable license for personal,
                non-commercial use of the App, limited to the term of the present User Agreement and
                subject to the scope of use defined therein. You are restricted from accessing
                certain content subject to subscription and payment for premium services. Any
                contractual use shall include the installation as well as the loading, display and
                playback of the installed App.
              </li>
              <li id="u152-60">
                Users shall not have the right to copy, edit or decompile the App. AS PART OF YOUR
                USE OF THE APP, YOU AFFIRMATIVELY CONSENT TO THE PROCESSING AND STORAGE OF YOUR
                PERSONAL INFORMATION IN THE UNITED STATES, INCLUDING THE PROCESSING AND STORING OF
                YOUR PERSONAL INFORMATION IN THE UNITED STATES FOR THE PURPOSES OF PROCESSING
                PAYMENTS AND TRACKING INDIVIDUAL USE OF THE APP. BY USING THE APP, YOU ACKNOWLEDGE
                THAT YOU UNDERSTAND AND AGREE THAT THE UNITED STATES MAY NOT HAVE THE SAME LEVEL OF
                PROTECTIONS FOR YOUR PERSONAL INFORMATION THAT EXIST IN YOUR COUNTRY OF RESIDENCE,
                AND YOU NONETHELESS CONSENT TO THE PROCESSING AND STORAGE OF YOUR PERSONAL
                INFORMATION IN THE UNITED STATES. WE WILL TAKE MEASURES AS REQUIRED TO COMPLY WITH
                APPLICABLE LAW REGARDING THE TRANSFER, STORAGE AND USE OF CERTAIN PERSONAL
                INFORMATION.
              </li>
              <li id="u152-64">
                Users shall not have the right to make available copies of the software, materials
                or other content on the App as backup copies or to transfer to third parties. In
                particular, you shall be required to refrain from selling, loaning, renting out or
                sublicensing the software, materials or other content on the App in any other way
                and from reproducing it in public or making it generally available.
              </li>
              <li id="u152-68">
                Should a user fail to comply with one of the foregoing provisions, all and any right
                of use granted by virtue of the Agreement shall become void with immediate effect.
                In such an event, the infringing user shall completely and immediately cease using
                the App and shall delete all and any copies of the App installed on their systems
                without delay.
              </li>
              <li id="u152-72">
                We have the right to block access to the App at any time, and for any reason or no
                reason, including without restriction when users infringe on their obligations set
                out in this Article. We also have the right to stop providing updates or services to
                the App. We may alter any and all content on the App in our sole and absolute
                discretion. No such change in content, scope of service, removal of access, or
                termination of the App will permit refund of fees paid to us. We will not charge
                your account for future charges upon a termination of your license to use the App.
              </li>
              <li id="u152-74">
                In the event of a violation of intellectual property rights, users shall not be
                refunded the user fees that may have been paid by them. Any such paid fees shall
                count towards the claims asserted by Primed Mind USA as a consequence of the
                aforementioned violation of intellectual property rights.
              </li>
            </ol>
            <p id="u152-79">
              Art. 3 Rights Granted with Respect to the App’s Audio and Video Files
            </p>
            <ol id="u152-110">
              <li id="u152-84">
                Users shall be entitled to listen to the audio files containing the mind coaching
                content as often as they wish and to use and utilize said content for their own
                private purposes and as part of their own lives.
              </li>
              <li id="u152-88">
                Any use of the audio files and/or their content for commercial or entrepreneurial
                purposes is prohibited, in particular for mind coaching, general coaching or life
                and social counseling.
              </li>
              <li id="u152-92">
                Any form of duplication, in particular downloading and recording of the audio files
                or any form of copying or transcribing their content or parts thereof is prohibited
                and constitutes a violation of intellectual property rights.
              </li>
              <li id="u152-99">
                All materials (including software and content whether downloaded or not) contained
                in the App are owned by us or a third-party licensor, as the case may be. You agree
                and acknowledge that the materials are valuable property and that other than any
                specific and limited license for use of such materials, you shall not acquire any
                ownership rights in or to such materials.
                <p>
                  The materials may not be used except as provided for in these Terms, and any other
                  relevant terms and conditions provided to you without our prior written
                  permission.
                </p>
              </li>
              <li id="u152-103">
                Audio or video content from Primed Mind not explicitly indicated as downloadable may
                not be downloaded or copied from the App.
              </li>
              <li id="u152-107">
                The App and all content are intended for your personal consumption and not for your
                commercial use. You must not use any part of the materials used in or on the App for
                commercial purposes without obtaining a written license to do so from us. Material
                from the App may not be copied or distributed, or republished, or transmitted in any
                way, without our prior written consent.
              </li>
              <li id="u152-109">
                You agree not to use the App for illegal purposes (including, without limitation,
                unlawful, harassing, libelous, invasion of another’s privacy, abusive, threatening
                or obscene purposes) and you agree that you will comply with all laws, rules and
                regulations related to your use of the App. ANY FORM OF BROADCASTING, PUBLIC
                REPRODUCTION, CREATION OF DERIVATIVE WORKS, DISPLAY OR MAKING AUDIO FILES AVAILABLE
                ONLINE AS WELL AS ANY COPYING OF AUDIO FILES AS A WHOLE OR IN PART IS PROHIBITED AND
                CONSTITUTES A VIOLATION OF INTELLECTUAL PROPERTY RIGHTS.
              </li>
            </ol>
            <p id="u152-115">Art. 4 Intellectual Property</p>
            <ol id="u152-131">
              <li id="u152-120">
                The special provisions set out in Art. 2 and 3 of the User Agreement
                notwithstanding, any information, brand names, the name of the mind coach as well as
                any other App content must neither be modified, copied, duplicated, sold, rented
                out, used, amended nor exploited in any way without prior written approval by Primed
                Mind USA.
              </li>
              <li id="u152-124">
                Apart from the user rights and other privileges explicitly granted herein, users
                shall neither be granted any additional rights whatsoever, in particular with regard
                to the company name of Primed Mind USA or its industrial property rights such as
                patents, utility models, brands or name rights, nor shall Primed Mind USA be obliged
                to grant any such additional rights itself.
              </li>
              <li id="u152-128">
                In the event that users choose to publish or propagate ideas and suggestions with
                regard to the App or submit said ideas and suggestions to Primed Mind USA, Primed
                Mind USA and its affiliates shall be entitled to utilize them for the development,
                improvement, and sale of the products in their portfolios free of charge.
              </li>
              <li id="u152-130">
                You acknowledge and agree that the Application is provided under license, and not
                sold, to you. You do not acquire any ownership interest in the Application under
                this Agreement, or any other rights thereto other than to use the Application in
                accordance with the license granted, and subject to all terms, conditions and
                restrictions, under this Agreement. We reserve, along with applicable licensors, and
                shall retain the entire right, title and interest in and to the App, including all
                copyrights, trademarks and other intellectual property rights therein or relating
                thereto, except for the license for your use as expressly granted to you in this
                Agreement.
              </li>
            </ol>
            <p id="u152-136">Art. 5 User Obligations</p>
            <ol id="u152-157">
              <li id="u152-154">
                In the course of using the Application, users shall be obliged to refrain from
                offending against common decency and good manners through their behavior as users;
                <ol id="u152-153" className="list1">
                  <li id="u152-142">
                    violating the property rights, copyrights, personal rights, ownership or any
                    other rights of third parties;
                  </li>
                  <li id="u152-144">
                    transmitting content infected by viruses, so-called trojan horses or any other
                    programming that may damage the software;
                  </li>
                  <li id="u152-146">
                    entering, saving or sending hyperlinks or content without proper authorization,
                    in particular if such hyperlinks or content infringe on non-disclosure
                    obligations or if they are illegal;
                  </li>
                  <li id="u152-148">
                    disseminating advertising content or unsolicited e-mails (so-called spam) or
                    incorrect warnings concerning viruses, malfunctions and suchlike, or asking
                    others to take part in raffles, Ponzi schemes, chain letters, pyramid schemes
                    and similar activities;
                  </li>
                  <li id="u152-152">taking any action in violation of this Agreement.</li>
                </ol>
              </li>
              <li id="u152-156">
                We shall have the right to block access to the App at any time, in particular when
                users infringe on their obligations set out in this Article.
              </li>
            </ol>
            <p id="u152-162">Art. 6 Fee, Due Date, Term, and Termination</p>
            <p id="u152-165">
              All fees, the applicable due date, term, and termination shall be subject to the
              users’ subscription models as set forth:
            </p>
            <ol id="u152-248">
              <li id="u152-189">
                <span id="u152-167">Fees</span>
                <p>
                  We offer certain enhanced features of the App which you can purchase as a monthly,
                  yearly or lifetime subscription (“Subscription”). All Subscriptions will have
                  access to the same enhanced features. The monthly Subscription provides you 30
                  calendar days access to enhanced features. The yearly Subscription provides you
                  365 calendar days access to enhanced features. The lifetime Subscription provides
                  you access to enhanced features for [the duration of your life] [120 years] or
                  until we cease to commercially offer the App. Notwithstanding, we may terminate
                  any Subscription immediately if you violate the terms of this Agreement.
                </p>
                <p>
                  A description of features associated with Subscriptions is available within the
                  App and on our webpage at https://www.primedmind.com. The amounts due and payable
                  by you for a Subscription that you purchase through the App will be presented to
                  you before you place your order. If you choose to purchase a Subscription via the
                  App you agree
                </p>
                <ol id="u152-188" className="list1">
                  <li id="u152-179">to pay the applicable fees and any taxes;</li>
                  <li id="u152-183">
                    that we may charge your account with the app store or distribution platform
                    (like the Apple App Store or Google Play) where the App is made available (each,
                    an “App Provider”); and
                  </li>
                  <li id="u152-187">
                    to bear any additional charges that your App Provider, bank or other financial
                    service provider may levy on you as well as any taxes or fees that may apply to
                    your order. You’ll receive a confirmation email after we confirm the payment for
                    your order. Your order is not binding on us until accepted and confirmed by us.
                    All payments made are non-refundable and non-transferable except as expressly
                    provided in these Terms.
                  </li>
                </ol>
              </li>
              <li id="u152-206">
                Monthly and yearly Subscriptions automatically renew according and are payable and
                charged to the following conditions:
                <ol id="u152-205" className="list1">
                  <li id="u152-196">
                    The first charge occurs at the beginning of the Subscription and, because each
                    such Subscription renews automatically for an additional period equal in length
                    to the expiring Subscription term until you cancel it, at the time of each
                    renewal until you cancel. You must cancel your Subscription before it renews to
                    avoid the billing of the fees for the next Subscription period, whether monthly
                    or annually. If you purchase your Subscription via an App Provider, you can
                    cancel the renewal of your Subscription with the App Provider. You will not
                    receive a refund for the fees you already paid for your current Subscription
                    period and you will continue to receive the enhance features ordered until the
                    end of your current Subscription period.
                  </li>
                  <li id="u152-200">
                    If you have any concerns or objections regarding charges, you agree to raise
                    them with us first and you agree not to cancel or reject any credit card or App
                    Provider charges unless you have made a reasonable attempt at resolving the
                    matter directly with us.
                  </li>
                  <li id="u152-204">
                    We reserve the right to not process or to cancel your order in certain
                    circumstances, for example, if your credit card is declined, if we suspect the
                    request or order is fraudulent, or in other circumstances we deem appropriate in
                    our sole discretion. We also reserve the right, in our sole discretion, to take
                    steps to verify your identity in connection with your order. We will either not
                    charge you or refund the charges for orders that we do not process or cancel.
                  </li>
                </ol>
              </li>
              <li id="u152-210">
                Lifetime Subscriptions are paid for by a onetime, upfront payment. There is no
                automatic renewal. You must be an individual (natural-born person) to have a
                lifetime Subscription.
              </li>
              <li id="u152-217">
                <span id="u152-211">Changes to Price Terms</span>
                <p>
                  Primed Mind reserves the right to change its pricing terms for Subscriptions at
                  any time and Primed Mind will notify you in advance of such changes becoming
                  effective. Changes to the pricing terms will not apply retroactively and will only
                  apply for Subscription renewals after such changed pricing terms have been
                  communicated to you. If you do not agree with the changes to Primed Mind’s pricing
                  terms then you may choose not to renew your Subscription.
                </p>
              </li>
              <li id="u152-224">
                <span id="u152-218">Future Functionality</span>
                <p>
                  You agree that your purchases are not contingent on the delivery of any future
                  functionality or features, or dependent on any oral or written public comments
                  made by Primed Mind regarding future functionality or features. We may change,
                  update, remove, or terminate any or all advanced features of the App at any time,
                  without notice and for any reason. Your Subscription does not provide any
                  guarantee to delivery of current or future functionality or features.
                </p>
              </li>
              <li id="u152-241">
                This Agreement commences when you download the App and will continue in effect until
                terminated by you or us.
                <ol id="u152-240" className="list1">
                  <li id="u152-231">
                    You may terminate this Agreement by deleting the App and providing notice to us
                    at support@primedmind.com.
                  </li>
                  <li id="u152-235">
                    We may terminate this Agreement at any time without notice for any reason or no
                    reason. We may terminate this Agreement if we cease to support the App.
                  </li>
                  <li id="u152-239">
                    This Agreement will terminate immediately and automatically without any notice
                    if you violate any terms and conditions of this Agreement.
                  </li>
                </ol>
              </li>
              <li id="u152-245">
                Upon termination, all rights granted to you under this Agreement will also terminate
                and you must cease all use of the App.
              </li>
              <li id="u152-247">
                Termination will not limit any of the Company’s rights or remedies at law or in
                equity.
              </li>
            </ol>
            <p id="u152-253">Art. 7 Availability</p>
            <ol id="u152-273">
              <li id="u152-258">
                In view of the nature of both the internet and computer systems, Primed Mind USA
                does not assume any liability for disruptions of the App’s availability. In
                particular, Primed Mind USA would like to advise users that limitations or
                impairments with regard to the App may occur that are beyond the control of Primed
                Mind USA.
              </li>
              <li id="u152-262">
                We may interrupt service and access to the App without notice to provide updates to
                the content, materials or software.
              </li>
              <li id="u152-266">
                The limitations or impairments mentioned above shall include, but are not limited
                to, activities on the part of third parties not acting on behalf of Primed Mind USA,
                technical internet conditions Primed Mind USA is unable to control as well as force
                majeure. The hardware, software and technological infrastructure employed by users
                may affect the App’s performance as well. To the extent that the aforementioned
                conditions impinge on the availability or functionality of the App, they cannot be
                considered to affect the App’s conformity with the provisions of the present
                Agreement.
              </li>
              <li id="u152-270">
                Primed Mind USA will provide users with the currently offered version of the App.
                Users, however, shall not be entitled to any version newer than the one supplied
                under this Agreement. 5. We may terminate or suspend your account immediately,
                without prior notice or liability, for any reason whatsoever, including without
                limitation if you breach the Terms.
              </li>
            </ol>
            <p id="u152-278">Art. 8 Warranty</p>
            <ol id="u152-283">
              <li id="u152-282">
                The use of the App in accordance with this Agreement does not conflict with any
                third-party rights. Primed Mind USA will eliminate and cure any material and legal
                defects that may arise from or in connection with the App within a reasonable period
                of time. THE APPLICATION IS PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND
                DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
                APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS
                AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
                WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
                APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF
                COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
                LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES
                NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS,
                ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
                APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY
                PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS
                CAN OR WILL BE CORRECTED.&nbsp;
                <span id="u152-281">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO
                  SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
                </span>
              </li>
            </ol>
            <p id="u152-288">Art. 9 Limitation of Liability</p>
            <ol id="u152-320">
              <li id="u152-293">
                Primed Mind USA would like to reiterate that the audio files described herein are no
                substitute for the services provided by life or social counselors, coaches,
                psychologists, psychiatrists, psychotherapists or physicians and that they are
                supplied without any promise or commitment in this respect. Consequently, Primed
                Mind USA does not assume any liability whatsoever in the event that users fail to
                consult qualified experts or specialists. No representation as to results or
                guarantee of any kind.
              </li>
              <li id="u152-297">
                Furthermore, Primed Mind USA does not assume any liability whatsoever for acts or
                omissions on the part of users that may arise in consequence of the use of the App.
              </li>
              <li id="u152-301">
                In all other respects, the disclaimer of liability on the part of Primed Mind USA
                shall extend, but shall not be limited, to any consequential damage unless such
                liability is mandated by the Product Liability Act due to willful acts, gross
                negligence, physical damage or adverse health effects or if such liability arises
                from the assumption of quality guarantees by Primed Mind USA, the fraudulent
                concealment of defects, or any breach of material contractual obligations on the
                part of Primed Mind USA. Compensation claims asserted for breaches of material
                contractual obligations, however, shall be limited to contractually typical and
                foreseeable damage unless caused by willful intent or gross negligence.
              </li>
              <li id="u152-317">
                Primed Mind USA does not assume any further liability. The limitation of liability
                defined above shall also apply to the employees, agents, and executive bodies of
                Primed Mind USA.
                <p>
                  To the fullest extent permitted by applicable law, in no event will Primed Mind or
                  its affiliates or any of its or their respective licensors or service providers,
                  have any liability arising from or related to your use of or inability to use the
                  application or the content and services for:
                </p>
                <ol id="u152-316" className="list1">
                  <li id="u152-311">
                    Personal injury, property damage, lost profits, cost of substitute goods or
                    services, loss of data, loss of goodwill, business interruption, computer
                    failure or malfunction, or any other consequential, incidental, indirect,
                    exemplary, special, or punitive damages.
                  </li>
                  <li id="u152-315">
                    Direct damages in amounts that in the aggregate exceed the amount actually paid
                    by you for the application.
                  </li>
                </ol>
              </li>
              <li id="u152-319">
                The foregoing limitations will apply whether such damages arise out of breach of
                contract, tort (including negligence), or otherwise and regardless of whether such
                damages were foreseeable or Primed Mind was advised of the possibility of such
                damages. Some jurisdictions do not allow certain limitations of liability so some or
                all of the above limitations of liability may not apply to you.
              </li>
            </ol>
            <p id="u152-325">Art. 10 Personal Data</p>
            <ol id="u152-337">
              <li id="u152-330">
                Upon registration of a user, Primed Mind USA will collect customizable information,
                also with regard to the use of the App.
              </li>
              <li id="u152-334">
                Primed Mind USA collects and utilizes personal data within the meaning of GDPR being
                effective on May 25th, 2018 in particular for developing new applications, improving
                existing ones and adapting them to user requirements.
              </li>
              <li id="u152-336">
                The Privacy Policy as shown here: https://www.primedmind.com/privacypolicy and
                updated from time to time, sets for how we use, gather, and store your personal
                information. You agree to the Privacy Policy.
              </li>
            </ol>
            <p id="u152-342">Art. 11 Miscellaneous</p>
            <ol id="u152-370">
              <li id="u152-347">
                For any transfer or assignment of rights arising from, or in connection with, the
                present Agreement to third parties by users, prior written approval by Primed Mind
                USA shall be required.
              </li>
              <li id="u152-351">
                This Agreement shall be exclusively subject to US-american law to the exclusion of
                the United Nations Convention on Contracts for the International Sale of Goods dated
                April 11, 1980 (Vienna Convention) as well as to the exclusion of any
                conflict-of-law rules. Any claim, cause of action, or other dispute must be referred
                to and decided in Utah, USA. You waive any and all objections to the exercise of
                jurisdiction over you by such courts and to venue in such courts.
              </li>
              <li id="u152-355">
                The Parties hereto acknowledge that they are aware of the fact that the software
                described herein may be subject to export and import restriction. In particular, the
                software may be subject to official approval; restrictions concerning the use of the
                software or its associated technologies may apply in foreign countries.
              </li>
              <li id="u152-359">
                The place of performance for this Agreement shall be USA, with the courts of Utah
                having exclusive jurisdiction provided both parties are entrepreneurs or corporate
                bodies under public law.
              </li>
              <li id="u152-363">
                Any dispute, controversy or claim arising out of or relating to this Agreement, or
                the breach thereof, shall be determined by arbitration administered by the
                International Centre for Dispute Resolution in accordance with its International
                Arbitration Rules. There shall be a single arbitrator appointed. The arbitration
                shall take place in the venue designated in this Agreement. The arbitration
                proceeding and decision shall be in English. This Arbitration provision shall
                survive termination of the Agreement.
              </li>
              <li id="u152-367">
                Any dispute, controversy or claim arising out of, relating to or in connection with
                this Agreement, including the breach, termination or validity thereof, shall be
                finally resolved by arbitration as set forth above. The tribunal shall have the
                power to rule on any challenge to its own jurisdiction or to the validity or
                enforceability of any portion of the agreement to arbitrate. The parties agree to
                arbitrate solely on an individual basis, and that this agreement does not permit
                class arbitration or any claims brought as a plaintiff or class member in any class
                or representative arbitration proceeding. The arbitral tribunal may not consolidate
                more than one person’s claims, and may not otherwise preside over any form of a
                representative or class proceeding. In the event the prohibition on class
                arbitration is deemed invalid or unenforceable, then the remaining portions of the
                arbitration agreement will remain in force.
              </li>
              <li id="u152-369">
                Should one or more provisions contained in the present Agreement be invalid, the
                remaining provisions shall nevertheless continue with full force and effect. In any
                such event, the Parties hereto shall endeavor to agree on a new provision in lieu of
                the invalid one corresponding to the contractual aim of this Agreement as to its
                legal and economic effect as closely as possible.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
