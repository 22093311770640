export const filterTags = (tagString: string | null, tagArray: Array<string>) => {
  let filteredTagsArray: string[] = [];

  if (tagArray.length !== 0) {
    filteredTagsArray = tagArray;
  }
  if (tagString) {
    filteredTagsArray = filteredTagsArray.concat(
      tagString?.split(',').map((s: string) => s.trim()),
    );
  }

  const filteredTagsSet = new Set(filteredTagsArray);
  const filteredTagsArraySet = Array.from(filteredTagsSet);

  return filteredTagsArraySet;
};
