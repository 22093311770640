/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/formik/input';
import { actions } from '../../../redux/slices/userSettingsSlice';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addNewCard, getCardList } from '../../../redux/thunks/userSettingsThunk';
import { THUNK_STATUS } from '../../../constants';
import { toast } from '../../../helpers/toaster';

interface ErrorCard {
  card_number: string | undefined;
  card_expiry: string | undefined;
  card_cvc: string | undefined;
}
const defaultErrorCardValue: ErrorCard = {
  card_number: '',
  card_expiry: '',
  card_cvc: '',
};

const validationSchema = Yup.object().shape({
  cardholderName: Yup.string().required("Please enter the cardholder's name."),
});

function CardForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { status, message } = useAppSelector((state) => state.userSettings.cardForm);
  const showModal = useAppSelector((state) => state.modal.visible === 'addCard');

  const [errorCardIncomplete, setErrorIncomplete] = useState<ErrorCard>(defaultErrorCardValue);

  const [errorCardInvalid, setErrorCardInvalid] = useState<ErrorCard>(defaultErrorCardValue);

  useEffect(() => {
    dispatch(actions.clearCardFormStatuses());
    return () => {
      dispatch(actions.clearCardFormStatuses());
    };
  }, []);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      toast(message || 'Card added.', 'success');
      dispatch(getCardList());
      // dispatch(modalActions.closeModal());
      dispatch(modalActions.setVisibleModal('subscriptionModal'));
    }
  }, [status]);

  const handleCloseClick = () => {
    dispatch(modalActions.closeModal());
    dispatch(modalActions.setSecondaryVisibleModal(''));
  };

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12"
          onClick={handleCloseClick}
          aria-label="Close"
        />
      </Modal.Header>
      <Formik
        initialValues={{ cardholderName: '' }}
        validationSchema={validationSchema}
        onSubmit={({ cardholderName }) => {
          if (!stripe || !elements) {
            return;
          }
          dispatch(actions.beginCardFormPending());
          const cardNumberElement = elements.getElement(
            CardNumberElement,
          ) as StripeCardNumberElement;
          stripe
            .createPaymentMethod({
              type: 'card',
              card: cardNumberElement,
            })
            .then(({ error, paymentMethod }) => {
              const currentError: string | undefined = error?.code;

              switch (currentError) {
                case 'incomplete_number':
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                    card_number: 'Card number is incomplete',
                  });
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'invalid_number':
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                    card_number: 'Card number is invalid',
                  });
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'incomplete_expiry':
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                    card_expiry: 'Expiry is incomplete',
                  });
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'invalid_expiry_year_past':
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                    card_expiry: 'Expiry is invalid',
                  });
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'invalid_expiry_year':
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                    card_expiry: 'Expiry is invalid',
                  });
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'incomplete_cvc':
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                    card_cvc: 'CVC is incomplete',
                  });
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                  });
                  break;
                case 'invalid_cvc':
                  setErrorCardInvalid({
                    ...defaultErrorCardValue,
                    card_cvc: 'CVC is invalid',
                  });
                  setErrorIncomplete({
                    ...defaultErrorCardValue,
                  });
                  break;
                default:
              }
              if (currentError) {
                dispatch(actions.removeCardFormPending());
                return;
              }
              stripe.createToken(cardNumberElement, { name: cardholderName }).then(({ token }) => {
                if (error === undefined) {
                  setErrorIncomplete(defaultErrorCardValue);
                  setErrorCardInvalid(defaultErrorCardValue);
                }
                if (paymentMethod?.id && token?.id && error === undefined) {
                  dispatch(
                    addNewCard({
                      cardToken: token.id,
                      paymentMethodToken: paymentMethod.id,
                      saveCard: true,
                    }),
                  );
                } else {
                  dispatch(actions.removeCardFormPending());
                }
              });
            });
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="ps-5 pe-5">
              <div className="text-center font-light">
                <h5 className="mb-3">Add Card</h5>
              </div>

              <div className="mb-3">
                <div className="mb-3 form-group">
                  <label htmlFor="cardnumber" className="form-label">
                    <b>Card Number *</b>
                  </label>

                  <div className="form-control shadow">
                    <CardNumberElement />
                  </div>
                  {errorCardInvalid?.card_number !== '' ? (
                    <div className=" text-danger w-1 h-1 font-weight-light">
                      <small>{errorCardInvalid?.card_number}</small>
                    </div>
                  ) : null}
                  {errorCardIncomplete?.card_number !== '' ? (
                    <div className=" text-danger w-1 h-1 font-weight-light">
                      <small>{errorCardIncomplete?.card_number}</small>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <Field
                  component={Input}
                  type="text"
                  className="form-control shadow"
                  label="Cardholder's Name *"
                  name="cardholderName"
                  aria-describedby="cardholder_name"
                  placeholder="Cardholder’s Name"
                />
              </div>

              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <div className="mb-3 form-group">
                    <label htmlFor="expdate" className="form-label">
                      <b>Expiry Date *</b>
                    </label>
                    <div className="form-control shadow">
                      <CardExpiryElement />
                    </div>
                    {errorCardIncomplete?.card_expiry !== '' ? (
                      <div className="text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardIncomplete?.card_expiry}</small>
                      </div>
                    ) : null}
                    {errorCardInvalid?.card_expiry !== '' ? (
                      <div className="text-danger w-1 h-1 font-weight-light ">
                        <small>{errorCardInvalid?.card_expiry}</small>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="mb-3 form-group">
                    <label htmlFor="cvc" className="form-label">
                      <b>CVC / CVV *</b>
                    </label>
                    <div className="form-control shadow">
                      <CardCvcElement />
                    </div>
                    {errorCardIncomplete?.card_cvc !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardIncomplete?.card_cvc}</small>
                      </div>
                    ) : null}
                    {errorCardInvalid?.card_cvc !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardInvalid?.card_cvc}</small>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
              <button
                className="btn btn-primary w-100"
                type="submit"
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  <div role="button" style={{ width: '100%', boxSizing: 'border-box' }}>
                    <i className="fa-regular fa-floppy-disk me-1" /> Save
                  </div>
                )}
              </button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default CardForm;
