import React from 'react';

interface DashboardScoreProps {
  streakDays: number;
  scorePercentage: number;
  bestStreakDays: number;
}

function DashboardScore({ streakDays, scorePercentage, bestStreakDays }: DashboardScoreProps) {
  return (
    <div className="card border-color-transparent overflow-hidden">
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        {/* eslint-disable jsx-a11y/aria-role */}
        <div
          className="my-3 my-xl-5"
          role="circular-progressbar"
          aria-valuenow={scorePercentage}
          aria-valuemin={0}
          aria-valuemax={100}
          style={
            {
              '--progress-value': scorePercentage,
              '--progress-title': streakDays,
            } as React.CSSProperties
          }
        />
        {/* eslint-enable jsx-a11y/aria-role */}
      </div>
      <div className="bg-color-gray-200 p-3 text-center">
        <div className="h2 font-bold color-primary mb-2">{bestStreakDays}</div>
        <div className="w-100">
          <div className="d-inline-block">
            <span className="font-medium">Your best streak</span>
            <hr className="mt-0" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardScore;
