import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  FacebookShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share';
import { SessionInterface } from '../../redux/interface/entitiesInterface';
import { generateShareAudioUrl } from '../../redux/thunks/playerThunk';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { THUNK_STATUS } from '../../constants';

export const MODAL_NAME = 'shareAudio';

export interface ShareAudioProps {
  sessionPlayed: SessionInterface;
}

function ShareAudio({ sessionPlayed }: ShareAudioProps) {
  const dispatch = useAppDispatch();

  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const { urlToShare: urlToShareState } = useAppSelector((state) => state.player.loading);
  const urlToShare = useAppSelector((state) => state.player.urlToShare);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(urlToShare);
  };

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  useEffect(() => {
    if (showModal) {
      dispatch(generateShareAudioUrl({ sessionId: sessionPlayed.id }));
    }
  }, [sessionPlayed, showModal]);

  useEffect(() => {
    if (urlToShareState === THUNK_STATUS.REJECTED) {
      handleCloseModal();
    }
  }, [handleCloseModal, urlToShareState]);

  return (
    <Modal show={!!(showModal && urlToShare)} size="lg" centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12"
          aria-label="Close"
          onClick={handleCloseModal}
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h5 className="mb-4">Share</h5>
          <div className="socials-list">
            <div className="socials-list__item">
              <a href={`mailto:${urlToShare}`} className="btn-icon btn-icon-round">
                <i className="fa-brands fa-google" />
              </a>
              <span className="socials-list__item-title">GMail</span>
            </div>
            <div className="socials-list__item">
              <TwitterShareButton url={urlToShare} className="btn-icon btn-icon-round">
                <i className="fa-brands fa-twitter" />
              </TwitterShareButton>
              <span className="socials-list__item-title">Twitter</span>
            </div>
            <div className="socials-list__item">
              <FacebookShareButton url={urlToShare} className="btn-icon btn-icon-round">
                <i className="fa-brands fa-facebook" />
              </FacebookShareButton>
              <span className="socials-list__item-title">Facebook</span>
            </div>
            <div className="socials-list__item">
              <WhatsappShareButton url={urlToShare} className="btn-icon btn-icon-round">
                <i className="fa-brands fa-whatsapp" />
              </WhatsappShareButton>
              <span className="socials-list__item-title">WhatsApp</span>
            </div>
            <div className="socials-list__item">
              <ViberShareButton url={urlToShare} className="btn-icon btn-icon-round">
                <i className="fa-brands fa-viber" />
              </ViberShareButton>
              <span className="socials-list__item-title">Viber</span>
            </div>
          </div>
          <hr className="w-75 mx-auto" />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="p-1 d-flex align-items-center justify-content-between bg-color-white radius-10 w-100 overflow-hidden">
          <a href={urlToShare} className="text-truncate color-blue">
            {urlToShare}
          </a>
          <button
            type="button"
            className="btn btn-primary px-3 ms-3 d-flex align-items-center"
            onClick={handleCopyToClipboard}
          >
            <i className="fa-regular fa-copy me-1" />
            &nbsp;Copy
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareAudio;
