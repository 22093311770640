/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useAppDispatch } from '../../../redux/hooks';
import { PrimerType } from '../../../redux/interface/channelsInterface';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import AddPrimerToChannel from '../../../containers/channels/modals/addPrimerToChannel';
import { swapElement } from '../../../helpers';

interface SelectPrimerProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
  primerType: PrimerType;
}

const extractSession = (sessItem: any) => (sessItem?.session ? sessItem.session : sessItem);

function SelectPrimer({ field, form, label, primerType }: SelectPrimerProps) {
  const dispatch = useAppDispatch();
  const isDeepPrimer = primerType === PrimerType.DEEP;
  const prefixLabel = isDeepPrimer ? 'Deep' : 'Power';

  const { value, name } = field;
  const { setFieldValue, setFieldTouched } = form;

  const [data, setData] = useState(value ? [...value] : []);

  useEffect(() => {
    setData(value);
  }, [value]);

  const deletePrimerFromList = (id: number) => {
    const newData = data.filter((sessListItem: any) => extractSession(sessListItem).id !== id);
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const reArrangeList = (action: string, primerId: string) => {
    const primers = [...data];
    const itemIndex = primers.findIndex((item: any) => item.session.id === primerId);
    const nextIndex = action === 'up' ? itemIndex - 1 : itemIndex + 1;
    const orderedPrimers = swapElement(primers, itemIndex, nextIndex).map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setData(orderedPrimers);
    setFieldValue(name, orderedPrimers);
  };

  const addPrimerToList = (selectedPrimer: any) => {
    const newData = [...data, { session: selectedPrimer }];
    setFieldValue(name, newData);
    setFieldTouched(name, true);
    setData(newData);
  };

  const handleOpenAddPrimerModal = () => {
    dispatch(modalActions.setVisibleModal(`Add${prefixLabel}PrimerToChannelModal`));
  };

  return (
    <>
      <h6 className="text-center">{label || `${prefixLabel} Primers`}</h6>
      <ul className="list-group list-group--channels mb-2">
        {data?.map((pr: any, index: number) => {
          const primer = pr?.session
            ? { title: pr?.session?.title, id: pr?.session?.id }
            : { title: pr?.title, id: pr?.id };
          return (
            <li className="list-group-item" key={primer.id}>
              <span className="order-icons">
                <i
                  className={`fa-solid fa-arrow-up ${index === 0 ? 'disabled' : ''}`}
                  role="button"
                  tabIndex={-1}
                  aria-label={`Move up ${primer.title}`}
                  onKeyUp={() => (index === 0 ? {} : reArrangeList('up', primer.id))}
                  onClick={() => (index === 0 ? {} : reArrangeList('up', primer.id))}
                />
                <i
                  className={`fa-solid fa-arrow-down ${
                    index === data.length - 1 ? 'disabled' : ''
                  }`}
                  role="button"
                  tabIndex={-1}
                  aria-label={`Move down ${primer.title}`}
                  onKeyUp={() =>
                    index === data.length - 1 ? {} : reArrangeList('down', primer.id)
                  }
                  onClick={() =>
                    index === data.length - 1 ? {} : reArrangeList('down', primer.id)
                  }
                />
              </span>
              <span>{primer.title}</span>

              <i
                className="fa fa-times"
                role="button"
                tabIndex={-1}
                aria-label={`Remove ${primer.title}`}
                onKeyUp={() => deletePrimerFromList(primer.id)}
                onClick={() => deletePrimerFromList(primer.id)}
              />
            </li>
          );
        })}
      </ul>

      <button
        className="btn btn-primary w-100 radius-8"
        type="button"
        onClick={handleOpenAddPrimerModal}
      >
        <i className="fa fa-plus" />
        &nbsp;
        {`Add ${prefixLabel} Primer`}
      </button>
      <AddPrimerToChannel
        primerType={primerType}
        selectedPrimers={data.map((sessListItem: any) => extractSession(sessListItem))}
        onSelectPrimer={addPrimerToList}
      />
    </>
  );
}

SelectPrimer.defaultProps = {
  label: '',
};

export default SelectPrimer;
