import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
// import useBreakpoint from 'use-breakpoint';
import CourseCard from './courseCard';
import {
  CourseInterface,
  SessionInterface,
  ChannelInterface,
  CardEntityTypeReq,
} from '../../redux/interface/entitiesInterface';
import { breakpoints, breakpointOrder, slidersResponsive } from '../../constants/mediaQueries';
// import CourseGrid from '../courseGrid';

enum ArrowDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

interface ArrowProps {
  className?: string;
  direction?: ArrowDirection;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface CourseSliderProps {
  headline?: string;
  description?: string;
  siblingTypes?: CardEntityTypeReq;
  parentType?: string;
  parentInfo?: CourseInterface | ChannelInterface;
  refer?: string;
  items: CourseInterface[] | SessionInterface[];
}

function SliderArrowNav({ className, direction, onClick }: ArrowProps) {
  const isLeft = direction === ArrowDirection.LEFT;
  return (
    <button
      className={classNames(`slider__control-${isLeft ? 'prev' : 'next'}`, className)}
      type="button"
      onClick={onClick}
    >
      <i className={classNames('fa-regular', `fa-chevron-${direction}`, 'icon-size-18')} />
    </button>
  );
}

SliderArrowNav.defaultProps = {
  className: '',
  direction: ArrowDirection.LEFT,
  onClick: () => {},
};

const responsive = breakpointOrder.map((breakpoint) => ({
  breakpoint: breakpoints[breakpoint],
  settings: {
    slidesToShow: slidersResponsive[breakpoint],
  },
}));
function CourseSlider({
  headline,
  description,
  items,
  siblingTypes,
  parentType,
  parentInfo,
  refer,
}: CourseSliderProps) {
  // const { breakpoint } = useBreakpoint(breakpoints);
  // const breakpointIdx = breakpointOrder.indexOf(breakpoint?.toString() || 'sm');
  // const { slidesToShow } = responsive[breakpointIdx].settings;

  const prevArrow = <SliderArrowNav direction={ArrowDirection.LEFT} />;

  const nextArrow = <SliderArrowNav direction={ArrowDirection.RIGHT} />;

  return (
    <div className="row mb-4">
      {(headline || description) && (
        <div className="col-12 mb-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center mb-3">
              <h4 className="m-0">{headline}</h4>
            </div>
            <div className="col-12">
              <p className="m-0 color-gray-500">{description}</p>
            </div>
          </div>
        </div>
      )}
      <div className="col-12">
        <div className="slider">
          <Slider
            dots={false}
            className="slider__inner"
            infinite={false}
            speed={300}
            slidesToShow={3}
            slidesToScroll={1}
            prevArrow={prevArrow}
            nextArrow={nextArrow}
            responsive={responsive}
            variableWidth
          >
            {items.map((item: CourseInterface | SessionInterface) => {
              const { title, description: desc, id } = item;
              return (
                <div key={JSON.stringify({ title, description, id })} className="slider__item">
                  {/*  eslint-disable-next-line react/jsx-props-no-spreading */}
                  <CourseCard
                    imgSrc={'image' in item ? item?.image?.url : item?.media?.url}
                    title={title}
                    description={desc}
                    id={id}
                    type={siblingTypes}
                    parentType={parentType}
                    info={item}
                    parentInfo={parentInfo}
                    refer={refer}
                    isFree={
                      siblingTypes !== 'session' ||
                      (siblingTypes === 'session' && 'isFree' in item && item.isFree)
                    }
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

CourseSlider.defaultProps = {
  headline: '',
  description: '',
  siblingTypes: 'session',
  parentType: 'upgrade',
  refer: '',
  parentInfo: null,
};

export default CourseSlider;
