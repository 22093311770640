import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import {
  AddNewTeamInfo,
  EditTeamInfo,
  NewTeamMemberInfo,
  NewTeamMemberCSVInfo,
  TeamState,
} from '../interface/teamInterface';
import sendInvitesTeamMemberCSV from '../services/addNewTeamMemberCsv';

export const fetchTeamInfo = createAsyncThunk(
  'team/fetchTeamInfo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(`/api/teams/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getAllTeams = createAsyncThunk('team/getAllTeams', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response.data['hydra:view'];
  const data = {
    totalItems: response.data['hydra:totalItems'],
    teamsInfo: response.data['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const addNewTeam = createAsyncThunk(
  'team/addNewTeam',
  async (detail: AddNewTeamInfo, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiClient.postRequest('/api/teams', { ...detail, seats: 5 });
      dispatch(getAllTeams('/api/teams'));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getAllTeamMembers = createAsyncThunk('team/getAllTeamMember', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response.data['hydra:view'];
  const data = {
    totalItems: response.data['hydra:totalItems'],
    teamMembersInfo: response.data['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const editTeam = createAsyncThunk(
  'team/editTeam',
  async ({ id, seats }: EditTeamInfo, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as { team: TeamState };
      const { presentTeam } = state.team;
      const currentTeam = state.team.allTeamMembersInfo[0];
      const currentSeats = presentTeam ? presentTeam.seats : currentTeam.team.seats;
      const newSeats = currentSeats + seats;
      const response = await apiClient.putRequest(`/api/teams/${id}`, { seats: newSeats });
      const BASE_URL = '/api/teams';
      const currentUrl = `${BASE_URL}/${id}/users`;
      dispatch(getAllTeamMembers(currentUrl));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteSingleTeam = createAsyncThunk(
  'team/deleteSingleTeam',
  async (teamId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/teams/${teamId}`);
      const data = { data: response.data, teamId };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const addNewTeamMember = createAsyncThunk(
  'team/addNewTeamMember',
  async ({ team, ...detail }: NewTeamMemberInfo, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest(`${team}/members`, detail);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteSingleTeamMember = createAsyncThunk(
  'team/deleteSingleTeamMember',
  async ({ userId, teamId }: { userId: string; teamId: string }, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/teams/${teamId}/members/${userId}`);
      const data = { data: response.data, userId };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const addNewTeamMemberCsvs = createAsyncThunk(
  'team/addNewTeamMemberCSV',
  async (detail: NewTeamMemberCSVInfo, { rejectWithValue }) => {
    try {
      const response = await sendInvitesTeamMemberCSV.sendInvites(detail);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
