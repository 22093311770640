/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import AddNewTeamMember from './modals/addNewTeamMember';
import AddNewMemberCSV from './modals/addNewMemberCSV';
import AddSeats from './modals/addSeats';
import { handlePagination, setParamValue } from '../../helpers';
import DeleteTeamMember from './modals/deleteTeamMember';
import { fetchTeamInfo, getAllTeamMembers } from '../../redux/thunks/teamThunk';

const BASE_URL = '/api/teams';
interface stateType {
  teamId: string;
  seats: string;
  teamName: string;
}

export interface NewTeamMemberType {
  email: string;
  name: string;
  plan: string;
  type: string;
  team: string;
}

function Team() {
  const { teamId, seats, teamName } = useLocation().state as stateType;
  const [numberOfRows, setNumberOfRows] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useAppDispatch();

  const status = useAppSelector((state) => state.team.status);
  const teamMembers = useAppSelector((state) => state.team.allTeamMembersInfo);

  const presentTeam = useAppSelector((state) => state.team.presentTeam);

  const totalTeamMembers = useAppSelector((state) => state.team.totalItems);
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.appUsers.pagination,
  );

  const [memberToDelete, setMemberToDelete] = useState('');
  const [currentUrl, setCurrentUrl] = useState(`${BASE_URL}/${teamId}/users`);

  useEffect(() => {
    dispatch(getAllTeamMembers(currentUrl));
    dispatch(fetchTeamInfo(teamId));
  }, [currentUrl]);

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}/${teamId}/users?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'search', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}/${teamId}/users?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  };

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: `${BASE_URL}/${teamId}/users`,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  const [newTeamMember, setNewTeamMember] = useState<NewTeamMemberType>({
    email: '',
    name: '',
    plan: 'monthly',
    type: 'free',
    team: `api/teams/${teamId}`,
  });

  const handleDeletingMember = (id: string) => {
    setMemberToDelete(id);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor" />
              <div className="row pb-3 pb-md-4 align-items-center">
                <div className="col-12 col-lg-6 col-xl-7">
                  <h1 className="headline-decor">Teams - {teamName}</h1>
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                  <div className="row">
                    <div className="col-4 d-flex">
                      <button
                        type="button"
                        className="btn btn-primary px-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#add_seats_team"
                      >
                        <i className="fa fa-plus" />
                        Add Seats
                      </button>
                    </div>
                    <div className="col-4 d-flex">
                      <button
                        type="button"
                        className="btn btn-primary px-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#add_new_member_csv"
                      >
                        <i className="fa fa-user-plus" /> Upload CSV
                      </button>
                    </div>
                    <div className="col-4 d-flex">
                      <button
                        type="button"
                        className="btn btn-primary px-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#add_new_team_member"
                      >
                        <i className="fa fa-plus" />
                        Add New Member
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid content-inner">
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <Search value={searchInput} onInputChange={handleFilterUsers} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 p-0">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              <div className="d-flex align-items-center">
                                Name
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Usage</th>
                            <th>Time Spent</th>
                            <th>Created</th>
                            <th>Last Used</th>
                            <th>Revenue</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamMembers.map((member) => (
                            <tr key={`${member.id}${member.name}`}>
                              <td className="font-medium">{member.name}</td>
                              <td>{member.email}</td>
                              <td>{member.userType}</td>
                              <td>{member.usage}</td>
                              <td>{member.formattedTimeSpent || '0s'}</td>
                              <td>
                                {member.createdAt &&
                                  format(new Date(member.createdAt), 'MM/dd/yyyy')}
                              </td>
                              <td>
                                {member.updatedAt &&
                                  format(new Date(member.updatedAt), 'MM/dd/yyyy')}
                              </td>
                              <td>{member.revenue}</td>
                              <td className="actions-cell">
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_team_member"
                                  type="button"
                                  onClick={() => handleDeletingMember(`${member.id}`)}
                                >
                                  <i className="fa-solid fa-trash-can" />
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {status === 'loading' && (
                        <span className="row h-100 w-100 align-items-center justify-content-center">
                          <span className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        onPageChange={handlePageChange}
                        totalCount={totalTeamMembers || 0}
                        currentPage={currentPage}
                        pageSize={numberOfRows}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2">
                Total Seats:{' '}
                <span className="text-secondary mt-10">
                  {presentTeam.seats ? presentTeam?.seats : teamMembers[0]?.team?.seats}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddSeats teamId={teamId} />
      <AddNewTeamMember newTeamMember={newTeamMember} />
      <AddNewMemberCSV teamId={teamId} />
      <DeleteTeamMember memberToDelete={memberToDelete} teamId={teamId} />
    </>
  );
}

export default Team;
