import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { AppUsersState } from '../interface/appUsersInterface';
import { toast } from '../../helpers/toaster';
import {
  fetchAppUsers,
  fetchAppUsersHistory,
  createAppUser,
  updateAppUser,
  resetPasswordAppUser,
  provideSubscriptionAppUsers,
  getUserListenHistory,
  getUserSubscriptionHistory,
  updateAppUserSubscription,
  deleteAppUser,
  getUserDetail,
} from '../thunks/appUsersThunk';

const initialState: AppUsersState = {
  data: [],
  singleUserData: undefined,
  history: [],
  listenHistoryState: {
    listenHistory: [],
    totalItems: 0,
    status: undefined,
    error: undefined,
    pagination: {
      current: '',
      first: '',
      last: '',
      next: '',
      previous: '',
    },
  },
  subscriptionHistoryState: {
    subscriptionHistory: [],
    totalItems: 0,
    status: undefined,
    error: undefined,
    pagination: {
      current: '',
      first: '',
      last: '',
      next: '',
      previous: '',
    },
  },
  historyPagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  historyTotalItems: 0,
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAppUsers.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchAppUsers.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        data: action.payload.users,
        pagination: action.payload.pagination,
        totalItems: action.payload.totalItems,
      }))
      .addCase(fetchAppUsers.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(fetchAppUsersHistory.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchAppUsersHistory.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        history: action.payload.usersHistory,
        historyPagination: action.payload.pagination,
        historyTotalItems: action.payload.totalItems,
      }))
      .addCase(fetchAppUsersHistory.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(createAppUser.pending, (state) => ({
        ...state,
        modalName: 'addNewAppUser',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(createAppUser.fulfilled, (state, action) => {
        toast('Created app user successfully', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          data: [action.payload, ...state.data],
          totalItems: state.totalItems + 1,
          modalOpen: false,
        };
      })
      .addCase(createAppUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error creating app users';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteAppUser.pending, (state) => ({
        ...state,
        modalName: 'deleteAppUser',
        modalOpen: true,
        status: 'deleteLoading',
        error: undefined,
      }))
      .addCase(deleteAppUser.fulfilled, (state, action) => {
        toast('Deleted app user successfully', 'success');
        return {
          ...state,
          status: 'deleteSucceeded',
          error: undefined,
          data: state.data.filter((appUser: any) => appUser.id !== action.payload.id),
          totalItems: state.totalItems - 1,
          modalOpen: false,
        };
      })
      .addCase(deleteAppUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error deleting app user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'deleteFailed',
          error: errorMessage,
        };
      })
      .addCase(updateAppUser.pending, (state) => ({
        ...state,
        modalName: 'editAppUser',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(updateAppUser.fulfilled, (state, action) => {
        toast('Updated app user successfully', 'success');
        const indexToUpdate = findIndex(state.data, (user) => user.id === action.payload.id);
        const newUpdatedAppUser = [...state.data];
        newUpdatedAppUser[indexToUpdate] = action.payload;
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          data: newUpdatedAppUser,
          modalOpen: false,
        };
      })
      .addCase(updateAppUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error updating app user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(updateAppUserSubscription.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(updateAppUserSubscription.fulfilled, (state, action) => {
        const indexToUpdate = findIndex(state.data, (user) => user.id === action.payload.id);
        const newUpdatedAppUser = [...state.data];
        newUpdatedAppUser[indexToUpdate] = action.payload;
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          data: newUpdatedAppUser,
        };
      })
      .addCase(updateAppUserSubscription.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error updating app user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(resetPasswordAppUser.pending, (state) => ({
        ...state,
        modalName: 'resetPassword',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(resetPasswordAppUser.fulfilled, (state) => {
        toast('Email sent for reset successfully', 'success');
        return {
          ...state,
          status: 'succeeded',
          modalOpen: false,
        };
      })
      .addCase(resetPasswordAppUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error reseting app user password';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(provideSubscriptionAppUsers.pending, (state) => ({
        ...state,
        modalName: 'provideSubscriptionsAppUser',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(provideSubscriptionAppUsers.fulfilled, (state, action) => {
        toast('Subscription provided successfully', 'success');
        const indexToUpdate = findIndex(state.data, (user) => user.id === action.payload.id);
        const newUpdatedAppUser = [...state.data];
        newUpdatedAppUser[indexToUpdate] = action.payload;
        return {
          ...state,
          status: 'succeeded',
          data: newUpdatedAppUser,
          modalOpen: false,
        };
      })
      .addCase(provideSubscriptionAppUsers.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error providing subscription app user';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(getUserListenHistory.pending, (state) => ({
        ...state,
        listenHistoryState: {
          ...state.listenHistoryState,
          status: 'loading',
          error: undefined,
        },
      }))
      .addCase(getUserListenHistory.fulfilled, (state, action) => ({
        ...state,
        listenHistoryState: {
          ...state.listenHistoryState,
          status: 'succeeded',
          listenHistory: action.payload.usersListenHistory,
          pagination: action.payload.pagination,
          totalItems: action.payload.totalItems,
        },
      }))
      .addCase(getUserListenHistory.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error in user listen history';
        toast(errorMessage, 'error');
        return {
          ...state,
          listenHistoryState: {
            ...state.listenHistoryState,
            status: 'failed',
            error: errorMessage,
          },
        };
      })
      .addCase(getUserSubscriptionHistory.pending, (state) => ({
        ...state,
        subscriptionHistoryState: {
          ...state.subscriptionHistoryState,
          status: 'loading',
          error: undefined,
        },
      }))
      .addCase(getUserSubscriptionHistory.fulfilled, (state, action) => ({
        ...state,
        subscriptionHistoryState: {
          ...state.subscriptionHistoryState,
          subscriptionHistory: action.payload.usersSubscriptionHistory,
          pagination: action.payload.pagination,
          totalItems: action.payload.totalItems,
        },
      }))
      .addCase(getUserSubscriptionHistory.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error in user subscription history';
        toast(errorMessage, 'error');
        return {
          ...state,
          subscriptionHistoryState: {
            ...state.subscriptionHistoryState,
            status: 'failed',
            error: errorMessage,
          },
        };
      })
      .addCase(getUserDetail.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(getUserDetail.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        singleUserData: action.payload,
      }))
      .addCase(getUserDetail.rejected, (state, action:any) => {
        const errorMessage = action?.payload || 'Error in getting user detail';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default appUsersSlice.reducer;
