/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, ChangeEvent } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Input from '../../components/formik/input';
import { useAppDispatch } from '../../redux/hooks';
import SavePublishChannel from './modals/savePublishChannel';
import SaveUnpublishChannel from './modals/saveUnpublishedChannel';
import { filterTags } from '../../helpers/filterTag';
import DragImage from '../../components/formik/dragImage';
import Dropdown from '../../components/formik/dropdown';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { PrimerType } from '../../redux/interface/channelsInterface';
import SelectPrimer from '../../components/formik/channelFields/selectPrimer';
import SelectCourses from '../../components/formik/channelFields/selectCourses';
import SelectChannel from '../../components/formik/channelFields/selectChannel';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the title name'),
  subtitle: Yup.string().required('Please enter subtitle'),
  file: Yup.string().required('File is required'),
  slug: Yup.string().required('Slug is required'),
  category: Yup.string().required('Category is required'),
});

function CreateNewChannel() {
  const dispatch = useAppDispatch();

  const [newChannel, setNewChannel] = useState<any>({
    title: '',
    subtitle: '',
    description: '',
    slug: '',
    tags: [],
    isSleep: false,
    isPublished: false,
    deepPrimer: [],
    powerPrimer: [],
    courses: [],
    channels: [],
    channelSessions: [],
    file: '',
    category: '',
  });

  const [selectedFile, setSelectedFile] = useState<File>();
  const [save, setSave] = useState<string>('');

  const [newTag, setNewTag] = useState<any>();
  const [filteredTags, setFilteredTags] = useState<any>();

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content mh-100">
              <div className="content_decor">
                <div className="row pb-3 pb-md-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <h1 className="headline-decor">Create New Channel</h1>
                  </div>
                </div>

                <div className="container-fluid content-inner py-3 py-lg-4 px-3 px-lg-4 px-xxl-5">
                  <Formik
                    initialValues={newChannel}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      const preSelectedApiPower = values.powerPrimer?.map(
                        (pp: any, index: number) => ({
                          session: `/api/sessions/${pp?.session?.id}`,
                          type: 'power-primer',
                          position: index + 1,
                        }),
                      );

                      const preSelectedApiDeep = values.deepPrimer?.map(
                        (dp: any, index: number) => ({
                          session: `/api/sessions/${dp?.session?.id}`,
                          type: 'deep-primer',
                          position: index + 1,
                        }),
                      );

                      const preSelectedApiChannel = values.channels?.map(
                        (dc: any, index: number) => ({
                          channelTarget: `/api/channels/${dc?.id}`,
                          position: index + 1,
                        }),
                      );
                      const preSelectedApiCourses = values.courses?.map(
                        (dc: any, index: number) => ({
                          course: `/api/courses/${dc?.id}`,
                          position: index + 1,
                        }),
                      );

                      setNewChannel({
                        ...values,
                        description: newChannel?.description,
                        isSleep: newChannel?.isSleep,
                        channelSessions: [...preSelectedApiDeep, ...preSelectedApiPower],
                        courses: preSelectedApiCourses,
                        channels: preSelectedApiChannel,
                      });
                      if (save === 'publish') {
                        dispatch(modalActions.setVisibleModal('SavePublishChannelModal'));
                      }
                      if (save === 'unpublish') {
                        dispatch(modalActions.setVisibleModal('SaveUnpublishChannelModal'));
                      }
                    }}
                  >
                    {({ handleSubmit }) => {
                      useEffect(() => {
                        setFilteredTags(filterTags(newTag, newChannel?.tags));
                      }, [newTag, newChannel?.tags]);
                      return (
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="row mb-4">
                                <div className="col-12 col-lg-6 col-xl-3">
                                  <div className="row">
                                    <div className="col-12 mb-3">
                                      <Field
                                        component={Input}
                                        type="text"
                                        name="title"
                                        description="title"
                                        placeholder="Dailies"
                                        label="Title *"
                                      />
                                    </div>
                                    <div className="col-12 mb-3 mb-xl-0">
                                      <Field
                                        component={Input}
                                        type="text"
                                        name="subtitle"
                                        description="subtitle"
                                        placeholder="Quick Confidence"
                                        label="Subtitle *"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3">
                                  <div className="row">
                                    <div className="col-12 mb-3">
                                      <Field
                                        component={Input}
                                        type="text"
                                        name="slug"
                                        description="slug"
                                        placeholder="Channel slug"
                                        label="Channel slug *"
                                      />
                                    </div>

                                    <div className="col-12 mb-3 mb-xl-0">
                                      <Field
                                        component={DragImage}
                                        name="file"
                                        label="Channel Image *"
                                        setSelectedFile={setSelectedFile}
                                        selectedFile={selectedFile}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-lg-0 d-flex flex-column">
                                  <p className="form-label">Description</p>
                                  <textarea
                                    className="form-control shadow flex-grow-1"
                                    id="description"
                                    placeholder="Description"
                                    onChange={(e) =>
                                      setNewChannel({ ...newChannel, description: e.target.value })
                                    }
                                  />
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 d-flex mb-3 mb-lg-0 flex-column">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p className="form-label">Tags</p>
                                  </div>
                                  <div className="col-12 mb-7 mb-xl-0 form-control tags-field @@classNameName ">
                                    <div
                                      className="d-inline-block input"
                                      contentEditable
                                      defaultValue=""
                                      onInput={(e) => setNewTag(e.currentTarget.textContent)}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <Field
                                      component={Dropdown}
                                      id="category"
                                      name="category"
                                      label="Awards *"
                                      placeholder="Select Awards"
                                      options={[
                                        { name: 'None', value: 'None' },
                                        { name: 'Competitors', value: 'competitors' },
                                        { name: 'Work', value: 'work' },
                                        { name: 'Quick Shift', value: 'quick-shift' },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row form-check me-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="isSleep"
                                  name="isSleep"
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setNewChannel({ ...newChannel, isSleep: event.target.checked });
                                  }}
                                />
                                <p className="form-check-label">Sleep Channel</p>
                              </div>
                              <div className="row">
                                <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                                  <Field
                                    name="deepPrimer"
                                    component={SelectPrimer}
                                    primerType={PrimerType.DEEP}
                                  />
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-xl-0">
                                  <Field
                                    name="powerPrimer"
                                    component={SelectPrimer}
                                    primerType={PrimerType.POWER}
                                  />
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3 mb-3 mb-lg-0">
                                  <Field name="courses" component={SelectCourses} />
                                </div>
                                <div className="col-12 col-lg-6 col-xl-3">
                                  <Field name="channels" component={SelectChannel} />
                                </div>
                              </div>

                              <div className="row justify-content-end align-items-end">
                                <div className="col-12 col-lg-6 col-xl-3">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-primary w-100 dropdown-toggle mt-20"
                                      type="button"
                                      id="chanenel-edit-dropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Save channel
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          onClick={() => {
                                            setSave('publish');
                                            handleSubmit();
                                          }}
                                        >
                                          Save & Publish
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() => {
                                            setSave('unpublish');
                                            handleSubmit();
                                          }}
                                          type="button"
                                        >
                                          Save as unpublished
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SavePublishChannel
        channel={{
          ...newChannel,
          channels: undefined,
          courses: undefined,
          channelUpgrades: newChannel.courses,
          subChannels: newChannel.channels,
          tags: filteredTags,
          isPublished: true,
        }}
        type="create"
      />
      <SaveUnpublishChannel
        channel={{
          ...newChannel,
          channels: undefined,
          courses: undefined,
          channelUpgrades: newChannel.courses,
          subChannels: newChannel.channels,
          tags: filteredTags,
          isPublished: false,
        }}
        type="create"
      />
    </>
  );
}

export default CreateNewChannel;
