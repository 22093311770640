import React, { useState, ChangeEvent, useEffect, MouseEvent } from 'react';
import classNames from 'classnames';
import { debounce, orderBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { formatDistance } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import Search from '../../components/search';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AdminUserForm, { MODAL_NAME as TEAM_MEMBER_FORM_MODAL } from './modals/adminUserForm';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import AdminUserCSVForm from './modals/adminUserCSVForm';
import AdminUserConfirmDelete, {
  MODAL_NAME as TEAM_MEMBER_CONFIRM_DELETE_MODAL,
} from './modals/adminUserConfirmDelete';
import { getTeamMemberList } from '../../redux/thunks/userSettingsThunk';
import { UserInfo } from '../../redux/interface/currentUserInterface';
import { UserFormInfo } from '../../redux/interface/userSettingsInterface';
import { useAuth } from '../../redux/authLayer';
import { THUNK_LOADING, defaultMetatags } from '../../constants';
import AdminUserMemberCountForm from './modals/adminUserMemberCountForm';
import { actions as pageActions } from '../../redux/slices/paginationSlice';
import ShowRows from '../../components/showRows';
import Pagination from '../../components/pagination';

const NAMESPACE: string = 'teamUsers';

enum SortValue {
  NONE = 'none',
  ASC = 'ascend',
  DESC = 'descend',
}

function TeamUsers() {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector((state) => state.pagination?.teamUsers);
  const [members, setMembers] = useState<UserInfo[]>([]);
  const [sortMode, setSortMode] = useState<SortValue>(SortValue.NONE);
  const status = useAppSelector((state) => state.userSettings.loading.teamMembers);
  const { list: teamMembers, metas } = useAppSelector((state) => state.userSettings.teamMembers);
  const { teamOwner } = useAuth();
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    if (teamOwner && pagination) {
      const { current, size, filters } = pagination;
      let params = {
        teamId: teamOwner.id.toString(),
        page: current.toString(),
        itemsPerPage: size.toString(),
      };
      if (filters) {
        params = {
          ...params,
          ...filters,
        };
      }

      dispatch(getTeamMemberList(params));
    }
  }, [pagination]);

  const resetPagination = () => dispatch(pageActions.initPage(NAMESPACE));

  useEffect(() => {
    resetPagination();
  }, [teamOwner]);

  useEffect(() => {
    if (sortMode === SortValue.DESC) {
      setMembers(orderBy(teamMembers, ['lastLogin'], ['desc']));
    } else if (sortMode === SortValue.ASC) {
      setMembers(orderBy(teamMembers, ['lastLogin'], ['asc']));
    } else {
      setMembers(teamMembers);
    }
  }, [teamMembers, sortMode]);

  const handleShowRows = (rows: number) => {
    dispatch(pageActions.setPage({ ...pagination, namespace: NAMESPACE, size: rows, current: 1 }));
  };

  const handlePageChange = (pageNumber: number) => {
    dispatch(pageActions.setPage({ ...pagination, namespace: NAMESPACE, current: pageNumber }));
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    if (value === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filters, ...params } = pagination;
      dispatch(pageActions.setPage({ ...params, namespace: NAMESPACE, current: 1 }));
    } else {
      dispatch(
        pageActions.setPage({
          ...pagination,
          filters: { search: value },
          namespace: NAMESPACE,
          current: 1,
        }),
      );
    }
  }, 500);

  const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
    debouncedSearchTrigger(e.target.value);
  };

  const handleOpenModalNewUser = () => {
    dispatch(modalActions.clearInfoForModal(TEAM_MEMBER_FORM_MODAL));
    dispatch(modalActions.setVisibleModal(TEAM_MEMBER_FORM_MODAL));
  };

  const handleOpenModalCSV = () => {
    dispatch(modalActions.setVisibleModal('adminUserCSVForm'));
  };

  const handleOpenMemberCountForm = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(modalActions.setVisibleModal('adminUserMemberCountForm'));
  };

  const handleOpenModalEditUser = ({ userId, name, email }: UserFormInfo) => {
    dispatch(modalActions.setInfo({ [TEAM_MEMBER_FORM_MODAL]: { userId, name, email } }));
    dispatch(modalActions.setVisibleModal(TEAM_MEMBER_FORM_MODAL));
  };

  const handleConfirmUserDelete = ({ name, userId }: UserFormInfo) => {
    dispatch(modalActions.setInfo({ [TEAM_MEMBER_CONFIRM_DELETE_MODAL]: { name, userId } }));
    dispatch(modalActions.setVisibleModal(TEAM_MEMBER_CONFIRM_DELETE_MODAL));
  };

  const handleChangeSort = () => {
    if (sortMode === SortValue.NONE) {
      setSortMode(SortValue.DESC);
    } else if (sortMode === SortValue.DESC) {
      setSortMode(SortValue.ASC);
    } else {
      setSortMode(SortValue.NONE);
    }
  };

  const renderRow = (rowData: UserInfo, even = false) => {
    const { userId, name, email, media, lastLogin, formattedTimeSpent } = rowData;
    const firstChar = name === null || name === undefined ? email.charAt(0).toUpperCase() : name.charAt(0).toUpperCase()

    return (
      <tr key={JSON.stringify(rowData)}>
        <td className="font-medium">
          <div className="d-flex align-items-center">
            <div className={classNames('avatar me-2', even && 'inverse')}>
              {media ? <img src={media.url} alt={name} /> : firstChar}
            </div>
            {name}
          </div>
        </td>
        <td>{email}</td>
        <td className="text-center">
          {lastLogin ? formatDistance(new Date(lastLogin), new Date(), { addSuffix: true }) : '-'}
        </td>
        <td className="text-center">{formattedTimeSpent || '0s'}</td>
        <td className="actions-cell">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleOpenModalEditUser({ userId: Number(userId), name, email })}
          >
            <i className="fa-regular fa-pen-to-square me-1" />
            Edit
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => handleConfirmUserDelete({ userId: Number(userId), name, email })}
          >
            <i className="fa-regular fa-trash-can me-1" />
            Delete
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
      <Helmet>
        <title>{defaultMetatags.title}</title>
        <meta name="description" content={defaultMetatags.description} />
      </Helmet>
      <div className="content_decor" />

      <div className="row pb-3 pb-md-4 align-items-center">
        <div className="col-12 col-lg-6 col-xl-7">
          <h1 className="headline-decor">Team</h1>
        </div>
        <div className="col-12 col-lg-6 col-xl-5 d-flex justify-content-start justify-content-lg-end">
          <button
            type="button"
            className="btn btn-primary px-3 me-4"
            onClick={handleOpenModalNewUser}
          >
            <i className="fa-regular fa-plus me-1" />
            Add new user
          </button>
          <button type="button" className="btn btn-primary px-3" onClick={handleOpenModalCSV}>
            <i className="fa-regular fa-file-circle-plus me-1" />
            Add via CSV
          </button>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12 col-md-6 mb-2 mb-md-0">
          <span className="color-gray-600">
            Total users account available:{' '}
            <span className="font-semi-bold color-secondary">
              {typeof teamOwner?.seats === 'number' ? teamOwner.seats - metas.total : 0}
            </span>
          </span>
        </div>
        <div className="col-12 col-md-6 justify-content-md-end d-flex">
          <span className="color-gray-600">
            Need more licenses? &nbsp;
            <a
              href="./"
              onClick={handleOpenMemberCountForm}
              className="font-semi-bold color-secondary text-decoration-none"
              role="button"
            >
              Click here
            </a>
            .
          </span>
        </div>
      </div>

      <div className="container-fluid content-inner">
        <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
          <div className="col-12 ps-0">
            <div className="d-flex">
              <Search value={searchKeyword} onInputChange={handleChangeKeyword} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="font-medium">Name</th>
                    <th className="font-medium">Email Address</th>
                    <th className="text-center font-medium btn" onClick={handleChangeSort}>
                      Last login&nbsp;
                      {sortMode === SortValue.ASC && <span className="fa-solid fa-caret-up" />}
                      {sortMode === SortValue.DESC && <span className="fa-solid fa-caret-down" />}
                      {sortMode === SortValue.NONE && <span className="fa-solid" />}
                    </th>
                    <th className="text-center font-medium">Total time spent</th>
                    <th className="font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {status === THUNK_LOADING ? (
                    <>
                      <tr>
                        <td colSpan={5} className="font-medium" style={{ textAlign: 'center' }}>
                          <Skeleton height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="font-medium" style={{ textAlign: 'center' }}>
                          <Skeleton height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="font-medium" style={{ textAlign: 'center' }}>
                          <Skeleton height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="font-medium" style={{ textAlign: 'center' }}>
                          <Skeleton height={30} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="font-medium" style={{ textAlign: 'center' }}>
                          <Skeleton height={30} />
                        </td>
                      </tr>
                    </>
                  ) : (
                    members.map((data, idx) => renderRow(data, idx % 2 === 1))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
          <div className="col-6 ps-0">
            <div className="d-flex">
              <ShowRows numberOfRows={pagination?.size || 10} onSelection={handleShowRows} />
            </div>
          </div>
          <div className="col-6 pe-0">
            <div className="d-flex justify-content-end">
              <Pagination
                onPageChange={handlePageChange}
                totalCount={metas?.total || 0}
                currentPage={pagination?.current || 1}
                pageSize={pagination?.size || 10}
              />
            </div>
          </div>
        </div>
      </div>
      <AdminUserForm onSubmitSuccess={resetPagination} />
      <AdminUserCSVForm onSubmitSuccess={resetPagination} />
      <AdminUserConfirmDelete onSubmitSuccess={resetPagination} />
      <AdminUserMemberCountForm />
    </div>
  );
}

export default TeamUsers;
