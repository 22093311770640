import React from 'react';
import { useNavigate } from 'react-router-dom';
import defaultProfileImg from '../assets/img/pmd_defaultavatar.png';
import { useAuth } from '../redux/authLayer';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions } from '../redux/slices/modalSlice';
import BadgeProgressBar from './cards/badgeProgressBar';

/* const badgeProgresses = [
  {
    title: 'Mountain Era',
    media: {
      url: 'https://storage.googleapis.com/primed-mind/award/trophy.png',
    },
    score: 83,
  },
  {
    title: 'Night Warrior',
    media: {
      url: 'https://storage.googleapis.com/primed-mind/award/star.png',
    },
    score: 52,
  },
  {
    title: 'Foresight',
    media: {
      url: 'https://storage.googleapis.com/primed-mind/award/achivment.png',
    },
    score: 71,
  },
]; */

function Header() {
  const { isEndUser } = useAuth();
  const navigate = useNavigate();
  const { email, name, media } = useAppSelector((state) => state.currentUser);
  const dispatch = useAppDispatch();

  const handleProfileClick = () => dispatch(actions.setVisibleModal('editProfile'));

  return (
    <div className="header container-fluid d-flex align-items-center justify-content-between px-3 px-md-4 px-xxl-5 py-2 py-md-3">
      {isEndUser ? (
        <BadgeProgressBar />
      ) : (
        <div className="header__navigation d-flex ">
          <button type="button" className="bg-white border-0" onClick={() => navigate(-1)}>
            <i className="fa-regular fa-angle-left icon-size-26" />
          </button>
          <button type="button" className="bg-white border-0" onClick={() => navigate(1)}>
            <i className="fa-regular fa-angle-right icon-size-26" />
          </button>
        </div>
      )}

      <div className="header__profile d-flex ms-auto ms-lg-5">
        {/* <button type='button' className='btn-icon me-3  me-md-4'>
          <i className='fa-regular fa-gear icon-size-26' />
        </button> */}

        <div className="header__profile-logo">
          <img
            src={media?.url || defaultProfileImg}
            alt={`${name} (${email})`}
            role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
            onClick={handleProfileClick}
            onKeyUp={handleProfileClick}
          />{' '}
          {/* eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        </div>

        {/* <button
          type='button'
          className='header__profile-btn btn btn-link font-medium text-decoration-none color-text pe-0'
          data-bs-toggle='modal'
          data-bs-target='#edit_profile'
        >
          <span className='d-none d-sm-inline'>Daniel Cooper</span>
          <i className='fa-solid fa-angle-down ms-sm-2  icon-size-16' />
        </button> */}
      </div>
    </div>
  );
}

export default Header;
