/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import Dropdown from '../../../components/formik/dropdown';
import DragAudio from '../../../components/formik/dragAudio';
import DragImage from '../../../components/formik/dragImage';
import { createSessions } from '../../../redux/thunks/sessionThunk';
import { NewSession, Narrator } from '../../../redux/interface/sessionInterface';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { filterTags } from '../../../helpers/filterTag';
import EditNarrator from './createEditNarrator';

interface AddNewSession {
  newSession: NewSession;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the session name'),
  description: Yup.string().required('Please enter description'),
  type: Yup.string().required('Type is required'),
  isFree: Yup.number().required('Audio type is required'),
  image: Yup.string().required('Image file is required'),
});

function AddNewSessions({ newSession }: AddNewSession) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.session);
  const content = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [newTag, setNewTag] = useState<any>();
  const [filteredTags, setFilteredTags] = useState<any>();

  const [error, setError] = useState(false);

  const [narratorId, setNarratorId] = useState<number>(1);

  const [narrators, setNarrators] = useState<Narrator[]>([]);
  const [editNarrator, setEditNarrator] = useState<Narrator>();

  const [more, setMore] = useState(false);

  const [selectedImageFile, setSelectedImageFile] = useState<File>();

  const [selectedNarratoAudioFile, setSelectedNarratorAudioFile] = useState<File>();

  const handleNarratorDelete = (id: any) => {
    const filteredNarrators = narrators.filter((narrator: Narrator) => narrator.id !== id);
    setNarrators(filteredNarrators);
  };

  useEffect(() => {
    if (narrators && editNarrator) {
      const restOfNarrator = narrators.filter(
        (narrator: Narrator) => narrator.id !== editNarrator?.id,
      );

      setNarrators(restOfNarrator.concat(editNarrator));
    }
  }, [editNarrator]);

  return (
    <>
      <div
        className="modal fade modal-edit-profile"
        id="add_new_session"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <Formik
          initialValues={{
            title: newSession?.title,
            description: newSession?.description,
            isFree: newSession?.isFree,
            type: newSession?.type,
            tags: newSession?.tags,
            image: newSession?.image,
            narratorName: '',
            narratorAudio: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (filteredTags === '') {
              setFilteredTags([]);
            }
            const { narratorName, narratorAudio, ...restOfSession } = values;
            dispatch(createSessions({ ...restOfSession, narrators, tags: filteredTags }));
          }}
        >
          {({ handleSubmit, resetForm, setValues, values }) => {
            const resetData = () => {
              resetForm();
              setFilteredTags([]);
              setSelectedNarratorAudioFile(undefined);
              setSelectedImageFile(undefined);
              setNarrators([]);
              setError(false);
              if (content.current) {
                content.current.textContent = '';
              }
            };
            useEffect(() => {
              if (status === 'succeeded' && modalName === 'addNewSession') {
                hideModal({ modalId: '#closeModal_addNewSession' });
                resetData();
              }
            }, [modalOpen]);

            useEffect(() => {
              setFilteredTags(filterTags(newTag, []));
            }, [newTag]);

            return (
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header pb-0 p-4">
                    <button
                      type="button"
                      className="btn-close icon-size-12 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => resetData()}
                    />
                  </div>
                  <div className="modal-body ps-5 pe-5">
                    <div className="text-center font-light">
                      <h4 className="mb-4">Add new Session</h4>
                    </div>

                    <form>
                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="title"
                          description="title"
                          placeholder="Title"
                          label="Session Name *"
                        />
                      </div>

                      <div className="mb-3">
                        <Field
                          component={Input}
                          type="text"
                          name="description"
                          description="description"
                          placeholder="Description"
                          label="Description *"
                        />
                      </div>

                      <div className="mb-3 flex-nowrap">
                        <p className="form-label">
                          <b>Audios</b>
                        </p>
                        {narrators.length !== 0 ? (
                          <div className="table-responsive bg-color-white  scrolling">
                            <table className="table no-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Narrator Name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {narrators &&
                                  narrators.map((narrator: Narrator) => (
                                    <tr key={narrator?.id}>
                                      <td>{narrator?.name}</td>
                                      <td className="actions-cell">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm btn-icon narrator-icons"
                                          onClick={() => setEditNarrator(narrator)}
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                          data-bs-target="#create_edit_narrator"
                                        >
                                          <i className="fa-solid fa-edit" />
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm btn-icon narrator-icons"
                                          onClick={() => handleNarratorDelete(narrator?.id)}
                                        >
                                          <i className="fa-solid fa-trash-can" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm mb-2 add-narrator-btn"
                            onClick={() => setMore(!more)}
                          >
                            <i className="fa-solid fa-music" />
                            Add audio narrator
                          </button>
                        </div>
                        {more ? (
                          <div className="bg-color-white radius-16 p-4">
                            {error ? (
                              <p className="text-danger fs-50">*Both fields are required</p>
                            ) : null}
                            <div>
                              <div>
                                <Field
                                  component={Dropdown}
                                  label=" Narrator *"
                                  placeholder="Select Narrator"
                                  name="narratorName"
                                  options={[
                                    { name: 'Elliot Roe', value: 'Elliot Roe' },
                                    { name: 'Christian Weilharter', value: 'Christian Weilharter' },
                                    { name: 'Adrienne Carter', value: 'Adrienne Carter' },
                                    { name: 'Samm Hunter', value: 'Samm Hunter' },
                                    { name: 'Stephen Baker', value: 'Stephen Baker' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div>
                              <Field
                                component={DragAudio}
                                name="narratorAudio"
                                label="Narrator Audio (.mp3)*"
                                setSelectedFile={setSelectedNarratorAudioFile}
                                selectedFile={selectedNarratoAudioFile}
                              />
                            </div>
                            <div className="d-flex justify-content-between">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm pr-4 ml-0"
                                onClick={() => {
                                  if (values?.narratorName !== '' && values?.narratorAudio !== '') {
                                    setNarrators([
                                      ...narrators,
                                      {
                                        name: values?.narratorName,
                                        audio: values?.narratorAudio,
                                        id: narratorId,
                                      },
                                    ]);
                                    setNarratorId(narratorId + 1);
                                    setValues({
                                      ...values,
                                      narratorName: '',
                                      narratorAudio: '',
                                    });
                                    setSelectedNarratorAudioFile(undefined);
                                    setMore(false);
                                    setError(false);
                                  } else {
                                    setError(true);
                                  }
                                }}
                              >
                                <i className="fa fa-check" />
                                save
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-dark btn-sm mr-0"
                                onClick={() => {
                                  setValues({
                                    ...values,
                                    narratorName: '',
                                    narratorAudio: '',
                                  });
                                  setSelectedNarratorAudioFile(undefined);
                                  setMore(false);
                                  setError(false);
                                }}
                              >
                                <i className="fa fa-times" />
                                cancel
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Field
                          component={DragImage}
                          name="image"
                          label="Image *"
                          setSelectedFile={setSelectedImageFile}
                          selectedFile={selectedImageFile}
                          from="session"
                        />
                      </div>

                      <div>
                        <Field
                          component={Dropdown}
                          name="type"
                          label="Category *"
                          placeholder="Select Category"
                          options={[
                            { name: 'Deep primer', value: 'deep-primer' },
                            { name: 'Power primer', value: 'power-primer' },
                            { name: 'Upgrades', value: 'upgrade' },
                            { name: 'Uncategorized', value: 'session' },
                          ]}
                        />
                      </div>

                      <div className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="form-label">Tags</p>
                        </div>
                        <p className="color-gray-600">
                          Enter the tags you want to add to the session separated by a comma
                        </p>
                        <div className="form-control tags-field @@classNameName">
                          {newSession?.tags?.map((t) => (
                            <div className="tag" key={t}>
                              <span className="tag__label">{t}</span>
                              <button
                                style={{
                                  border: 'none',
                                  margin: 0,
                                  padding: 0,
                                  background: 'none',
                                }}
                                type="button"
                              >
                                <i className="fa-regular fa-xmark" />
                              </button>
                            </div>
                          ))}
                          <div
                            className="d-inline-block input"
                            ref={content}
                            contentEditable
                            defaultValue=""
                            onInput={(e) => setNewTag(e.currentTarget.textContent)}
                          />
                        </div>
                      </div>

                      <div>
                        <Field
                          component={Dropdown}
                          name="isFree"
                          label="Select Type *"
                          placeholder="Select audio type"
                          options={[
                            { name: 'Free Audio', value: 1 },
                            { name: 'Paid audio', value: 0 },
                          ]}
                        />
                      </div>
                    </form>
                  </div>

                  <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 px-1 ps-0">
                          <button
                            type="button"
                            id="closeModal_addNewSession"
                            className="btn btn-primary w-100"
                            disabled={status === 'loading'}
                          >
                            {status === 'loading' ? (
                              <span
                                className="spinner-border"
                                style={{ width: '1em', height: '1em' }}
                                role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </span>
                            ) : (
                              <div
                                onClick={() => handleSubmit()}
                                role="button"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                              >
                                <i className="fa fa-check" />
                                Add New Session
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-6 px-1 pe-0">
                          <button
                            type="button"
                            className="btn btn-outline-dark w-100"
                            onClick={() => resetData()}
                            data-bs-dismiss="modal"
                          >
                            <i className="fa fa-times" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
      <EditNarrator narratorTOEdit={editNarrator} setNarratorToEdit={setEditNarrator} />
    </>
  );
}

export default AddNewSessions;
