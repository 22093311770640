import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationPayload, PaginationStateInterface } from '../interface/paginationInterface';

export const initialState: PaginationStateInterface = {
  global: {
    current: 1,
    size: 10,
  },
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    initPage: (state, action: PayloadAction<string>) => ({
      ...state,
      [action.payload]: { ...initialState.global },
    }),
    setPage: (state, { payload: { namespace, ...payload } }: PayloadAction<PaginationPayload>) => ({
      ...state,
      [namespace]: payload,
    }),
  },
});

export const { actions } = paginationSlice;

export default paginationSlice.reducer;
