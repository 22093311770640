/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  // CardNumberElement,
  // CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/formik/input';
import { actions } from '../../../redux/slices/userSettingsSlice';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { editCard, getCardList } from '../../../redux/thunks/userSettingsThunk';
import Dropdown from '../../../components/formik/dropdown';
// import ErrorAlert from '../../../components/errorAlert';
import { THUNK_STATUS } from '../../../constants';

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter the cardholder's name."),
  month: Yup.string().required('Please select the month'),
  year: Yup.string().required('Please select the year'),
});

interface ExpiryDate {
  name: string;
  value: string;
}
const months = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

let currentMonths = [...months];

function EditCardForm({ cardInfo }: any) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.userSettings.cardForm);
  const showModal = useAppSelector((state) => state.modal.visible === 'editCard');
  const [expiryArray, setExpiryArray] = useState<ExpiryDate[]>([]);

  useEffect(() => {
    const presentYear = new Date().getFullYear();
    const expiryDate: ExpiryDate[] = [];
    for (let i = presentYear; i <= presentYear + 10; i += 1)
      expiryDate.push({ name: i.toString(), value: i.toString() });
    setExpiryArray([...expiryDate]);
    dispatch(actions.clearCardFormStatuses());
    return () => {
      dispatch(actions.clearCardFormStatuses());
    };
  }, []);

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      dispatch(getCardList());
      // dispatch(modalActions.closeModal());
    }
  }, [status]);

  const handleCloseClick = () => dispatch(modalActions.closeModal());

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12"
          onClick={handleCloseClick}
          aria-label="Close"
        />
      </Modal.Header>
      <Formik
        initialValues={{ name: cardInfo.name, month: '', year: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (!stripe || !elements) {
            return;
          }

          dispatch(actions.beginCardFormPending());
          dispatch(
            editCard({
              name: values?.name,
              expiry_month: values?.month,
              expiry_year: values?.year,
              id: cardInfo?.id,
            }),
          );
        }}
      >
        {({ handleSubmit, values }) => {
          if (values.year === `${new Date().getFullYear()}`) {
            currentMonths = months.slice(new Date().getMonth(), 12);
          } else {
            currentMonths = months;
          }
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Body className="ps-5 pe-5">
                {/* <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} /> */}
                <div className="text-center font-light">
                  <h5 className="mb-3">Edit Card</h5>
                </div>

                <div className="mb-3">
                  <Field
                    component={Input}
                    type="text"
                    className="form-control shadow"
                    label="Cardholder's Name"
                    id="name"
                    name="name"
                    aria-describedby="cardholder_name"
                    placeholder="Cardholder’s Name"
                  />
                </div>

                <div className="row">
                  {/* <div className="col-12 col-sm-6 mb-3 mb-sm-0"> */}
                  <div className="mb-3form-group">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="expdate" className="form-label">
                      <b>Expiry Date</b>
                    </label>
                    <div className=" form-control shadow d-flex justify-content-between">
                      {/* <CardExpiryElement /> */}
                      <Field
                        component={Dropdown}
                        label="Month *"
                        id="month"
                        name="month"
                        placeholder="Select month"
                        options={currentMonths}
                      />
                      <Field
                        component={Dropdown}
                        label="Year *"
                        id="year"
                        name="year"
                        placeholder="Select year"
                        options={expiryArray}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
                <button
                  className="btn btn-primary px-5"
                  type="submit"
                  disabled={status === THUNK_STATUS.LOADING}
                  onClick={() => handleSubmit()}
                >
                  {status === THUNK_STATUS.LOADING ? (
                    <span
                      className="spinner-border"
                      style={{ width: '1em', height: '1em' }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  ) : (
                    <>
                      <i className="fa-regular fa-floppy-disk me-1" /> Save
                    </>
                  )}
                </button>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default EditCardForm;
