/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Input from '../../../components/formik/input';
import { EditCourses } from '../../../redux/interface/courseInterface';
import DragImage from '../../../components/dragImage';
import { updateCourse } from '../../../redux/thunks/courseThunk';
import { hideModal } from '../../../helpers/closeModalApiSuccess';
import { filterTags } from '../../../helpers/filterTag';
import { getCoursesChannels } from '../../../redux/thunks/channelsThunk';
import { THUNK_STATUS } from '../../../constants';

interface EditCourseProps {
  courseToEdit: EditCourses;
  opt: any;
}

interface SessionOptionInterface {
  value: string | undefined;
  label: string | undefined;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter the title name'),
  subtitle: Yup.string().required('Please enter subtitle'),
  sessions: Yup.array().min(1, 'Choose atleast one session from dropdown'),
});

function EditCourse({ courseToEdit, opt }: EditCourseProps) {
  const { status, modalOpen, modalName } = useAppSelector((state) => state.course);
  const { allCoursesPagination: pagination, totalItems } = useAppSelector((state) => state.channel);
  const dispatch = useAppDispatch();
  const [newTag, setNewTag] = useState<string | null>('');
  const [filteredTags, setFilteredTags] = useState<any>();
  const [pic, setPic] = useState<string | ArrayBuffer | null | undefined>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [remainingSession, setRemainingSession] = useState<SessionOptionInterface[]>([]);
  const [selectedSession, setSelectedSession] = useState<any>([]);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRemainingSession(() => {
      const results = opt.filter(
        ({ value: id1 }: any) => !selectedSession.some(({ value: id2 }: any) => id2 === id1),
      );
      return results;
    });
  }, [selectedSession]);

  const handleLoadMoreCourseChannels = (page: number) => {
    dispatch(getCoursesChannels({ page, itemsPerPage: 10 }));
  };

  return (
    <div
      className="modal fade modal-edit-profile"
      id="edit_course"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <Formik
        initialValues={courseToEdit}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const finalSession = [
            ...selectedSession.filter((value: { value: string }) => value.value !== ''),
          ];
          dispatch(
            updateCourse({
              ...values,
              tags: filteredTags,
              courseSessions: finalSession?.map((session: any, index) => ({
                position: index,
                session: session.value,
              })),
              image: pic,
            }),
          );
        }}
      >
        {({ handleSubmit, setValues, setTouched, values }) => {
          const handleRemove = (t: string) => {
            const fileteredTags = values?.tags?.filter((tag) => tag !== t);
            setValues({
              ...values,
              tags: fileteredTags,
            });
          };

          useEffect(() => {
            if (courseToEdit) {
              const preSelected = courseToEdit?.courseSessions?.map((selSession: any) => ({
                value: `/api/sessions/${selSession.id}`,
                label: selSession.title,
              }));
              setSelectedSession(preSelected);
              setSelectedFile(courseToEdit?.image);
              setValues({
                ...values,
                id: courseToEdit?.id,
                title: courseToEdit?.title,
                subtitle: courseToEdit?.subtitle,
                tags: courseToEdit?.tags,
                option: courseToEdit?.option,
                courseSessions: courseToEdit?.courseSessions,
                image: courseToEdit?.image,
                isDayCourse: courseToEdit?.isDayCourse,
              });
            }
          }, [courseToEdit]);

          useEffect(() => {
            setFilteredTags(filterTags(newTag, values?.tags));
          }, [newTag, values?.tags]);

          useEffect(() => {
            if (status === 'succeeded' && modalName === 'editCourse') {
              hideModal({ modalId: '#closeModal_editCourse' });
            }
            setTouched({});
            if (content.current) {
              content.current.textContent = '';
            }
          }, [modalOpen]);

          return (
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header pb-0 p-4">
                  <button
                    type="button"
                    className="btn-close icon-size-12 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setTouched({});
                      if (content.current) {
                        content.current.textContent = '';
                      }
                    }}
                  />
                </div>
                <div className="modal-body ps-5 pe-5">
                  <div className="text-center font-light">
                    <h4 className="mb-4">Edit Upgrade</h4>
                  </div>

                  <form>
                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="title"
                        description="title"
                        placeholder="Title"
                        label="Course Name *"
                      />
                    </div>

                    <div className="mb-3">
                      <Field
                        component={Input}
                        type="text"
                        name="subtitle"
                        description="subtitle"
                        placeholder="Subtitle"
                        label="Subtitle *"
                      />
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="form-label">Tags</p>
                      </div>
                      <p className="color-gray-600">
                        Enter the tags you want to add to the course separated by a comma
                      </p>
                      <div className="form-control tags-field @@classNameName">
                        {values?.tags?.map((t) => (
                          <div className="tag" key={`${t}${courseToEdit?.id}`}>
                            <span className="tag__label">{t}</span>
                            <button
                              style={{
                                border: 'none',
                                margin: 0,
                                padding: 0,
                                background: 'none',
                              }}
                              type="button"
                              onClick={() => handleRemove(t)}
                            >
                              <i className="fa-regular fa-xmark" />
                            </button>
                          </div>
                        ))}
                        <div
                          className="d-inline-block input"
                          ref={content}
                          contentEditable="true"
                          defaultValue=""
                          onInput={(e) => setNewTag(e.currentTarget.textContent)}
                        />
                      </div>
                    </div>

                    {opt && (
                      <div className="mb-3">
                        { /* eslint-disable-next-line jsx-a11y/label-has-for */ }
                        <label className="form-label">Sessions *</label>

                        {selectedSession.map((value: any, index: number) => (
                          <div className="row mb-2" key={value?.value}>
                            <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                              <span className="color-gray-600">{index + 1}</span>
                            </div>
                            <div className="col-10 p-0">
                              <Select
                                options={remainingSession}
                                onMenuScrollToBottom={() => {
                                  if (
                                    pagination.status !== THUNK_STATUS.LOADING &&
                                    pagination?.total &&
                                    remainingSession.length < totalItems - 1
                                  ) {
                                    handleLoadMoreCourseChannels(pagination.current + 1);
                                  }
                                }}
                                onChange={(valued) => {
                                  const newObj = {
                                    label: valued?.label,
                                    value: valued?.value,
                                  };
                                  setSelectedSession((pre: any) => {
                                    const obj = [...pre];
                                    obj[index] = newObj;
                                    return obj;
                                  });
                                }}
                                value={value}
                              />
                            </div>
                            <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                              <span className="cursor-pointer ms-2">
                                <i
                                  className="fa-regular fa-xmark color-black "
                                  onClick={() => {
                                    setSelectedSession((prev: any[]) =>
                                      prev.filter((_val, index1) => index1 !== index),
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            onClick={() => {
                              if (selectedSession.length === opt.length) return;
                              setSelectedSession((prev: any) => [
                                ...prev,
                                { value: '', label: 'Select...' },
                              ]);
                            }}
                          >
                            <i className="fa-regular fa-plus me-1" />
                            Add session
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <p className="form-label">
                        <b>Image *</b>
                      </p>
                      <DragImage
                        file={selectedFile}
                        setImage={setPic}
                        image={pic}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                      />
                    </div>

                    <div className="form-check me-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="isDayCourse"
                        name="isDayCourse"
                        checked={values?.isDayCourse}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setValues({ ...values, isDayCourse: event.target.checked });
                        }}
                      />
                      <p className="form-check-label">Categorize as upgrade</p>
                    </div>
                  </form>
                </div>
                <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-6 px-1 ps-0">
                        <button
                          type="button"
                          id="closeModal_editCourse"
                          className="btn btn-primary w-100"
                          disabled={status === 'loading'}
                        >
                          {status === 'loading' ? (
                            <span
                              className="spinner-border"
                              style={{ width: '1em', height: '1em' }}
                              role="status"
                            >
                              <span className="visually-hidden">Loading...</span>
                            </span>
                          ) : (
                            <div
                              onClick={() => handleSubmit()}
                              role="button"
                              style={{ width: '100%', boxSizing: 'border-box' }}
                            >
                              <i className="fa fa-check" />
                              Upgrade
                            </div>
                          )}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setTouched({});
                            if (content.current) {
                              content.current.textContent = '';
                            }
                          }}
                        >
                          <i className="fa fa-times pr-20" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default EditCourse;
