import { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { CheckoutPage } from '../../../redux/interface/checkoutPageInterface';
import { hideModal } from '../../../helpers/closeModalApiSuccess';

interface Props {
  handleDelete: (checkoutPage: CheckoutPage) => void;
}

function DeleteCheckoutPage({ handleDelete }: Props) {
  const { status, modalOpen, modalName, selectedCheckoutPage } = useAppSelector(state => state.checkoutPages);

  useEffect(() => {
    if (status === 'succeeded' && modalName === 'promptDeleteCheckout') {
      hideModal({ modalId: '#delete_checkout_page' });
    }
  }, [modalOpen]);

  const handleConfirmDelete = () => {
    if (selectedCheckoutPage) {
      handleDelete(selectedCheckoutPage);
    }
  };

  return (
    <div
      className="modal fade modal-edit-profile"
      id="delete_checkout_page"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              aria-label="Close"
              data-form-type=""
            />
          </div>
          <div className="modal-body ps-5 pe-5 pb-0">
            <div className="text-center font-light">
              <h5 className="mb-3">Delete Check-out Page?</h5>

              <p className="color-gray-600">
                You are about to delete check-out page <b>{selectedCheckoutPage?.name}</b>. Are you sure you would
                like to do this? This cannot be undone.
              </p>
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-dismiss="modal"
                  >
                    {' '}
                    <i className="fa fa-times me-1" />
                    Cancel
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-danger w-100"
                    onClick={handleConfirmDelete}
                  >
                    {' '}
                    <i className="fa fa-trash me-1" />
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteCheckoutPage;
