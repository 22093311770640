/* eslint-disable @typescript-eslint/no-unused-vars */
import { ROLES } from '../../constants';

interface UserProps {
  id: string;
  name: string;
  email: string;
  role: string;
  userType: ROLES;
}
interface AdminUserProps {
  id: string;
  name: string;
  email: string;
  userType: ROLES;
  role: string;
  handleEdit: (user: UserProps) => void;
  handleDelete: (userId: string) => void;
}
function AdminUser({ id, name, email, userType, handleEdit, role, handleDelete }: AdminUserProps) {
  const handleEditing = () => {
    handleEdit({ id, name, email, userType, role });
  };
  const handleDeleting = () => {
    handleDelete(id);
  };
  return (
    <tr key={id}>
      <td className="font-medium">{name}</td>
      <td>{email}</td>
      <td>{userType}</td>
      <td className="actions-cell">
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#edit_admin_user"
          onClick={handleEditing}
        >
          <i className="fas fa-edit" />
          Edit
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_admin_user"
          onClick={handleDeleting}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
      </td>
    </tr>
  );
}

export default AdminUser;
