import { useEffect, useState } from 'react';

export const useRefferal = () => {
  const [referral, setReferral] = useState<null | string>(null);

  useEffect(() => {
    window.rewardful('ready', () => {
      setReferral(window.Rewardful.referral);
    });
  }, []);

  return { referral };
};
