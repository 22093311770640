import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../components/formik/input';
import { userLogin } from '../redux/thunks/currentUserThunk';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { THUNK_LOADING } from '../constants';
import { URL_ROUTES } from '../constants/routes';
import ErrorAlert from '../components/errorAlert';
import OAuthSignIn from '../components/oAuthSignIn';
import TncPpLinks from '../components/TncPpLinks';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email format')
    .required('Please enter your email address.'),
  password: Yup.string().required('Please enter your password.'),
});

function Login() {
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((state) => state.currentUser.login);

  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      <div className="onboarding__form-box d-flex flex-column w-100 pt-4 pt-xl-5 mt-auto">
        <h3 className="mb-3 mb-lg-4 text-center color-black">Welcome back to Primed Mind!</h3>
        <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
        <Formik
          initialValues={{ email: '', password: '', type: 'web' }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(userLogin(values));
          }}
        >
          {({ handleSubmit }) => {
            const handleSubmitForm = (event: any) => {
              event.preventDefault();
              handleSubmit();
            };
            return (
              <form onSubmit={handleSubmitForm} id="sign-in-form">
                <div className="mb-3">
                  <Field
                    component={Input}
                    name="email"
                    type="email"
                    label="Email"
                    description="email"
                    placeholder="Email Address"
                  />
                </div>
                <div className="mb-4">
                  <Field
                    component={Input}
                    type="password"
                    label="Password"
                    name="password"
                    description="password"
                    placeholder="Password"
                    endAdornment={
                      <Link
                        to={URL_ROUTES.RestorePassword}
                        className="color-secondary text-decoration-none"
                      >
                        Forgot your password?
                      </Link>
                    }
                  />
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    className="d-block btn btn-primary px-5 w-75 m-auto"
                    disabled={status === THUNK_LOADING}
                  >
                    {status === THUNK_LOADING ? (
                      <span
                        className="spinner-border"
                        style={{ width: '1em', height: '1em' }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    ) : (
                      <>
                        Login <i className="fa-regular fa-arrow-right-to-bracket ms-1" />
                      </>
                    )}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        <div className="mb-3 text-center">
          Don&apos;t have an account?{' '}
          <Link
            to={URL_ROUTES.SignUp}
            className="color-secondary text-decoration-none font-semi-bold"
          >
            Sign up
          </Link>
        </div>
        <div className="mb-3">
          <div className="onboarding__or-decor-line d-flex align-items-center justify-content-center w-100">
            {' '}
            <span className="d-block px-1 bg-color-white">or</span>
          </div>
        </div>
        <OAuthSignIn signUp={false} />
      </div>
      <TncPpLinks />
    </div>
  );
}

export default Login;
