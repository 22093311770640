import React, { useEffect, useState, useCallback, ChangeEvent, Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AddNewSessions from './modals/addNewSessions';
import EditSessions from './modals/editSessions';
import DeleteSessions from './modals/deleteSessions';
import { NewSession, EditSession } from '../../redux/interface/sessionInterface';
import { fetchSession } from '../../redux/thunks/sessionThunk';
import Session from './session';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import { handlePagination, setParamValue } from '../../helpers';

const BASE_URL = '/api/sessions';

function Sessions() {
  const dispatch = useAppDispatch();

  // local state
  const [sessionToEdit, setSessionToEdit] = useState<EditSession>({
    id: '',
    title: '',
    description: '',
    isFree: '',
    type: '',
    tags: [],
    image: '',
    narrators: [],
  });

  const [newSession] = useState<NewSession>({
    title: '',
    description: '',
    isFree: '',
    type: '',
    tags: [],
    image: '',
    narrators: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [sessionToDelete, setSessionToDelete] = useState('');
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);
  const [numberOfRows, setNumberOfRows] = useState(10);

  // redux store
  const status = useAppSelector((state) => state.session.status);
  const handleEditSession = (session: EditSession) => setSessionToEdit(session);
  const handleDeleteSession = (sessionId: string) => setSessionToDelete(sessionId);
  const sessions = useAppSelector((state) => state.session.sessionsInfo);
  const totalSessions = useAppSelector((state) => state.session.totalItems);

  // pagination
  const { first, last, next, previous, current } = useAppSelector(
    (state) => state.session.pagination,
  );

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    setCurrentPage(1);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    setCurrentPage(1);
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'title', value },
    ];
    const newQueryParams = setParamValue(current || currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    
    if (
      Math.ceil(totalSessions / numberOfRows) !== 0 &&
      Math.ceil(totalSessions / numberOfRows) < currentPage
    ) {
      setCurrentPage(currentPage - 1);
      handlePageChange(currentPage - 1, 'PAGE_NUMBER');
    }
    dispatch(fetchSession(currentUrl));
  }, [currentUrl, totalSessions]);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor" />
              <div className="row pb-3 pb-md-4 align-items-center">
                <div className="col-12 col-sm-6">
                  <h1 className="headline-decor">Sessions</h1>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary px-md-3 px-lg-5"
                    data-bs-toggle="modal"
                    data-bs-target="#add_new_session"
                  >
                    <i className="fa fa-plus" />
                    Add New Session
                  </button>
                </div>
              </div>

              <div className="container-fluid content-inner">
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <Search value={searchInput} onInputChange={handleFilterUsers} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 p-0">
                    <div className="table-responsive">
                      <table className="table text-nowrap">
                        <thead>
                          <tr>
                            <th className="col-3">
                              <div className="d-flex align-items-center col-3">
                                Title
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center col-3">
                                Description
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-1">Free</th>
                            <th className="col-3">Tags</th>
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        {status === 'loading' ? (
                          <tbody>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {sessions &&
                              sessions?.map((s) => (
                                <Session
                                  key={s?.id}
                                  id={s?.id}
                                  title={s?.title}
                                  description={s?.description}
                                  isFree={s?.isFree}
                                  type={s?.type}
                                  tags={s?.tags}
                                  image={s?.image}
                                  narrators={s?.narrators}
                                  handleEdit={handleEditSession}
                                  handleDelete={handleDeleteSession}
                                />
                              ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        onPageChange={handlePageChange}
                        totalCount={totalSessions || 0}
                        currentPage={currentPage}
                        pageSize={numberOfRows}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewSessions newSession={newSession} />
      <EditSessions sessionToEdit={sessionToEdit} />
      <DeleteSessions id={sessionToDelete} status={status} />
    </>
  );
}

export default Sessions;
