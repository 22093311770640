import classNames from 'classnames';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import OnBoardingLogoBox from '../components/onBoardinLogoBox';
import paymentSuccessful from '../assets/img/payment-successful.png';
import { appStoreLink, googlePlayLink, metaPixel, pixelImg } from '../constants';
import appStoreLogo from '../assets/img/apple.svg';
import googlePlayLogo from '../assets/img/google.svg';

function ThankYouPage() {
  useEffect(() => {
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    script.async = true;
    script.innerHTML = metaPixel;
    noscript.innerHTML = pixelImg;

    document.body.appendChild(script);
    document.body.appendChild(noscript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return (
    <div className={classNames('container-fluid onboarding')} style={{ height: '100vh' }}>
      <div className="row h-100">
        <div className={classNames('d-none d-md-flex col-md-6 p-0')}>
          <OnBoardingLogoBox />
        </div>
        <div className={classNames('col-12 col-md-6 p-0 h-100')}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100 px-5">
            <img
              src={paymentSuccessful}
              alt="Thank you"
              style={{ width: '200px', height: '200px', marginBottom: '20px' }}
            />

            <h1 className="fs-1 mb-3">Thank you</h1>

            <p className="fs-2">You have successfully subscribed to the plan.</p>
            <Button
              type="button"
              className="d-block btn btn-primary px-5 my-2"
              style={{ width: '300px' }}
              onClick={() => {
                setTimeout(() => {
                  window.location.replace('/');
                }, 500);
              }}
            >
              Start Priming <i className="fa-regular fa-arrow-right ms-1" />
            </Button>

            <p className="lh-lg text-center">
              If you need anything at all, please send a message to{' '}
              <a href="mailto:support@primedmind.com" className="text-secondary font-weight-bold">
                support@primedmind.com
              </a>
              . We have team members on hand ready to help you with any questions you might have.
            </p>
            <div className="d-flex justify-content-center flex-wrap mb-2">
              <a
                href={appStoreLink}
                className="m-3"
                target="_blank"
                rel="noreferrer"
                id="app-store-link"
              >
                <img
                  src={appStoreLogo}
                  alt="AppStore"
                  title="App Store"
                  style={{ maxWidth: 150 }}
                />
              </a>
              <a
                href={googlePlayLink}
                className="m-3"
                target="_blank"
                rel="noreferrer"
                id="play-store-link"
              >
                <img
                  src={googlePlayLogo}
                  alt="GooglePlay"
                  title="Google Play"
                  style={{ maxWidth: 170, height: 50 }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
