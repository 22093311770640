import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { THUNK_STATUS } from '../../constants';
import { toast } from '../../helpers/toaster';
import { MarketingState, marketingAddsState } from '../interface/marketingInterface';
import {
  fetchMarketingInfo,
  createMarketingInfo,
  removeImage,
  editMarketingInfo,
  deleteMarketingInfo,
  fetchMarketingAddInfo,
} from '../thunks/marketingThunk';

const initialState: MarketingState = {
  info: [],
  loading: { info: undefined, form: undefined },
  error: undefined,
  modalOpen: undefined,
  modalName: '',
  webmarketingAddsState: marketingAddsState,
};

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMarketingInfo.pending, (state) => ({
        ...state,
        loading: { ...state.loading, info: THUNK_STATUS.LOADING },
        error: undefined,
      }))
      .addCase(fetchMarketingInfo.fulfilled, (state, action) => ({
        ...state,
        loading: { ...state.loading, info: THUNK_STATUS.SUCCEEDED },
        info: action.payload,
      }))
      .addCase(fetchMarketingInfo.rejected, (state, action) => ({
        ...state,
        loading: { ...state.loading, info: THUNK_STATUS.REJECTED },
        error: action.error.message,
      }))
      .addCase(createMarketingInfo.pending, (state) => ({
        ...state,
        modalName: 'createMarketing',
        modalOpen: true,
        loading: { ...state.loading, form: THUNK_STATUS.LOADING },
      }))
      .addCase(createMarketingInfo.fulfilled, (state, action) => {
        toast('Marketing info created successfully', 'success');

        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.SUCCEEDED },
          info: [...state.info, action.payload],
          modalOpen: false,
        };
      })
      .addCase(createMarketingInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error in creating marketing ad.';
        toast(errorMessage, 'error');
        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.REJECTED },
          error: errorMessage,
        };
      })
      .addCase(editMarketingInfo.pending, (state) => ({
        ...state,
        modalName: 'editMarketing',
        modalOpen: true,
        loading: { ...state.loading, form: THUNK_STATUS.LOADING },
      }))
      .addCase(editMarketingInfo.fulfilled, (state, action) => {
        toast('Marketing info edited successfully', 'success');
        const indexToUpdate = findIndex(state.info, (info) => info.id === action.payload.data.id);
        const newUpdatedMarketingInfo = [...state.info];
        newUpdatedMarketingInfo[indexToUpdate] = action.payload.data;
        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.SUCCEEDED },
          error: undefined,
          info: newUpdatedMarketingInfo,
          modalOpen: false,
        };
      })
      .addCase(editMarketingInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error in editing marketing ad.';
        toast(errorMessage, 'error');
        toast('Editing failed', 'error');
        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.REJECTED },
          error: errorMessage,
        };
      })
      .addCase(deleteMarketingInfo.pending, (state) => ({
        ...state,
        modalName: 'deleteMarketing',
        modalOpen: true,
        loading: { ...state.loading, form: THUNK_STATUS.LOADING },
      }))
      .addCase(deleteMarketingInfo.fulfilled, (state, action) => {
        toast('Marketing info deleted successfully', 'success');
        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.SUCCEEDED },
          error: undefined,
          info: state.info.filter((info: any) => info.id !== action.payload.id),
          modalOpen: false,
        };
      })
      .addCase(deleteMarketingInfo.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Deleting marketing ad failed.';
        toast(errorMessage, 'error');
        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.REJECTED },
          error: errorMessage,
        };
      })
      .addCase(removeImage.pending, (state) => ({
        ...state,
        loading: { ...state.loading, form: THUNK_STATUS.LOADING },
        error: undefined,
      }))
      .addCase(removeImage.fulfilled, (state, action) => {
        toast('Image removed', 'success');
        const indexToUpdate = findIndex(state.info, (info) => info.id === action.payload.id);
        const newUpdatedMarketingInfo = [...state.info];
        newUpdatedMarketingInfo[indexToUpdate] = action.payload.data;

        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.SUCCEEDED },
          info: newUpdatedMarketingInfo,
        };
      })
      .addCase(removeImage.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Issue in removing image. Please try again.';
        toast(errorMessage, 'error');

        return {
          ...state,
          loading: { ...state.loading, form: THUNK_STATUS.REJECTED },
          error: errorMessage,
        };
      })
      .addCase(fetchMarketingAddInfo.pending, (state) => ({
        ...state,
        webmarketingAddsState: {
          ...state.webmarketingAddsState,
          status: THUNK_STATUS.LOADING,
          error: undefined,
        },
      }))
      .addCase(fetchMarketingAddInfo.fulfilled, (state, action) => ({
        ...state,
        webmarketingAddsState: {
          ...state.webmarketingAddsState,
          status: THUNK_STATUS.SUCCEEDED,
          marketingInfo: action.payload,
        },
      }))
      .addCase(fetchMarketingAddInfo.rejected, (state, action) => ({
        ...state,
        webmarketingAddsState: {
          ...state.webmarketingAddsState,
          status: THUNK_STATUS.REJECTED,
          error: action.error.message,
        },
      }));
  },
});

export default marketingSlice.reducer;
