/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { debounce } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Pagination from '../../components/pagination';
import Search from '../../components/search';
import ShowRows from '../../components/showRows';
import AddNewTeam from './modals/addNewTeam';
import DeleteTeam from './modals/deleteTeam';
import { getAllTeams } from '../../redux/thunks/teamThunk';
import SingleTeam from './singleTeam';
import { handlePagination, setParamValue } from '../../helpers';

const BASE_URL = '/api/teams';

function Teams() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // local state
  const [newTeam, setNewTeam] = useState({
    name: '',
    label: '',
    email: '',
    subscriptionTime: '',
  });
  const [currentUrl, setCurrentUrl] = useState(BASE_URL);
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [teamToDelete, setTeamToDelete] = useState('');

  //  redux store
  const status = useAppSelector((state) => state.team.status);
  const allTeams = useAppSelector((state) => state.team.allTeamsInfo);

  // pagination
  const totalTeams = useAppSelector((state) => state.team.totalItems);
  const { first, last, next, previous, current } = useAppSelector((state) => state.team.pagination);

  const handleShowRows = (rows: number) => {
    setNumberOfRows(rows);
    const queryParams = [
      { param: 'itemsPerPage', value: `${rows}` },
      { param: 'page', value: '1' },
    ];
    const newQueryParams = setParamValue(currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  };

  const handlePageChange = (pageNumber: number, page: string) => {
    const pagination = {
      current: current || currentUrl,
      first,
      last,
      next,
      previous,
      baseURL: BASE_URL,
      pageNumber: `${pageNumber}`,
      page,
    };
    const updatedURL = handlePagination(pagination);
    setCurrentUrl(updatedURL);
    setCurrentPage(pageNumber);
  };

  const debouncedSearchTrigger = debounce((value: string) => {
    const queryParams = [
      { param: 'page', value: '1' },
      { param: 'search', value },
    ];
    const newQueryParams = setParamValue(currentUrl, queryParams);
    const newUrl = `${BASE_URL}?${newQueryParams}`;
    setCurrentUrl(newUrl);
  }, 500);

  const handleFilterUsers = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearchTrigger(e.target.value);
  }, []);

  useEffect(() => {
    dispatch(getAllTeams(currentUrl));
  }, [currentUrl]);

  // actions
  const handleViewTeam = (teamId: string, seats: string, teamName: string) => {
    navigate('/team', {
      state: {
        teamId,
        seats,
        teamName,
      },
    });
  };

  const handleDeleteTeam = (teamId: string) => setTeamToDelete(teamId);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col p-0 d-flex flex-column overflow-hidden">
            <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-4 p-xxl-5 content">
              <div className="content_decor" />
              <div className="row pb-3 pb-md-4 align-items-center">
                <div className="col-12 col-sm-6">
                  <h1 className="headline-decor">Teams</h1>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end">
                  <button
                    type="button"
                    id="add_team_root"
                    className="btn btn-primary px-md-3 px-lg-5"
                    data-bs-toggle="modal"
                    data-bs-target="#add_new_team"
                  >
                    <i className="fa fa-plus" />
                    Add New Team
                  </button>
                </div>
              </div>
              <div className="container-fluid content-inner">
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <Search value={searchInput} onInputChange={handleFilterUsers} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-15 p-0">
                    <div className="table-responsive">
                      <table className="table text-nowrap">
                        <thead>
                          <tr>
                            <th className="col-3">
                              <div className="d-flex align-items-center">
                                Name
                                <button
                                  className="btn-icon d-inline color-gray-600 ms-2"
                                  type="button"
                                >
                                  <i className="fa-regular fa-angle-down" />
                                </button>
                              </div>
                            </th>
                            <th className="col-2">Users</th>
                            <th className="col-2">MRR</th>
                            <th className="col-2">Revenue Total</th>
                            <th className="col-2">Usage</th>
                            <th className="col-2">Type</th>
                            <th className="col-2">Actions</th>
                          </tr>
                        </thead>
                        {status === 'loading' ? (
                          <tbody>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 text-truncate-column">
                                <Skeleton width={120} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 ">
                                <Skeleton width={50} height={30} />
                              </td>
                              <td className="col-3 actions-cell">
                                <Skeleton width={50} height={30} />
                                <Skeleton width={50} height={30} />
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {allTeams?.map((t) => (
                              <SingleTeam
                                key={t.id}
                                handleView={handleViewTeam}
                                handleDelete={handleDeleteTeam}
                                teamId={t.id}
                                t={t}
                              />
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom-gray-100 py-3 px-3 px-xl-4 px-xxl-5">
                  <div className="col-6 ps-0">
                    <div className="d-flex">
                      <ShowRows numberOfRows={numberOfRows} onSelection={handleShowRows} />
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        onPageChange={handlePageChange}
                        totalCount={totalTeams || 0}
                        currentPage={currentPage}
                        pageSize={numberOfRows}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddNewTeam newTeam={newTeam} />

      <DeleteTeam teamId={teamToDelete} status={status} />
    </>
  );
}

export default Teams;
