import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { ROLES } from '../../constants/index';
import {
  NewAppUser,
  EditAppUser,
  SubscriptionDetial,
  EditAppUserSubscription,
} from '../interface/appUsersInterface';

export const createAppUser = createAsyncThunk(
  'adminUsers/createAppUser',
  async (user: NewAppUser, { rejectWithValue }) => {
    try {
      const requestBody = {
        ...user,
        roles: [ROLES.USER],
      };
      const response = await apiClient.postRequest('/api/users', requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteAppUser = createAsyncThunk(
  'adminUsers/deleteAppUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/users/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchAppUsers = createAsyncThunk('adminUsers/fetchAppUsers', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const pages = response.data?.['hydra:view'];
  const data = {
    totalItems: response.data?.['hydra:totalItems'],
    users: response.data?.['hydra:member'],
    pagination: {
      current: pages?.['@id'],
      first: pages?.['hydra:first'],
      last: pages?.['hydra:last'],
      next: pages?.['hydra:next'],
      previous: pages?.['hydra:previous'],
    },
  };
  return data;
});

export const fetchAppUsersHistory = createAsyncThunk(
  'adminUsers/fetchAppUsersHistory',
  async (url: string) => {
    const response = await apiClient.getRequest(url);
    const pages = response.data['hydra:view'];
    const data = {
      totalItems: response.data['hydra:totalItems'],
      usersHistory: response.data['hydra:member'],
      pagination: {
        current: pages?.['@id'],
        first: pages?.['hydra:first'],
        last: pages?.['hydra:last'],
        next: pages?.['hydra:next'],
        previous: pages?.['hydra:previous'],
      },
    };
    return data;
  },
);

export const showAppUsers = createAsyncThunk('adminUsers/showAppUsers', async (url: string) => {
  const response = await apiClient.getRequest(url);
  const data = {
    totalItems: response.data['hydra:totalItems'],
    users: response.data['hydra:member'],
  };
  return data;
});

export const updateAppUser = createAsyncThunk(
  'adminUsers/updateAppUser',
  async (user: EditAppUser, { rejectWithValue }) => {
    try {
      const requestBody = {
        name: user.name,
        email: user.email,
        accountType: user.type,
        discountApplied: user.discountApplied,
        isActive: user.isActive,
      };
      const response = await apiClient.putRequest(`/api/users/${user.id}`, requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const updateAppUserSubscription = createAsyncThunk(
  'adminUsers/updateAppUserSubscription',
  async ({ lmData, id }: EditAppUserSubscription, { rejectWithValue }) => {
    try {
      const response = await apiClient.putRequest(`/api/users/${id}`, { lmData });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const provideSubscriptionAppUsers = createAsyncThunk(
  'adminUsers/provideSubscriptionAppUsers',
  async (detail: SubscriptionDetial, { rejectWithValue }) => {
    try {
      const response = await apiClient.putRequest(`/api/users/${detail.id}/provide-subscription`, {
        accountType: detail.accountType,
        subscription: detail.subscription,
        discountApplied: detail.discountApplied === '1',
        token: detail?.token,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const resetPasswordAppUser = createAsyncThunk(
  'appUsers/resetPasswordAppUser',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/reset/password', { email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getUserListenHistory = createAsyncThunk(
  'user/listenHistory',
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(url);
      const pages = response.data['hydra:view'];
      const data = {
        totalItems: response.data['hydra:totalItems'],
        usersListenHistory: response.data['hydra:member'],
        pagination: {
          current: pages?.['@id'],
          first: pages?.['hydra:first'],
          last: pages?.['hydra:last'],
          next: pages?.['hydra:next'],
          previous: pages?.['hydra:previous'],
        },
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getUserSubscriptionHistory = createAsyncThunk(
  'user/subscriptionHistory',
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(url);
      const pages = response.data['hydra:view'];
      const data = {
        totalItems: response.data['hydra:totalItems'],
        usersSubscriptionHistory: response.data['hydra:member'],
        pagination: {
          current: pages?.['@id'],
          first: pages?.['hydra:first'],
          last: pages?.['hydra:last'],
          next: pages?.['hydra:next'],
          previous: pages?.['hydra:previous'],
        },
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getUserDetail = createAsyncThunk(
  'user/getDetail',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(`/api/users/${id}`);
      const d = response.data;
      const AppUserData = {
        id: d?.id,
        name: d?.name,
        totalRevenue: d.totalRevenue,
        listens: d.listens,
        usage: d.usage,
        discountApplied: d.discountApplied,
        type: d?.type,
        email: d?.email,
        media: d?.media,
        timeSpent: d?.timeSpent,
        formattedTimeSpent: d?.formattedTimeSpent,
        roles: d?.roles['hydra:member'],
        userType: d?.userType,
        history: d?.history['hydra:member'],
        devices: d?.devices,
        source: d?.source,
        isFree: d?.isFree,
        latestBillingPlan: d?.latestBillingPlan,
      };
      return AppUserData;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
