/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import { getTouchErrorByField } from '../../helpers/formik';

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
  type: string;
  selectedFile: any;
  setSelectedFile: React.Dispatch<React.SetStateAction<any>>;
}

function DragAudio({ field, form, label = 'Input Label', selectedFile, setSelectedFile }: Props) {
  const { touched, error } = getTouchErrorByField(field.name, form);

  const { setFieldValue } = form;

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      if (field.name === 'audio' || field.name === 'narratorAudio') {
        setFieldValue(`${field?.name}`, acceptedFiles[0]);
      } else {
        setFieldValue(`${field?.name}`, e?.target?.result);
      }
      setSelectedFile(acceptedFiles[0]);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'audio/*': [],
    },
  });

  const renderInput = () => {
    const input = (
      <div
        {...getRootProps()}
        className={`form-control radius-10${
          touched && error ? ' is-invalid' : ''
        } text-truncate shadow`}
      >
        <input {...getInputProps()} />
        <span>{selectedFile?.name ? selectedFile?.name : 'Drag and drop new audio here'}</span>
      </div>
    );
    const helpBlock = touched && error && <div className="invalid-feedback">{error as string}</div>;
    return (
      <>
        {input}
        {helpBlock}
      </>
    );
  };

  return (
    <div className="mb-3 form-group">
      {label && (
        <label htmlFor={field.name} className="form-label">
          <b>{label}</b>
        </label>
      )}
      {renderInput()}
    </div>
  );
}

DragAudio.defaultProps = {
  label: '',
};

export default DragAudio;
