import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { getInvoiceDetail } from '../../../redux/thunks/billingThunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { THUNK_STATUS } from '../../../constants';
import { download } from '../../../components/userSettings/invoiceList';

export const MODAL_NAME = 'invoiceDetails';

interface Props {
  id: number;
}

function InvoiceDetails({ id }: Props) {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.modal.visible === 'invoiceDetails');

  const {
    invoiceDetail,
    loading: { details: status },
  } = useAppSelector((state) => state.billing.invoiceState);

  useEffect(() => {
    if (id) {
      dispatch(getInvoiceDetail(id));
    }
  }, [id]);

  const handleCloseModal = () => dispatch(modalActions.closeModal());

  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  const openInGoogleDocsViewer = (pdfUrl: string) => {
    window.open(pdfUrl, '_blank');
  };

  const renderSubscriptionName = () => {
    if (invoiceDetail.subscription.includes('Annual membership')) {
      return invoiceDetail.subscription.replace('Annual membership', 'Yearly membership');
    }
    return invoiceDetail?.subscription;
  };

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12"
          aria-label="Close"
          onClick={handleCloseModal}
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        {status === THUNK_STATUS.LOADING ? (
          <>
            <div className="row mb-4">
              <div className="col-12">
                <div className="h4 mb-4">Invoice</div>
                <Skeleton width={63} height={38} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="color-secondary font-semi-bold text-uppercase mb-3">
                  <Skeleton width={24} height={24} />
                </div>
                <div className="font-semi-bold color-gray-600">
                  <Skeleton width={70} height={24} />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="color-secondary font-semi-bold text-uppercase mb-3">
                  <Skeleton width={35} height={24} />
                </div>
                <div className="font-semi-bold color-gray-600">
                  <Skeleton width={100} height={24} />
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="table-responsive">
              <table className="invoice-table">
                <tbody>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={170} height={24} />
                    </td>
                    <td className="w-50 py-1 color-gray-600">
                      <Skeleton width={45} height={24} />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={140} height={24} />
                    </td>
                    <td className="w-50 py-1 color-gray-600">
                      <Skeleton width={24} height={24} />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={160} height={24} />
                    </td>
                    <td className="w-50 py-1 color-gray-600">
                      <Skeleton width={140} height={24} />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={70} height={24} />
                    </td>
                    <td className="w-50 py-1 color-gray-600">
                      <Skeleton width={140} height={24} />
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={180} height={24} />
                    </td>
                    <td className="w-50 py-1  color-gray-600">
                      <Skeleton width={170} height={24} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="my-4" />
            <div className="table-responsive">
              <table className="invoice-table">
                <thead>
                  <tr>
                    <td className="color-secondary text-uppercase font-semi-bold ps-0">
                      <Skeleton width={40} height={24} />
                    </td>
                    <td className="color-secondary text-uppercase font-semi-bold">
                      <Skeleton width={50} height={24} />
                    </td>
                    <td className="color-secondary text-uppercase font-semi-bold text-end pe-0">
                      <Skeleton width={60} height={24} />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="color-gray-600 ps-0">
                      <Skeleton width={180} height={24} />
                    </td>
                    <td className="color-gray-600">
                      <Skeleton width={40} height={24} />
                    </td>
                    <td className="color-gray-600 pe-0 text-end">
                      <Skeleton width={40} height={24} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div className="d-flex justify-content-end mb-4">
              <span className="color-secondary text-uppercase font-semi-bold me-5">
                <Skeleton width={60} height={24} />
              </span>
              <span className="color-gray-600 pe-0 text-end">
                <Skeleton width={80} height={24} />
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-4">
              <div className="col-12">
                <div className="h4 mb-4">Invoice</div>
                <span className="px-3 py-2  bg-color-gray-400 color-gray-600 radius-10">
                  {invoiceDetail?.invoice_no}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="color-secondary font-semi-bold text-uppercase mb-3">To:</div>
                <div className="font-semi-bold color-gray-600">{invoiceDetail?.to}</div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="color-secondary font-semi-bold text-uppercase mb-3">From:</div>
                <div className="font-semi-bold color-gray-600">{invoiceDetail?.from}</div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="table-responsive">
              <table className="invoice-table">
                <tbody>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      Invoice number:
                    </td>
                    <td className="w-50 py-1 color-gray-600">{invoiceDetail?.invoice_no}</td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      Invoice ID:
                    </td>
                    <td className="w-50 py-1 color-gray-600">{invoiceDetail.id}</td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      Date created:
                    </td>
                    <td className="w-50 py-1 color-gray-600">{invoiceDetail?.created_at}</td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      Paid:
                    </td>
                    <td className="w-50 py-1 color-gray-600">{invoiceDetail.paid_at}</td>
                  </tr>
                  <tr>
                    <td className="w-50 py-1 color-secondary text-uppercase font-semi-bold ps-0">
                      payment method:
                    </td>
                    <td className="w-50 py-1  color-gray-600">{invoiceDetail.card_brand}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="my-4" />
            <div className="table-responsive">
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th className="color-secondary text-uppercase font-semi-bold ps-0">Item:</th>
                    <th className="color-secondary text-uppercase font-semi-bold">Price:</th>
                    <th className="color-secondary text-uppercase font-semi-bold text-end pe-0">
                      Total:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="color-gray-600 ps-0">{renderSubscriptionName()}</td>
                    <td className="color-gray-600">
                      {invoiceDetail?.currency}
                      {invoiceDetail?.price.toFixed(2)}
                    </td>
                    <td className="color-gray-600 pe-0 text-end">
                      {invoiceDetail?.currency}
                      {(invoiceDetail.price - invoiceDetail.discount).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div className="d-flex justify-content-end mb-4">
              <span className="color-secondary text-uppercase font-semi-bold me-5">Total:</span>
              <span className="color-gray-600 pe-0 text-end">
                {invoiceDetail?.currency}
                {(invoiceDetail.price - invoiceDetail.discount).toFixed(2)}
              </span>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        {status === THUNK_STATUS.LOADING ? (
          <div className="d-flex justify-content-end w-100">
            <Skeleton width={42} height={43} className="me-3" />
            <Skeleton width={203} height={43} />
          </div>
        ) : (
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => {
                openInGoogleDocsViewer(invoiceDetail?.invoice_pdf);
              }}
            >
              <i className="fa-regular fa-print" />
            </button>
            <button
              disabled={isLoading[invoiceDetail.id]}
              type="button"
              className="btn btn-primary px-5"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#loaded_invoice"
              onClick={() => {
                setIsLoading({ ...isLoading, [invoiceDetail.id]: true });
                download(invoiceDetail, () => {
                  setIsLoading({ ...isLoading, [invoiceDetail.id]: false });
                });
              }}
            >
              <i className="fa-regular fa-download me-1" />
              &nbsp;Download
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default InvoiceDetails;
