import { EditCourses } from '../../redux/interface/courseInterface';

interface CourseProps {
  id: string;
  title: string;
  subtitle: string;
  tags: string[];
  courseSessions: any;
  image: any;
  option: any;
  isDayCourse: boolean;
  handleEdit: (course: EditCourses) => void;
  handleDelete: (courseId: string) => void;
}
function Course({
  id,
  title,
  subtitle,
  tags,
  image,
  option,
  courseSessions,
  isDayCourse,
  handleEdit,
  handleDelete,
}: CourseProps) {
  const handleEditing = () => {
    handleEdit({ id, title, subtitle, tags, image, courseSessions, option, isDayCourse });
  };
  const handleDeleting = () => {
    handleDelete(id);
  };
  return (
    <tr key={id}>
      <td className="font-medium text-truncate-column">{title}</td>
      <td className="text-truncate-column">{subtitle}</td>
      <td className="text-truncate-column">
        {tags.map((t) => (
          <div className="tag" key={`${t}${id}`}>
            <span className="tag__label">{t}</span>
          </div>
        ))}
      </td>
      <td className="actions-cell">
        <button
          className="btn btn-outline-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#edit_course"
          type="button"
          onClick={handleEditing}
        >
          <i className="fas fa-edit" />
          Edit
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_course"
          type="button"
          onClick={handleDeleting}
        >
          <i className="fa-solid fa-trash-can" />
          Delete
        </button>
      </td>
    </tr>
  );
}

export default Course;
