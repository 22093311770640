import React, { useMemo, createContext, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InvoiceList from '../../components/userSettings/invoiceList';
import PayMethodSlider from '../../components/userSettings/payMethodSlider';
import { PaymentCredential, Invoice } from '../../redux/interface/userSettingsInterface';
import { invoices } from '../../constants/mocks';
import CardForm from './modals/cardForm';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { generateStripeCustomer, getCardList } from '../../redux/thunks/userSettingsThunk';
import { defaultMetatags } from '../../constants';
import ConfirmSubscription from './modals/confirmSubscription';
import { actions as modalActions } from '../../redux/slices/modalSlice';

export interface BillingContextType {
  paymentMethods: PaymentCredential[];
  invoices: Invoice[];
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const BillingContext = createContext<BillingContextType>({ paymentMethods: [], invoices: [] });

export const useBilling = () => useContext<BillingContextType>(BillingContext);

function Billing() {
  const dispatch = useAppDispatch();
  const paymentMethods = useAppSelector((state) => state.userSettings.cards);

  useEffect(() => {
    if (paymentMethods.length !== 0) {
      dispatch(modalActions.setVisibleModal('subscriptionModal'));
    }
  }, [paymentMethods.length]);

  useEffect(() => {
    dispatch(generateStripeCustomer());
    dispatch(getCardList());
  }, []);

  const contextValues = useMemo(() => ({ paymentMethods, invoices }), [paymentMethods, invoices]);
  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
      <Helmet>
        <title>{defaultMetatags.title}</title>
        <meta name="description" content={defaultMetatags.description} />
      </Helmet>
      <div className="content_decor" />

      <div className="row align-items-center">
        <div className="col-12">
          <h1 className="headline-decor">Billing</h1>
        </div>
      </div>
      <Elements stripe={stripe}>
        <BillingContext.Provider value={contextValues}>
          <PayMethodSlider />
          <InvoiceList />
          <CardForm />
        </BillingContext.Provider>
      </Elements>
      <ConfirmSubscription />
    </div>
  );
}

export default Billing;
