import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import { Link, useLocation, generatePath } from 'react-router-dom';
import { ChannelInterface, CourseInterface } from '../../redux/interface/entitiesInterface';
import CourseSlider from '../../components/cards/courseSlider';
import Headline from '../../components/headline';
import SubscriptionBanner, {
  SubscriptionBannerSize,
} from '../../components/cards/subscriptionBanner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { searchChannelSessions } from '../../redux/thunks/entitiesThunk';
import { defaultMetatags } from '../../constants';
import { useServices } from '../../redux/servicesLayer';
import { URL_ROUTES } from '../../constants/routes';
import logo from '../../assets/img/logo.png';
import { condenseChannel } from '../../helpers/entityHelpers';
import { useAuth } from '../../redux/authLayer';

interface ChannelDetailsProps {
  channel: ChannelInterface | null;
  isLoading: boolean;
}

const isChannelNonEmpty = (channel: ChannelInterface) =>
  (channel?.courses && channel?.courses.length) ||
  (channel?.deepPrimer && channel?.deepPrimer.length) ||
  (channel?.powerPrimer && channel?.powerPrimer.length);

function ChannelDetails({ channel, isLoading }: ChannelDetailsProps) {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isPageDarkMode } = useServices();
  const { isFree: isFreeUser } = useAuth();
  const [defaultChannelId, setDefaultChannelId] = useState<number>(0);
  const [searchData, setSearchData] = useState({ channelId: channel?.id, keyword: '' });
  const searchStatus = useAppSelector((state) => state.entities.search.status);
  const sessions = useAppSelector((state) => state.entities.search.sessions);
  const [focusedChannel, setFocusedChannel] = useState<ChannelInterface | null>(null);

  useEffect(() => {
    if (channel?.id) {
      setSearchData((state) => ({ ...state, channelId: channel?.id }));
    }
  }, [channel]);

  const handleSubChannelChange = (e: any | number) => {
    const channelId = typeof e === 'number' ? e : Number(e.target.value);
    setSearchData((state) => {
      if (state.keyword) {
        const value = encodeURIComponent(state.keyword.trim());
        const query = `key=${value}&channel_id=${channelId}`;
        dispatch(searchChannelSessions(query));
      }
      return { ...state, channelId };
    });
    if (channel?.channels) {
      const selectedChannel = channel.channels.find(
        (subChannel: ChannelInterface) => Number(subChannel.id) === channelId,
      );
      if (selectedChannel) {
        setFocusedChannel(condenseChannel(selectedChannel));
      } else if (Number(e.target.value) === Number(channel.id)) {
        setFocusedChannel(channel);
      }
    }
  };

  useEffect(() => {
    if (channel && (isChannelNonEmpty(channel) || !channel?.channels.length)) {
      setDefaultChannelId(channel.id);
      setFocusedChannel(channel);
      setSearchData((state) => ({ ...state, channelId: channel.id }));
    } else {
      const chosen = channel?.channels.find((ch) => ch && isChannelNonEmpty(ch));
      if (chosen) {
        setDefaultChannelId(chosen.id);
        handleSubChannelChange(chosen.id);
        setSearchData((state) => ({ ...state, channelId: chosen.id }));
      }
    }
    return () => {
      setSearchData({ channelId: undefined, keyword: '' });
    };
  }, [channel]);

  const handleChangeKeyword = (value: string, channelId?: number) => {
    setSearchData((state) => ({ ...state, keyword: value }));
    if (value.trim()) {
      const query = `key=${encodeURIComponent(value)}&channel_id=${channelId}`;
      dispatch(searchChannelSessions(query));
    }
  };

  const midControl = useMemo(() => {
    const subChannels = channel ? channel.channels : [];

    if (!subChannels.length) {
      return null;
    }

    const options = [channel, ...subChannels].map((subChannel: ChannelInterface | null) => (
      <option value={subChannel?.id} key={subChannel?.id}>
        {subChannel?.title}
      </option>
    ));

    return (
      <select
        onChange={handleSubChannelChange}
        id="user_level"
        className="form-select me-3 border-0 radius-10 shadow"
        aria-label="user_level"
        style={{ maxWidth: '280px' }}
        defaultValue={defaultChannelId}
      >
        {options}
      </select>
    );
  }, [channel]);

  const findUnfinishedCourse = useCallback(() => {
    if (!focusedChannel || !focusedChannel.courses) {
      return null;
    }
    const selectedCourse: CourseInterface | undefined = focusedChannel.courses.find(
      (crs: CourseInterface) =>
        crs?.completedSessions !== undefined &&
        crs.completedSessions < crs.sessions.length &&
        (!isFreeUser || crs.sessions.some((sess) => sess.isFree)),
    );
    if (selectedCourse) {
      return selectedCourse;
    }
    return focusedChannel.courses[0];
  }, [focusedChannel]);

  if (isLoading) {
    return (
      <SkeletonTheme
        {...(isPageDarkMode(pathname) ? { baseColor: '#4b1972', highlightColor: '#6e25a9' } : {})} // eslint-disable-line react/jsx-props-no-spreading
      >
        <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
          <div className="content_decor" />
          <div className="col-12 mb-4">
            <h4 className="m-0">
              <Skeleton height={54} />
            </h4>
          </div>
          <div className="row mb-4">
            <Skeleton height={293} />
            <div className="row mb-4">
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                    <Skeleton height={24} />
                  </div>
                  <div className="col-12">
                    <Skeleton height={24} width={150} />
                    <p className="m-0 color-gray-500">
                      <Skeleton height={24} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Skeleton height={340} />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                    <Skeleton height={24} />
                  </div>
                  <div className="col-12">
                    <Skeleton height={24} width={150} />
                    <p className="m-0 color-gray-500">
                      <Skeleton height={24} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Skeleton height={340} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    );
  }

  if (!channel) {
    return (
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
        <div className="content_decor" />
        <Headline caption="Channel not found" className="mb-4" />
      </div>
    );
  }

  const course: CourseInterface | null = findUnfinishedCourse();

  const getFirstSessionIDInCourse = () => {
    if (!course) {
      return -1;
    }
    const findFreeSession = course.sessions.find((sess) => sess.isFree) || course.sessions[0];
    return !isFreeUser ? course.sessions[0].id : findFreeSession?.id;
  };

  const renderTopBox = () =>
    !course ? (
      <div className="col-12 mb-4">
        <SubscriptionBanner size={SubscriptionBannerSize.LONG} />
      </div>
    ) : (
      <>
        <div className="col-12 mb-4">
          <h4 className="m-0">Upgrades</h4>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-lg-6 col-xxl-6 mb-4 mb-xl-0" style={{ maxHeight: '300px' }}>
            <Link
              className="card--select"
              to={generatePath(URL_ROUTES.Player, {
                sessionId: getFirstSessionIDInCourse().toString(),
                listType: 'upgrade',
                parentId: course.id.toString(),
              })}
            >
              <div className="card card--horizontal h-100">
                <div className="row g-0 h-100">
                  <div className="col-12 col-md-6 " style={{ height: '300px' }}>
                    {course?.media?.url ? (
                      <img src={course?.media?.url} className="img-fluid" alt={course.title} />
                    ) : (
                      <div
                        className="onboarding__card bg-secondary px-3 py-3 d-flex flex-column justify-content-center"
                        style={{ height: '300px' }}
                      >
                        <img src={logo} alt={course.title} />
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card-body h-100 d-flex flex-column justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h4 className="card-title">{course.title}</h4>
                        </div>
                        <p className="color-gray-500">{course.description}</p>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <div className="bg-color-gray-200 color-gray-700 px-3 py-1 radius-10 d-inline-block">
                          {`${course?.completedSessions || '0'} of ${
                            course.sessions.length
                          } completed`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-6 col-xxl-6 mb-4 mb-xl-0" style={{ maxHeight: '300px' }}>
            <SubscriptionBanner size={SubscriptionBannerSize.MEDIUM} />
          </div>
        </div>
      </>
    );

  const searchedChannel = [channel, ...channel.channels].filter(
    (subChannel: ChannelInterface) => subChannel.id === Number(searchData.channelId),
  )[0]?.title;

  let bodyContent = (
    <>
      {sessions.length === 0 && searchData.keyword && (
        <div className="col-12 d-flex justify-content-between align-items-center mb-3">
          <h4 className="m-0">
            No match for {searchedChannel} - {searchData.keyword}
          </h4>
        </div>
      )}
      <div className="row mb-4">
        {renderTopBox()}
        {focusedChannel && focusedChannel.powerPrimer.length > 0 && (
          <CourseSlider
            headline="Power Primers"
            items={focusedChannel.powerPrimer}
            siblingTypes="session"
            parentType="primer"
            refer="powerPrimer"
            parentInfo={focusedChannel}
          />
        )}

        {focusedChannel && focusedChannel.deepPrimer.length > 0 && (
          <CourseSlider
            headline="Deep Primers"
            items={focusedChannel.deepPrimer}
            siblingTypes="session"
            refer="deepPrimer"
            parentType="primer"
            parentInfo={focusedChannel}
          />
        )}
      </div>
    </>
  );

  if (searchStatus === 'loading') {
    bodyContent = (
      <div className="row mb-4">
        <Skeleton height={293} />
      </div>
    );
  } else if (sessions.length > 0 && searchData.keyword) {
    bodyContent = (
      <div className="row mb-4">
        {focusedChannel && sessions && (
          <CourseSlider
            headline={`Result(s) for "${searchData.keyword}" in ${searchedChannel}`}
            items={sessions}
            parentInfo={focusedChannel}
            siblingTypes="upgrade"
          />
        )}
      </div>
    );
  }

  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
      <Helmet>
        <title>{`${!isLoading && focusedChannel?.title ? `${focusedChannel.title} - ` : ''}${
          defaultMetatags.title
        }`}</title>
        <meta
          name="description"
          content={
            !isLoading && focusedChannel?.description
              ? focusedChannel?.description
              : defaultMetatags.title
          }
        />
      </Helmet>
      <div className="content_decor" />
      <Headline
        caption={focusedChannel ? focusedChannel.title : 'Loading'}
        className="mb-4"
        midControl={midControl}
        currentChannelId={searchData.channelId}
        onChangeKeyword={handleChangeKeyword}
      />
      {bodyContent}
    </div>
  );
}

export default ChannelDetails;
