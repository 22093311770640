import { ROLES, Badge } from '../../constants/index';
import { MediaInterface } from './entitiesInterface';
import { FormState } from './formStatusInterface';

interface Streak {
  best: number;
  current: number;
}

interface Level {
  current: number;
  total: string;
  progress: number;
  progress_per: number;
}

export enum SubscriptionPaymentType {
  IOS = 'ios',
  Android = 'android',
  Stripe = 'stripe',
  Free = 'free',
}

export interface StreakLevel {
  total_minutes: number;
  streak: Streak;
  level: Level;
}

export interface TeamInfo {
  id: number;
  ownerUserId: number;
  seats: number | null;
  createdAt: string;
  updatedAt: string;
  totalRevenue: number;
  monthlyRevenue: number;
}

export interface UserInfo {
  userId: number | null;
  name: string;
  email: string;
  roles: ROLES[];
  listens: number;
  formattedTimeSpent: string;
  media?: MediaInterface;
  lastLogin: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProgressAward extends Omit<Badge, 'media'> {
  media: string;
  description: string;
  slug: string;
  id: number;
  progress: number;
}

export interface CurrentUserInfo extends Omit<UserInfo, 'listens'> {
  teamOwner: TeamInfo | null;
  isOnboarded: boolean;
  isFree: boolean;
  currentPlan: number;
  progressAward: ProgressAward[];
  subscriptionPaymentType: SubscriptionPaymentType | null;
}

export enum OAuthType {
  Facebook = 'facebook',
  Google = 'google',
  Apple = 'apple',
}

export interface LoginCredentials {
  email: string;
  password: string;
  type: string;
}

export interface OAuthCredentials {
  type: OAuthType;
  access_token: string;
  code?: string;
  name?: string;
  lmData?: any;
}

export interface RegistrationFields {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  lmData?: any;
  referral?: string;
}

export interface ResetPasswordFields {
  password: string;
  confirmPassword: string;
  token: string;
}

export interface CurrentUserState extends CurrentUserInfo {
  token: string;
  login: Omit<FormState, 'message'>;
  register: FormState;
  resetPass: FormState;
  resetPassToken: FormState;
  patchUserInfo: FormState;
  deleteUserInfo: FormState;
  streakLevel: StreakLevel[];
  oauth: Omit<FormState, 'message'>;
  isTeamMember: boolean | undefined;
  latestBillingPlan: {
    cancelled: boolean | undefined;
    productPrice: number | undefined;
    price: number | undefined;
    frequency: string | undefined;
    currency: string | undefined;
  };
}
