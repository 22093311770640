import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { getTouchErrorByField } from '../../helpers/formik';

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
  placeholder?: string;
  type: string;
  description?: string;
  currentValue?: number | string | undefined;
  min?: string;
  step?: string;
  endAdornment?: React.ReactElement | null;
  disabled?: boolean;
}

function Input({
  field,
  form,
  label = 'Input Label',
  placeholder = 'Placeholder',
  type,
  currentValue,
  min,
  step,
  description = 'description',
  endAdornment,
  disabled,
}: Props) {
  const { touched, error } = getTouchErrorByField(field.name, form);
  const { value, onBlur, onChange, name } = field;

  const renderInput = () => {
    const InputBox = (
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        type={type}
        name={name}
        value={
          // eslint-disable-next-line no-nested-ternary
          name === 'trialDays' ? (value !== undefined && value !== null ? value : '') : value || ''
        }
        onKeyDown={(e) => {
          if (type === 'number' && step === '1' && e.key === '.') {
            e.preventDefault();
          }
        }}
        onChange={onChange}
        onBlur={onBlur}
        className={`form-control${!endAdornment ? ' shadow' : ''}${
          touched && error ? ' is-invalid' : ''
        } ${disabled ? 'disabled-input' : ''}`}
        aria-describedby={description}
        placeholder={placeholder}
        defaultValue={currentValue}
        min={min}
        disabled={disabled}
        step={step}
      />
    );
    const helpBlock = touched && error && <div className="invalid-feedback">{error as string}</div>;
    return endAdornment ? (
      <div className="input-group radius-10">
        {InputBox}
        <span className="input-group-text bg-color-gray-200" id="basic-addon2">
          {endAdornment}
        </span>
        {helpBlock}
      </div>
    ) : (
      <>
        {InputBox}
        {helpBlock}
      </>
    );
  };

  return (
    <div className="mb-3 form-group">
      {label && (
        <label htmlFor={field.name} className="form-label">
          <b>{label}</b>
        </label>
      )}
      {renderInput()}
    </div>
  );
}

Input.defaultProps = {
  label: '',
  placeholder: '',
  description: 'description',
  currentValue: undefined,
  min: '2',
  endAdornment: null,
  disabled: false,
  step: '0.01',
};

export default Input;
