export const PAGES = {
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
  FIRST: 'FIRST',
  LAST: 'LAST',
  PAGE_NUMBER: 'PAGE_NUMBER',
};

export enum THUNK_STATUS {
  LOADING = 'loading',
  SUCCEEDED = 'succeded',
  REJECTED = 'rejected',
}

export const THUNK_LOADING = 'loading';
export const THUNK_SUCCEEDED = 'succeeded';
export const THUNK_REJECTED = 'rejected';

export enum ROLES {
  OWNER = 'ROLE_OWNER',
  ADMIN = 'ROLE_ADMIN',
  SUPPORT = 'ROLE_SUPPORT',
  USER = 'ROLE_USER',
  DEFAULT = 'SELECT_ROLE',
}

export interface PaginationMetas {
  first: string;
  last: string;
  previous: string;
  next: string;
  total: number;
}

export interface Badge {
  id: number;
  title: string;
  category?: string;
  achieved?: boolean;
  description?: string;
  media: {
    url: string;
  };
}

export interface BadgeCategory {
  id: number;
  title: string;
  achieved: boolean;
  media: {
    url: string;
  };
  awards: Badge[];
}

export const defaultMetatags = {
  title: 'Primed Mind: The Best Mindset & Hypnosis App',
  description:
    "Primed Mind is the world's best mindset and hypnosis app. Download for free and activate your best self in just minutes a day.",
};

export const appStoreLink =
  'https://apps.apple.com/us/app/primed-mind-mindset-hypnosis/id1224121850';
export const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.primedmind_next';

export const metaPixel = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1328375747882470');
fbq('track', 'PageView');`;

export const pixelImg = `<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1328375747882470&ev=PageView&noscript=1"
/>`;
