/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants/index';
import { EntitiesState } from '../interface/entitiesInterface';
import {
  getChannelInfo,
  getChannelsList,
  getCourseList,
  getSleepChannel,
  searchChannelSessions,
  getSubscriptionPlans,
} from '../thunks/entitiesThunk';

const initialState: EntitiesState = {
  loading: {
    courses: undefined,
    channel: undefined,
    channels: undefined,
    sleepChannel: undefined,
    subscriptions: undefined,
  },
  course: null,
  session: null,
  courses: [],
  channel: null,
  channels: [],
  sleepChannel: null,
  search: {
    sessions: [],
    totalSessions: 0,
    status: undefined,
    error: undefined,
  },
  subscriptions: [],
};

export const playerSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCourseList.pending, (state) => {
        state.loading = { ...state.loading, courses: THUNK_STATUS.LOADING };
      })
      .addCase(getCourseList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, courses: THUNK_STATUS.SUCCEEDED };
        state.courses = action.payload['hydra:member'];
      })
      .addCase(getCourseList.rejected, (state) => {
        state.loading = { ...state.loading, courses: THUNK_STATUS.REJECTED };
      })
      .addCase(getChannelsList.pending, (state) => {
        state.loading = { ...state.loading, channels: THUNK_STATUS.LOADING };
      })
      .addCase(getChannelsList.fulfilled, (state, action) => {
        state.loading = { ...state.loading, channels: THUNK_STATUS.SUCCEEDED };
        state.channels = action.payload;
      })
      .addCase(getChannelsList.rejected, (state) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.REJECTED };
      })
      .addCase(getChannelInfo.pending, (state) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.LOADING };
      })
      .addCase(getChannelInfo.fulfilled, (state, action) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.SUCCEEDED };
        state.channel = action.payload;
      })
      .addCase(getChannelInfo.rejected, (state) => {
        state.loading = { ...state.loading, channel: THUNK_STATUS.REJECTED };
      })
      .addCase(getSleepChannel.pending, (state) => {
        state.loading = { ...state.loading, sleepChannel: THUNK_STATUS.LOADING };
      })
      .addCase(getSleepChannel.fulfilled, (state, action) => {
        state.loading = { ...state.loading, sleepChannel: THUNK_STATUS.SUCCEEDED };
        state.sleepChannel = action.payload;
      })
      .addCase(getSleepChannel.rejected, (state) => {
        state.loading = { ...state.loading, sleepChannel: THUNK_STATUS.REJECTED };
      })
      .addCase(searchChannelSessions.pending, (state) => {
        state.search.status = THUNK_STATUS.LOADING;
        state.search.error = undefined;
      })
      .addCase(searchChannelSessions.fulfilled, (state, action) => {
        state.search.status = THUNK_STATUS.SUCCEEDED;
        state.search.error = undefined;
        state.search.sessions = action.payload;
      })
      .addCase(searchChannelSessions.rejected, (state, action) => {
        state.search.status = THUNK_STATUS.REJECTED;
        state.search.error = action.error.message;
      })
      .addCase(getSubscriptionPlans.pending, (state) => {
        state.loading = { ...state.loading, subscriptions: THUNK_STATUS.LOADING };
      })
      .addCase(getSubscriptionPlans.fulfilled, (state, action) => {
        state.loading = { ...state.loading, subscriptions: THUNK_STATUS.SUCCEEDED };
        state.subscriptions = action.payload;
      });
  },
});

export const { actions } = playerSlice;

export default playerSlice.reducer;
