export {};
type EnhancedWindow<T extends {}, K extends string> = Window & {
  [key in K]: T;
};

const windowHasProperty = <T extends {}, K extends string>(
  providedWindow: Window,
  key: K,
): providedWindow is EnhancedWindow<T, K> =>
  typeof providedWindow !== 'undefined' &&
  typeof (providedWindow as EnhancedWindow<T, K>)[key] !== 'undefined';

const withWindowProperty = <T extends {}>(key: string, callback: (property: T) => void): void => {
  if (windowHasProperty<T, typeof key>(window, key)) {
    callback(window[key]);
  }
};

type DataLayer = {
  push: (data: Record<string, unknown>) => void;
};

// Alternatively, we could create a helper function with a cleaner signature
export const pushWithDataLayer: DataLayer['push'] = (data) =>
  withWindowProperty<DataLayer>('dataLayer', ({ push }) => push(data));
