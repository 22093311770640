import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { getTouchErrorByField } from '../../helpers/formik';

interface Options {
  name: string;
  value: string | number;
}

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  options: Array<Options>;
  label?: string;
  description?: string;
  value?: any;
  disabled?: boolean;
  placeholder: string;
  handleResetPlan?: any;
}

function Dropdown({
  field,
  form,
  label = 'Input Label',
  value,
  description = 'description',
  options,
  placeholder,
  disabled,
  handleResetPlan,
}: Props) {
  const { touched, error } = getTouchErrorByField(field.name, form);

  const { handleChange, handleBlur } = form;

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
    if (field.name === 'userType' && handleResetPlan) {
      handleResetPlan();
    }
  };
  const renderDropdown = () => {
    const dropdown = (
      <select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        name={field.name}
        className={`form-control form-select shadow pe-4 me-4${
          touched && error ? ' is-invalid' : ''
        } ${disabled ? 'disabled-input' : ''}`}
        aria-describedby={description}
        defaultValue={value}
        onChange={(e) => handleSelectChange(e)}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    );
    const helpBlock = touched && error && <div className="invalid-feedback">{error as string}</div>;
    return (
      <>
        {dropdown}
        {helpBlock}
      </>
    );
  };

  return (
    <div className="mb-3 form-group">
      {label && (
        <label htmlFor={field.name} className="form-label">
          <b>{label}</b>
        </label>
      )}
      {renderDropdown()}
    </div>
  );
}

Dropdown.defaultProps = {
  label: '',
  description: 'description',
  value: undefined,
  disabled: false,
  handleResetPlan: undefined,
};

export default Dropdown;
