import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { CoursesState } from '../interface/courseInterface';
import { toast } from '../../helpers/toaster';
import {
  createCourses,
  fetchCourses,
  updateCourse,
  deleteCourse,
  fetchCourseChannels,
} from '../thunks/courseThunk';
import { THUNK_STATUS } from '../../constants';

const initialState: CoursesState = {
  coursesInfo: [],
  pagination: {
    current: '',
    first: '',
    last: '',
    next: '',
    previous: '',
  },
  scrollPagination: { current: 1, size: 10 },
  totalItems: 0,
  status: undefined,
  error: undefined,
  modalOpen: undefined,
  modalName: '',
};

export const courseSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    clearCoursesForChannel: (state) => ({
      ...state,
      coursesInfo: [],
      scrollPagination: {
        ...initialState.scrollPagination,
      },
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCourses.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(fetchCourses.fulfilled, (state, action) => ({
        ...state,
        status: 'succeeded',
        error: undefined,
        pagination: action.payload.pagination,
        totalItems: action.payload.totalItems,
        coursesInfo: action.payload.courses,
      }))
      .addCase(fetchCourses.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
      }))
      .addCase(fetchCourseChannels.pending, (state, action) => ({
        ...state,
        status: 'loading',
        error: undefined,
        scrollPagination: {
          current: action.meta.arg?.page || 1,
          size: action.meta.arg?.itemsPerPage || 10,
          filters: action.meta.arg?.filters || undefined,
          status: THUNK_STATUS.LOADING,
        },
      }))
      .addCase(fetchCourseChannels.fulfilled, (state, action) => {
        const scrollPagination = {
          ...state.scrollPagination,
          status: THUNK_STATUS.SUCCEEDED,
          total: action.payload.totalItems,
        };
        const coursesInfo =
          scrollPagination.current === 1
            ? action.payload.courses
            : [...state.coursesInfo, ...action.payload.courses];

        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          coursesInfo,
          scrollPagination,
          totalItems: action.payload.totalItems,
        };
      })
      .addCase(fetchCourseChannels.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message,
        scrollPagination: {
          ...state.scrollPagination,
          status: THUNK_STATUS.REJECTED,
        },
      }))
      .addCase(createCourses.pending, (state) => ({
        ...state,
        modalName: 'addNewCourse',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(createCourses.fulfilled, (state, action) => {
        toast('Created course successfull', 'success');
        return {
          ...state,
          modalOpen: false,
          status: 'succeeded',
          error: undefined,
          coursesInfo: [...state.coursesInfo, action.payload],
          totalItems: state.totalItems + 1,
        };
      })
      .addCase(createCourses.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error creating course';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(updateCourse.pending, (state) => ({
        ...state,
        modalName: 'editCourse',
        modalOpen: true,
        status: 'loading',
        error: undefined,
      }))
      .addCase(updateCourse.fulfilled, (state, action) => {
        toast('Edited course successfull', 'success');
        const indexToUpdate = findIndex(
          state.coursesInfo,
          (course) => course.id === action.payload.id,
        );
        const updatedCourses = [...state.coursesInfo];
        updatedCourses[indexToUpdate] = action.payload;
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          coursesInfo: updatedCourses,
          modalOpen: false,
        };
      })
      .addCase(updateCourse.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error editing course.';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      })
      .addCase(deleteCourse.pending, (state) => ({
        ...state,
        status: 'loading',
        error: undefined,
      }))
      .addCase(deleteCourse.fulfilled, (state, action) => {
        toast('Deleted course successfull', 'success');
        return {
          ...state,
          status: 'succeeded',
          error: undefined,
          coursesInfo: state.coursesInfo.filter((course) => course.id !== action.payload.courseId),
          totalItems: state.totalItems - 1,
        };
      })
      .addCase(deleteCourse.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error deleting course';
        toast(errorMessage, 'error');
        return {
          ...state,
          status: 'failed',
          error: errorMessage,
        };
      });
  },
});

export default courseSlice.reducer;
