import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import OnBoardingLogoBox from '../../components/onBoardinLogoBox';
import { useServices } from '../../redux/servicesLayer';
import CheckoutForm from './checkoutForm';
import { SubscriptionType } from '../../redux/interface/entitiesInterface';
import { SubscriptionPaymentType } from '../../redux/interface/currentUserInterface';
import ErrorAlert from '../../components/errorAlert';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

function Checkout() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { singleSubscriptions, teamSubscriptions, subscriptionPaymentType } = useServices();
  const { subscribeType, subscribeId } = params;

  const mode = { type: 'checkout', seat: 5 };
  if (searchParams.get('mode') === 'addTeam') {
    mode.type = 'addTeam';
    mode.seat = Number(searchParams.get('seat'));
  }

  const subscriptionInfo =
    subscribeType === 'team'
      ? teamSubscriptions[0]
      : singleSubscriptions.find((subscr) => subscr.id === Number(subscribeId));
  const ineligibleWebAppSubscriber =
    subscriptionPaymentType !== null &&
    [SubscriptionPaymentType.IOS, SubscriptionPaymentType.Android].includes(
      subscriptionPaymentType,
    );

  const renderContent = () =>
    subscriptionInfo &&
    (ineligibleWebAppSubscriber ? (
      <ErrorAlert errorMessage="You cannot update your subscription here on web app. Please switch to our Primed Mind mobile app to update your subscription." />
    ) : (
      <CheckoutForm
        subscriptionInfo={subscriptionInfo}
        subscriptionType={subscribeType as SubscriptionType}
        mode={mode}
      />
    ));

  return (
    <Elements stripe={stripe}>
      <div className="container-fluid onboarding">
        <div className="row">
          <div className="d-none d-md-flex col-md-6 p-0">
            <OnBoardingLogoBox />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
              {subscriptionInfo ? (
                renderContent()
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                >
                  <span className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default Checkout;
