import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';

export const getInvoicesList = createAsyncThunk(
  'billing/getInvoice',
  async (url: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(url);
      const pages = response.data?.['hydra:view'];
      const data = {
        totalItems: response.data?.['hydra:totalItems'],
        invoices: response.data?.['hydra:member'][0],
        pagination: {
          current: pages?.['@id'],
          first: pages?.['hydra:first'],
          last: pages?.['hydra:last'],
          next: pages?.['hydra:next'],
          previous: pages?.['hydra:previous'],
        },
      };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const getInvoiceDetail = createAsyncThunk(
  'user-settings/fetchSingleInvoiceDetail',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.getRequest(`/api/invoice/detail/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
