import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import reactSelect from 'react-select';
import { PageParameters } from '../../../redux/interface/channelsInterface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { THUNK_STATUS } from '../../../constants';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { getChannelsForChannel } from '../../../redux/thunks/channelsThunk';

interface Props {
  currentChannelId?: number;
  selectedChannels: any[];
  onSelectChannel: (channel: any) => void;
}

const MODAL_NAME = 'AddChannelToChannelModal';

function AddChannelToChannel({ currentChannelId, selectedChannels, onSelectChannel }: Props) {
  const dispatch = useAppDispatch();
  const { channelsScroll: channels, channelsScrollPagination: pagination } = useAppSelector(
    (state) => state.channel,
  );

  const [options, setOptions] = useState<any>();
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);
  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    const filtered = channels.filter(
      (channel) =>
        Number(channel.id) !== Number(currentChannelId) &&
        !selectedChannels.find((selitem: any) => selitem.id === channel.id),
    );
    setOptions(filtered);
  }, [channels]);

  useEffect(() => {
    if (showModal) {
      const params: PageParameters = { page: 1, itemsPerPage: 10 };
      dispatch(getChannelsForChannel(params));
    }
  }, [showModal]);

  const onMenuScrollToBottom = (page: number, key?: string) => {
    const params: PageParameters = { page, itemsPerPage: 10 };
    if (key) {
      params.filters = { title: key };
    }
    dispatch(getChannelsForChannel(params));
  };

  const handleInputChange = (key: string, action: { action: string }) => {
    if (action.action === 'input-change') {
      onMenuScrollToBottom(1, key);
    }
  };

  const addLabelValueProps = (opts: any[]) =>
    opts.map((option: any) => ({
      ...option,
      label: option?.title,
      value: option?.id,
    }));

  return (
    <Modal show={showModal} centered>
      <Formik
        initialValues={{
          selectedChannel: options?.length ? options[0].id : null,
        }}
        enableReinitialize
        onSubmit={(values) => {
          onSelectChannel(options.find((option: any) => option.id === values.selectedChannel));
          handleCloseModal();
        }}
      >
        {({ handleSubmit, values, setValues }) => {
          const optionsDropdownItems = addLabelValueProps(options);

          return (
            <form onSubmit={handleSubmit}>
              <Modal.Header className="pb-0 p-4">
                <button
                  type="button"
                  className="btn-close icon-size-12"
                  onClick={handleCloseModal}
                  aria-label="Close"
                />
              </Modal.Header>
              <Modal.Body className="ps-5 pe-5">
                <div className="text-center font-light">
                  <h4 className="mb-4">Add Channel</h4>
                </div>

                <div>
                  <Field
                    component={reactSelect}
                    name="selectedChannel"
                    label="Select Channel *"
                    options={optionsDropdownItems}
                    value={optionsDropdownItems.filter(
                      (option: any) => option?.id === values.selectedChannel,
                    )}
                    onMenuScrollToBottom={() => {
                      if (
                        pagination.status !== THUNK_STATUS.LOADING &&
                        pagination?.total &&
                        options.length < pagination.total - 1
                      ) {
                        onMenuScrollToBottom(pagination.current + 1, pagination?.filters?.title);
                      }
                    }}
                    onInputChange={handleInputChange}
                    onChange={(e: any) => {
                      setValues({
                        selectedChannel: e?.value,
                      });
                    }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-6 px-1 ps-0">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={values.selectedChannel === null}
                      >
                        <i className="fa fa-check" />
                        Add
                      </button>
                    </div>
                    <div className="col-6 px-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        onClick={handleCloseModal}
                      >
                        <i className="fa fa-times" />
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

AddChannelToChannel.defaultProps = {
  currentChannelId: -1,
};

export default AddChannelToChannel;
