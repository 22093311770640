import React from 'react';
import { Link } from 'react-router-dom';
import { URL_ROUTES } from '../constants/routes';

function TncPpLinks() {
  return (
    <div className="d-flex justify-content-center align-items-center mt-auto mb-4 mb-xl-5">
      <Link to={URL_ROUTES.TermsOfService} className="me-4 color-gray-600">
        Terms &amp; Conditions
      </Link>
      <Link to={URL_ROUTES.PrivacyPolicy} className="me-4 color-gray-600">
        Privacy Policy
      </Link>
    </div>
  );
}

export default TncPpLinks;
