/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { Narrator } from '../../../redux/interface/sessionInterface';
import DragAudio from '../../../components/dragAudio';
import Dropdown from '../../../components/dropdown';

interface Props {
  narratorTOEdit: Narrator | undefined;
  setNarratorToEdit: React.Dispatch<React.SetStateAction<Narrator | undefined>>;
}

function EditNarrator({ narratorTOEdit, setNarratorToEdit }: Props) {
  const [selectedNarratoAudioFile, setSelectedNarratorAudioFile] = useState<File>();
  const [audio, setAudio] = useState<any>('');

  const [localEdit, setLocalEdit] = useState<Narrator>();
  useEffect(() => {
    setLocalEdit({
      id: narratorTOEdit?.editId ? narratorTOEdit?.editId : narratorTOEdit?.id,
      name: narratorTOEdit?.name,
      audio: narratorTOEdit?.audio,
    });
    setAudio(narratorTOEdit?.audio);
    setSelectedNarratorAudioFile(narratorTOEdit?.audio);
  }, [narratorTOEdit]);

  return (
    <div
      className="modal fade modal-edit-profile"
      id="create_edit_narrator"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header pb-0 p-4">
            <button
              type="button"
              className="btn-close icon-size-12 "
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              aria-label="Close"
              data-bs-target="#add_new_session"
            />
          </div>
          <div className="modal-body ps-5 pe-5">
            <div className="text-center font-light">
              <h4 className="mb-4">Edit Narrator</h4>
            </div>
            <div className="mb-3">
              <Dropdown
                id="narratorName"
                label=" Narrator *"
                options={[
                  { name: 'Elliot Roe', value: 'Elliot Roe' },
                  { name: 'Christian Weilharter', value: 'Christian Weilharter' },
                  { name: 'Adrienne Carter', value: 'Adrienne Carter' },
                  { name: 'Samm Hunter', value: 'Samm Hunter' },
                  { name: 'Stephen Baker', value: 'Stephen Baker' },
                ]}
                onSelection={(e: ChangeEvent<HTMLSelectElement>) =>
                  setLocalEdit({
                    ...narratorTOEdit,
                    name: e?.target?.value,
                  })
                }
              />
            </div>

            <div className="mb-3">
              <p className="form-label">
                <b>Audio *</b>
              </p>
              <DragAudio
                selectedFile={selectedNarratoAudioFile}
                setSelectedFile={setSelectedNarratorAudioFile}
                setAudio={setAudio}
                file={audio}
                from="narrator"
              />
            </div>
          </div>
          <div className="modal-footer d-flex flex-column ps-5 pe-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-6 px-1 ps-0">
                  <button type="button" id="closeModal_newTeam" className="btn btn-primary w-100">
                    <div
                      role="button"
                      onClick={() => {
                        if (localEdit?.name && narratorTOEdit?.audio) {
                          setNarratorToEdit({
                            id: localEdit?.id,
                            name: localEdit?.name,
                            audio,
                          });
                        }
                      }}
                      style={{ width: '100%', boxSizing: 'border-box' }}
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      data-bs-target="#add_new_session"
                    >
                      <i className="fa fa-check" />
                      Save narrator
                    </div>
                  </button>
                </div>
                <div className="col-6 px-1 pe-0">
                  <button
                    type="button"
                    className="btn btn-outline-dark w-100"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    data-bs-target="#add_new_session"
                  >
                    <i className="fa fa-times" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditNarrator;
