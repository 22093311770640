import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { MarketingInfo } from '../interface/marketingInterface';
import adminMarketing from '../services/adminMarketing';

export const createMarketingInfo = createAsyncThunk(
  'marketing/createMarketingInfo',
  async (marketing: MarketingInfo, { rejectWithValue }) => {
    try {
      const response = await adminMarketing.createAdminMarketing(marketing);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const editMarketingInfo = createAsyncThunk(
  'marketing/editMarketingInfo',
  async (marketing: MarketingInfo, { rejectWithValue }) => {
    try {
      const response = await adminMarketing.editAdminMarketing(marketing);
      return { data: response.data, id: response.data.id };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const removeImage = createAsyncThunk(
  'marketing/removeImage',
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await apiClient.putRequest(`/api/marketings/${id}/remove-image`);
      return { data: response.data, id };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchMarketingInfo = createAsyncThunk('marketing/fetchMarketingInfo', async () => {
  const response = await apiClient.getRequest('/api/marketings');
  return response.data['hydra:member'];
});

export const deleteMarketingInfo = createAsyncThunk(
  'marketing/deleteMarketingInfo',
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/marketings/${id}`);
      return { data: response.data['hydra:member'], id };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchMarketingAddInfo = createAsyncThunk(
  'marketing/fetchMarketingAddInfo',
  async () => {
    const response = await apiClient.getRequest('/api/marketing/web');
    return response.data.marketing;
  },
);
