import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as modalActions } from '../../../redux/slices/modalSlice';
import { THUNK_LOADING, THUNK_STATUS } from '../../../constants';
import ErrorAlert from '../../../components/errorAlert';
import { toast } from '../../../helpers/toaster';
import { subscribePaidAccount } from '../../../redux/thunks/userSettingsThunk';

export const MODAL_NAME = 'confirmSubscription';

function ConfirmSubscription() {
  const dispatch = useAppDispatch();
  const { confirmSubscription: subscription } = useAppSelector((state) => state.modal.info);
  const { confirmSubscription: secondarySubscription } = useAppSelector(
    (state) => state.modal.secondaryInfo,
  );
  const { status, error, message } = useAppSelector((state) => state.userSettings.subscribe);
  const showModal = useAppSelector((state) => state.modal.visible === MODAL_NAME);

  const handleCloseModal = () => {
    dispatch(modalActions.setVisibleModal('adminUserMemberCountForm'));
    dispatch(modalActions.setSecondaryVisibleModal(''));
  };

  const handleConfirmSubscribe = () => {
    if (subscription) {
      dispatch(
        subscribePaidAccount({
          subscriptionId: Number(
            secondarySubscription ? secondarySubscription?.id : subscription?.id,
          ),
        }),
      );
    }
  };

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      if (message) {
        toast(message, 'success');
      }
      dispatch(modalActions.setSecondaryVisibleModal(''));
      dispatch(modalActions.closeModal());
    }
  }, [status]);

  return (
    <Modal show={showModal} centered>
      <Modal.Header className="pb-0 p-4">
        <button
          type="button"
          className="btn-close icon-size-12 "
          onClick={handleCloseModal}
          aria-label="Close"
        />
      </Modal.Header>
      <Modal.Body className="ps-5 pe-5 pb-0">
        <div className="text-center font-light">
          <h5 className="mb-3">{`Subscribe to ${
            secondarySubscription ? secondarySubscription.title : subscription?.title
          }`}</h5>
          <p className="color-gray-600">
            {`You will be charged with $${
              secondarySubscription ? secondarySubscription.price : subscription?.price
            }. Do you want to proceed?`}
          </p>
          <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column ps-5 pe-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-6 px-1 pe-1">
              <button
                type="button"
                className="btn btn-success w-100"
                onClick={handleConfirmSubscribe}
                disabled={status === THUNK_LOADING}
              >
                {status === THUNK_LOADING ? (
                  <span
                    className="spinner-border"
                    style={{ width: '1em', height: '1em' }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </span>
                ) : (
                  'Yes, Proceed'
                )}
              </button>
            </div>
            <div className="col-6 px-1 ps-1">
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={handleCloseModal}
                data-bs-dismiss="modal"
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmSubscription;
