import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient, { errorMessageThrower } from '../../helpers/api.js';

export const getRecommendationsHome = createAsyncThunk(
  'channels/fetchAllRecommendationsHome',
  async () => {
    try {
      const response = await apiClient.getRequest('api/recommendation');
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getAllAchievementsHome = createAsyncThunk(
  'channels/fetchAllAchievementsHome',
  async () => {
    try {
      const response = await apiClient.getRequest('api/award/all');
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const getAllAchievements = createAsyncThunk('dashboard/fetchAllAchievements', async () => {
  try {
    const response = await apiClient.getRequest('api/achievements');
    return response.data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const getUserSessionsHistory = createAsyncThunk(
  'entities/sessions/sessionsHistory',
  async () => {
    try {
      const response = await apiClient.getRequest('/api/session_users/recent');
      return response.data;
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);

export const sevenDayCoursesGet = createAsyncThunk('sevenDayCourses/fetch', async () => {
  try {
    const response = await apiClient.getRequest('/api/seven-day-course');

    const data = {
      array: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    };

    return data;
  } catch (err) {
    throw errorMessageThrower(err);
  }
});

export const sevenDayCoursesSearch = createAsyncThunk(
  'sevenDayCourses/search',
  async (query: string) => {
    try {
      const response = await apiClient.getRequest(`/api/seven-day-course/search?${query}`);
      return response.data['hydra:member'];
    } catch (err) {
      throw errorMessageThrower(err);
    }
  },
);
