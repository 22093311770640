import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../components/formik/input';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { THUNK_LOADING, THUNK_STATUS } from '../constants';
import { URL_ROUTES } from '../constants/routes';
import ErrorAlert from '../components/errorAlert';
import { requestResetPasswordToken } from '../redux/thunks/currentUserThunk';
import SuccessAlert from '../components/successAlert';
import TncPpLinks from '../components/TncPpLinks';
import LockIcon from '../assets/img/lock-icon.png';
import { actions } from '../redux/slices/currentUserSlice';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter your password.'),
  confirmPassword: Yup.string()
    .required('Please enter your password.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    status,
    error,
    message: successMessage,
  } = useAppSelector((state) => state.currentUser.resetPassToken);

  const handleSuccessRedirection = () => {
    setTimeout(() => {
      navigate('/login');
      actions.resetState();
    }, 3000);
  };

  useEffect(() => {
    if (status === THUNK_STATUS.SUCCEEDED) {
      handleSuccessRedirection();
    }
  }, [status]);

  return (
    <div className="onboarding__container overflow-auto d-flex flex-column align-items-center justify-content-center px-4">
      {!searchParams.get('token') ? (
        <>
          <div className="py-4 d-flex w-100">
            <Link to={URL_ROUTES.Login} className="btn btn-link text-decoration-none">
              <i className="fa-regular fa-angle-left color-primary me-1" />
              <span className="color-black">Back</span>
            </Link>
          </div>
          <div className="onboarding__form-box d-flex flex-column w-100 pt-4 pt-xl-5 mt-auto">
            <div className="mb-3 d-flex justify-content-center w-100 mb-4">
              <div className="onboarding__lock-icon-box d-flex align-items-center justify-content-center">
                <img src={LockIcon} alt="" />
              </div>
            </div>

            <h3 className="mb-3 mb-lg-4 text-center color-black">Reset Password</h3>

            <p className="text-center">
              Your reset password URL seems invalid. Please contact our admin.
            </p>
          </div>
        </>
      ) : (
        <div className="onboarding__form-box d-flex flex-column w-100 mt-auto">
          <h3 className="mb-3 mb-lg-4 text-center color-black">Reset Password</h3>
          <ErrorAlert errorMessage={typeof error !== 'string' ? error?.message : error} />
          {status === THUNK_STATUS.SUCCEEDED && successMessage && (
            <SuccessAlert message={successMessage} />
          )}
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(
                requestResetPasswordToken({ ...values, token: searchParams.get('token') || '' }),
              );
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Field
                    component={Input}
                    type="password"
                    label="Password"
                    name="password"
                    description="password"
                    placeholder="Password"
                  />
                </div>
                <div className="mb-4">
                  <Field
                    component={Input}
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    description="confirm password"
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    className="d-block btn btn-primary px-5 w-75 m-auto"
                    disabled={status === THUNK_LOADING}
                  >
                    {status === THUNK_LOADING ? (
                      <span
                        className="spinner-border"
                        style={{ width: '1em', height: '1em' }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    ) : (
                      <>
                        Continue <i className="fa-regular fa-arrow-right ms-1" />
                      </>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
      <TncPpLinks />
    </div>
  );
}

export default ResetPassword;
