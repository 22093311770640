/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { actions as userSettingActions } from '../../redux/slices/userSettingsSlice';
import Input from '../../components/formik/input';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  generateStripeCustomer,
  addNewCard,
  subscribePaidAccount,
  inquireMoreSeats,
  updateSeats,
  getCardList,
} from '../../redux/thunks/userSettingsThunk';
import { SubscriptionInterface, SubscriptionType } from '../../redux/interface/entitiesInterface';
import ErrorAlert from '../../components/errorAlert';
import { THUNK_STATUS } from '../../constants';
import { toast } from '../../helpers/toaster';
import PayMethodInfo from '../../components/userSettings/payMethodInfo';
import { formatNumber } from '../../helpers';
import { useAuth } from '../../redux/authLayer';
import TncPpLinks from '../../components/TncPpLinks';

interface CheckoutFormProps {
  subscriptionInfo: SubscriptionInterface;
  subscriptionType: SubscriptionType;
  mode: {
    type: string;
    seat: number;
  };
}

interface ErrorCard {
  card_number: string | undefined;
  card_expiry: string | undefined;
  card_cvc: string | undefined;
}

const defaultErrorCardValue: ErrorCard = {
  card_number: '',
  card_expiry: '',
  card_cvc: '',
};

const validationSchema = Yup.object().shape({
  cardholderName: Yup.string().required("Please enter the cardholder's name."),
  addressLine1: Yup.string().required('Please enter the billing address.'),
  addressCity: Yup.string().required('Please enter the billing city.'),
  addressState: Yup.string().required('Please enter the billing state / province.'),
  addressZip: Yup.string().required('Please enter the billing zip code.'),
  saveCard: Yup.boolean(),
});

function CheckoutForm({ subscriptionInfo, subscriptionType, mode }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addCardMode, setAddCardMode] = useState<boolean>(true);
  const { addSeats, cards } = useAppSelector((state) => state.userSettings);
  const [seatValue, setSeatValue] = useState<number>(mode.seat || 0);
  const { isSuperuser } = useAuth();

  const { status: cardStatus, error: cardError } = useAppSelector(
    (state) => state.userSettings.cardForm,
  );
  const {
    status: subscribeStatus,
    error: subscribeError,
    message: subscribeMessage,
  } = useAppSelector((state) => state.userSettings.subscribe);
  const {
    status: seatStatus,
    error: seatError,
    message: seatMessage,
  } = useAppSelector((state) => state.userSettings.updateSeats);

  const { customSubscription } = useAppSelector((state) => state.subscriptions);
  const [errorCardIncomplete, setErrorIncomplete] = useState<ErrorCard>(defaultErrorCardValue);
  const [errorCardInvalid, setErrorCardInvalid] = useState<ErrorCard>(defaultErrorCardValue);

  const isAddTeamMode = mode.type === 'addTeam';
  const isTeamSubscription = subscriptionType === SubscriptionType.Team;

  const isProcessing = [cardStatus, subscribeStatus, seatStatus].includes(THUNK_STATUS.LOADING);

  useEffect(() => {
    dispatch(generateStripeCustomer());
    if (isAddTeamMode || isTeamSubscription) {
      const seatparams: { seats: number; uuid?: string } = {
        seats: mode.seat,
      };
      if (subscriptionInfo?.uuid) {
        seatparams.uuid = subscriptionInfo?.uuid;
      }
      dispatch(inquireMoreSeats(seatparams));
    }
    dispatch(getCardList());
  }, []);

  useEffect(() => {
    if (cards.length) {
      setAddCardMode(false);
    }
  }, [cards]);

  useEffect(() => {
    if (seatStatus === THUNK_STATUS.SUCCEEDED) {
      if (seatMessage) {
        toast(seatMessage, 'success');
      }
    }
  }, [seatStatus]);

  useEffect(() => {
    if (subscribeStatus === THUNK_STATUS.SUCCEEDED) {
      if (subscribeMessage) {
        toast(subscribeMessage, 'success');
      }
      navigate('/thank-you', { state: { from: 'checkout-page' } });
    }
  }, [subscribeStatus]);

  const debouncedInquireTrigger = debounce((value: number) => {
    dispatch(inquireMoreSeats({ seats: Number(value) }));
  }, 500);

  const increaseSeatByOne = () => {
    setSeatValue(seatValue + 1);
    debouncedInquireTrigger(seatValue + 1);
  };

  const decreaseSeatByOne = () => {
    if (seatValue === 5) {
      toast('Minimum of 5 seat is required for team subscription', 'info');
      return;
    }
    setSeatValue(seatValue - 1);
    debouncedInquireTrigger(seatValue - 1);
  };

  const handleExistingCardProcess = () => {
    const params = {
      card_id: (cards.find((card) => card.isPrimary) || cards[0]).id,
      discount_id: subscriptionInfo?.uuid,
    };
    if (isAddTeamMode) {
      dispatch(updateSeats({ seats: seatValue, ...params }));
    } else if (isTeamSubscription) {
      dispatch(
        subscribePaidAccount({
          subscriptionId: Number(subscriptionInfo.id),
          data: { ...params, seats: subscriptionInfo?.uuid ? subscriptionInfo?.seats : seatValue },
        }),
      );
    } else {
      dispatch(
        subscribePaidAccount({
          subscriptionId: Number(subscriptionInfo.id),
          data: params,
        }),
      );
    }
  };

  const renderTeamSeats = () => (
    <div className="p-4 bg-color-secondary radius-12 color-white mb-4">
      <div className="row align-items-center">
        <div className="col-auto label-regular-1">Add seats</div>
        <div className="col-auto m-auto">
          <div className="d-flex radius-10 align-items-center" style={{ backgroundColor: '#fff' }}>
            <button
              onClick={decreaseSeatByOne}
              onKeyUp={decreaseSeatByOne}
              type="button"
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <i className="fa-solid fa-minus" style={{ color: '#000', marginLeft: '10px' }} />
            </button>
            <span
              style={{
                width: 55,
                textAlign: 'center',
                padding: '5px 10px',
                color: '#000',
                fontWeight: 700,
              }}
            >
              {seatValue}
            </span>
            <button
              onClick={increaseSeatByOne}
              onKeyUp={increaseSeatByOne}
              type="button"
              style={{ border: 'none', backgroundColor: 'transparent' }}
            >
              <i
                className="fa-solid fa-plus"
                style={{ color: '#000', marginRight: '10px !important' }}
              />
            </button>
          </div>
        </div>
        <div className="col-auto label-regular-1" style={{ width: '150px', textAlign: 'right' }}>
          ${addSeats?.seatPrice} / seat
        </div>
      </div>
      <div className="row border-top mt-4 pt-2 label-regular-1">
        <div className="col-auto">
          <i className="fa-regular fa-cart-shopping fa-lg" />
        </div>
        <div className="col-auto">Total</div>
        <div className="col-auto ms-auto">
          ${addSeats.price} /{' '}
          {subscriptionInfo?.frequency === 'yearly'
            ? 'year'
            : subscriptionInfo?.frequency === 'monthly'
            ? 'month'
            : 'lifetime'}
        </div>
      </div>
    </div>
  );

  const renderCheckoutPrice = () => {
    if (subscriptionInfo?.discountedPrice) {
      return subscriptionInfo?.discountedPrice.toFixed(2);
    }
    return 0;
  };

  const renderCheckoutItem = () => (
    <div className="p-4 bg-color-secondary radius-12 color-white mb-4">
      <div className="d-flex align-middle align-items-center justify-content-between">
        <div className="row">
          <div className="col-auto">
            <i className="fa-regular fa-cart-shopping fa-lg" />
          </div>
          <div className="col-auto label-regular-1">{subscriptionInfo?.description}</div>
        </div>
        <div className="d-flex flex-column">
          <div className="col-auto ms-auto label-regular-1">
            $
            {isTeamSubscription && subscriptionInfo?.uuid
              ? addSeats?.price
              : renderCheckoutPrice() || subscriptionInfo.price.toFixed(2)}{' '}
            /{' '}
            {subscriptionInfo?.frequency === 'yearly'
              ? 'year'
              : subscriptionInfo?.frequency === 'monthly'
              ? 'month'
              : 'lifetime'}
          </div>
          {subscriptionInfo?.frequency === 'yearly' && !isTeamSubscription && (
            <small className="col-auto ms-auto label-regular-1">
              Only $
              {subscriptionInfo?.discountedPrice
                ? formatNumber(Math.trunc((subscriptionInfo.discountedPrice / 12) * 100) / 100)
                : formatNumber(Math.trunc((subscriptionInfo.price / 12) * 100) / 100)}
              /month. Billed Annually
            </small>
          )}
          {subscriptionInfo?.frequency === 'yearly' &&
            isTeamSubscription &&
            !subscriptionInfo?.uuid && (
              <small className="col-auto ms-auto label-regular-1">
                Only $
                {subscriptionInfo?.discountedPrice
                  ? formatNumber(
                      Math.trunc(
                        (subscriptionInfo.discountedPrice /
                          (subscriptionInfo?.seats ? subscriptionInfo.seats : 1)) *
                          100,
                      ) / 100,
                    )
                  : formatNumber(
                      Math.trunc(
                        (subscriptionInfo.price /
                          (subscriptionInfo?.seats ? subscriptionInfo.seats : 1)) *
                          100,
                      ) / 100,
                    )}
                /seat. Billed Annually
              </small>
            )}
          {subscriptionInfo?.frequency === 'yearly' &&
            isTeamSubscription &&
            subscriptionInfo?.uuid && (
              <small className="col-auto ms-auto label-regular-1">
                {`Only $${addSeats?.seatPrice}/seat. Billed Annually`}
              </small>
            )}
        </div>
      </div>
    </div>
  );

  const renderErrorDisplays = () =>
    isAddTeamMode ? (
      <ErrorAlert errorMessage={typeof seatError !== 'string' ? seatError?.message : seatError} />
    ) : (
      <ErrorAlert
        errorMessage={typeof subscribeError !== 'string' ? subscribeError?.message : subscribeError}
      />
    );

  const renderPayBtnText = () => {
    if (isProcessing) {
      return (
        <span className="spinner-border" style={{ width: '1em', height: '1em' }} role="status">
          <span className="visually-hidden">Loading...</span>
        </span>
      );
    }

    if (customSubscription.trialDays === 0) {
      return <>Complete Payment</>;
    }

    if (subscriptionInfo.trialDays > 0) {
      return <>Start your trial</>;
    }

    return <>Complete Payment</>;
  };

  const renderCustomTrialDays = () => {
    if (customSubscription.trialDays === null) {
      return subscriptionInfo.trialDays;
    }

    return customSubscription.trialDays;
  };

  const renderStandardSingleYearly = () => (
    <>
      {subscriptionInfo.trialDays > 0 &&
        `$0.00 today for ${subscriptionInfo.trialDays}-day free trial; converts to $${subscriptionInfo?.price} annually renewing subscription. Cancel anytime.`}

      {subscriptionInfo.trialDays === 0 &&
        `$${subscriptionInfo?.discountedPrice?.toFixed(2)} for ${subscriptionInfo?.repetitions} ${
          subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 'years' : 'year'
        }, billed annually. After the discount term your subscription renews at the standard price of $${subscriptionInfo?.price.toFixed(
          2,
        )}. Cancel anytime.`}
    </>
  );

  const renderMonthlySingleCustomRepetition = () => {
    if (!subscriptionInfo?.repetitions) {
      return 'for 1 month';
    }
    return `for ${subscriptionInfo?.repetitions} ${
      subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 'months' : 'month'
    }`;
  };

  const renderCheckoutInfoText = () => (
    <div className="text-dark">
      {/* Monthly. Single. Custom */}
      {subscriptionInfo?.frequency === 'monthly' && subscriptionInfo?.uuid && (
        <p className="my-2" style={{ fontSize: '13px', fontWeight: 500 }}>
          {!customSubscription.trialDays &&
            `$${subscriptionInfo?.discountedPrice?.toFixed(
              2,
            )} ${renderMonthlySingleCustomRepetition()}, billed monthly. After the discount term your subscription renews at the standard price of $${subscriptionInfo?.price.toFixed(
              2,
            )}. Cancel anytime.`}

          {customSubscription.trialDays > 0 &&
            `$0.00 today for ${renderCustomTrialDays()}-day free trial, then $${subscriptionInfo?.discountedPrice?.toFixed(
              2,
            )} ${renderMonthlySingleCustomRepetition()}, billed monthly. After the discount term your subscription renews at the standard price of $${
              subscriptionInfo?.price
            }. Cancel anytime.`}
        </p>
      )}

      {/* Monthly. Single. Standard */}
      {subscriptionInfo?.frequency === 'monthly' && !subscriptionInfo?.uuid && (
        <small style={{ fontSize: '13px', fontWeight: 500 }}>
          {`$${subscriptionInfo?.price.toFixed(2)} monthly renewing subscription. Cancel anytime.`}
        </small>
      )}

      {/* Yearly. Single. Custom */}
      {subscriptionInfo?.frequency === 'yearly' && !isTeamSubscription && subscriptionInfo?.uuid && (
        <p className="my-2" style={{ fontSize: '13px', fontWeight: 500 }}>
          {customSubscription.trialDays === null && renderStandardSingleYearly()}

          {customSubscription.trialDays > 0 &&
            `$0.00 today for ${renderCustomTrialDays()}-day free trial, then $${subscriptionInfo?.discountedPrice?.toFixed(
              2,
            )} for ${subscriptionInfo?.repetitions} ${
              subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 'years' : 'year'
            }, billed annually. After the discount term your subscription renews at the standard price of $${
              subscriptionInfo?.price
            }. Cancel anytime.`}

          {customSubscription.trialDays === 0 &&
            `$${subscriptionInfo?.discountedPrice?.toFixed(2)} for ${
              subscriptionInfo?.repetitions
            } ${
              subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 'years' : 'year'
            }, billed annually. After the discount term your subscription renews at the standard price of $${subscriptionInfo?.price.toFixed(
              2,
            )}. Cancel anytime.`}
        </p>
      )}

      {/* Yearly. Single. Standard */}
      {subscriptionInfo?.frequency === 'yearly' && !isTeamSubscription && !subscriptionInfo?.uuid && (
        <p className="my-2" style={{ fontSize: '13px', fontWeight: 500 }}>
          {renderStandardSingleYearly()}
        </p>
      )}

      {/* Yearly. Team. Custom */}
      {subscriptionInfo?.frequency === 'yearly' && isTeamSubscription && subscriptionInfo?.uuid && (
        <p className="my-2" style={{ fontSize: '13px', fontWeight: 500 }}>
          {!customSubscription.trialDays &&
            `$${addSeats?.seatPrice.toFixed(2)} per seat${
              subscriptionInfo?.repetitions
                ? ` for ${subscriptionInfo?.repetitions} year${
                    subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 's' : ''
                  }`
                : ''
            }.${
              subscriptionInfo?.repetitions
                ? ` After the discount term your subscription renews at the standard price of $${addSeats?.actualSeatPrice} per seat.`
                : ''
            } Cancel anytime.`}

          {customSubscription.trialDays > 0 &&
            `$${addSeats?.seatPrice.toFixed(2)} per seat with a ${
              customSubscription.trialDays
            }-day${customSubscription.trialDays > 1 ? 's' : ''} free trial${
              subscriptionInfo?.repetitions
                ? ` and renewing subscription for ${subscriptionInfo?.repetitions} year${
                    subscriptionInfo?.repetitions && subscriptionInfo?.repetitions > 1 ? 's' : ''
                  }`
                : ''
            }.${
              subscriptionInfo?.repetitions
                ? ` After the discount term your subscription renews at the standard price of $${addSeats?.actualSeatPrice} per seat.`
                : ''
            } Cancel anytime.`}
        </p>
      )}

      {/* Team. Lifetime */}
      {subscriptionInfo?.frequency === 'lifetime' && isTeamSubscription && (
        <small style={{ fontSize: '13px', fontWeight: 500 }}>
          {`$${
            subscriptionInfo?.discountedPrice
              ? formatNumber(
                  Math.trunc(
                    (subscriptionInfo.discountedPrice /
                      (subscriptionInfo?.seats ? subscriptionInfo.seats : 1)) *
                      100,
                  ) / 100,
                )
              : formatNumber(
                  Math.trunc(
                    (subscriptionInfo.price /
                      (subscriptionInfo?.seats ? subscriptionInfo.seats : 1)) *
                      100,
                  ) / 100,
                )
          } per seat paid once for lifetime.`}
        </small>
      )}

      {/* Standard. Lifetime */}
      {subscriptionInfo?.frequency === 'lifetime' && !isTeamSubscription && (
        <small style={{ fontSize: '13px', fontWeight: 500 }}>
          {`$${subscriptionInfo?.discountedPrice?.toFixed(2)} paid once for lifetime.`}
        </small>
      )}
    </div>
  );

  return (
    <div className="onboarding__form-box d-flex flex-column w-100 pt-4 pt-xl-5">
      {mode.type !== 'addTeam' && renderCheckoutItem()}
      {!subscriptionInfo?.uuid && (isAddTeamMode || isTeamSubscription) && renderTeamSeats()}
      {addCardMode ? (
        <Formik
          initialValues={{
            cardholderName: '',
            addressLine1: '',
            addressCity: '',
            addressState: '',
            addressZip: '',
            saveCard: true,
          }}
          validationSchema={validationSchema}
          onSubmit={({
            cardholderName,
            addressLine1,
            addressCity,
            addressState,
            addressZip,
            saveCard,
          }) => {
            if (!stripe || !elements) {
              return;
            }
            dispatch(userSettingActions.beginCardFormPending());
            const cardNumberElement = elements.getElement(
              CardNumberElement,
            ) as StripeCardNumberElement;
            stripe
              .createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
              })
              .then(({ error, paymentMethod }) => {
                const currentError: string | undefined = error?.code;

                switch (currentError) {
                  case 'incomplete_number':
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                      card_number: 'Card number incomplete*',
                    });
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'invalid_number':
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                      card_number: 'Card number invalid*',
                    });
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'incomplete_expiry':
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                      card_expiry: 'Incomplete expiry*',
                    });
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'invalid_expiry_year_past':
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                      card_expiry: 'Invalid expiry*',
                    });
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'invalid_expiry_year':
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                      card_expiry: 'Invalid expiry*',
                    });
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'incomplete_cvc':
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                      card_cvc: 'Incomplete csv*',
                    });
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                    });
                    break;
                  case 'invalid_cvc':
                    setErrorCardInvalid({
                      ...defaultErrorCardValue,
                      card_cvc: 'Invalid csv*',
                    });
                    setErrorIncomplete({
                      ...defaultErrorCardValue,
                    });
                    break;
                  default:
                }
                stripe
                  .createToken(cardNumberElement, {
                    name: cardholderName,
                    address_line1: addressLine1,
                    address_city: addressCity,
                    address_state: addressState,
                    address_zip: addressZip,
                  })
                  .then(({ token }) => {
                    if (error === undefined) {
                      setErrorIncomplete(defaultErrorCardValue);
                      setErrorCardInvalid(defaultErrorCardValue);
                    }
                    dispatch(
                      addNewCard({
                        cardToken: token?.id || '',
                        paymentMethodToken: paymentMethod?.id || '',
                        saveCard,
                      }),
                    ).then((info: PayloadAction<any>) => {
                      const params = saveCard
                        ? {
                            card_id: info.payload.data?.id,
                            discount_id: subscriptionInfo?.uuid,
                          }
                        : {
                            payment_method_token: token?.card?.id,
                            discount_id: subscriptionInfo?.uuid,
                          };
                      if (isAddTeamMode) {
                        dispatch(updateSeats({ seats: seatValue, ...params }));
                      } else if (isTeamSubscription) {
                        dispatch(
                          subscribePaidAccount({
                            subscriptionId: Number(subscriptionInfo.id),
                            data: {
                              ...params,
                              seats: subscriptionInfo?.uuid ? subscriptionInfo?.seats : seatValue,
                            },
                          }),
                        );
                      } else {
                        dispatch(
                          subscribePaidAccount({
                            subscriptionId: Number(subscriptionInfo.id),
                            data: params,
                          }),
                        );
                      }
                    });
                  });
              });
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <h3 className="mb-3 mb-lg-4 color-black">
                  {subscriptionInfo?.uuid ? subscriptionInfo?.title : 'Set Up Payment'}
                </h3>
                {renderCheckoutInfoText()}
                <ErrorAlert
                  errorMessage={typeof cardError !== 'string' ? cardError?.message : cardError}
                />
                {renderErrorDisplays()}
              </div>

              <div className="mb-4">
                <div className="col-12">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                  <label className="form-label">Card Information</label>
                </div>
                <Field
                  component={Input}
                  type="text"
                  className="form-control"
                  name="cardholderName"
                  aria-describedby="cardholder_name"
                  placeholder="Cardholder’s Name"
                />
              </div>
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="mb-4 form-group">
                    <div className="form-control">
                      <CardNumberElement />
                    </div>
                    {errorCardInvalid?.card_number !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardInvalid?.card_number}</small>
                      </div>
                    ) : null}
                    {errorCardIncomplete?.card_number !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardIncomplete?.card_number}</small>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-8 col-lg-3">
                  <div className="mb-4 form-group">
                    <div className="form-control">
                      <CardExpiryElement />
                    </div>
                    {errorCardIncomplete?.card_expiry !== '' ? (
                      <div className="text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardIncomplete?.card_expiry}</small>
                      </div>
                    ) : null}
                    {errorCardInvalid?.card_expiry !== '' ? (
                      <div className="text-danger w-1 h-1 font-weight-light ">
                        <small>{errorCardInvalid?.card_expiry}</small>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-4 col-lg-2">
                  <div className="mb-4 form-group">
                    <div className="form-control">
                      <CardCvcElement />
                    </div>
                    {errorCardIncomplete?.card_cvc !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardIncomplete?.card_cvc}</small>
                      </div>
                    ) : null}
                    {errorCardInvalid?.card_cvc !== '' ? (
                      <div className=" text-danger w-1 h-1 font-weight-light">
                        <small>{errorCardInvalid?.card_cvc}</small>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                  <label className="form-label">Billing Address</label>
                </div>
                <div className="col-12 col-md-7">
                  <div className="mb-4">
                    <Field
                      component={Input}
                      type="text"
                      className="form-control"
                      name="addressLine1"
                      placeholder="Street address"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-4">
                    <Field
                      component={Input}
                      type="text"
                      className="form-control"
                      name="addressCity"
                      placeholder="City"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="mb-4">
                    <Field
                      component={Input}
                      type="text"
                      className="form-control"
                      name="addressState"
                      placeholder="State / Province"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-4">
                    <Field
                      component={Input}
                      type="text"
                      className="form-control"
                      name="addressZip"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-5">
                <button
                  className="d-block btn btn-primary px-5 w-75 m-auto"
                  type="submit"
                  disabled={isProcessing || isSuperuser}
                >
                  {renderPayBtnText()}
                </button>
              </div>
              {cards.length > 0 && (
                <div className="mt-5 mb-5 text-center">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => setAddCardMode(false)}
                  >
                    Or, use your existing card.
                  </button>
                </div>
              )}
            </form>
          )}
        </Formik>
      ) : (
        <>
          <div className="mb-4">
            <h3 className="mb-3 mb-lg-4 color-black">
              {subscriptionInfo?.uuid ? subscriptionInfo?.title : 'Set Up Payment'}
            </h3>
            {renderCheckoutInfoText()}
            {renderErrorDisplays()}
          </div>
          <div className="mb-4 mx-auto">
            <div className="text-dark text-center">
              <small>We will process using your primary card:</small>
            </div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <PayMethodInfo {...(cards.find((card) => card.isPrimary) || cards[0])} displayMode />
          </div>
          <div className="mb-4">
            <button
              className="d-block btn btn-primary px-5 w-75 m-auto"
              type="button"
              disabled={isProcessing || isSuperuser}
              onClick={handleExistingCardProcess}
            >
              {renderPayBtnText()}
            </button>
          </div>
          <div className="mt-5 mb-5 mx-auto">
            <button type="button" className="btn btn-light" onClick={() => setAddCardMode(true)}>
              Or, use a different card.
            </button>
          </div>
        </>
      )}
      <TncPpLinks />
    </div>
  );
}

export default CheckoutForm;
