import React, { useCallback } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { getTouchErrorByField } from '../../helpers/formik';
import profileImage from '../../assets/img/pmd_defaultavatar.png';

interface Props {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
}

function DragProfileImage({ field, form }: Props) {
  const { touched, error } = getTouchErrorByField(field.name, form);

  const { setFieldValue } = form;

  const handleUpload = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const reader = new FileReader();
    reader.onload = () => {
      setFieldValue(`${field?.name}`, reader.result);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'image/*': [],
    },
  });
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div
      {...getRootProps()}
      className={classNames('col-4 ps-0', touched && error && ' is-invalid')}
    >
      <input {...getInputProps()} />
      <div className="modal-edit-profile__avatar">
        <img src={field.value ? field.value : profileImage} alt="user-logo" />
      </div>
      {touched && error && <div className="invalid-feedback">{error as string}</div>}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default DragProfileImage;
