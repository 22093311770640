import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../helpers/api.js';
import { DiscountInfo } from '../interface/discountInterface';

export const fetchDiscountInfo = createAsyncThunk('marketing/fetchDiscountInfo', async () => {
  const response = await apiClient.getRequest('/api/subscription_discounts');
  return response.data;
});

export const saveDiscountInfo = createAsyncThunk(
  'marketing/saveDiscountInfo',
  async (detail: DiscountInfo, { rejectWithValue }) => {
    try {
      const response = await apiClient.postRequest('/api/subscription_discounts', {
        startAt: detail.startAt,
        endAt: detail.endAt,
        discount: detail.discount,
        status: detail.status === 'true',
        type: detail.type,
        device: detail.device,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
