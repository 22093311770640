/* eslint-disable react/destructuring-assignment */
import React from 'react';
import CreditCardInfo from './creditCardInfo';
import { useAppDispatch } from '../../redux/hooks';
import { PaymentCredential } from '../../redux/interface/userSettingsInterface';
import { actions } from '../../redux/slices/modalSlice';
import DeleteCard from '../../containers/userSettings/modals/deleteCard';
import EditCardForm from '../../containers/userSettings/modals/editCardForm';

interface PayMethodInfoProps extends PaymentCredential {
  displayMode?: boolean;
}

function PayMethodInfo({ displayMode, ...cardInfo }: PayMethodInfoProps) {
  const dispatch = useAppDispatch();
  const handleEditCardClick = () => dispatch(actions.setVisibleModal('editCard'));

  return (
    <>
      <div className="col-auto mt-4">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <CreditCardInfo {...cardInfo} />

        <div className="credit-card-actions mt-3">
          {/* <div className="credit-card-actions__priority">
              {priority}
          </div> */}
          {!displayMode && (
            <div>
              <button
                type="button"
                className="btn btn-primary me-2"
                data-bs-toggle="modal"
                data-bs-target="#edit_credit_card"
                onClick={handleEditCardClick}
              >
                <i className="fa-regular fa-pencil icon-size-20" />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#delete_credit_card"
              >
                <i className="fa-regular fa-trash-can icon-size-20" />
              </button>
            </div>
          )}
        </div>
      </div>
      <DeleteCard id={cardInfo?.id} />
      <EditCardForm cardInfo={cardInfo} />
    </>
  );
}

PayMethodInfo.defaultProps = {
  displayMode: false,
};

export default PayMethodInfo;
