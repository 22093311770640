import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isNaN } from 'lodash';
import OnBoardingLogoBox from './components/onBoardinLogoBox';
import { PolicyUrls, URL_ROUTES } from './constants/routes';
import Login from './containers/login';
import SignUp from './containers/signUp';
import RestorePassword from './containers/restorePassword';
import ResetPassword from './containers/resetPassword';
import PrivacyPolicy from './containers/privacyPolicy';
import TermsOfService from './containers/termsOfService';
import { useAuth } from './redux/authLayer';

function LoginArea() {
  const location = useLocation();
  const { token: authToken } = useAuth();
  let path = location.pathname as URL_ROUTES;

  if (location.pathname.slice(0, 9) === URL_ROUTES.Checkout.slice(0, 9)) {
    const pathnameSplits = location.pathname.split('/');
    const uuid = isNaN(Number(pathnameSplits[2])) ? pathnameSplits[2] : undefined;
    if (!authToken && uuid) {
      localStorage.setItem('uuid', uuid);
      path = URL_ROUTES.CustomCheckOutPage;
    }
  }

  const scrollable = PolicyUrls.includes(path);

  let ComponentToDisplay;

  switch (path) {
    case URL_ROUTES.SignUp:
      ComponentToDisplay = SignUp;
      break;
    case URL_ROUTES.RestorePassword:
      ComponentToDisplay = RestorePassword;
      break;
    case URL_ROUTES.ResetPassword:
      ComponentToDisplay = ResetPassword;
      break;
    case URL_ROUTES.PrivacyPolicy:
      ComponentToDisplay = PrivacyPolicy;
      break;
    case URL_ROUTES.TermsOfService:
      ComponentToDisplay = TermsOfService;
      break;
    case URL_ROUTES.Login:
      ComponentToDisplay = Login;
      break;
    case URL_ROUTES.CustomCheckOutPage:
      ComponentToDisplay = SignUp;
      break;
    default:
      ComponentToDisplay = Login;
  }

  return (
    <div className={classNames('container-fluid onboarding', scrollable && 'onboarding__maxh')}>
      <div className="row">
        <div
          className={classNames('d-none d-md-flex col-md-6 p-0', scrollable && 'onboarding__maxh')}
        >
          <OnBoardingLogoBox />
        </div>
        <div className={classNames('col-12 col-md-6 p-0', scrollable && 'onboarding__maxhscroll')}>
          <ComponentToDisplay />
        </div>
      </div>
    </div>
  );
}

export default LoginArea;
