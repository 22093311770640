import { THUNK_STATUS } from '../../constants';

export interface webMarketingAddInterface {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  advantages: string | undefined;
  link: string | undefined;
  cta: string | undefined;
  type: string | undefined;
  media: {
    url: string | undefined;
  };
}

export const webMarketingAdd = {
  id: -1,
  title: '',
  description: '',
  advantages: '',
  cta: '',
  link: '',
  type: '',
  media: { url: '' },
};
export interface MarketingInfo {
  id?: string;
  title: string;
  description: string;
  advantages: string;
  cta: string;
  link: string;
  type: string;
  media?: any;
  image: any;
  status: number | boolean;
}

export interface webMarketingAddsInterface {
  marketingInfo: webMarketingAddInterface | null;
  status: string | undefined;
  error: string | undefined;
}

export const marketingAddsState = {
  marketingInfo: null,
  status: '',
  error: '',
};

export interface MarketingState {
  info: MarketingInfo[];
  loading: { [key: string]: THUNK_STATUS | undefined };
  error: string | undefined;
  modalOpen: boolean | undefined;
  modalName: string | undefined;
  webmarketingAddsState: webMarketingAddsInterface;
}
