/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useCallback } from 'react';
import { compact } from 'lodash';
import classNames from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { URL_ROUTES } from '../../constants/routes';
import {
  CardEntityTypeReq,
  ChannelInterface,
  CourseInterface,
  SessionInterface,
} from '../../redux/interface/entitiesInterface';
import logo from '../../assets/img/pmd_img_placeholder.png';
import { useAuth } from '../../redux/authLayer';
import { useAppDispatch } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import NarratorAvatar from './narratorAvatar';

export interface CourseCardProps {
  imgSrc: string;
  title: string;
  description: string;
  id: number;
  isFree?: boolean;
  type?: CardEntityTypeReq;
  parentType?: string;
  info: CourseInterface | SessionInterface;
  parentInfo?: CourseInterface | ChannelInterface | null;
  refer?: string;
}

function CourseCard({
  imgSrc,
  title,
  description,
  type,
  id,
  info,
  refer,
  parentType,
  parentInfo,
  isFree,
}: CourseCardProps) {
  const { isFree: isFreeUser } = useAuth();
  const isAccessible = isFree || !isFreeUser;
  const courseType = type === 'upgrade' && 'sessions' in info && info.sessions?.length;
  const dispatch = useAppDispatch();

  const getFirstSessionIDInCourse = () => {
    if (courseType) {
      const findFreeSession = info.sessions.find((session) => session.isFree);
      return !isFreeUser ? info.sessions[0].id : findFreeSession?.id || 0;
    }
    return 0;
  };

  const generateThePath = useCallback(() => {
    const sessionId = courseType ? getFirstSessionIDInCourse().toString() : id.toString();
    if (courseType) {
      return generatePath(URL_ROUTES.Player, {
        sessionId,
        listType: parentType,
        parentId: info.id.toString(),
      });
    }
    if (!courseType && parentInfo) {
      return generatePath(URL_ROUTES.Player, {
        sessionId,
        listType: parentType,
        parentId: parentInfo.id.toString(),
      });
    }
    return generatePath(URL_ROUTES.PlayerNoParentId, {
      sessionId,
      listType: parentType,
    });
  }, [id]);

  const linkGoesTo = compact([generateThePath(), refer ? `ref=${refer}` : null]).join('?');

  const renderTooltip = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props}>{compact([title, description]).join(' - ')}</Tooltip>
  );

  const renderFreeTooltip = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props}>
      <p>Access to this content requires a paid account.</p>
    </Tooltip>
  );

  const renderAddLink = (component: ReactElement) => {
    if (isAccessible) {
      return <Link to={linkGoesTo}>{component}</Link>;
    }
    return component;
  };

  const renderAddTooltip = (component: ReactElement) =>
    isAccessible ? (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        {component}
      </OverlayTrigger>
    ) : (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderFreeTooltip}>
        {component}
      </OverlayTrigger>
    );

  const renderNarratorAvatar = () => {
    if ('narrators' in info) {
      return (
        <div
          style={{
            position: 'absolute',
            color: '#74f2a4',
            paddingLeft: '10px',
            paddingRight: '10px',
            right: 5,
            bottom: 95,
            borderRadius: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {info.narrators.map((narrator, index) => (
            <NarratorAvatar
              narrator={narrator}
              key={narrator.name}
              style={{ marginLeft: -10, zIndex: 10 - index }}
            />
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classNames('card')}>
      {!isAccessible && (
        <div
          style={{
            position: 'absolute',
            color: '#74f2a4',
            background: '#280548',
            width: '35px',
            height: '35px',
            left: 10,
            top: 10,
            borderRadius: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <i className={classNames('fa-regular fa-lock')} />
        </div>
      )}
      {'narrators' in info && (
        <div
          style={{
            position: 'absolute',
            color: '#74f2a4',
            background: '#280548',
            height: '35px',
            paddingLeft: '10px',
            paddingRight: '10px',
            right: 10,
            top: 10,
            borderRadius: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <span>{info.narrators[0]?.humanReadableDuration}</span>
        </div>
      )}

      {renderNarratorAvatar()}
      {renderAddLink(
        <div
          onClick={() => {
            if (isAccessible) {
              return;
            }
            dispatch(modalActions.setInfo({ subscriptionModal: { openSubOnly: true } }));
            dispatch(modalActions.setVisibleModal('subscriptionModal'));
          }}
        >
          <div
            className="card-img-top"
            style={{ backgroundImage: `url(${imgSrc ? imgSrc.trim().replace(' ', '%20') : logo})` }}
          />
          {renderAddTooltip(
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="card-title">{title}</h5>
              </div>

              <p className="card-text color-gray-500">{description}</p>
            </div>,
          )}
        </div>,
      )}
    </div>
  );
}

CourseCard.defaultProps = {
  refer: '',
  type: 'session',
  parentType: 'upgrade',
  parentInfo: null,
  isFree: true,
};

export default CourseCard;
