import React, { useEffect, useRef } from 'react';

interface VolumeBarProps {
  volume: number;
  onVolumeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
function VolumeBar({ volume, onVolumeChange, isVisible, setIsVisible }: VolumeBarProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isVisible) {
    return null;
  }
  return (
    <div
      ref={wrapperRef}
      className="d-flex p-3 align-items-center"
      style={{
        transform: 'rotate(-90deg)',
        backgroundColor: 'white',
        border: '1px solid rgba(90, 48, 123, 0.7)',
      }}
    >
      <input
        min={0}
        max={100}
        type="range"
        value={volume}
        className="player__range-input"
        onChange={onVolumeChange}
        style={{
          background: `linear-gradient(to right, #74F2A4 0%, #20d25e ${volume}%, #dae4ed ${volume}%, #dae4ed 100%)`,
        }}
      />
    </div>
  );
}

export default VolumeBar;
