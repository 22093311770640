import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewUser } from '../interface/adminUserInterface';
import apiClient from '../../helpers/api.js';

export const createAdminUser = createAsyncThunk(
  'adminUsers/createAdminUser',
  async (user: NewUser, { rejectWithValue }) => {
    try {
      const userRole = user.userType;
      const requestBody = {
        ...user,
        roles: [userRole],
      };
      const response = await apiClient.postRequest('/api/users', requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const fetchAdminUsers = createAsyncThunk(
  'adminUsers/fetchAdminUsers',
  async (url: string) => {
    const response = await apiClient.getRequest(url);
    const pages = response?.data['hydra:view'];
    const data = {
      totalItems: response.data['hydra:totalItems'],
      users: response.data['hydra:member'],
      pagination: {
        current: pages?.['@id'],
        first: pages?.['hydra:first'],
        last: pages?.['hydra:last'],
        next: pages?.['hydra:next'],
        previous: pages?.['hydra:previous'],
      },
    };
    return data;
  },
);

export const updateAdminUser = createAsyncThunk(
  'adminUsers/updateAdminUser',
  async (user: NewUser, { rejectWithValue }) => {
    try {
      const userRole = user.userType;
      const requestBody = {
        name: user.name,
        email: user.email,
        roles: [userRole],
      };
      const response = await apiClient.putRequest(`/api/users/${user.id}`, requestBody);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);

export const deleteAdminUser = createAsyncThunk(
  'adminUsers/deleteAdminUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.deleteRequest(`/api/users/${userId}`);
      const data = { data: response.data, userId };
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data['hydra:description']);
    }
  },
);
