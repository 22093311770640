/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THUNK_STATUS } from '../../constants/index';
import { loginUser, logoutUser } from '../../helpers/api.js';
import { CurrentUserState } from '../interface/currentUserInterface';
import { FormState } from '../interface/formStatusInterface';
import {
  userLogin,
  verifyUser,
  registerNewUser,
  userOAuth,
  requestResetPassword,
  requestResetPasswordToken,
  patchCurrentUser,
  deleteCurrentUser,
} from '../thunks/currentUserThunk';

const initFormState: FormState = { status: undefined, error: undefined, message: undefined };

const initialState: CurrentUserState = {
  userId: Number(localStorage.getItem('userId')) || null,
  email: localStorage.getItem('email') || '',
  token: localStorage.getItem('token') || '',
  roles: [],
  name: '',
  teamOwner: null,
  createdAt: '',
  updatedAt: '',
  lastLogin: '',
  formattedTimeSpent: '',
  progressAward: [],
  subscriptionPaymentType: null,
  login: { status: undefined, error: undefined },
  register: { ...initFormState },
  resetPass: { ...initFormState },
  resetPassToken: { ...initFormState },
  patchUserInfo: { ...initFormState },
  deleteUserInfo: { ...initFormState },
  oauth: { status: undefined, error: undefined },
  streakLevel: [
    {
      total_minutes: 20,
      streak: {
        best: 0,
        current: 0,
      },
      level: {
        current: 0,
        total: '',
        progress: 0,
        progress_per: 0,
      },
    },
  ],
  isOnboarded: false,
  isFree: true,
  currentPlan: -1,
  isTeamMember: undefined,
  latestBillingPlan: {
    cancelled: undefined,
    productPrice: 0,
    price: 0,
    frequency: '',
    currency: '',
  },
  // streakLevel: [],
};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    userLogout: (state) => {
      logoutUser();
      window.location.href = window.location.origin;
      return state;
    },
    authenticate: (state, action: PayloadAction<boolean>) => ({
      ...state,
      authenticated: action.payload,
    }),
    resetState: (state) => ({ ...state, ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.pending, (state) => {
        state.login = { status: THUNK_STATUS.LOADING, error: undefined };
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.login = { status: THUNK_STATUS.SUCCEEDED, error: undefined };
        const {
          data: { id, email, roles },
          token,
        } = action.payload;

        setTimeout(() => {
          loginUser(email, token, id);
          if (roles.includes('ROLE_ADMIN')) {
            window.location.replace('/app-users');
          } else {
            const uuid = localStorage.getItem('uuid');
            if (uuid) {
              window.location.replace(`/checkout/${uuid}`);
            } else if (window.location.pathname === '/cancel-subscription') {
              window.location.replace('/cancel-subscription');
            } else {
              window.location.replace('/');
            }
          }
        }, 100);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.login = { status: THUNK_STATUS.REJECTED, error: action.error.message };
      })
      .addCase(verifyUser.rejected, () => {
        logoutUser();
        window.location.href = window.location.origin;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        const {
          data: {
            email,
            name,
            roles,
            media,
            own_team: ownTeam,
            formattedTimeSpent,
            createdAt,
            updatedAt,
            streakLevel,
            currentPlan,
            progressAward,
            subscriptionType,
            isOnboarded,
            isFree,
            team,
            latestBillingPlan,
          },
          token,
        } = action.payload;
        loginUser(email, token);
        state.email = email;
        state.name = name;
        state.roles = roles;
        state.token = token;
        state.teamOwner = ownTeam || null;
        state.createdAt = createdAt;
        state.updatedAt = updatedAt;
        state.streakLevel = streakLevel;
        state.currentPlan = currentPlan;
        state.progressAward = progressAward;
        state.subscriptionPaymentType = subscriptionType;
        state.isOnboarded = !!isOnboarded;
        state.formattedTimeSpent = formattedTimeSpent || '';
        state.isFree = isFree;
        state.latestBillingPlan = latestBillingPlan;
        state.isTeamMember = !!team;
        if (media) {
          state.media = media;
        }
      })
      .addCase(patchCurrentUser.pending, (state) => {
        state.patchUserInfo = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(patchCurrentUser.rejected, (state, action: any) => {
        const errorMessage = action?.payload || 'Error updating app user';
        state.patchUserInfo = {
          status: THUNK_STATUS.REJECTED,
          error: errorMessage,
          message: errorMessage,
        };
      })
      .addCase(patchCurrentUser.fulfilled, (state, action) => {
        state.patchUserInfo = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: undefined,
        };
        const { email, name, media } = action.payload;
        state.email = email;
        state.name = name;
        state.media = media;
      })
      .addCase(deleteCurrentUser.pending, (state) => {
        state.deleteUserInfo = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(deleteCurrentUser.rejected, (state, action) => {
        state.deleteUserInfo = {
          status: THUNK_STATUS.REJECTED,
          error: action.error.message,
          message: undefined,
        };
      })
      .addCase(deleteCurrentUser.fulfilled, (state) => {
        state.deleteUserInfo = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: undefined,
        };
        logoutUser();
        window.setTimeout(() => {
          window.location.href = window.location.origin;
        }, 2000);
      })
      .addCase(userOAuth.fulfilled, (state, action) => {
        state.oauth = { status: THUNK_STATUS.SUCCEEDED, error: undefined };
        const {
          data: { email, id },
          token,
        } = action.payload;

        setTimeout(() => {
          loginUser(email, token, id);
          const uuid = localStorage.getItem('uuid');
          if (uuid) {
            window.location.replace(`/checkout/${uuid}`);
          } else if (window.location.pathname === '/cancel-subscription') {
            window.location.replace('/cancel-subscription');
          } else {
            window.location.replace('/');
          }
        }, 100);
      })
      .addCase(registerNewUser.pending, (state) => {
        state.register = { status: THUNK_STATUS.LOADING, error: undefined, message: undefined };
      })
      .addCase(registerNewUser.fulfilled, (state, action) => {
        const {
          data: { id, email, name, roles },
          token,
          success,
        } = action.payload;

        state.register = { status: THUNK_STATUS.SUCCEEDED, error: undefined, message: success };

        state.userId = id;
        state.email = email;
        state.name = name;
        state.roles = roles;
        state.token = token;

        loginUser(email, token, id);
      })
      .addCase(registerNewUser.rejected, (state, action) => {
        state.register = {
          status: THUNK_STATUS.REJECTED,
          error: action.error.message,
          message: undefined,
        };
      })
      .addCase(requestResetPassword.pending, (state) => {
        state.resetPass = { status: THUNK_STATUS.LOADING, error: undefined, message: undefined };
      })
      .addCase(requestResetPassword.fulfilled, (state, action) => {
        const { message } = action.payload;

        state.resetPass = { status: THUNK_STATUS.SUCCEEDED, error: undefined, message };
      })
      .addCase(requestResetPassword.rejected, (state, action) => {
        state.resetPass = {
          status: THUNK_STATUS.REJECTED,
          error: action.error.message || 'Unkown error occured.',
          message: undefined,
        };
      })
      .addCase(requestResetPasswordToken.pending, (state) => {
        state.resetPassToken = {
          status: THUNK_STATUS.LOADING,
          error: undefined,
          message: undefined,
        };
      })
      .addCase(requestResetPasswordToken.fulfilled, (state, action) => {
        const { success } = action.payload;

        state.resetPassToken = {
          status: THUNK_STATUS.SUCCEEDED,
          error: undefined,
          message: success,
        };
      })
      .addCase(requestResetPasswordToken.rejected, (state, action) => {
        state.resetPassToken = {
          status: THUNK_STATUS.REJECTED,
          error: action.error.message || 'Unkown error occured.',
          message: undefined,
        };
      });
  },
});

export const { actions } = currentUserSlice;

export default currentUserSlice.reducer;
