import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import CourseSlider from '../../components/cards/courseSlider';
import Headline from '../../components/headline';
import SubscriptionBanner, {
  SubscriptionBannerSize,
} from '../../components/cards/subscriptionBanner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { sevenDayCoursesGet, sevenDayCoursesSearch } from '../../redux/thunks/homeThunk';
import { defaultMetatags, THUNK_STATUS } from '../../constants';
import { CourseInterface, EntityType } from '../../redux/interface/entitiesInterface';
import CourseGrid from '../../components/courseGrid';

function SevenDayCourses() {
  const dispatch = useAppDispatch();
  const {
    coursesSeven: {
      list: sevenCoursesList,
      search: { courses: sevenCoursesSearchResult, status: searchStatus },
    },
    loading: { sevenCourses: sevenCoursesState },
  } = useAppSelector((state) => state.home);
  const [searchData, setSearchData] = useState<{ key: string }>({ key: '' });

  useEffect(() => {
    dispatch(sevenDayCoursesGet());
  }, []);

  const handleChangeKeyword = (value: string) => {
    setSearchData({ key: value });
    if (value.trim()) {
      dispatch(sevenDayCoursesSearch(`key=${encodeURIComponent(value)}`));
    }
  };

  const renderMetas = () => (
    <Helmet>
      <title>Upgrades - {defaultMetatags.title}</title>
      <meta name="description" content={defaultMetatags.title} />
    </Helmet>
  );

  if (sevenCoursesState === THUNK_STATUS.LOADING) {
    return (
      <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
        {renderMetas()}
        <div className="content_decor" />
        <div className="col-12 mb-4">
          <h4 className="m-0">
            <Skeleton height={54} />
          </h4>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-3">
            <div className="row">
              <Skeleton height={36} />
            </div>
          </div>
          <div className="col-12">
            <Skeleton height={340} />
          </div>
        </div>
      </div>
    );
  }

  let bodyContent = (
    <>
      {sevenCoursesSearchResult.length === 0 && searchData.key && (
        <div className="col-12 d-flex justify-content-between align-items-center mb-3">
          <h4 className="m-0">{`No match for "${searchData.key}"`}</h4>
        </div>
      )}
      {sevenCoursesList.map((course: CourseInterface, index: number) => (
        <Fragment key={JSON.stringify(course)}>
          <CourseSlider
            headline={course.title}
            description={course.subtitle}
            items={course.sessions}
            parentInfo={course}
            parentType={EntityType.Course}
            siblingTypes={EntityType.Session}
          />
          {index === 0 && (
            <div className="row mb-4">
              <div className="col-12">
                <SubscriptionBanner size={SubscriptionBannerSize.LONG} />
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </>
  );

  if (searchStatus === 'loading') {
    bodyContent = (
      <div className="row mb-4">
        <Skeleton height={293} />
      </div>
    );
  } else if (sevenCoursesSearchResult.length > 0 && searchData.key) {
    bodyContent = sevenCoursesSearchResult && (
      <div className="row mb-4">
        <div className="col-12 mb-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center mb-3">
              <h4 className="m-0">{`Result(s) for "${searchData.key}"`}</h4>
            </div>
          </div>
        </div>
        <CourseGrid data={sevenCoursesSearchResult} siblingTypes="upgrade" />
      </div>
    );
  }

  return (
    <div className="container-fluid flex-grow-1 py-3 py-md-4 px-3 px-md-5 content">
      {renderMetas()}
      <div className="content_decor" />
      <Headline caption="Upgrades" className="mb-4" onChangeKeyword={handleChangeKeyword} />
      {bodyContent}
    </div>
  );
}

export default SevenDayCourses;
