export const secondsToMinSec = (timeSeconds: number) => {
  const time = Math.round(timeSeconds);
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return { minutes, seconds };
};

export const secondsToMinSecFormat = (timeSeconds: number) =>
  Object.values(secondsToMinSec(timeSeconds))
    .map((unit) => String(unit).padStart(2, '0'))
    .join(':');
